import React from "react";
import style from "./Header.module.scss"
import TopMenu from "../../Components/TopMenu/TopMenu";
import LogoTop from "./LogoTop/LogoTop";
import {worlds} from "../../Utils/localization";

export default class Header extends React.Component {
    changeLanguage = (lang) => {
        this.props.changeLanguage(lang)
        worlds.setLanguage(lang)
    }

    render() {
        worlds.setLanguage(this.props.currentLanguage)
        return (
          <div className={style.header}>
            <LogoTop />
            <TopMenu />
          </div>
        )
    }
}
