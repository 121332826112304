import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import style from "./ModalAuthDHCP.module.scss"
import ButtonRectangle from "../../Buttons/ButtonRectangle/ButtonRectangle";
import {worlds} from "../../../Utils/localization";
import {getOnvif} from "../../../api/state/cam/actions";
import {closeModal} from "../actions";
import TextFieldM from "../../Inputs/TextFieldM";

class ModalAuthDHCP extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            password: "",
        }
    }

    addDhcpCam = async () => {
        const res = await getOnvif(this.props.ip,this.state.name,this.state.password);
        this.setState({name: "",password: ""})
        if (res) this.props.history.push(`/addCam/${encodeURIComponent(res.streams[0].url)}/${encodeURIComponent(res.streams[1].url)}/${this.props.ip}/${this.props.mac}`);
    }

    render() {
        return (
          <div className={style.modalAuthDHCP}>
            <div className={style.content}>
              <TextFieldM
                style={{margin: "0.42vw 0", width: "100%"}}
                variant="outlined"
                value={this.state.name}
                placeholder={worlds.login}
                onChange={(e) => this.setState({name: e.target.value})}
              />
              <TextFieldM
                variant="outlined"
                style={{margin: "0.42vw 0 1.87vw", width: "100%"}}
                value={this.state.password}
                placeholder={worlds.password}
                onChange={(e) => this.setState({password: e.target.value})}
              />
            </div>
            <div className={style.controls}>
              <ButtonRectangle width="15.25rem" caption={worlds.addCamera} action={() => { this.addDhcpCam(); closeModal() }} />
              <ButtonRectangle width="7.55rem" dark caption={worlds.cancel} action={() => closeModal()} />
            </div>
          </div>
        )
    }
}

export default withRouter(connect((state) => state,null)(ModalAuthDHCP))
