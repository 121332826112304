import React from "react";
import {withRouter} from "react-router";
import style from "./ButtonPopup.module.scss"

class ButtonPopup extends React.Component {
    onClick = (e) => {
        if (this.props.href) {
            this.props.history.push(this.props.href)
            this.props.popupState.close()
        } else {
            this.props.action()
        }
    }

    render() {
        return (
          <div onClick={(e) => this.onClick(e)} className={style.buttonPopup} height={this.props.height ? this.props.height : "2.13vw"} style={{paddingLeft: this.props.paddingLeft ? this.props.paddingLeft : "0"}}>
            {this.props.icon && <div className={style.icon} style={{backgroundImage: `url(${this.props.icon})`}} />}
            <div className={style.caption}>{this.props.caption}</div>
          </div>
        )
    }
}

export default withRouter(ButtonPopup)
