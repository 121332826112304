import React from "react";
import {DragSource} from "react-dnd";
import style from "./Cam.module.scss"
import iconDragCam from "../../../../Assets/icons/pin.svg";
import PopoverPlayer from "../../../../Components/Popovers/PopoverPlayer/PopoverPlayer";
import {getCam} from "../../../../api/state/cam/actions";
import {showModalDeleteCamInPlan} from "../../../../Components/Modals/actions";
import {emitter} from "../../../../Utils/globalEmitter";

const Types = {
    ITEM: 'camEdit',
}

const itemSource = {
    beginDrag(props) {
        return props.cam
    },
    endDrag(props, monitor, component) {
        if (!monitor.didDrop()) {
            return
        }
        const item = monitor.getItem()
        const dropResult = monitor.getDropResult()
    },
}
function collect(connect, monitor) {
    return {
        connectDragSource: connect.dragSource(),
        connectDragPreview: connect.dragPreview(),
        isDragging: monitor.isDragging(),
    }
}

class Cam extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            camFull: null,
            fullName: {open: false, anchor: null},
            playerOpen: {open: false,anchor: null},
            detection: false,
        }
    }

    async componentDidMount() {
        this.setState({camFull: await getCam(this.props.cam.id) || null})
        const {connectDragPreview} = this.props;
        const img = new Image();
        img.src = iconDragCam;
        img.onload = () => connectDragPreview(img);
        emitter.addListener("camUpdate",(cam) => {
            if (this.state.camFull?.id === cam.id) {
                this.setState({camFull: {...cam}})
            }
        });
        emitter.addListener("deleteCamInPlan", (cam, plan) => {
            if (this.state.camFull?.id === cam.id && this.props.plan.id === plan.id) {
                this.setState({camFull: null})
            }
        });
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.camFull && this.state.camFull?.id !== this.props.cam?.id && this.props.cams) {
            this.setState({camFull: await getCam(this.props.cam.id) || null})
        }
        if (this.state.camFull?.detection) {
           setTimeout(() => {
               this.setState({detection: true})
               setTimeout(() => {
                   const cam = this.state.camFull
                   cam.detection = false;
                   this.setState({detection: false,camFull: cam})
               },4000)
           },1000);
        }
    }

    onClick = (el) => {
        if (el.target !== el.currentTarget || this.props.edit) {
            return
        }
        this.setState({active: true})
        const {playerOpen} = this.state;
        if (playerOpen.open) {
            return
        }
        playerOpen.open = true;
        playerOpen.anchor = el.currentTarget;
        this.setState({playerOpen})
    }

    closePlayer = () => {
        this.setState({active: false})
        const {playerOpen} = this.state;
        if (!playerOpen.open) {
            return
        }
        playerOpen.open = false;
        playerOpen.anchor = null;
        this.setState({playerOpen})
    }

    deleteCam = () => {
        showModalDeleteCamInPlan(this.state.camFull ?? this.props.cam,this.props.plan)
    }

    render() {
        const {cam} = this.props;
        const { isDragging, connectDragSource, src } = this.props
        return connectDragSource(
          <div onClick={this.onClick} style={{left: `${`${(cam.x - 1.25) * (window.innerWidth / 100)}px`}`,top: `${`${(cam.y - 1.25) * (window.innerHeight / 100)}px`}`}} className={`${style.cam} ${this.state.detection && style.camRed} ${!this.state.camFull?.online && style.camOff}`}>
            <div style={{marginTop: this.props.edit && "4vw"}} className={style.caption}>{this.state.camFull?.name}</div>
            {this.props.edit && <div className={style.deleteCam} onClick={() => this.deleteCam()} /> }
            {this.state.camFull && <PopoverPlayer position="center" open={this.state.playerOpen.open} anchor={this.state.playerOpen.anchor} cam={this.state.camFull} close={() => this.closePlayer()} time={null} />}
          </div>,
        )
    }
}

export default DragSource(Types.ITEM, itemSource, collect)(Cam)
