import {plainToClass} from "class-transformer";
import {CURRENT_NETWORK_CONFIG} from "./consts";
import Network, {Lan, Wan} from "../../models/Network";

const prevState = {
    networkConfig: {},
}

export const networkConfig = (state = prevState.networkConfig, action) => {
    switch (action.type) {
        case CURRENT_NETWORK_CONFIG:
            if (!action.obj) return state;
            // eslint-disable-next-line no-case-declarations
            const config = action.obj;
            config.lan = plainToClass(Lan,config.lan)
            config.wan = plainToClass(Wan,config.wan)
            return plainToClass(Network,{...config});
        default:
            return state;
    }
}
