import {plainToClass} from "class-transformer";
import {
    ADD_FACE,
    ADD_FACE_SEARCH,
    CLEAR_LIST,
    CLEAR_LIST_SEARCH,
    CURRENT_FACE_API_CONFIG,
    FACE_API_SEARCH,
    FACE_API_SEARCH_PROGRESS,
} from "./consts";
import FaceApi, {FaceModelOptions} from "../../models/FaceApi";

const prevState = {
    faceApi: [],
    faceApiSearchData: [],
    faceApiConfig: {},
    faceApiSearch: false,
    faceApiSearchProgress: 0,
}

export const faceApiSearchProgress = (state = prevState.faceApiSearchProgress, action) => {
    switch (action.type) {
        case FACE_API_SEARCH_PROGRESS:
            return action.obj;
        default:
            return state;
    }
}

export const faceApiSearch = (state = prevState.faceApiSearch, action) => {
    switch (action.type) {
        case FACE_API_SEARCH:
            return action.obj;
        default:
            return state;
    }
}

export const faceApiSearchData = (state = prevState.faceApiSearchData, action) => {
    switch (action.type) {
        case ADD_FACE_SEARCH:
            if (!action.obj) return state;
            state.push(action.obj.object)
            return [...state];
        case CLEAR_LIST_SEARCH:
            return [];
        default:
            return state;
    }
}

export const faceApi = (state = prevState.faceApi, action) => {
    switch (action.type) {
        case ADD_FACE:
            if (!action.obj) return state;
            state.push(action.obj.object)
            return [...state];
        case CLEAR_LIST:
            return [];
        default:
            return state;
    }
}

export const faceApiConfig = (state = prevState.faceApiConfig, action) => {
    switch (action.type) {
        case CURRENT_FACE_API_CONFIG:
            if (!action.obj) return state;
            // eslint-disable-next-line no-case-declarations
            const config = {...action.obj};
            for (const val in config) {
                if (val !== "faceModelOptions") config[val] = `${config[val]}`;
            }
            config.faceModelOptions.minConfidence = `${config.faceModelOptions.minConfidence}`;
            // eslint-disable-next-line no-case-declarations
            const newConfig = plainToClass(FaceApi,config);
            newConfig.faceModelOptions = plainToClass(FaceModelOptions,config.faceModelOptions)
            return newConfig;
        default:
            return state;
    }
}
