import {GET_SYSTEM_CONFIG} from "./consts";

const prevState = {
    systemConfig: {},
}

export const systemConfig = (state = prevState.systemConfig, action) => {
    switch (action.type) {
        case GET_SYSTEM_CONFIG:
            return action.obj;
        default:
            return state;
    }
}
