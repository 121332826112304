import React from "react";
import {store} from "../../../App";
import {action} from "../../ReduxHelper";
import {GET_SYSTEM_CONFIG} from "./consts"

let api = null;

export const initiateSystemApi = async () => {
    try {
        api = store.getState().api;
        await initiateSystem();
    } catch (e) {}
}

export const initiateSystem = async () => {
    try {
        const config = await api.emit("global.params");
        store.dispatch(action(GET_SYSTEM_CONFIG,config))
    } catch (e) {}
}
