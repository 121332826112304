import React from 'react';
import Popover from '@material-ui/core/Popover';
import PopupState, {bindPopover, bindTrigger} from 'material-ui-popup-state';
import style from "./MenuPower.module.scss"
import ButtonPopup from "../../../../Components/Buttons/ButtonPopup/ButtonPopup";
import powerIcon from "../../../../Assets/icons/power.svg"
import renewalIcon from "../../../../Assets/icons/renewal.svg"
import {worlds} from "../../../../Utils/localization";
import {powerOff, reboot} from "../../../../api/globalActions";

export default function MenuPower(props) {
    return (
      <PopupState variant="popover">
        {(popupState) => (
          <div className={style.menuPower}>
            <div className={style.button} {...bindTrigger(popupState)}>
              <div className={style.icon} />
              <div className={style.caption}>{worlds.turnOffTheDevice}</div>
              <div className={style.icon2} />
            </div>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
              transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
              PaperProps={{
                            className: style.menu,
                        }}
            >
              <ButtonPopup action={() => reboot()} icon={renewalIcon} caption={worlds.reboot} />
              <ButtonPopup action={() => powerOff()} icon={powerIcon} caption={worlds.switchOff} />
            </Popover>
          </div>
            )}
      </PopupState>
    );
}
