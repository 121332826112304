import {withStyles} from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";

export default withStyles((theme) => ({
    root: {
    },
    colorPrimary: {
        backgroundColor: "#8C939B",
        borderRadius: 1,
    },
    bar: {
        backgroundColor: '#6FCF97',
    },
}))(LinearProgress);
