import React from "react";
import {connect} from "react-redux";
import style from "./Security.module.scss";
import {worlds} from "../../Utils/localization";
import RuleTable from "./RuleTable/RuleTable";

class Security extends React.Component {
    render() {
        return (
          <div className={style.security}>
            <div className={style.header}>
              <div className={style.caption}>{worlds.security}</div>
            </div>
            <RuleTable />
          </div>
        )
    }
}

export default connect((state) => state,null)(Security)
