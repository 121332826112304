import React from 'react';
import moment from "moment-timezone";
import style from './ReactionsTable.module.scss';
import {worlds} from "../../../../Utils/localization";
import ReactionsTableItem from './ReactionsTableItem/ReactionsTableItem';

function ReactionsTable(props) {
  const sampleData = [
    { dateTime: new Date(moment().format()), ruleName: 'Событие', reaction: 'Открыть тревожный монитор' },
    { dateTime: '17.06.2020 14:24:58', ruleName: 'Событие', reaction: 'Открыть тревожный монитор' },
    { dateTime: '17.06.2020 14:24:58', ruleName: 'Событие', reaction: 'Открыть тревожный монитор' },
    { dateTime: '17.06.2020 14:24:58', ruleName: 'Событие', reaction: 'Открыть тревожный монитор' },
    { dateTime: '17.06.2020 14:24:58', ruleName: 'Событие', reaction: 'Открыть тревожный монитор' },
  ];

  return (
    <div className={style.reactionsTable}>
      <div className={style.header}>
        <div className={style.headerItem} style={{width: "13.38vw"}}>{worlds.dateTime}</div>
        <div className={style.headerItem} style={{width: "36.46vw"}}>{worlds.ruleName}</div>
        <div className={style.headerItem} style={{width: "36.46vw"}}>{worlds.reaction}</div>
        <div className={style.headerItem} style={{width: "2.5vw"}} />
      </div>
      {sampleData.length > 0
            ? sampleData.map((data,i) => <ReactionsTableItem data={data} key={i} />)
            : <div className={style.noData}>{worlds.addReactionsOnEvent}</div>}
    </div>
  );
}

export default ReactionsTable;
