import React from "react";
import {connect} from "react-redux";
import {TransformComponent, TransformWrapper} from "react-zoom-pan-pinch";
import style from "./ModalFaceApiFullImage.module.scss"
import ButtonSquare from "../../Buttons/ButtonSquare/ButtonSquare";
import iconClose from "../../../Assets/icons/cancel.svg"
import {closeModal} from "../actions";

class ModalFaceApiFullImage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            image: {
                scale: 1,
                translation: { x: 0, y: 0 },
            },
        }
    }

    render() {
        return (
          <div className={style.ModalFaceApiFullImage}>
            <div className={style.close}>
              <ButtonSquare width="2.81vw" height="2.81vw" icon={iconClose} action={() => closeModal()} />
            </div>
            <div className={style.info}>
              <div className={style.name}>{ this.props.face?.camera?.name}</div>
              <div className={style.date}>{ this.props.face.detected_at ? new Date(this.props.face.detected_at).toLocaleString().slice(0,17).replace(",","") : ""}</div>
            </div>
            <TransformWrapper pan={{paddingSize: 0}}>
              <TransformComponent>
                <img src={this.props.face.full_image} className={style.fullImage} />
              </TransformComponent>
            </TransformWrapper>
          </div>
        )
    }
}

export default connect((state) => state,null)(ModalFaceApiFullImage)
