import {CHANGE_CELLS} from "./actions";
import {electronApi} from "../../api/state/electron/actions";

const prevState = {
    cells: [],
}

export const cells = (state = prevState.cells, action) => {
    switch (action.type) {
        case CHANGE_CELLS:
            electronApi?.('set-cells',JSON.stringify(action.obj))
            localStorage.setItem("cells",JSON.stringify(action.obj))
            return [...action.obj];
        default:
            return state;
    }
}
