import {MaxLength, MinLength} from "class-validator";
import {worlds} from "../../Utils/localization";

export default class Trigger {
    @MinLength(3 , {
        message: worlds.titleTooShort,
    })
    @MaxLength(300 , {
        message: worlds.titleTooLong,
    })
    name:string="";

    events:Array<string> = []

    filter:any = {}

    reactions:Array<any> = []

    data:any= {}
}
