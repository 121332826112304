import React from "react";
import style from "./OpenVideoButton.module.scss";
import iconCamDark from "../../../../Assets/icons/camDark.svg";
import {worlds} from "../../../../Utils/localization";

export default class OpenVideoButton extends React.Component {
    onClick = () => {
        this.props.action();
    }

    render() {
        return (
          <div onClick={this.onClick} className={style.openVideoButton}>
            <div className={style.imgCam}>
              <img src={iconCamDark} width="100%" height="100%" />
            </div>
            <div className={style.caption}>{worlds.openVideoStream}</div>
          </div>
        )
    }
}
