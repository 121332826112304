import React from "react";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {validate} from "class-validator";
import moment from "moment-timezone";
import {withStyles} from "@material-ui/core";
import style from "./EditCreateReaction.module.scss";
import arrowIcon from "../../../../Assets/icons/arrow-dark.svg";
import ButtonSquare from "../../../../Components/Buttons/ButtonSquare/ButtonSquare";
import {worlds} from "../../../../Utils/localization";
import ButtonRectangle from "../../../../Components/Buttons/ButtonRectangle/ButtonRectangle";
import TextFieldM from "../../../../Components/Inputs/TextFieldM";
import AutocompleteM from "../../../../Components/Inputs/AutocompleteM";
import {
    createEditTrigger,
    createTrigger,
    deleteEditTrigger,
    updateTrigger,
} from "../../../../api/state/trigger/actions";
import Trigger from "../../../../api/models/Trigger";
import TextFieldAutoComplete from "../../../../Components/Inputs/TextFieldAutoComplete";
import SwitchM from "../../../../Components/SwitchM/SwitchM";
import iconTrash from "../../../../Assets/icons/trash.svg"
import CheckBox from "../../../../Components/CheckBox/CheckBox";

const eventsInput = [
    {name: "I-PORT 1",param: "external.gpio-input-1"},
    {name: "I-PORT 2",param: "external.gpio-input-2"},
    {name: "I-PORT 3",param: "external.gpio-input-3"},
    {name: "I-PORT 4",param: "external.gpio-input-4"},
    {name: "I-PORT 5",param: "external.gpio-input-5"},
    {name: "I-PORT 6",param: "external.gpio-input-6"},
    {name: "I-PORT 7",param: "external.gpio-input-7"},
    {name: "Motion Detection",param: "guard.detection"}]

const eventsOutput = [
    {name: "O-PORT 1",param: "external.gpio-output-1"},
    {name: "O-PORT 2",param: "external.gpio-output-2"},
    {name: "O-PORT 3",param: "external.gpio-output-3"},
    {name: "RELAY 1",param: "external.gpio-relay-1"},
    {name: "RELAY 2",param: "external.gpio-relay-2"},
    {name: "RELAY 3",param: "external.gpio-relay-3"},
    {name: "RELAY 4",param: "external.gpio-relay-4"}]

const TextAreaM = withStyles({
    root: {
        margin: "0",
        height: "6.51vw",
        width: "40.73vw",
        alignItems: "flex-start",
        '& label': {
            color: "#8C939B",
            marginTop: "-0.29vw", // -0.35rem
            fontFamily: "Inter",
            fontSize: "0.73vw",
        },
        '& label.Mui-focused': {
            color: "#EAEAEA",
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#EAEAEA',
        },
        '& .MuiOutlinedInput-root': {
            height: "100%",
            width: "100%",
            alignItems: "flex-start",
            backgroundColor: "#3C4347",
            borderRadius: 0,
            color: "#EAEAEA",
            padding: "0.88vw 0.83vw",
            '& fieldset': {
                borderColor: "transparent",
                borderSize: "0.05vw",
            },
            '&:hover fieldset': {
                borderColor: '#EAEAEA',
            },
            '&.Mui-focused fieldset': {
                borderSize: "0.05vw",
                borderColor: '#EAEAEA',
            },
        },
    },
})(TextFieldAutoComplete);

class EditCreateReaction extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            errors: null,
            lvs: false,
        }
    }

    componentDidMount() {
        this.createTrigger();
    }

    createTrigger = () => {
        if (this.props?.match?.params?.id) {
            const trigger = this.props.triggers.find((i) => i.id === this.props.match.params.id)
            if (trigger.events.includes("guard.detection")) {
                if (trigger.filter === {"payload.action": "start"}) {
                    trigger.filter = {...trigger.filter, ...{status: "disable"}}
                } else {
                    trigger.filter = {...trigger.filter, ...{status: "disable"}}
                }
                this.setState({lvs: false})
            }
            if (trigger.reactions.length < 2) {
                trigger.reactions[1] = {
                    type: "lvs.push",
                    packet: {
                        type: "alarm",
                        block: true,
                        auto_deblock_after: 60,
                    },
                    camera: {
                        id: "",
                    },
                }
            } else {
                this.setState({lvs: true})
            }
            createEditTrigger(trigger)
        } else {
            const trigger = new Trigger()
            trigger.events = [eventsInput[0].param];
            trigger.filter = {
                status: "disable",
            }
            trigger.data = ""
            trigger.reactions = [{
                type: "apiEvent.push",
                event: "switching-module",
                payload: {
                    status: false,
                    port: {name: eventsInput[0].name,status: "disable"},
                    sound: {path: "",play: "stop"},
                    date: moment().toISOString(),
                },
            },
            {
                type: "lvs.push",
                packet: {
                    type: "alarm",
                    block: true,
                    auto_deblock_after: 60,
                },
                camera: {
                    id: "",
                },
            },
            ];
            trigger.data = {triggerType: "switching"}
            createEditTrigger(trigger)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.editTrigger?.data?.triggerType !== "switching" && this.props.history.location.pathname === "/createSwitchingReaction") {
            this.createTrigger()
        }
    }

    componentWillUnmount() {
        deleteEditTrigger()
    }

    changeName = (e) => {
        const trigger = this.props.editTrigger;
        trigger.name = e.target.value;
        trigger.reactions[0].payload.name = e.target.value;
        createEditTrigger(trigger)
    }

    dropCamera = () => {
        const trigger = this.props.editTrigger;
        trigger.reactions[1].camera.id = ""
        createEditTrigger(trigger)
    }

    changeAutoDeblock = (e) => {
        const trigger = this.props.editTrigger;
        trigger.reactions[1].packet.auto_deblock_after = e.target.value;
        createEditTrigger(trigger)
    }

    changeEvent = (event) => {
        const trigger = this.props.editTrigger;
        trigger.events = [eventsInput.find((i) => i.name === event).param];
        if (trigger.events.includes("guard.detection")) {
            this.setState({lvs: false})
        }
        createEditTrigger(trigger)
    }

    lvsShow = (bool) => {
        const trigger = this.props.editTrigger;
        if (bool) {
            if (trigger.events.includes("guard.detection")) {
                this.setState({lvs: false})
            } else {
                this.setState({lvs: true})
            }
        } else {
            this.setState({lvs: false})
        }
    }

    switchEvent = (event) => {
        const trigger = this.props.editTrigger;
        trigger.filter = { status: event ? "disable" : "enable"};
        createEditTrigger(trigger)
    }

    switchReaction = (event) => {
        const trigger = this.props.editTrigger;
        trigger.reactions[0].payload.port.status = event ? "disable" : "enable";
        createEditTrigger(trigger)
    }

    changeReaction = (reaction) => {
        const trigger = this.props.editTrigger;
        trigger.reactions[0].payload.port.name = eventsOutput.find((i) => i.name === reaction).param;
        createEditTrigger(trigger)
    }

    switchSound = (event) => {
        const trigger = this.props.editTrigger;
        trigger.reactions[0].payload.sound.play = event ? "stop" : "start";
        createEditTrigger(trigger)
    }

    switchBlock = (event) => {
        const trigger = this.props.editTrigger;
        trigger.reactions[1].packet.block = !event;
        createEditTrigger(trigger)
    }

    changeSound = (sound) => {
        const trigger = this.props.editTrigger;
        trigger.reactions[0].payload.sound.path = sound
        createEditTrigger(trigger)
    }

    changeDescription = (description) => {
        const trigger = this.props.editTrigger;
        trigger.data.description = description.target.value;
        createEditTrigger(trigger)
    }

    createOrEdit = () => {
        const trigger = this.props.editTrigger;
        validate(trigger).then(async (errors) => { // errors is an array of validation errors
            if (errors.length > 0) {
                this.setState({errors});
            } else {
                trigger.reactions[1].packet.event_name = trigger.name;
                if (!this.state.lvs) {
                    trigger.reactions = [trigger.reactions[0]]
                }
                if (trigger.events.includes("guard.detection")) {
                    if (trigger.filter.status === "enable") {
                        trigger.filter = {"payload.action": "start"}
                    } else {
                        trigger.filter = {"payload.action": "stop"}
                    }
                    trigger.reactions = [trigger.reactions[0]]
                }
                if (trigger.id) {
                    updateTrigger(trigger)
                } else {
                    createTrigger(trigger)
                }
                this.props.history.goBack()
            }
        });
    }

    render() {
        const trigger = this.props.editTrigger;
        const {sounds} = this.props;
        if (!sounds.includes("None")) {
            sounds.unshift("None");
        }
        if (!trigger || this.props.editTrigger.data?.triggerType !== "switching") {
            return null;
        }
        return (
          <div className={style.editCreateRule}>
            <div className={style.header}>
              <ButtonSquare width="1.25vw" height="1.25vw" icon={arrowIcon} back />
              <div className={style.rule}>
                <div className={style.caption}>
                  {trigger.id ? worlds.edit : worlds.addRule}
                </div>
                <ButtonRectangle action={() => this.createOrEdit()} caption={trigger.id ? worlds.save : worlds.add} />
              </div>
            </div>
            <div className={style.content}>
              <label>
                {worlds.ruleName}
                <small>300</small>
              </label>
              <TextFieldM
                placeholder={worlds.name}
                value={trigger.name}
                name="name"
                onChange={this.changeName}
                errors={this.state.errors}
                variant="outlined"
                style={{width: "100%", height: "2.92vw",margin: "0"}}
              />
              <label>{worlds.inputSignals}</label>
              <div className={style.pointer}>
                <AutocompleteM
                  value={trigger.events?.[0] ? eventsInput.find((i) => i.param === trigger.events[0]).name : eventsInput[0].name}
                  onChange={(e, newValue) => {
                                           this.changeEvent(newValue);
                                       }}
                  disableClearable
                  inputValue={trigger.events?.[0] ? eventsInput.find((i) => i.param === trigger.events?.[0]).name : eventsInput[0].name}
                  options={eventsInput.map((i) => i.name)}
                  style={{width: "100%", height: "2.92vw"}}
                />
                <div className={style.switch}>
                  <SwitchM onChange={() => this.switchEvent(trigger.filter?.status === "enable")} checked={trigger.filter?.status === "enable"} />
                </div>
              </div>
              <label>{worlds.event}</label>
              <div className={style.pointer}>
                <AutocompleteM
                  value={trigger.reactions?.[0].payload.port.name ? eventsOutput.find((i) => i.param === trigger.reactions[0].payload.port.name)?.name : eventsOutput[0].name}
                  onChange={(e, newValue) => {
                                           this.changeReaction(newValue);
                                       }}
                  disableClearable
                  inputValue={trigger.reactions?.[0].payload.port.name ? eventsOutput.find((i) => i.param === trigger.reactions[0].payload.port.name)?.name : eventsOutput[0].name}
                  options={eventsOutput.map((i) => i.name)}
                  style={{width: "100%", height: "2.92vw"}}
                />
                <div className={style.switch}>
                  <SwitchM onChange={() => this.switchReaction(trigger.reactions?.[0]?.payload?.port?.status === "enable")} checked={trigger.reactions?.[0]?.payload?.port?.status === "enable"} />
                </div>
              </div>
              <label>{worlds.soundEvent}</label>
              <div className={style.pointer}>
                <AutocompleteM
                  value={trigger.reactions?.[0].payload.sound.path ? this.props.sounds.find((i) => i === trigger.reactions[0].payload.sound.path) : "None"}
                  onChange={(e, newValue) => {
                                           this.changeSound(newValue);
                                       }}
                  disableClearable
                  inputValue={trigger.reactions?.[0].payload.sound.path ? this.props.sounds.find((i) => i === trigger.reactions[0].payload.sound.path) : "None"}
                  options={sounds}
                  style={{width: "100%", height: "2.92vw"}}
                />
                <div className={style.switch}>
                  <SwitchM onChange={() => this.switchSound(trigger.reactions?.[0]?.payload?.sound?.play === "start")} checked={trigger.reactions?.[0]?.payload?.sound?.play === "start"} />
                </div>
              </div>
              <label>{worlds.description}</label>
              <TextFieldM
                value={trigger.data?.description}
                onChange={(e) => this.changeDescription(e)}
                placeholder={worlds.description}
                variant="outlined"
                style={{width: "100%",height: "2.92vw",margin: "0"}}
              />
              <label>LVS</label>
              <div className={style.pointer}>
                <CheckBox checked={this.state.lvs} onChange={() => this.lvsShow(!this.state.lvs)} />
              </div>
              {this.state.lvs && (
              <>
                <label>{worlds.camera}</label>
                <div className={style.pointer}>
                  <AutocompleteM
                    value={trigger.reactions[1]?.camera?.id ? this.props.cams[trigger.reactions[1]?.camera?.id]?.name : ""}
                    onChange={(e, newValue) => {
                                           trigger.reactions[1].camera.id = Object.values(this.props.cams).find((i) => i.name === newValue)?.id
                                       }}
                    disableClearable
                    inputValue={trigger.reactions[1]?.camera?.id ? this.props.cams[trigger.reactions[1]?.camera?.id]?.name : ""}
                    options={Object.values(this.props.cams).map((i) => i.name)}
                    style={{width: "100%", height: "2.92vw"}}
                  />
                  <div className={style.switch}>
                    <ButtonSquare icon={iconTrash} name={worlds.delete} action={() => this.dropCamera()} />
                  </div>
                </div>
                <label>{worlds.releaseSignal}</label>
                <div className={style.pointer}>
                  <TextFieldM
                    placeholder={worlds.releaseSignal}
                    value={trigger.reactions[1]?.packet?.auto_deblock_after}
                    name="signal"
                    onChange={this.changeAutoDeblock}
                    errors={this.state.errors}
                    variant="outlined"
                    style={{width: "100%", height: "2.92vw",margin: "0"}}
                  />
                  <div className={style.switch}>
                    <SwitchM onChange={() => this.switchBlock(trigger.reactions?.[1]?.packet?.block)} checked={trigger.reactions?.[1]?.packet?.block} />
                  </div>
                </div>
              </>
)}
            </div>
          </div>
        )
    }
}

export default withRouter(connect((state) => state,null)(EditCreateReaction));
