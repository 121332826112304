import React from "react";
import moment from "moment-timezone";
import style from "./Cam.module.scss";
import {accountGet} from "../../../../api/state/account/actions";

class Cam extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            account: "",
        }
    }

    async componentDidMount() {
        const accId = this.props.log?.event?.payload?.account;
        if (accId) this.setState({account: (await accountGet(accId)).username})
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.log?.event?.payload?.account !== prevProps.log?.event?.payload?.account) {
            if (this.props.log?.event?.payload?.account) this.setState({account: (await accountGet(this.props.log?.event?.payload?.account)).username})
            else this.setState({account: ""})
        }
    }

    render() {
        return (
          <div className={style.cam}>
            <div className={style.status} style={{minWidth: "10.31vw"}}>{moment(this.props.cam.created_at).format('DD.MM.YYYY HH:mm:ss')}</div>
            <div className={style.status} style={{width: "100%"}}>{this.props.cam.name}</div>
            <div className={style.status} style={{minWidth: "13.475vw", color: this.props.cam.online ? "green" : "red"}}>{this.props.cam.online ? "Online" : "Offline"}</div>
            <div className={style.status} style={{minWidth: "13.475vw", color: this.props.cam.isArchive ? "green" : "red"}}>{this.props.cam.isArchive ? "On" : "Off"}</div>
            <div className={style.status} style={{minWidth: "13.475vw", color: this.props.cam.guard ? "green" : "red"}}>{this.props.cam.guard ? "On" : "Off"}</div>
            <div className={style.status} style={{minWidth: "13.475vw", color: this.props.cam.faceApi ? "green" : "red"}}>{this.props.cam.faceApi ? "On" : "Off"}</div>
          </div>
        )
    }
}

export default Cam;
