import React from "react";
import {withRouter} from "react-router";
import {validate} from "class-validator";
import {connect} from "react-redux";
import {InputAdornment, withStyles} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import moment from "moment-timezone";
import style from "./EditCreateReaction.module.scss"
import ButtonSquare from "../../../../Components/Buttons/ButtonSquare/ButtonSquare";
import arrowIcon from "../../../../Assets/icons/arrow-dark.svg"
import {worlds} from "../../../../Utils/localization";
import ButtonRectangle from "../../../../Components/Buttons/ButtonRectangle/ButtonRectangle";
import TextFieldM from "../../../../Components/Inputs/TextFieldM";
import AutocompleteM from "../../../../Components/Inputs/AutocompleteM";
import TextFieldAutoComplete from "../../../../Components/Inputs/TextFieldAutoComplete";
import RadioButton from "../../../../Components/Buttons/RadioButton/RadioButton";
import iconWatch from "../../../../Assets/icons/watch.svg";
import WeekDayPicker from "../../../../Components/WeekDayPicker/WeekDayPicker";
import PeriodPicker from "./PeriodPicker/PeriodPicker";
import {createEditTrigger, deleteEditTrigger} from "../../../../api/state/trigger/actions";
import Trigger from "../../../../api/models/Trigger";

const events = ["event1", "event2", "event3"]
const cameras = ["cam1", "cam2"]
const screens = ["screen1", "screen2"]

const TextAreaM = withStyles({
    root: {
        margin: "0",
        height: "6.51vw",
        width: "40.73vw",
        alignItems: "flex-start",
        '& label': {
            color: "#8C939B",
            marginTop: "-0.29vw", // -0.35rem
            fontFamily: "Inter",
            fontSize: "0.73vw",
        },
        '& label.Mui-focused': {
            color: "#EAEAEA",
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#EAEAEA',
        },
        '& .MuiOutlinedInput-root': {
            height: "100%",
            width: "100%",
            alignItems: "flex-start",
            backgroundColor: "#3C4347",
            borderRadius: 0,
            color: "#EAEAEA",
            padding: "0.88vw 0.83vw",
            '& fieldset': {
                borderColor: "transparent",
                borderSize: "0.05vw",
            },
            '&:hover fieldset': {
                borderColor: '#EAEAEA',
            },
            '&.Mui-focused fieldset': {
                borderSize: "0.05vw",
                borderColor: '#EAEAEA',
            },
        },
    },
})(TextFieldAutoComplete);

const FormControlLabelM = withStyles({
    root: {
        margin: "0 0 0 1.25vw",
        '& .MuiFormControlLabel-label': {
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: "0.94vw",
            color: "#8C939B",
        },
        '& .Mui-checked + .MuiFormControlLabel-label': {
            color: "#EAEAEA",
        },
    },
})(FormControlLabel)

class EditCreateReaction extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: null,
            event: worlds.choose,
            currentReaction: "screenshot",
            camera: worlds.choose,
            screen: worlds.choose,
            showDate: false,
        }
    }

    createTrigger = () => {
        if (this.props.match.params.id) {
            createEditTrigger(this.props.triggers.find((i) => i.id === this.props.match.params.id))
        } else {
            const trigger = new Trigger()
            const filter = {
                $and: [{"moment.time": {$gte: `${new Date(moment().format()).getHours()}${new Date(moment().format()).getMinutes()}`,$lte: `${new Date(moment().format()).getHours()}${new Date(moment().format()).getMinutes()}`}}],
                $or: [{"moment.dow": 1},{"moment.dow": 2},{"moment.dow": 3},{"moment.dow": 4},{"moment.dow": 5}],
            }
            trigger.events = ["cron.minutely"]
            trigger.reactions = [{guard: false,type: "guard.set"}]
            trigger.filter = filter;
            trigger.data = {triggerType: "reactions"}
            createEditTrigger(trigger)
        }
    }

    componentDidMount() {
        this.createTrigger()
    }

    componentWillUnmount() {
        deleteEditTrigger()
    }

    checkInErrors = () => {
        this.setState({errors: null})
        validate(this.props.editTrigger).then(async (errors) => { // errors is an array of validation errors
            if (errors.length > 0) {
                console.log(errors)
                this.setState({errors});
            } else {
                console.log("asd")
            }
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.editTrigger?.data?.triggerType !== "reactions" && this.props.history.location.pathname.includes("createReaction")) {
            this.createTrigger()
        }
    }

    changeDays = (day) => {
        day = parseInt(day, 10);
        const trigger = this.props.editTrigger;
        if (trigger.filter?.$or.find((i) => i["moment.dow"] === day)) {
            trigger.filter.$or = trigger.filter?.$or.filter((i) => i["moment.dow"] !== day);
        } else if (trigger.filter.$or) {
            trigger.filter.$or.push({"moment.dow": day})
        }
        createEditTrigger(trigger)
    }

    changeName = (e) => {
        const trigger = this.props.editTrigger;
        trigger.name = e.target.value;
        createEditTrigger(trigger)
    }

    changePeriod = (period) => {
        const trigger = this.props.editTrigger;
        trigger.filter.$and = [{"moment.time": {$gte: `${period.start.hours}${period.start.minutes}`,$lte: `${period.end.hours}${period.end.minutes}`}}]
        createEditTrigger(trigger)
    }

    showDate = () => {
        this.setState({showDate: !this.state.showDate});
    }

    render() {
        const trigger = this.props.editTrigger;
        if (!trigger || this.props.editTrigger.data?.triggerType !== "reactions") {
            return null;
        }
        let time1; let time2 = "00:00"
        if (trigger.filter?.$and) {
             time1 = `${(`0${trigger.filter.$and?.[0]?.["moment.time"]?.$gte.slice(0,2)}`).slice(-2)}:${trigger.filter.$and?.[0]?.["moment.time"]?.$gte.slice(-2)}`
             time2 = `${(`0${trigger.filter.$and?.[0]?.["moment.time"]?.$lte.slice(0,2)}`).slice(-2)}:${trigger.filter.$and?.[0]?.["moment.time"]?.$lte.slice(-2)}`
        }
        const days = trigger.filter?.$or?.map((item) => item['moment.dow']) ?? [];
        return (
          <div className={style.editCreateReaction} onClick={() => this.state.showDate && this.setState({showDate: false})}>
            <div className={style.header}>
              <ButtonSquare width="1.25vw" height="1.25vw" icon={arrowIcon} back />
              <div className={style.caption}>{worlds.addReaction}</div>
              <div className={style.rightContent}>
                <ButtonRectangle action={this.checkInErrors} caption={worlds.save} />
              </div>
            </div>
            <div className={style.content}>
              <div className={style.leftContent}>
                <div className={style.head}>{worlds.chooseReaction}</div>
                <RadioGroup style={{width: "100%", display: "flex",flexDirection: "column"}} defaultValue={this.state.currentReaction} onChange={(e) => this.setState({currentReaction: e.target.value})} aria-label="reaction" name="customized-radios">
                  {/* <FormControlLabelM value="email" control={<RadioButton />} label={worlds.sendByEmail} inputProps={{color:"white"}}/> */}
                  <FormControlLabelM value="screenshot" control={<RadioButton />} label={worlds.createScreenshot} />
                  {/* <FormControlLabelM value="screen" control={<RadioButton />} label={worlds.openScreen} /> */}
                  {/* <FormControlLabelM value="monitor" control={<RadioButton />} label={worlds.openAlarmMonitor} /> */}
                </RadioGroup>
              </div>
              <div className={style.form}>
                <label>
                  {worlds.ruleName}
                  <span>*</span>
                  <small>300</small>
                </label>
                <TextFieldM
                  placeholder={worlds.name}
                  errors={this.state.errors}
                  name="name"
                  value={trigger.name}
                  onChange={this.changeName}
                  variant="outlined"
                  style={{width: "40.73vw", height: "2.92vw",margin: "0"}}
                />
                {(this.state.currentReaction === "email" || this.state.currentReaction === "monitor")
                        && (
                        <div>
                          <label>
                            {worlds.events}
                            <span>*</span>
                          </label>
                          <AutocompleteM
                            value={this.state.event}
                            onChange={(e, newValue) => {
                                               this.setState({event: newValue})
                                           }}
                            disableClearable
                            inputValue={this.state.event}
                            options={events}
                            style={{width: "40.73vw", height: "2.92vw"}}
                          />
                        </div>
)}
                {(this.state.currentReaction === "email")
                        && (
                        <div>
                          <label>
                            Email
                            <span>*</span>
                          </label>
                          <TextFieldM
                            placeholder="Email"
                            variant="outlined"
                            style={{width: "40.73vw", height: "2.92vw", margin: "0"}}
                          />
                        </div>
)}
                {(this.state.currentReaction === "screen")
                        && (
                        <div>
                          <label>
                            {worlds.screen}
                            <span>*</span>
                          </label>
                          <AutocompleteM
                            value={this.state.screen}
                            onChange={(e, newValue) => {
                                               this.setState({screen: newValue})
                                           }}
                            disableClearable
                            inputValue={this.state.screen}
                            options={screens}
                            style={{width: "40.73vw", height: "2.92vw"}}
                          />
                        </div>
)}
                <label>{worlds.camera}</label>
                <AutocompleteM
                  value={this.state.camera}
                  onChange={(e,newValue) => {
                                           this.setState({camera: newValue})
                                       }}
                  disableClearable
                  inputValue={this.state.camera}
                  options={cameras}
                  style={{width: "40.73vw",height: "2.92vw"}}
                />
                <div style={{position: "relative"}}>
                  {this.state.showDate && <PeriodPicker period={this.changePeriod} bottom={this.state.currentReaction === "screenshot" && true} />}
                  <label>{worlds.period}</label>
                  <TextFieldM
                    placeholder={worlds.choose}
                    variant="outlined"
                    disableKeyboard
                    value={`${time1}-${time2}`}
                    style={{width: "40.73vw", height: "2.92vw",margin: "0 0 .42vw 0"}}
                    InputProps={{
                                            endAdornment: (
                                              <InputAdornment position="end" onClick={() => this.showDate()}>
                                                <img className={style.iconWatch} src={iconWatch} />
                                              </InputAdornment>
                                            ),
                                        }}
                  />
                </div>
                <WeekDayPicker caption={worlds.daysOfWeek} days={days} action={this.changeDays} />
                <label>{worlds.description}</label>
                <TextAreaM variant="outlined" multiline rowsMin={3} rows={7} rowsMax={7} placeholder={worlds.description} />
              </div>
            </div>
          </div>
        )
    }
}

export default withRouter(connect((state) => state,null)(EditCreateReaction))
