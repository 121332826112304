import React from "react";
import {InputAdornment} from "@material-ui/core";
import {connect} from 'react-redux';
import style from "./Tree.module.scss"
import TaxonomyCam from "./TaxonomyCam/TaxonomyCam";
import TextFieldM from "../Inputs/TextFieldM";
import {worlds} from "../../Utils/localization";
import SearchIcon from '../../Assets/icons/search.svg';
import CancelIcon from '../../Assets/icons/cancel.svg';
import ButtonAddCam from "../Buttons/ButtonAddCam/ButtonAddCam";
import Folder from "./Folder/Folder";
import {initiateApi, initiateTaxonomy} from "../../api/state/taxonomy/actions";
import {emitter} from "../../Utils/globalEmitter";

class Tree extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: "",
            isShowDelete: {show: false,cam: {}},
            preloader: false,
        }
    }

    async componentDidMount() {
        // emitter.addListener("camUpdate",async (camera) => {
        //     initiateTaxonomy()
        // })
        //
        // emitter.addListener("camCreate",async (camera) => {
        //     initiateTaxonomy()
        // })
        //
        // emitter.addListener("camDelete",async (camera) => {
        //     initiateTaxonomy()
        // })
        initiateApi()
        initiateTaxonomy()
    }

    loading = (bool) => {
        this.setState({preloader: bool})
    }

    showDelete = (cam) => {
        this.setState({isShowDelete: {show: !this.state.isShowDelete.show,cam}})
    }

    deleteCam = (cam) => {
        if (cam.taxonomies.length > 1) {
            cam.taxonomies.splice(cam.taxonomies.indexOf(cam.parent),1);
            this.props.editCamTaxonomy(cam,true)
        } else {
            this.props.deleteCam(cam);
        }
    }

    load=() => {
        this.setState({load: !this.state.load});
    }

    render() {
        let camsLength = 0;
        for (const key in this.props.cams) {
            if (this.props.cams[key].parents.length > 0) {
                camsLength += this.props.cams[key].parents.length;
            } else {
                camsLength++;
            }
        }
        if (camsLength > 0)camsLength -= 1;
        return (
          <div className={style.taxonomy}>
            {/* <Preloader show={this.state.preloader} position={"absolute"}/> */}
            <div className={style.searchInput}>
              <TextFieldM
                type="text"
                variant="outlined"
                style={{height: "2.18vw"}}
                value={this.state.searchText}
                placeholder={worlds.searchByCameras}
                onChange={(e) => this.setState({searchText: e.target.value})}
                InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <img style={{width: "0.94vw"}} src={SearchIcon} />
                              </InputAdornment>
                        ),
                            endAdornment: (
                                this.state.searchText.length > 0 && (
                                <InputAdornment position="end" onClick={() => this.setState({searchText: ""})}>
                                  <img className={style.cancelSearch} src={CancelIcon} />
                                </InputAdornment>
)
                            ),
                        }}
              />
            </div>
            {this.state.searchText.length === 0
                && (
                <>
                  <span className={style.totalCameras}>{`${worlds.totalCameras}: ${camsLength > 0 ? camsLength + 1 : 0}`}</span>
                  <div className={style.content}>
                    {this.props.taxonomies.map((item,i) => {
                                if (item.hasOwnProperty("isArchive")) return <TaxonomyCam currentAccount={this.props.currentAccount} parent={null} showDelete={this.showDelete} plan={this.props.plan} item={item} key={i} />
                                return <Folder currentAccount={this.props.currentAccount} showDelete={this.showDelete} plan={this.props.plan} item={item} key={i} />
                        })}
                  </div>
                </>
)}
            {this.state.searchText.length > 0
                    && (
                    <div className={style.searchContent}>
                      {Object.values(this.props.cams).filter((i) => i.name.toUpperCase().includes(this.state.searchText.toUpperCase())).map((item,i) => <TaxonomyCam currentAccount={this.props.currentAccount} search showDelete={this.showDelete} plan={this.props.plan} key={i} item={item} />)}
                    </div>
)}

            {this.props.currentAccount?.username !== "user" && <ButtonAddCam href="/addCam" caption={worlds.addCamera} />}
          </div>
        )
    }
}

export default connect((state) => state,null)(Tree)
