import React, {useEffect, useState} from 'react';

import {InputAdornment} from "@material-ui/core";
import {connect} from "react-redux";
import moment from "moment-timezone";
import styles from './FaceDetectionForm.module.scss';
import TextFieldM from "../../../Components/Inputs/TextFieldM";
import {worlds} from "../../../Utils/localization";
import iconCalendar from "../../../Assets/icons/calendar.svg";
import iconImage from "../../../Assets/icons/image.svg"
import DateTimePicker from "../../../Components/DateTimePicker/DateTimePicker";
import ButtonRectangle from "../../../Components/Buttons/ButtonRectangle/ButtonRectangle";
import CancelIcon from "../../../Assets/icons/cancel.svg";
import {
    searchFaceDetectionsClear,
    searchFaceDetectionsStart,
    searchFaceDetectionsStop,
} from "../../../api/state/faceApi/actions";
import {searchCamsIdByName} from "../../../api/state/cam/actions";
import {showModalFileManager} from "../../../Components/Modals/actions";
import {storageGet} from "../../../api/state/fileManager/actions";
import BorderLinearProgress from "../../../Components/BorderLinearProgress/BorderLinearProgress";

function FaceDetectionForm(props) {
    const [image,setImage] = useState(null)
    const [showDateTime,setShowDateTime] = useState({show: false,index: 0})
    const [isReset,setIsReset] = useState(false)
    const [dateRange,setDateRange] = useState([new Date(moment().format()),new Date(moment().format())])
    const [searchText,setSearchText] = useState("")
    const [isSearch,setIsSearch] = useState(null)
    const [camsIds,setCamsIds] = useState([]);
    const [enableSearchButton,setEnableSearchButton] = useState(true);

    useEffect(() => {
        if (!enableSearchButton && !props.faceApiSearch) {
            setEnableSearchButton(true);
        }
    })
    const fileSelector = () => {
        showModalFileManager(false,async (item) => {
            const url = await storageGet(item.filepath);
            setImage((prevState) => {
                prevState = {name: item.filename, image: url}
                return {...prevState}
            })
        });
    }

    const reset = (bool) => {
        setIsReset(bool)
    }

    const showAndCloseDateTimeIndex = (index) => {
            setShowDateTime((prevState) => {
                prevState.show = !showDateTime.show
                prevState.index = index;
                return {...prevState}
            })
    }

    const onChangeDate = (date) => {
        setDateRange((prevState) => {
            prevState[showDateTime.index] = new Date(date);
            return {...prevState}
        })
        if ((new Date(Date.parse(dateRange[1])) < new Date(Date.parse(date)) && showDateTime.index === 0) || (new Date(Date.parse(dateRange[0])) > new Date(Date.parse(date)) && showDateTime.index === 1)) {
            setDateRange((prevState) => {
                prevState[0] = new Date(new Date(Date.parse(dateRange[1])).setMinutes(new Date(Date.parse(dateRange[1])).getMinutes() - 1))
                return {...prevState}
            })
        }
    }

    const startStopSearch = async () => {
        const date = [moment(dateRange[0]).format(),moment(dateRange[1]).format()]
        if (props.faceApiSearch) {
            setEnableSearchButton(false);
            await searchFaceDetectionsStop(isSearch);
            setIsSearch(null)
        } else {
            const result = await searchFaceDetectionsStart(date,camsIds,image)
            if (result?.process_id) setIsSearch(result.process_id)
        }
    }

    const searchCams = (e) => {
        setCamsIds([...searchCamsIdByName(e.target.value)]);
        setSearchText(e.target.value);
    }

    const clear = async () => {
        setSearchText("");
        setCamsIds([]);
        setImage(null);
        setDateRange([new Date(moment().format()),new Date(moment().format())])
        await searchFaceDetectionsClear();
        props.clear();
    }
    return (
      <div className={styles.faceDetectionForm}>
        {showDateTime.show
            && (
            <div style={{right: showDateTime.index === 1 && "12.6vw"}} className={styles.dateTime}>
              <div className={styles.closeDateTime} onClick={() => showAndCloseDateTimeIndex(showDateTime.index)} />
              <DateTimePicker reset={reset} isReset={isReset} dateTime={(date) => onChangeDate(date)} />
            </div>
)}

        <TextFieldM
          placeholder={worlds.searchByCameraName}
          variant="outlined"
          style={{width: "53.17vw",margin: "0", height: "2.5vw"}}
          onChange={searchCams}
          value={searchText}
          InputProps={{
                            startAdornment: (
                               image && (
                               <InputAdornment position="start">
                                 <div className={styles.imageSearch}>
                                   <img className={styles.imageSearchView} src={`data:image/jpeg;base64,${image.image}`} />
                                   <div className={styles.imageSearchName}>{image.name}</div>
                                   <img className={styles.icon} src={CancelIcon} onClick={() => setImage(null)} />
                                 </div>
                               </InputAdornment>
)
                            ),
                            endAdornment: (
                              <>
                                {!props.faceApiSearch
                                        ? (
                                          <>
                                            {searchText.length > 0
                                                && (
                                                <InputAdornment position="start" onClick={() => { setSearchText("") }}>
                                                  <img className={styles.icon} src={CancelIcon} />
                                                </InputAdornment>
)}
                                            {props.currentAccount.username !== "user" && (
                                            <InputAdornment position="start" onClick={() => fileSelector()}>
                                              <img className={styles.icon} src={iconImage} />
                                            </InputAdornment>
)}
                                          </>
)
                                        : (
                                          <InputAdornment position="end">
                                            <div className={styles.searchProgressText}>{`${props.faceApiSearchProgress}%`}</div>
                                          </InputAdornment>
)}
                              </>
                            ),
                        }}
        />
        <ButtonRectangle style={{margin: "0 2.5vw 0 0"}} dark={!enableSearchButton} action={() => enableSearchButton && startStopSearch()} caption={props.faceApiSearch ? worlds.cancel : worlds.search} width="6.6vw" height="2.4vw" />
        <div className={styles.searchProgress}>
          {props.faceApiSearch && <BorderLinearProgress variant="determinate" value={props.faceApiSearchProgress} />}
        </div>
        <div className={styles.date}>
          <div className={styles.label}>{`${worlds.period}:`}</div>
          <TextFieldM
            variant="outlined"
            style={{width: "11.45vw",margin: "0 0.83vw",height: "2.5vw"}}
            value={moment(dateRange[0]).format('DD.MM.YYYY HH:mm ')}
            disableKeyboard
            InputProps={{
                        endAdornment: (
                          <InputAdornment position="end" onClick={() => showAndCloseDateTimeIndex(0)}>
                            <img className={styles.iconCalendar} src={iconCalendar} />
                          </InputAdornment>
                        ),
                    }}
          />
          <div className={styles.label} style={{color: "#3C4347"}}>—</div>
          <TextFieldM
            variant="outlined"
            style={{width: "11.45vw",margin: "0 0.83vw",height: "2.5vw"}}
            value={moment(dateRange[1]).format('DD.MM.YYYY HH:mm ')}
            disableKeyboard
            InputProps={{
                        endAdornment: (
                          <InputAdornment position="end" onClick={() => showAndCloseDateTimeIndex(1)}>
                            <img className={styles.iconCalendar} src={iconCalendar} />
                          </InputAdornment>
                        ),
                    }}
          />
        </div>
        <ButtonRectangle action={clear} caption={worlds.reset} dark width="6.6vw" height="2.4vw" />
      </div>
    );
}

export default connect((state) => state,null)(FaceDetectionForm);
