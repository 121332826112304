import React from "react";
import {connect} from "react-redux";
import style from "./NTP.module.scss";
import HeaderSetup from "../HeaderSetup/HeaderSetup";
import {worlds} from "../../../Utils/localization";
import TextFieldM from "../../../Components/Inputs/TextFieldM";
import ButtonRectangle from "../../../Components/Buttons/ButtonRectangle/ButtonRectangle";
import {editNtpConfig} from "../../../api/state/ntp/actions";
import {saveNetworkConfig} from "../../../api/state/network/actions";

class NTP extends React.Component {
    changeConfig = (e) => {
        const config = this.props.ntpConfig;
        config[0] = e.target.value;
        editNtpConfig(config);
    }

    saveConfig = () => {
        const config = this.props.ntpConfig;
        saveNetworkConfig(config)
    }

    render() {
        return (
          <div className={style.ntp}>
            <HeaderSetup caption={worlds.ntpSetup} />
            <div className={style.content}>
              <label>{worlds.serverAddress}</label>
              <TextFieldM
                name="serverAddress"
                value={this.props.ntpConfig?.[0]}
                onChange={this.changeConfig}
                variant="outlined"
                style={{width: "100%", height: "2.92vw", margin: "0"}}
              />
              <div className={style.button}>
                <ButtonRectangle action={this.saveConfig} caption={worlds.save} height="2.92vw" width="10.31vw" />
              </div>
            </div>
          </div>
        )
    }
}

export default connect((state) => state,null)(NTP);
