import {store} from "../../../App";
import {action} from "../../ReduxHelper";
import {
    CURRENT_TRIGGER_CREATE,
    EDIT_TRIGGER_CREATE,
    EDIT_TRIGGER_DELETE,
    REACTIONS_LIST,
    TRIGGER_CREATE,
    TRIGGER_DELETE,
    TRIGGER_LIST,
    TRIGGER_UPDATE,
} from "./consts"
import TriggerDao from "./triggerDao";
import {worlds} from "../../../Utils/localization";

let api = null;
let dao = null;

export const initiateApiTrigger = () => {
    api = store.getState().api;
    dao = new TriggerDao(api);
}

export const initiateTriggers = async () => {
    store.dispatch(action(TRIGGER_LIST,await dao.list()))
    await reactionsList();
}

export const reactionsList = async () => {
    try {
        const reactions = await api.emit("trigger.reactions", {});
        store.dispatch(action(REACTIONS_LIST, await reactions.reactions))
    } catch (e) {}
}

export const createTrigger = async (trigger) => {
    store.dispatch(action(TRIGGER_CREATE, await dao.create(trigger)))
}

export const createCurrentTrigger = async (trigger) => {
    store.dispatch(action(CURRENT_TRIGGER_CREATE,trigger))
}

export const createEditTrigger = async (trigger) => {
    store.dispatch(action(EDIT_TRIGGER_CREATE,trigger))
}

export const deleteEditTrigger = async () => {
    store.dispatch(action(EDIT_TRIGGER_DELETE,{}))
}

export const updateTrigger = async (trigger) => {
    store.dispatch(action(TRIGGER_UPDATE,await dao.update(trigger)))
}

export const deleteTrigger = async (trigger) => {
    try {
        store.dispatch(action(TRIGGER_DELETE, await dao.delete(trigger)))
        store.getState().snackbar("success",{header: worlds.ruleDeletedSuccessfully,description: ""});
    } catch (e) {}
}
