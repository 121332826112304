import React from "react";
import PopupState, {bindPopover, bindTrigger} from "material-ui-popup-state";
import Popover from "@material-ui/core/Popover";
import style from "./MenuDrive.module.scss";
import ButtonPopup from "../../../../Components/Buttons/ButtonPopup/ButtonPopup";
import erase from "../../../../Assets/icons/clearScreenLight.svg";
import {worlds} from "../../../../Utils/localization";
import iconPlus from "../../../../Assets/icons/plus.svg"
import iconMinus from "../../../../Assets/icons/minusSmall.svg"
import {showModalDiskUnmount} from "../../../../Components/Modals/actions";

class MenuDrive extends React.Component {
    render() {
        return (
          <PopupState variant="popover">
            {(popupState) => (
              <div className={style.menuDrive}>
                <div className={style.button} {...bindTrigger(popupState)} />
                <Popover
                  {...bindPopover(popupState)}
                  onClick={() => popupState.close()}
                  anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                  transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                  PaperProps={{
                                className: style.menu,
                            }}
                >
                  <ButtonPopup popupState={popupState} icon={erase} caption={worlds.formatDrive} />
                  {this.props.isMount
                                ? <ButtonPopup action={() => showModalDiskUnmount(this.props.unmount)} popupState={popupState} icon={iconMinus} caption={worlds.unmount} />
                                : <ButtonPopup action={() => this.props.mount()} popupState={popupState} icon={iconPlus} caption={worlds.mount} />}
                </Popover>
              </div>
                )}
          </PopupState>
        )
    }
}

export default MenuDrive;
