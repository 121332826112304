import React, {
    useCallback, useLayoutEffect, useRef, useState, useEffect, useReducer,
} from "react";
import {connect} from "react-redux";
import {parseInt} from "lodash";
import style from "./Player.module.scss"
import {
    camTakeScreenShot,
    getArchive,
    getHistoryDetection,
    getPlayer,
    getPolygon,
    playArchive,
} from "../../api/state/cam/actions";
import Mse from "./mse"
import Preloader from "../Preloader/Preloader";
import {getDate, getTime} from "../Player/actions";
import ButtonSquare from "../Buttons/ButtonSquare/ButtonSquare";
import iconCancel from "../../Assets/icons/cancel.svg";
import TimeLine from "./TimeLine/TimeLine";
import iconCam from "../../Assets/icons/camLight.svg";
import {worlds} from "../../Utils/localization";
import iconLog from "../../Assets/icons/screensPatterns.png";
import iconScreenShot from "../../Assets/icons/screenshot.svg";
import iconToMoment from "../../Assets/icons/moment.svg";
import {showModalDateTimePicker, showModalDownloadFile, showModalFileManager} from "../Modals/actions";
import iconDownload from "../../Assets/icons/download.svg";
import iconFullScreen from "../../Assets/icons/fullScreen.svg";
import {emitter} from "../../Utils/globalEmitter";
import {deleteGuardCamView} from "../../api/state/guard/actions";

const Player = (props) => {
    const parent = useRef();
    const [cam,setCam] = useState(() => props.cam);
    const [preloader,setPreloader] = useState(true)
    const [showPolygon,setShowPolygon] = useState(false);
    const [isLive,setIsLive] = useState(true);
    const [polygon,setPolygon] = useState("");
    const [isFull, setIsFull] = useState(false);
    const [currentTime,setCurrentTime] = useState(new Date(Date.now()));
    const [mse, setMse] = useState(null);
    const [archiveData,setArchiveData] = useState([]);
    const [guardData,setGuardData] = useState([]);
    const [movePlayer,setMovePlayer] = useState(false);
    const [timeoutMove,setTimeOutMove] = useState(null);

    if (!props?.cam?.player?.url && !cam?.player?.secondary?.url) {
        return null
    }

    useEffect(() => {
        const mseInit = new Mse(cam?.player?.secondary?.url || props?.cam?.player?.url, document.getElementById(props.videoKey));
        setMse(mseInit)
        return () => {
            mseInit.stop()
            props.cam && deleteGuardCamView(props.cam)
        }
    },[])

    useEffect(() => {
            setPreloader(preloader)
    },[preloader])

    useEffect(() => {
        if (mse) {
            init();
            fillTimeLine()
            loadGlobalListeners();
            addListeners(mse.video)
        }
    },[mse])

    const loadGlobalListeners = () => {
        document.addEventListener("fullscreenchange",async (el) => {
            if (!document.fullscreenElement) {
                if (isLive && isFull) {
                    await start(cam?.player?.secondary?.url || cam?.player?.url);
                }
                setIsFull(false)
            }
        })

        emitter.addListener("server_connected",() => {
            try {
                mse?.video?.play()
            } catch (e) {}
        })

        emitter.addListener("server_disconnect",() => {
            try {
                mse?.video?.pause()
            } catch (e) {}
            // destroy()
        })

        emitter.addListener("camUpdate",async (camera) => {
            if (cam?.id === camera.id && !camera.guardEvent) {
                camera.player = await getPlayer(camera.id)
                setCam(camera)
                try {
                    if (isLive && (cam?.player?.url !== camera?.player?.url || cam?.player?.secondary?.url !== camera?.player?.secondary?.url)) {
                        await runLive()
                    }
                } catch (e) {}
                const p = await getPolygon(camera.id);
                setPolygon((prevState) => {
                    prevState = p
                    return prevState;
                });
            }
        })

        emitter.addListener("camDelete",async (camera) => {
            if (cam?.id === camera.id) {
                destroy()
                await onClose();
            }
        })

        emitter.addListener("playAllArchive",async (time) => {
            try {
                const timePlay = new Date(Date.parse(time));
                await runArchive({hour: timePlay.getHours(), minute: timePlay.getMinutes()});
            } catch (e) {
                await runLive()
            }
        })
    }

    const init = async () => {
        if (cam?.player.url || cam?.player.secondary.url) {
            mse.onSeek = onSeek;
            mse?.start()
        }

        const p = await getPolygon(props.cam.id);
        setPolygon((prevState) => {
            prevState = p
            return prevState;
        });
    }

    const fillTimeLine = async () => {
        if (props.cam.isArchive) {
            const archive = (await getArchive(props.cam))?.summary;
            setArchiveData((prevState) => {
                prevState = archive
                return prevState;
            })
        }

        const gd = (await getHistoryDetection(props.cam) || []);
        setGuardData(gd)
    }

    const destroy = () => {
        mse?.stop();
        props.cam && deleteGuardCamView(props.cam)
    }

    const start = async (url,isArchive) => {
        if (url && cam) {
            setIsLive(!isArchive)
            mse.url = url
            await mse?.stop();
            await mse?.start();
        } else {
            // eslint-disable-next-line no-throw-literal
            throw "error url"
        }
    }

    const addListeners = (video) => {
        try {
        video.addEventListener("playing",playing);
        video.addEventListener("loadstart",loadstart)
        video.addEventListener("loadeddata",loadeddata)
        video.addEventListener("ended",ended)
        video.addEventListener("timeupdate",onSeek)
        } catch (e) {}
    }

    const removeListeners = (video) => {
        try {
        video.removeEventListener("playing", playing);
        video.removeEventListener("loadstart", loadstart)
        video.removeEventListener("loadeddata",loadeddata)
        video.removeEventListener("ended", ended)
        video.removeEventListener("timeupdate",onSeek)
        } catch (e) {}
    }

    const loadeddata = () => {
        setPreloader(false);
    }

    const playing = () => {
        setPreloader(false);
    }

    const loadstart = () => {
        setPreloader(true);
    }

    const ended = async () => {
        setPreloader(true);
    }

    const onError = async () => {
        props.snackbar('warning',{header: worlds.error,description: props.cam.name})
    }

    const onSeek = (e) => {
        setCurrentTime((prevState) => {
            const date = new Date(prevState)
            if (date.getSeconds() === 59) {
                setPreloader(false)
                fillTimeLine();
            }
            return new Date(date.getTime() + 250);
        });
    }

    const onClose = async () => {
        await destroy()
        try {
            props.close();
        } catch (e) {}
    }

    const runLive = async () => {
        try {
            await start(isFull ? cam.player.url : cam.player.secondary.url || cam.player.url)
        } catch (e) {
        }
        setPreloader(true)
        setCurrentTime((prevState) => new Date(Date.now()));
    }

    const runArchive = async (time) => {
        if (!cam) {
            return
        }
        if (time.checkAll) {
            emitter.emit("playAllArchive",time.date)
            return
        }
        setPreloader(true)
        let url = ""
        if (!time.hasOwnProperty("date")) {
            const newTime = new Date(currentTime);
            newTime.setHours(time.hour);
            newTime.setMinutes(time.minute);
            if (time.seconds) newTime.setSeconds(time.seconds)
            try {
                url = await playArchive(cam, `${getDate(newTime)}T${getTime(newTime)}`)
            } catch (e) {
                await runLive();
                return;
            }
            await start(url,true)
            setCurrentTime(newTime)
        } else {
            try {
                url = await playArchive(cam, time.date)
                await start(url,true)
                setCurrentTime(new Date(time.date))
            } catch (e) {
                await runLive()
            }
        }
    }

    const onMouseMove = () => {
        setMovePlayer(true)
        clearTimeout(timeoutMove)
        setTimeOutMove((prevState) => {
            prevState = setTimeout(() => {
                setMovePlayer(false)
            },1000)
            return prevState;
        })
    }

    const fullScreen = async () => {
        if (!document.fullscreenElement) {
            setIsFull(true);
            if (isLive) {
                await start(cam.player.url || cam.player.secondary.url);
            }
            parent.current.requestFullscreen();
        } else if (document.exitFullscreen) {
                await document.exitFullscreen();
                if (isLive) {
                    await start(cam.player.secondary.url || cam.player.url);
                }
            setIsFull(false);
            }
    }

    const takeScreenShot = async () => {
        await breakFullScreen();
        showModalFileManager(true,async (item) => {
            const url = item.filepath;
            await camTakeScreenShot(cam,url,currentTime.toISOString())
        });
    }

    const breakFullScreen = async () => {
        if (document.fullscreenElement && document.exitFullscreen) {
            await document.exitFullscreen();
            setIsFull(false);
        }
    }

    return (
      <div ref={parent} onDoubleClick={fullScreen} style={{fontSize: mse?.isFull ? "2vw" : "1em"}} onMouseMove={onMouseMove} className={`${style.player} ${movePlayer ? style.controlsShow : ""}`}>
        {preloader && (
          <div className={style.preloader}>
            <Preloader position="absolute" background="rgba(0,0,0,0.3)" show={preloader} />
          </div>
)}
        {showPolygon && <img src={polygon} className={style.polygon} />}
        {!isLive && (
          <div className={style.status}>
            <div className={style.statusIcon} />
            <div className={style.statusCaption}>Архив</div>
          </div>
)}
        <div className={`${style.controlsBottom}`}>
          {currentTime && (
          <div className={style.dateTime}>
            <div className={style.date}>{getDate(currentTime)}</div>
            <div className={style.time}>{getTime(currentTime)}</div>
            {!isLive && <ButtonSquare style={{transition: "none"}} width="2em" height="2em" icon={iconCancel} action={() => { runLive() }} />}
          </div>
)}
          <div className={style.timeline}>
            {currentTime && <TimeLine detectionsData={guardData} playArchive={props.currentAccount?.username !== "user" ? runArchive : () => {}} key="timeline" time={currentTime} archiveVideo={props.currentAccount?.username !== "user" ? archiveData : []} />}
          </div>
        </div>
        <div className={`${style.controlsTop}`}>
          <div className={style.camName}>
            <ButtonSquare style={{transition: "none"}} fill="transparent" width="1em" height="1em" icon={iconCam} />
            <div className={style.camNameCaption}>
              {cam.name}
            </div>
          </div>
          <div className={style.controlsTopButtons}>
            {polygon && <ButtonSquare fill={showPolygon && "rgba(255,0,0,0.5)"} style={{transition: "none"}} name={worlds.zone} width="1em" height="1em" icon={iconLog} action={() => { setShowPolygon(!showPolygon) }} />}
            <ButtonSquare style={{transition: "none"}} name={worlds.screenshot} width="1em" height="1em" icon={iconScreenShot} action={() => { takeScreenShot() }} />
            <ButtonSquare style={{transition: "none"}} width="1em" height="1em" icon={iconToMoment} action={() => { breakFullScreen(); showModalDateTimePicker(runArchive) }} name={worlds.goToMoment} />
            {props.currentAccount?.username !== "user" && <ButtonSquare style={{transition: "none"}} width="1em" height="1em" icon={iconDownload} action={() => { breakFullScreen(); showModalDownloadFile(props.cam) }} name={worlds.download} />}
            <ButtonSquare style={{transition: "none"}} width="1em" height="1em" icon={iconFullScreen} action={() => { fullScreen() }} name={worlds.fullScreen} />
            {props.close && !mse?.isFull && <ButtonSquare style={{transition: "none"}} width="1em" height="1em" icon={iconCancel} action={() => { onClose() }} name={worlds.clearScreen} />}
          </div>
        </div>
        <video id={props.videoKey} autoPlay muted />
      </div>
    )
}

export default connect((state) => state,null)(Player);
