import {IBase} from "../iBase";
import Trigger from "../../models/Trigger";

export default class TriggerDao implements IBase<Trigger> {
    api: any;

    constructor(api:any) {
        this.api = api;
    }

    async create(obj: Trigger): Promise<Trigger> {
        const {trigger} = await this.api.emit("trigger.create",{trigger: obj});
        return trigger;
    }

    async delete(obj: Trigger): Promise<Trigger> {
        const {trigger} = await this.api.emit("trigger.delete",{trigger: obj});
        return trigger;
    }

    async get(id: string): Promise<Trigger> {
        const {trigger} = await this.api.emit("trigger.get",{trigger: {id}});
        return trigger;
    }

    async list(): Promise<Array<Trigger>> {
        const triggerList = (await this.api.emit("trigger.list", {})).triggers;
        return triggerList;
    }

    async update(obj: Trigger): Promise<Trigger> {
        const {trigger} = await this.api.emit("trigger.update",{trigger: obj});
        return trigger;
    }
}
