import React from "react";
import style from "./TimeLine.module.scss"
import TimeLineHour from "./TimeLineHour/TimeLineHour";

export default class TimeLine extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            position: 0,
            hoverTime: "",
            hoverPosition: 0,
        }

        this.timeLine = React.createRef()
    }

    onClick = (el) => {

    }

    getTimeMouse = (el) => {
        const targetCoords = el.currentTarget.offsetParent.getBoundingClientRect()
        const xCoord = el.clientX - targetCoords.left;
        if (xCoord > 0 && xCoord < targetCoords.width) this.setState({hoverPosition: xCoord})
        const time = (targetCoords.width / this.props.duration).toFixed(3);
        if (time < 0.1) return
        const currentMinute = xCoord / time;
        if (currentMinute < 0) return
        this.props.seek(currentMinute);
    }

    render() {
        return (
          <div className={style.timeLine}>
            <div className={style.timeHour}>
              {
                        Array.from(Array(12)).map((_,i) => <TimeLineHour key={i} hour={i * 2} />)
                    }
            </div>
            <div ref={this.timeLine} onClick={this.getTimeMouse} onMouseLeave={this.onMouseLeave} onMouseMove={this.onMouseMove} className={style.archive}>
              {this.timeLine.current && <div style={{width: this.timeLine.current.offsetWidth / this.props.duration * this.props.currentTime}} className={style.line} />}
            </div>
          </div>
        )
    }
}
