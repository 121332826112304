import React, {useState} from 'react';
import {InputAdornment} from "@material-ui/core";
import moment from "moment-timezone";
import style from './AlarmSwitchingForm.module.scss';
import TextFieldM from "../../../../Components/Inputs/TextFieldM";
import {worlds} from "../../../../Utils/localization";
import SearchIcon from "../../../../Assets/icons/search.svg";
import iconCalendar from "../../../../Assets/icons/calendar.svg";
import ButtonRectangle from "../../../../Components/Buttons/ButtonRectangle/ButtonRectangle";
import CancelIcon from "../../../../Assets/icons/cancel.svg";
import DateTimePicker from "../../../../Components/DateTimePicker/DateTimePicker";
import {initiateSwitching} from "../../../../api/state/switching/actions";

function AlarmSwitchingForm(props) {
    const [showDateTime,setShowDateTime] = useState({show: false,index: 0})
    const [isReset,setIsReset] = useState(false)
    const [dateRange,setDateRange] = useState([null,null])

    const reset = (bool) => {
        setIsReset(bool)
    }

    const showAndCloseDateTimeIndex = (index) => {
        setShowDateTime((prevState) => {
            prevState.show = !showDateTime.show
            prevState.index = index;
            return {...prevState}
        })
    }

    const startSearch = () => {
        if (dateRange[1] && dateRange[0]) {
            props.setDateRangeCB(dateRange);
            const filter = {updated_at: {$gte: `${dateRange[0]}`,$lte: `${dateRange[1]}`}};
            initiateSwitching(filter);
        } else {
            initiateSwitching();
        }
    }
    const onChangeDate = (date) => {
        setDateRange((prevState) => {
            prevState[showDateTime.index] = new Date(date);
            return {...prevState}
        })
        if ((new Date(Date.parse(dateRange[1])) < new Date(Date.parse(date)) && showDateTime.index === 0) || (new Date(Date.parse(dateRange[0])) > new Date(Date.parse(date)) && showDateTime.index === 1)) {
            setDateRange((prevState) => {
                prevState[0] = new Date(new Date(Date.parse(dateRange[1])).setMinutes(new Date(Date.parse(dateRange[1])).getMinutes() - 1))
                return {...prevState}
            })
        }
    }

    const clear = () => {
        setDateRange([null,null])
        props.setDateRangeCB([null,null]);
        initiateSwitching()
    }

  return (
    <div className={style.alarmForm}>
      {showDateTime.show
          && (
          <div style={{right: showDateTime.index === 1 && "13.1vw"}} className={style.dateTime}>
            <div className={style.closeDateTime} onClick={() => showAndCloseDateTimeIndex(showDateTime.index)} />
            <DateTimePicker reset={reset} isReset={isReset} dateTime={(date) => onChangeDate(date)} />
          </div>
)}
      <div className={style.searchInput}>
        <TextFieldM
          value={props.searchText}
          type="text"
          variant="outlined"
          style={{height: "100%",width: "100%",margin: "0"}}
          placeholder={worlds.searchByEvents}
          onChange={(e) => props.searchTextChange(e.target.value)}
          InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img className={style.icon} src={SearchIcon} />
                  </InputAdornment>
                ),
                endAdornment: (
                    props.searchText.length > 0
                            && (
                            <InputAdornment position="start" onClick={() => { props.searchTextChange("") }}>
                              <img className={style.icon} src={CancelIcon} />
                            </InputAdornment>
)
                ),
              }}
        />
      </div>
      <ButtonRectangle action={startSearch} style={{margin: "0 2.5vw 0 0"}} caption={worlds.search} width="6.6vw" height="2.4vw" />
      <div className={style.date}>
        <div className={style.label}>{`${worlds.period}:`}</div>
        <TextFieldM
          variant="outlined"
          style={{width: "11.45vw",margin: "0 0.83vw",height: "2.5vw"}}
          value={dateRange[0] ? moment(dateRange[0]).format('DD.MM.YYYY HH:mm ') : worlds.choose}
          disableKeyboard
          InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" onClick={() => showAndCloseDateTimeIndex(0)}>
                          <img className={style.iconCalendar} src={iconCalendar} />
                        </InputAdornment>
                      ),
                  }}
        />
        <div className={style.label} style={{color: "#3C4347"}}>—</div>
        <TextFieldM
          variant="outlined"
          style={{width: "11.45vw",margin: "0 0.83vw",height: "2.5vw"}}
          value={dateRange[1] ? moment(dateRange[1]).format('DD.MM.YYYY HH:mm ') : worlds.choose}
          disableKeyboard
          InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" onClick={() => showAndCloseDateTimeIndex(1)}>
                          <img className={style.iconCalendar} src={iconCalendar} />
                        </InputAdornment>
                      ),
                  }}
        />
      </div>
      <div className={style.resetButton}>
        <ButtonRectangle action={clear} caption={worlds.reset} dark height="2.5vw" width="6.61vw" />
      </div>
    </div>
  );
}

export default AlarmSwitchingForm;
