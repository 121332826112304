import React from 'react';
import moment from "moment-timezone";
import style from './AlarmSwitchingListItem.module.scss';
import placeholder from "../../../../Assets/placeholder.png";
import {showModalFaceApiFullImage} from "../../../../Components/Modals/actions";

function AlarmSwitchingListItem(props) {
  return (
    <div className={style.alarmListItem}>
      <div onClick={() => showModalFaceApiFullImage({camera: {name: props.data.type},full_image: placeholder})} className={style.camPhoto}>
        <img width="100%" height="100%" src={placeholder} />
      </div>
      <div className={style.camName}>{props.data.event.name}</div>
      <div className={style.description} style={{color: props.data.event.payload.status === "enable" ? "green" : "red"}}>{props.data.event.payload.status}</div>
      <div className={style.dateTime}>{moment(props.data.updated_at).format('DD.MM.YYYY HH:mm:ss')}</div>
    </div>
  );
}

export default AlarmSwitchingListItem;
