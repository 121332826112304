export default class DHCP {
    mac:string = ""

    ip:string = ""

    expired_at:string = ""

    hostname:string = ""

    client_id:string = ""
}
