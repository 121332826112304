import {plainToClass} from "class-transformer";
import {
    ADD_GUARD_CAM_VIEW,
    ADD_GUARD_DETECTION,
    ADD_GUARD_DETECTION_LIST,
    CURRENT_GUARD,
    DELETE_GUARD_CAM_VIEW,
    SET_GUARD_COUNT,
    UPDATE_GUARD_DETECTION,
} from "./consts";
import GuardDetection from "../../models/GuardDetection";

const prevState = {
    guard: false,
    guardDetections: [],
    guardCount: 0,
    guardCamView: {},
}

export const guardCamView = (state = prevState.guardCamView, action) => {
    switch (action.type) {
        case ADD_GUARD_CAM_VIEW:
            state[action.obj.id] = action.obj
            return {...state};
        case DELETE_GUARD_CAM_VIEW:
            delete state[action.obj.id]
            return {...state};
        default:
            return state;
    }
}

export const guardCount = (state = prevState.guardCount, action) => {
    switch (action.type) {
        case SET_GUARD_COUNT:
            return action.obj;
        default:
            return state;
    }
}

export const guardDetections = (state = prevState.guardDetections, action) => {
    switch (action.type) {
        case ADD_GUARD_DETECTION_LIST:
            if (!action.obj) return state;
            return action.obj;
        case ADD_GUARD_DETECTION:
            if (!action.obj) return state;
            if (state.length >= 7) {
                state.pop();
            }
            state.unshift(plainToClass(GuardDetection,{...action.obj}))
            return [...state];
        case UPDATE_GUARD_DETECTION:
            if (!action.obj) return state;
            state.splice(state.findIndex((i) => i.id === action.obj.id),1,action.obj);
            return [...state];
        default:
            return state;
    }
}

export const guard = (state = prevState.guard, action) => {
    switch (action.type) {
        case CURRENT_GUARD:
            return action.obj;
        default:
            return state;
    }
}
