import {plainToClass} from "class-transformer";
import {store} from "../../../App";
import CamDao from "./camDao";
import {
CAM_CREATE, CAM_LIST, CAM_UPDATE, EDIT_CAM_CREATE, EDIT_CAM_DELETE,
} from "./consts"
import {action} from "../../ReduxHelper";
import {worlds} from "../../../Utils/localization";
import {globalLoader} from "../account/actions";
import Cam from "../../models/Cam";
import {emitter} from "../../../Utils/globalEmitter";
import {addDownloadFile} from "../fileManager/actions";
import {initiateTaxonomy} from "../taxonomy/actions";

let api = null;
let dao = null;

export const initiateApi = () => {
    api = store.getState().api;
    dao = new CamDao(api);
}

export const initiateCams = async () => {
    store.dispatch(action(CAM_LIST, await dao.list()))
}

export const createEdit = async (cam) => {
    store.dispatch(action(EDIT_CAM_CREATE,cam))
}

export const deleteEdit = async () => {
    store.dispatch(action(EDIT_CAM_DELETE,null))
}

export const getCam = async (id) => {
    const cam = store.getState().cams[id];
    if (cam) cam.player = await getPlayer(id)
    return cam;
}

export const getPlayer = async (id) => {
    try {
        return await dao.getPlayer(id)
    } catch (e) {
    }
}

export const addCam = async (cam) => {
    const newCam = null;
    try {
        cam.server = store.getState().serverBrc.id;
        const newCam = await dao.create(cam);
        // store.dispatch(action(CAM_CREATE, newCam))
        store.getState().snackbar("success", {header: worlds.cameraAddedSuccessfully, description: ""});
        newCam.online = true;
    } catch (e) {}
    return newCam;
}

export const addCams = async (cams,callback) => {
    try {
        const bulk = []
        cams.urls.map((item, i) => {
            item = item.trim();
            if (item.includes("rtsp://")) {
                bulk.push({
                    method: "camera.create",
                    params: {
                        camera: {
                            name: `${cams.name}_${i + 1}`,
                            url: item,
                            archive: {enable: cams.isArchive,days: cams.archiveDays},
                            server: store.getState().serverBrc.id,
                            taxonomies: cams.parents,
                            api_connection: {brc: {details: {max_keep_days: parseInt(cams.archiveDays, 10)}}},
                        },
                    },
                })
            }
        })
        const data = await api.emit('bulk.parallel', {bulk})
        const newCams = []
        data.results.filter((i) => !i.data.error).map((item) => {
            const cam = new Cam()
            cam.serialize(item.data.camera)
            newCams.push(cam)
        })
        let oldCams = store.getState().cams;
        oldCams = {...oldCams,...newCams};
        // store.dispatch(action(CAM_LIST, oldCams))
        callback(newCams)
    } catch (e) {}
}

export const deleteCam = async (cam,parent) => {
    try {
        if (parent && cam.parents.length > 1) {
            cam.parents = cam.parents.filter((i) => i !== parent);
           await dao.update(cam)
        } else {
            await dao.delete(cam);
        }
    } catch (e) {}
}

export const updateCam = async (cam) => {
    try {
        const updateCam = await dao.update(cam);
        updateCam.online = cam.online
        emitter.emit("camUpdate", updateCam);
        // store.dispatch(action(CAM_UPDATE, updateCam));
        store.getState().snackbar("success",{header: worlds.cameraEditedSuccessfully,description: ""});
    } catch (e) {}
}

// eslint-disable-next-line no-return-await
export const playArchive = async (cam,time,disableError) => await dao.playArchive(cam.id, time,disableError)

export const getArchive = async (cam) => {
    try {
        return await dao.getArchive(cam.id);
    } catch (e) {}
}

export const getHistoryDetection = async (cam) => {
    try {
        return await dao.getHistoryDetection(cam.id);
    } catch (e) {}
}

export const searchCamsIdByName = (name) => {
    const cams = Object.values(store.getState().cams);
    return cams.filter((i) => i.name.toUpperCase().includes(name.toUpperCase())).map((i) => i.id) || [];
}

export const downloadArchive = async (cameraId,storage,start,end) => {
    try {
        let res = {};
        res.snapshot = await getSnapShot(cameraId, start);
        const interval = setInterval(async () => {
            try {
                res = {...res,...(await dao.downloadArchive(cameraId, storage, start, end))};
                addDownloadFile(res);
                if (res.error) {
                    clearInterval(interval);
                }
            } catch (e) {
                clearInterval(interval);
            }
            if (res.percent === 100) {
                clearInterval(interval);
            }
        },1000)
        return res;
    } catch (e) {
        return null;
    }
}

export const getSnapShot = async (id,time) => {
    try {
        const res = await dao.getSnapshot(id, time)
        return res;
    } catch (e) {}
}

export const getCamGlobal = async (id) => {
    try {
        return await api.emit("camera.get",{camera: {id},include: ["snapshot"]},false,false);
    } catch (e) {}
}
export const getOnvif = async (ip,login,pass) => {
    try {
        const bulk = []
        bulk.push({
            method: "camera.onvif.info",
            params: {
                camera: {
                    host: ip,
                    port: 80,
                    username: login,
                    password: pass,
                },
            },
        })
        bulk.push({
            method: "camera.onvif.info",
            params: {
                camera: {
                    host: ip,
                    port: 2000,
                    username: login,
                    password: pass,
                },
            },
        })
        bulk.push({
            method: "camera.onvif.info",
            params: {
                camera: {
                    host: ip,
                    port: 5000,
                    username: login,
                    password: pass,
                },
            },
        })
        bulk.push({
            method: "camera.onvif.info",
            params: {
                camera: {
                    host: ip,
                    port: 8090,
                    username: login,
                    password: pass,
                },
            },
        })
        const data = await api.emit('bulk.parallel', {bulk})
        let res = "";
        data.results.map((item) => {
            if (item.data) {
                res = item.data;
            }
        })
        if (!res) {
            store.getState().snackbar("warning",{header: worlds.authorisationError,description: ""});
        }
        return res;
    } catch (e) {}
}

export const deleteAllCams = async () => {
    await globalLoader(true,"")
    try {
        const {cams} = store.getState();
        const bulk = []
        for (const key in cams) {
            if (cams[key]) {
                bulk.push({
                    method: "camera.delete",
                    params: {
                        camera: {
                            id: key,
                        },
                    },
                })
            }
        }
        await api.emit('bulk.parallel', {bulk},false,true)
        await api.emit('strazh.dhcp.mapping.set',{mapping: []});
        // store.dispatch(action(CAM_LIST,{}))
        store.getState().snackbar("success",{header: worlds.cameraRemovedSuccessfully,description: ""});
    } catch (e) {
        store.getState().snackbar("warning",{header: e,description: ""});
    }
    await globalLoader(false,"")
}

export const enableSettingsAllRecord = async (result) => {
    await globalLoader(true,"")
        try {
            const {cams} = store.getState();
            const bulk = []
            for (const key in cams) {
                if (cams[key]) {
                    cams[key].isArchive = result.record;
                    cams[key].archiveDays = `${result.recordDay}`;
                    bulk.push({
                        method: "camera.update",
                        params: {
                            camera: cams[key].deserialize(),
                        },
                    })
                }
            }
            const res = await api.emit('bulk.queue', {bulk},false,true)
            let error = false;
            res.results.map((item) => {
                if (item.error) {
                    error = true;
                    store.getState().snackbar("warning",{header: item.params.camera.name,description: item.error});
                }
            })
            if (!error) store.getState().snackbar("success",{header: worlds.settingsSavedSuccessfully,description: ""});
        } catch (e) {
            store.getState().snackbar("warning",{header: e,description: ""});
        }
    await globalLoader(false,"")
}

export const enableSettingsAllLocation = async (result) => {
    await globalLoader(true,"")
    try {
        const {cams} = store.getState();
        const bulk = []
        for (const key in cams) {
            if (cams[key]) {
                cams[key].latlng = result.latlng;
                bulk.push({
                    method: "camera.update",
                    params: {
                        camera: cams[key].deserialize(),
                    },
                })
            }
        }
        const res = await api.emit('bulk.queue', {bulk},false,true)
        let error = false;
        res.results.map((item) => {
            if (item.error) {
                error = true;
                store.getState().snackbar("warning",{header: item.params.camera.name,description: item.error});
            }
        })
        await deleteEdit();
        if (!error) store.getState().snackbar("success",{header: worlds.settingsSavedSuccessfully,description: ""});
    } catch (e) {
        store.getState().snackbar("warning",{header: e,description: ""});
    }
    await globalLoader(false,"")
}

export const camTakeScreenShot = async (cam,path,time) => {
    try {
        await api.emit('camera.snapshot_store',{camera: {id: cam.id},storage: {path},time: {start: time}});
    } catch (e) {}
}

export const camDetectionConfigGet = async (cam) => {
    try {
        return await api.emit('camera.detection.config.get',{camera: {id: cam.id}});
    } catch (e) {}
}

export const camDetectionConfigSet = async (cam,config) => {
    try {
        await api.emit('camera.detection.config.set',{camera: {id: cam.id},config});
        emitter.emit("camUpdate", cam);
        store.getState().snackbar("success",{header: worlds.settingsSavedSuccessfully,description: ""});
    } catch (e) {}
}

export const getPolygon = async (id) => {
    try {
        const cam = await api.emit("camera.get", {camera: {id}, include: ['motion_mask']},true,true);
        const {host} = new URL(window.location.hostname && window.location.hostname !== "localhost" ? `http://${window.location.hostname}:4080/` : window.config.host);
        const url = cam?.motion_mask?.url.replace("localhost", host).replace(":4080", "");
        return url;
    } catch (e) {
        return null
    }
}
