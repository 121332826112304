import React from "react";
import style from "./LicenseFile.module.scss";
import ButtonRectangle from "../../../../Components/Buttons/ButtonRectangle/ButtonRectangle";
import {worlds} from "../../../../Utils/localization";
import fileIcon from "../../../../Assets/icons/file.svg";
import cancelIcon from "../../../../Assets/icons/cancel.svg";
import ButtonSquare from "../../../../Components/Buttons/ButtonSquare/ButtonSquare";

export default class LicenseFile extends React.Component {
    constructor(props) {
        super(props);
    }

    addFile = () => {
        this.props.saveEnable(this.props.caption);
    }

    cancelFile = () => {
        this.props.delete(this.props.caption);
    }

    render() {
        return (
          <div className={style.licenseFile}>
            <div className={style.license}>
              <div className={style.caption}>{this.props.caption}</div>
              {!this.props.license.saved
                        ? <ButtonRectangle action={this.addFile} caption={worlds.chooseFile} icon width="9.11vw" dark />
                    : <ButtonRectangle action={() => this.props.delete(this.props.caption)} caption={worlds.deleteLicense} width="9.79vw" />}
            </div>
            {!this.props.license.saved ? (this.props.license.file.data || this.props.license.file.path) && (
              <div className={style.file}>
                <div style={{display: "flex",alignItems: "center"}}>
                  <img className={style.icon} src={fileIcon} />
                  <div className={style.fileName}>{this.props.license.file.path}</div>
                </div>
                <ButtonSquare action={this.cancelFile} icon={cancelIcon} width="1.04vw" height="1.04vw" />
              </div>
) : (
  <div className={style.file}>
    <div className={style.activated}>{worlds.licenseActivated}</div>
    <div className={style.expirationDate}>
      {worlds.until}
      {' '}
      {this.props.license.date}
      {' '}
      года
    </div>
  </div>
)}
          </div>
        )
    }
}
