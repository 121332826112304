import React from "react";
import style from "./Versions.module.scss";
import {worlds} from "../../../../Utils/localization";

class Versions extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
          <div className={style.version}>
            <div className={style.caption}>{this.props.caption}</div>
            <div className={style.caption}>{`${worlds.version} ${this.props.version}`}</div>
          </div>
        )
    }
}

export default Versions;
