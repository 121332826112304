import React from "react";
import {InputAdornment, TextField, withStyles} from "@material-ui/core";
import {connect} from "react-redux";
import style from "./TextFieldM.module.scss";
import {setKeyBoardShow} from "../../api/state/keyboard/actions";
import iconKeyboard from "../../Assets/icons/keyboard.svg";

const TextFieldNew = withStyles({
    root: {
        margin: "1.25vw 1.46vw 1.25vw 0.94vw",
        height: "2.91vw",
        width: "15.73vw",
        '& label': {
            color: "#8C939B",
            // marginTop:"-0.35rem",
            marginTop: "-1.2vh",
            fontFamily: "Inter",
            fontSize: "0.93vw",
        },
        '& label.Mui-focused': {
            color: "#EAEAEA",
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#EAEAEA',
        },
        '& .MuiOutlinedInput-root': {
            height: "100%",
            backgroundColor: "#3C4347",
            borderRadius: 0,
            color: "#EAEAEA",
            fontSize: "0.93vw",
            paddingRight: "0.78vw",
            '& fieldset': {
                borderColor: "transparent",
                borderWidth: "0.05vw",
            },
            '&:hover fieldset': {
                borderColor: '#EAEAEA',
            },
            '&.Mui-focused fieldset': {
                borderWidth: "0.05vw",
                borderColor: '#EAEAEA',
            },
            '&.Mui-disabled': {
                color: "#8C939B",
            },
        },

    },
})(TextField)

class TextFieldM extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorText: "",
            error: false,
        }
        this.ref = React.createRef()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.errors !== this.props.errors) {
            this.checkErrors()
        }
    }

    checkErrors = () => {
        const {errors} = this.props;
        if (errors && errors.length > 0) {
            errors.map((error) => {
                if (error.children?.length > 0) {
                    error.children.map((errorChild) => {
                        if (errorChild.property === this.props.name) this.setState({errorText: Object.values(errorChild.constraints).join('\n'), error: true})
                        else this.setState({errorText: "", error: false})
                    })
                } else if (error.property === this.props.name) this.setState({errorText: Object.values(error.constraints).join('\n'), error: true})
                    else this.setState({errorText: "", error: false})
            })
        } else {
            this.setState({errorText: "", error: false})
        }
    }

    openKeyBoard = () => {
        if (!this.props.keyboardShow) setKeyBoardShow(true,this.ref,this.props.onChange)
        else setKeyBoardShow(false,null,null)
    }

    render() {
        const disableKeyboard = `${!this.props.disableKeyboard}`;
        return (
          <TextFieldNew
            helperText={this.state.errorText}
            error={this.state.error}
            inputRef={this.ref}
            disabled={this.props.disabled}
            value={this.props.value}
            onChange={this.props.onChange}
            placeholder={this.props.placeholder}
            variant={this.props.variant}
            style={this.props.style}
            type={this.props.type}
            className={this.props.className}
            InputProps={{
                ...this.props.InputProps,
                endAdornment: this.props.disableKeyboard ? this.props.InputProps?.endAdornment : this.props.InputProps?.endAdornment
                    ? (
                      <>
                        {this.props.InputProps?.endAdornment}
                        <InputAdornment position="end" onClick={() => this.openKeyBoard()}>
                          <img keyboard={disableKeyboard} className={style.iconKeyboard} src={iconKeyboard} />
                        </InputAdornment>
                      </>
)
                    : (
                      <InputAdornment position="end" onClick={() => this.openKeyBoard()}>
                        <img keyboard={disableKeyboard} className={style.iconKeyboard} src={iconKeyboard} />
                      </InputAdornment>
                    ),
            }}
          />
        )
    }
}

export default connect((state) => state,null)(TextFieldM);
