import React from "react";
import {connect} from "react-redux";
import style from "./SecurityDrill.module.scss";
import {worlds} from "../../../Utils/localization";
import ButtonRectangle from "../../../Components/Buttons/ButtonRectangle/ButtonRectangle";

class SecurityDrill extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isEnabled: false,
        }
    }

    click = () => {
        this.setState({isEnabled: !this.state.isEnabled});
    }

    render() {
        return (
          <div className={style.drill}>
            <div className={style.header}>
              <div className={style.caption}>{worlds.securityDrill}</div>
            </div>
            <div className={style.switch}>
              <div className={style.detail}>
                {this.state.isEnabled ? <span>{worlds.enabled}</span> : worlds.disabled}
              </div>
              <ButtonRectangle action={this.click} caption={this.state.isEnabled ? worlds.disable : worlds.enable} width="6.72vw" height="1.98vw" dark={this.state.isEnabled && true} />
            </div>
          </div>
        );
    }
}

export default connect((state) => state,null)(SecurityDrill);
