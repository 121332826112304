import React from "react";
import {InputAdornment} from "@material-ui/core";
import {connect} from "react-redux";
import style from "./DHCP.module.scss";
import {worlds} from "../../../Utils/localization";
import searchIcon from "../../../Assets/icons/search.svg";
import TextFieldM from "../../../Components/Inputs/TextFieldM";
import DeviceItem from "./DeviceItem/DeviceItem";
import {getDhcpClientList} from "../../../api/state/dhcp/actions";
import ButtonRectangle from "../../../Components/Buttons/ButtonRectangle/ButtonRectangle";
import {showModalClearDhcpList} from "../../../Components/Modals/actions";

class DHCP extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            disabled: true,
            search: "",
        }
    }

    search = (e) => {
        this.setState({search: e.target.value})
    }

    render() {
        return (
          <div className={style.dhcp}>
            <div className={style.header}>
              <div className={style.searchInput}>
                <TextFieldM
                  type="text"
                  variant="outlined"
                  style={{height: "2.5vw", width: "46.67vw", margin: "0"}}
                            // value={this.state.searchText}
                  placeholder={worlds.search}
                  onChange={this.search}
                  InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <img style={{width: "0.94vw"}} src={searchIcon} />
                                  </InputAdornment>
                                ),
                            }}
                />
              </div>
              <ButtonRectangle height="2.4vw" caption={worlds.update} action={() => getDhcpClientList()} />
              <ButtonRectangle style={{marginLeft: "auto"}} height="2.4vw" caption={worlds.clearTheList} action={() => showModalClearDhcpList()} />
            </div>
            <div className={style.deviceList}>
              <div className={style.headItem}>
                <div className={style.head} style={{width: "3.85vw"}}>{worlds.type}</div>
                <div className={style.head} style={{width: "15vw"}}>{worlds.macAddress}</div>
                <div className={style.head} style={{width: "15vw"}}>{worlds.ipAddress}</div>
                <div className={style.head} style={{width: "17.49vw"}}>{worlds.hostname}</div>
                <div className={style.head} style={{width: "14.02vw"}}>{worlds.cameraName}</div>
                <div className={style.head} style={{width: "11.83vw",padding: 0, justifyContent: "center"}}>{worlds.addCamera}</div>
              </div>
              <div className={style.data}>
                {(this.props.dhcpClients.length > 0)
                        ? this.props.dhcpClients.filter((i) => i.ip.includes(this.state.search) || i.mac.includes(this.state.search)).map((item, i) => <DeviceItem search={this.state.search} key={i} elem={item} />)
                        : <div className={style.noData}>{worlds.addDevicesToSee}</div>}
              </div>
            </div>
          </div>
        )
    }
}

export default connect((state) => state,null)(DHCP);
