import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {withStyles} from "@material-ui/core";
import Popover from "@material-ui/core/Popover";
import {worlds} from "../../Utils/localization";
import Monitor from "./Monitor/Monitor";
import style from "./AlarmMonitor.module.scss";
import {emitter} from "../../Utils/globalEmitter";
import {createCurrent} from "../../api/state/plan/actions";

const PopoverM = withStyles({
    root: {
        pointerEvents: 'none',
    },
    paper: {
        backgroundColor: "#2A2F32",
        fontFamily: "Inter",
        fontSize: "0.73vw",
        color: "#EAEAEA",
        borderRadius: "0.23vw",
        border: "0.05vw solid #3C4347",
        padding: "0.52vw 0.62vw 0",
        marginTop: "1.25vw",
    },
})(Popover);

class AlarmMonitor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            fullName: {open: false, anchor: null},
        }
    }

    componentDidMount() {
        emitter.addListener('guardDetection',(cam) => {
            this.setState({show: true})
            for (const plan of this.props.plans) {
                for (const camInPlan of plan.cams) {
                    if (camInPlan.id === cam.id) {
                        this.props.history.push("/plans");
                        createCurrent(plan);
                        break;
                    }
                }
            }
        })
    }

    click = () => {
        this.setState({show: !this.state.show});
    }

    enterCaption = (el) => {
        const {fullName} = this.state;
        if (fullName.open) {
            return
        }
        fullName.open = true;
        fullName.anchor = el.currentTarget;
        this.setState({fullName})
    }

    leaveCaption = () => {
        const {fullName} = this.state;
        if (!fullName.open) {
            return
        }
        fullName.open = false;
        fullName.anchor = null;
        this.setState({fullName})
    }

    render() {
        return (
          <div className={style.alarmMonitor}>
            <div style={{display: "flex",marginRight: "1.25vw"}} onMouseEnter={this.enterCaption} onMouseLeave={this.leaveCaption} onClick={() => this.click()}>
              <div className={`${style.button} ${this.state.show && style.open}`} onClick={() => this.click()} />
              <PopoverM
                open={this.state.fullName.open}
                anchorEl={this.state.fullName.anchor}
                anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                disableRestoreFocus
              >
                {worlds.alarmMonitor}
              </PopoverM>
            </div>
            <Monitor show={this.state.show} cancel={this.click} />
          </div>
        );
    }
}

export default withRouter(connect((state) => state,null)(AlarmMonitor))
