import React from "react";
import {connect} from "react-redux"
import {validate} from "class-validator";
import style from "./Network.module.scss";
import ButtonRectangle from "../../../Components/Buttons/ButtonRectangle/ButtonRectangle";
import {worlds} from "../../../Utils/localization";
import NetworkCard from "./NetworkCard/NetworkCard";
import ButtonTopMenu from "../../../Components/Buttons/ButtonTopMenu/ButtonTopMenu";
import {networkStatus, saveNetworkConfig} from "../../../api/state/network/actions";

const type = ["LAN","WAN"];

class Network extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            type: type[0],
            errors: null,
        }
    }

    componentDidMount() {
        // setInterval(() => {
        //     networkStatus();
        // },10000)
    }

    checkInErrors = async () => {
        this.setState({errors: null});
        const errorsLan = await validate(this.props.networkConfig.lan);
        const errorsWan = await validate(this.props.networkConfig.wan);
        if (errorsLan.length > 0 || (this.props.networkConfig.wan.inet === "static" && errorsWan.length > 0)) {
            this.setState({errors: {lan: errorsLan,wan: errorsWan}});
        } else {
            saveNetworkConfig(this.props.networkConfig)
        }
    }

    render() {
        return (
          <div className={style.network}>
            <div className={style.header}>
              <div className={style.caption}>{worlds.networkConfiguration}</div>
              <ButtonRectangle action={this.default} caption={worlds.default} width="8.96vw" dark />
            </div>
            <div className={style.middle}>
              <ButtonTopMenu active={this.state.type === type[0]} action={() => this.setState({type: type[0]})} caption="LAN" />
              <ButtonTopMenu active={this.state.type === type[1]} action={() => { this.setState({type: type[1]}) }} caption="WAN" />
            </div>
            <NetworkCard errors={this.state.errors} networkConfig={this.props.networkConfig} type={this.state.type} />
            <div className={style.button}>
              <ButtonRectangle action={() => this.checkInErrors()} caption={worlds.save} width="10.31vw" height="2.92vw" />
            </div>
          </div>
        )
    }
}

export default connect((state) => state,null)(Network)
