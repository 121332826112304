import React from "react";
import {withStyles} from "@material-ui/core";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import style from "./ModalTechnicalWork.module.scss"
import TextFieldAutoComplete from "../../Inputs/TextFieldAutoComplete";

const TextAreaM = withStyles({
    root: {
        margin: "0",
        height: "6.51vw",
        width: "40.73vw",
        alignItems: "flex-start",
        '& label': {
            color: "#8C939B",
            marginTop: "-0.29vw", // -0.35rem
            fontFamily: "Inter",
            fontSize: "0.73vw",
        },
        '& label.Mui-focused': {
            color: "#EAEAEA",
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#EAEAEA',
        },
        '& .MuiOutlinedInput-root': {
            height: "100%",
            width: "100%",
            alignItems: "flex-start",
            backgroundColor: "#3C4347",
            borderRadius: 0,
            color: "#EAEAEA",
            padding: "0.88vw 0.83vw",
            '& fieldset': {
                borderColor: "transparent",
                borderSize: "0.05vw",
            },
            '&:hover fieldset': {
                borderColor: '#EAEAEA',
            },
            '&.Mui-focused fieldset': {
                borderSize: "0.05vw",
                borderColor: '#EAEAEA',
            },
        },
    },
})(TextFieldAutoComplete);

const ModalTechnicalWork = (props) => (
  <div className={style.modalTechnicalWork}>
    <TextAreaM variant="outlined" multiline rowsMin={3} rows={4} rowsMax={4} placeholder="" />
  </div>
    )

export default withRouter(connect((state) => state,null)(ModalTechnicalWork))
