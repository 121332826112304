import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import Pagination from "@material-ui/lab/Pagination";
import style from "./RuleTable.module.scss";
import RuleForm from "./RuleForm/RuleForm";
import {worlds} from "../../../Utils/localization";
import RuleListItem from "./RuleListItem/RuleListItem";

const RuleTable = (props) => {
    const [page,setPage] = useState(1);
    const [count,setCount] = useState(1);
    const [search,setSearch] = useState("");
    const changePage = (el,pageNum) => {
        setPage(pageNum)
    }

    useEffect(() => {
        setCountPage()
    })

    const setCountPage = () => {
        setCount((props.triggers.filter((i) => i.filter?.hasOwnProperty("$and") && i.name.toUpperCase().includes(search.toUpperCase())).length - 1) % 11 === 0 ? (props.triggers.filter((i) => i.filter?.hasOwnProperty("$and") && i.name.toUpperCase().includes(search.toUpperCase())).length - 1) / 11 + 1 : 1)
    }
    const searchItem = (e) => {
        setSearch((prevState) => {
            prevState = e.target.value;
            return prevState;
        });
        setCountPage()
    }

    return (
      <div className={style.ruleTable}>
        <RuleForm search={searchItem} />
        <div className={style.content}>
          <div className={style.headItem}>
            <div className={style.head} style={{width: "100%"}}>{worlds.ruleName}</div>
            <div className={style.head} style={{minWidth: "16.45vw",width: "16.45vw"}}>{worlds.time}</div>
            <div className={style.head} style={{minWidth: "16.45vw",width: "16.45vw"}}>{worlds.daysOfWeek}</div>
            <div className={style.head} style={{minWidth: "2.13vw",width: "2.13vw"}} />
          </div>
          {props.triggers.length > 0
                    ? props.triggers.filter((i) => i.data.triggerType === "guard").filter((i) => i.name.toUpperCase().includes(search.toUpperCase())).map((item, i) => {
                            if ((page * 11 - 11) <= i && page * 11 > i) return <RuleListItem trigger={item} key={i} />
                        })
                    : <div className={style.emptyList}>{worlds.emptyList}</div>}
        </div>
        <div className={style.pagination}>
          <Pagination outlined="false" onChange={changePage} count={count} shape="rounded" />
        </div>
      </div>
    )
}

export default connect((state) => state,null)(RuleTable)
