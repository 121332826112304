import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import style from "./Licensing.module.scss";
import {worlds} from "../../../Utils/localization";
import LicenseFile from "./LicenseFile/LicenseFile";
import ButtonRectangle from "../../../Components/Buttons/ButtonRectangle/ButtonRectangle";
import {showModalFileManager} from "../../../Components/Modals/actions";
import {
getLicenseBrc, getLicenseFaceApi, setLicenseBrc, setLicenseFaceApi,
} from "../../../api/globalActions";

const Licensing = (props) => {
    const [disabled,setDisabled] = useState(true);
    const [brcLicense,setBrcLicense] = useState({file: {data: "",path: ""},saved: false,date: 0});
    const [apiLicense,setApiLicense] = useState({file: {data: "",path: ""},saved: false,date: 0});
    useEffect(() => {
        getLicence();
    },[])

    const getLicence = async () => {
        let brc = null;
        let faceApi = null;
        try {
             brc = await getLicenseBrc();
             faceApi = await getLicenseFaceApi();
        } catch (e) {
             faceApi = await getLicenseFaceApi();
        }
        if (brc) {
            setBrcLicense((prevState) => {
                prevState.file.data = brc.data;
                prevState.saved = true;
                prevState.date = new Date(Date.parse(brc.expired_at)).getFullYear();
                return {...prevState}
            })
        }
        if (faceApi && props.systemConfig.services.faceapi) {
            setApiLicense((prevState) => {
                prevState.file.data = faceApi.data;
                prevState.saved = true;
                prevState.date = new Date(Date.parse(faceApi.expired_at)).getFullYear();
                return {...prevState}
            })
        }
    }

    const fileSelector = (type) => {
        showModalFileManager(false,async (item) => {
            // eslint-disable-next-line default-case
            switch (type) {
                case "brc":
                    setBrcLicense((prevState) => {
                        prevState.file.path = item.filepath
                        prevState.saved = false;
                        return {...prevState}
                    })
                    break;
                case "faceApi":
                    setApiLicense((prevState) => {
                        prevState.file.path = item.filepath
                        prevState.saved = false;
                        return {...prevState}
                    })
                    break;
            }
            setDisabled(false);
        });
    }

    const saveLicense = () => {
        setDisabled(true);
        if (brcLicense.file.path || brcLicense.file.data) {
            setLicenseBrc(brcLicense.file.path,brcLicense.file.data);
        }
        if ((apiLicense.file.path || apiLicense.file.data) && props.systemConfig.services.faceapi) {
            setLicenseFaceApi(apiLicense.file.path,apiLicense.file.data);
        }
        getLicence();
    }

    const deleteLicense = (type) => {
        // eslint-disable-next-line default-case
        switch (type) {
            case "brc":
                setBrcLicense((prevState) => {
                    prevState.saved = false;
                    prevState.file = {data: "",path: ""};
                    return {...prevState}
                })
                setLicenseBrc(null,"-");
                break;
            case "faceApi":
                setApiLicense((prevState) => {
                    prevState.saved = false;
                    prevState.file = {data: "",path: ""};
                    return {...prevState}
                })
                setLicenseFaceApi(null,"-");
                break;
        }
        if (!brcLicense.file.data && !brcLicense.file.path && !apiLicense.file.data && !apiLicense.file.path) setDisabled(true);
    }

    return (
      <div className={style.licensing}>
        <div className={style.header}>
          <div className={style.caption}>{worlds.licensing}</div>
        </div>
        <div className={style.content}>
          <LicenseFile key={1} caption="brc" saveEnable={fileSelector} license={brcLicense} delete={deleteLicense} />
          {props.systemConfig.services.faceapi && <LicenseFile key={2} caption="faceApi" saveEnable={fileSelector} license={apiLicense} delete={deleteLicense} />}
        </div>
        <div className={style.button}>
          <ButtonRectangle action={saveLicense} caption={worlds.save} width="10.31vw" height="2.92vw" disabled={disabled} />
        </div>
      </div>
    )
}

export default connect((state) => state,null)(Licensing);
