import React from "react";
import {connect} from "react-redux";
import style from "./ModalDeleteCam.module.scss"
import ButtonRectangle from "../../Buttons/ButtonRectangle/ButtonRectangle";
import {worlds} from "../../../Utils/localization";
import {deleteCam} from "../../../api/state/cam/actions";
import {closeModal} from "../actions";

class ModalDeleteCam extends React.Component {
    delete = (cam,parent) => {
        deleteCam(cam,parent)
    }

    render() {
        return (
          <div className={style.modalDeleteCam}>
            <div className={style.caption}>
              {`${worlds.removeCamera} ${this.props.cam?.name}?`}
            </div>
            <div className={style.controls}>
              <ButtonRectangle width="7.55rem" caption={worlds.delete} action={() => { this.delete(this.props.cam,this.props.parent); closeModal() }} />
              <ButtonRectangle width="7.55rem" dark caption={worlds.cancel} action={() => closeModal()} />
            </div>
          </div>
        )
    }
}

export default connect((state) => state,null)(ModalDeleteCam)
