import React, {useEffect, useState} from 'react';
import Pagination from '@material-ui/lab/Pagination';
import {connect} from "react-redux";
import style from './FaceDetection.module.scss';
import FaceDetectionForm from './FaceDetectionForm/FaceDetectionForm';
import FaceDetectionListItem from './FaceDetectionListItem/FaceDetectionListItem';

function FaceDetectionSearch(props) {
    const [page,setPage] = useState(1);
    const [count,setCount] = useState(1);
    const changePage = (el,pageNum) => {
        setPage(pageNum)
    }

    const clear = () => {
        setPage(1)
        setCount(1)
    }

    useEffect(() => {
        setCount((props.faceApiSearchData.filter((i) => i.face_group?.length > 0).length - 1) % 7 === 0 ? (props.faceApiSearchData.filter((i) => i.face_group?.length > 0).length - 1) / 7 + 1 : count)
    })
    return (
      <div>
        <FaceDetectionForm clear={clear} />
        <div className={style.faces}>
          { props.faceApiSearchData.filter((i) => i.face_group?.length > 0).reverse().map((data,key) => {
                        // return data['face_group'].map((face,key2)=>{
                            if ((page * 7 - 7) <= (key) && page * 7 > (key)) {
 return (
   <FaceDetectionListItem
     key={key}
     smallFace={data.face_group[0]}
     face={data}
   />
)
}
                        // })
                }) }
        </div>
        <div className={style.pagination}>
          <Pagination outlined="false" onChange={changePage} count={count} shape="rounded" />
        </div>
      </div>
    );
}

export default connect((state) => state,null)(FaceDetectionSearch);
