import {store} from "../../../App";
import {action} from "../../ReduxHelper";
import {
    ADD_FACE,
    ADD_FACE_SEARCH,
    CLEAR_LIST_SEARCH,
    CURRENT_FACE_API_CONFIG,
    FACE_API_SEARCH,
    FACE_API_SEARCH_PROGRESS,
} from "./consts";
import {worlds} from "../../../Utils/localization";

let api = null;
const startSearch = false;

export const initiateFaceApiConfig = async () => {
    api = store.getState().api;
    try {
        api.emit("faceapi.subscribe", {})
    } catch (e) {}
}

export const loadFaceApiConfig = async () => {
    try {
        const config = await api.emit("faceapi.config.get", {});
        store.dispatch(action(CURRENT_FACE_API_CONFIG, config.config))
    } catch (e) {}
}

export const updateFaceApiConfig = async (faceApiConfig) => {
    store.dispatch(action(CURRENT_FACE_API_CONFIG,faceApiConfig))
}

export const setFaceApiConfig = async (faceApiConfig) => {
    try {
        faceApiConfig.faceDetectionImagesFullDraw = faceApiConfig.faceDetectionImagesFullDraw === "true";
        faceApiConfig.ffmpegMinimizeDelay = faceApiConfig.ffmpegMinimizeDelay === "true";
        faceApiConfig.faceDetectionImagesGroupResult = faceApiConfig.faceDetectionImagesGroupResult === "true";
        await api.emit("faceapi.config.set", {config: faceApiConfig});
        store.dispatch(action(CURRENT_FACE_API_CONFIG, faceApiConfig))
        store.getState().snackbar("success", {header: worlds.settingsSavedSuccessfully, description: ""});
    } catch (e) {}
}

export const initializeEventDetections = async (data) => {
    // eslint-disable-next-line default-case
    switch (data.event) {
        case "faceapi.detection":
            store.dispatch(action(ADD_FACE, data))
            break;
        case "faceapi.search.progress":
            setFaceApiSearchProgress(data.object.progress);
            break;
        case "faceapi.search.start":
            store.dispatch(action(FACE_API_SEARCH, true))
            store.getState().snackbar("success",{header: worlds.startSearchingForDetections,description: ""});
            break;
        case "faceapi.search.end":
            store.dispatch(action(FACE_API_SEARCH, false))
            setFaceApiSearchProgress(0);
            store.getState().snackbar("success",{header: worlds.detectionSearchIsOver,description: ""});
            break;
        case "faceapi.search.result":
            store.dispatch(action(ADD_FACE_SEARCH, {
                object: {
                    process_id: data.object.process_id,
                    detected_at: data.object.detected_at,
                    face_group: [data.object.image_data],
                    full_image: data.object.image_data,
                    camera: data.object.camera,
                },
            }))
            break;
    }
}

export const searchFaceDetectionsStart = async (date,camsId,imageUrl) => {
    try {
        const image = {
            filedata: imageUrl?.image,
        };
        const filter = {
            "camera.id": {
                $in: camsId || [],
            },
            date: {
                $gte: date?.[0] ?? "",
                $lte: date?.[1] ?? "",
            },
        }
        const result = await api.emit("faceapi.search.start", {image, filter})
        store.dispatch(action(FACE_API_SEARCH, true))
        return result;
    } catch (e) {}
}

export const searchFaceDetectionsStop = async (process_id) => {
    if (!process_id) {
        return;
    }
    try {
        const result = await api.emit("faceapi.search.stop", {process_id})
        setFaceApiSearchProgress(0);
        store.dispatch(action(FACE_API_SEARCH, false))
        return result;
    } catch (e) {}
}

export const searchFaceDetectionsClear = async () => {
    store.dispatch(action(CLEAR_LIST_SEARCH, {}))
}
export const setFaceApiSearchProgress = (progress) => {
    if (progress) store.dispatch(action(FACE_API_SEARCH_PROGRESS, progress))
}
