// eslint-disable-next-line max-classes-per-file
import {IsIP, ValidateNested} from "class-validator";
import {worlds} from "../../Utils/localization";

export class Wan {
    inet:string = "dhcp" // "static"

    @IsIP(4,{
        message: worlds.errorIpAddress,
    })
    address:string = ""

    @IsIP(4,{
        message: worlds.errorIpAddress,
    })
    gateway:string = ""

    @IsIP(4,{
        message: worlds.errorSubnetMask,
    })
    netmask:string = ""

    @IsIP(4,{
        message: worlds.errorDnsServer,
    })
    "dns-nameservers":string = ""

    constructor() {
    }
}

export class Lan {
    @IsIP(4,{
        message: worlds.errorIpAddress,
    })
    address:string = ""

    @IsIP(4,{
        message: worlds.errorIpAddress,
    })
    netmask:string = ""

    constructor() {
    }
}

export default class Network {
    @ValidateNested()
    wan:Wan = new Wan()

    @ValidateNested()
    lan:Lan = new Lan()

    constructor() {
    }
}
