import {Switch, withStyles} from "@material-ui/core";

export default withStyles((theme) => ({
    root: {
        width: "2.5vw",
        height: "1.4vw",
        padding: 0,
        display: 'flex',
    },
    switchBase: {
        padding: "0.31vw",
        color: "#8C939B",
        transition: "all 0.5s ease",
        '&$checked': {
            transform: 'translateX(1.25vw)',
            color: "#EAEAEA",
            '& + $track': {
                opacity: 1,
                backgroundColor: "#FB3C4A",
                borderColor: "#EAEAEA",
            },
        },
        '&:hover': {
            color: "#EAEAEA",
            '& + $track': {
                opacity: 1,
                borderColor: "#EAEAEA",
            },
        },
    },
    thumb: {
        top: "0.83vw",
        width: "0.62vw",
        height: "0.62vw",
        boxShadow: 'none',
    },
    track: {
        height: "1.25vw",
        width: "2.5vw",
        border: `0.05vw solid #8C939B`,
        borderRadius: "1.56vw",
        opacity: 1,
        backgroundColor: "transparent",
    },
    checked: {},
}))(Switch);
