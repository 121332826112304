import io from "socket.io-client";
import moment from "moment-timezone";
import {store} from "../App";
import {action} from "./ReduxHelper";
import {SERVER_DISCONNECT} from "./consts";
import {CURRENT_ACCOUNT_DELETE} from "./state/account/consts";
import {emitter} from "../Utils/globalEmitter";
import debug from "../Utils/debug";
import {showModalLogon} from "../Components/Modals/actions";
import {authorizationWithToken} from "./state/account/actions";

export default class Api {
    constructor(server) {
      this.init(server)
    }

     init = async (server) => {
            this.socket = io(server, {
                transports: ['websocket'],
                reconnect: true,
                autoConnect: false,
            })
            this.server = server
            this.connect = false
            this.connection();
    }

    tryReconnect = () => {
        store.getState()
            .snackbar("warning", {
                header: `Попытка соединения`,
                description: "Переподключение к серверу",
            })
    }

    connection = () => {
        this.socket?.removeAllListeners();

        this.socket.on('connection', (socket) => {
            console.log("connection")
            store.dispatch(action(SERVER_DISCONNECT,false))
        })

        this.socket.on('disconnect', async (err) => {
            console.log("disconnect")
            this.disconnectEvent()
            this.tryReconnect()
        })

        this.socket.on('connect_error', async (err) => {
            console.log("connect_error")
            this.disconnectEvent()
            this.tryReconnect()
        })

        this.socket.on('connect_timeout', async (err) => {
            console.log("connect_timeout")
            this.disconnectEvent()
            this.tryReconnect()
        })

        this.socket.on('reconnect_failed', async (err) => {
            console.log("reconnect_failed")
            this.disconnectEvent()
            this.tryReconnect()
        })

        this.socket.on('reconnect_error', async (err) => {
            console.log("reconnect_error")
            this.disconnectEvent()
            this.tryReconnect()
        })

        this.socket.on("reconnect_failed", () => {
            console.log("reconnect_failed")
        });

        this.socket.on("reconnect_attempt", () => {
            console.log("reconnect_attempt")
        })

        this.socket.on("reconnecting", () => {
            this.disconnectEvent()
            this.tryReconnect()
        })

        this.socket.on("reconnect", () => {
            this.disconnectEvent()
            this.tryReconnect()
        })

        this.socket.on('connect', () => {
            console.log("connect")
            this.connectEvent()
        })

        this.socket.on("close", () => {
            console.log("close")
            this.disconnectEvent()
            this.tryReconnect()
        });

        this.socket.on("error", (error) => {
            this.disconnectEvent()
            this.tryReconnect()
        });

        this.socket.connect()
        console.log("SOCKET INIT")
    }

    disconnectEvent = () => {
        store.dispatch(action(CURRENT_ACCOUNT_DELETE,null))
        store.dispatch(action(SERVER_DISCONNECT,true))
        emitter.emit("loadingApi", false);
        emitter.emit("server_disconnect",{})
        showModalLogon()
    }

    connectEvent = async () => {
        emitter.emit("loadingApi", false);
        emitter.emit("server_connected",{})
        store.dispatch(action(CURRENT_ACCOUNT_DELETE,null))
        store.getState().snackbar("success",{header: `Соединение с сервером установлено`,description: ""});
        store.dispatch(action(SERVER_DISCONNECT,false))
        showModalLogon()
        authorizationWithToken()
    }

    async emit(method,params,disableErrors,disableLoading) {
        if (!this.socket.connected) {
            if (!disableErrors) emitter.emit("errorApi","error connect")
            this.tryReconnect()
            // eslint-disable-next-line no-throw-literal
            throw "error connect"
        }
        if (!disableLoading) emitter.emit("loadingApi",true,method.split(".")[0]);
        this.writeLog(`${method}(${JSON.stringify(params)})`)
        const self = this
        return new Promise((resolve, reject) => {
            debug(`${method} ${JSON.stringify(params)}`)
            this.socket.emit(method, params, (err, data) => {
                if (err) {
                    self.writeLog(`${method} => ERROR => ${typeof err === 'object' ? JSON.stringify(err) : err}`)
                    console.error(method," ",(typeof err === 'object' ? JSON.stringify(err) : err));
                    if (!disableErrors) emitter.emit("errorApi", err)
                    if (!disableLoading) emitter.emit("loadingApi", false);
                    reject((typeof err === 'object' ? JSON.stringify(err) : err))
                } else {
                    self.writeLog(`${method} => RESULT => ${typeof data === 'object' ? JSON.stringify(data) : data}`)
                    if (!disableLoading) {
                        emitter.emit("loadingApi", false);
                    }
                    resolve(data)
                }
            });
        })
    }

    async event(method,callback) {
        if (!this.socket.connected) {
            emitter.emit("errorApi","error connect")
            this.tryReconnect()
            // eslint-disable-next-line no-throw-literal
            throw "error connect"
        }

        const cb = (param) => {
            this.writeLog(`${method} => EVENT => ${JSON.stringify(param)}`)
            callback(param)
        }
        this.socket.on(method,cb);
    }

    async bulk(method,params) {
        if (!this.socket.connected) {
            emitter.emit("errorApi","error connect")
            this.tryReconnect()
            // eslint-disable-next-line no-throw-literal
            throw "error connect"
        }
        emitter.emit("loadingApi",true);
        return new Promise((resolve, reject) => {
            const bulk = [];
            params.map((item) => {
                bulk.push({
                    method,
                    params: item,
                })
            })
            this.socket.emit('bulk.parallel', {bulk}, (err, data) => {
                if (err) {
                    console.error(err);
                    emitter.emit("errorApi",err)
                    emitter.emit("loadingApi",false);
                    reject(err)
                } else {
                    emitter.emit("loadingApi",false);
                    resolve(data.results)
                }
            });
        })
    }

    // eslint-disable-next-line class-methods-use-this
    async writeLog(log) {
        if (window.config.logs) {

        }
    }
}
