import React, {useCallback, useRef, useState} from "react";
import {useDrop} from "react-dnd";
import style from "./Folder.module.scss";
import Camera from "../Cam/Camera";
import {errorEditCamTaxanomy} from "../action";
import {updateCam} from "../../../../api/state/cam/actions";
import PopoverContext from "../../../../Components/Popovers/PopoverContext/PopoverContext";
import PopoverMenuItem from "../PopoverMenuItem/PopoverMenuItem";
import {showModalCreateTaxonomy, showModalDeleteTaxonomy} from "../../../../Components/Modals/actions";
import {worlds} from "../../../../Utils/localization";
import iconPlus from "../../../../Assets/icons/plus.svg";
import iconEdit from "../../../../Assets/icons/edit.svg"
import iconDelete from "../../../../Assets/icons/trash.svg"

const ItemTypes = {
    CT: "ct",
}

const Folder = (props) => {
    const [open,setOpen] = useState(true)
    const ref = useRef()
    const [showPopup,setShowPopup] = useState(false)
    const [{ isOver}, drop] = useDrop({
        accept: ItemTypes.CT,
        drop: (obj,monitor,ct) => {
            const didDrop = monitor.didDrop()
            if (didDrop && !monitor.getItem()) {
                return
            }
            const cam = monitor.getItem().item;
            const parent = monitor.getItem().taxa;
            if (!props.item.children.find((i) => i.id === cam.id)) {
                if (parent) {
                    cam.parents = cam.parents.filter((i) => i !== parent);
                }
                cam.parents.push(props.item.id);
                updateCam(cam)
            } else {
                errorEditCamTaxanomy()
            }

            return { name: 'Dustbin' }
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            isOverCurrent: monitor.isOver({ shallow: true }),
            canDrop: monitor.canDrop(),
            item: monitor.getItem(),
        }),
    })

    const closeOpen = () => {
        setOpen(!open)
    }
    const handler = useCallback(() => {
        setShowPopup((prevState) => { prevState = false; return prevState })
    }, [])

    const leftMouseDown = (el) => {
        handler()
        const buttonPressed = el.button;
        if (buttonPressed === 2) {
            setShowPopup((prevState) => {
                prevState = true;
                return {...prevState}
            })
        }
    }

    return (
      <div ref={ref} className={style.folder}>
        <PopoverContext
          close={() => handler()}
          anchor={ref.current}
          position="left"
          show={showPopup}
          style={{marginTop: "1vw"}}
        >
          <div className={style.menu}>
            <PopoverMenuItem action={() => showModalCreateTaxonomy(props.item)} caption={worlds.addGroup} icon={iconPlus} />
            <PopoverMenuItem action={() => showModalCreateTaxonomy(props.item,true)} caption={worlds.edit} icon={iconEdit} />
            <PopoverMenuItem action={() => showModalDeleteTaxonomy(props.item)} caption={worlds.delete} icon={iconDelete} />
          </div>
        </PopoverContext>
        <div ref={drop} onMouseDown={leftMouseDown} onClick={() => closeOpen()} className={`${style.info} ${isOver && style.infoSuccess}`}>
          {props.item.parent && <div className={style.dotsHor} />}
          {props.item.parent && <div style={{height: props.last && "1.15vw"}} className={style.dots} />}
          <div className={`${style.icon} ${open ? style.iconOpen : style.iconClose}`} />
          <div className={style.caption}>{props.item.name}</div>
        </div>
        <div className={style.background} />
        <div className={`${style.children} ${!open && style.close}`}>
          {props.item.children.map((child,i) => {
                    const last = props.item.children.length === (i + 1)
                    if (child.hasOwnProperty("isArchive")) return <Camera parent={props.item} taxa={props.item.id} last={last} item={child} key={i} />
                    return <Folder last={last} item={child} key={i} />
                })}
        </div>
      </div>
    )
}

export default Folder;
