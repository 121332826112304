import React from "react";
import {connect} from "react-redux";
import style from "./Modal.module.scss"
import ButtonSquare from "../Buttons/ButtonSquare/ButtonSquare";
import cancelIcon from "../../Assets/icons/cancel.svg"
import {closeModal} from "./actions";

class Modal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ref: React.createRef(),
            event: false,
        }
    }

    close = () => {
        if (!this.props.modalContent?.offClose) closeModal();
    }

    render() {
        if (!this.props.modalContent?.content) {
            return null;
        }
        return (
          <div ref={this.state.ref} className={`${style.modal} ${this.props.modal ? style.show : style.hide}`}>
            {this.props.modalContent?.caption
                        ? (
                          <div className={style.content}>
                            <div className={style.caption}>{this.props.modalContent?.caption}</div>
                            {!this.props.modalContent?.offClose && <ButtonSquare action={() => this.close()} icon={cancelIcon} style={{position: "absolute", right: "0.74vw", top: "1.85vh"}} />}
                            <div className={style.body}>
                              {this.props.modalContent?.content}
                            </div>
                          </div>
)
                        : this.props.modalContent?.content}
          </div>
        )
    }
}

export default connect((state) => state,null)(Modal)
