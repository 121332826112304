import React from "react";
import style from "./Plans.module.scss"
import FooterMenu from "./FooterMenu/FooterMenu";
import PlanView from "./PlanView/PlanView";
import ButtonAddCam from "../../Components/Buttons/ButtonAddCam/ButtonAddCam";
import {worlds} from "../../Utils/localization";
import Preloader from "../../Components/Preloader/Preloader";
import {createEdit} from "../../api/state/plan/actions";
import Plan from "../../api/models/Plan";
import {showModalFileManager} from "../../Components/Modals/actions";
import {storageGet} from "../../api/state/fileManager/actions";

export default class Plans extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            plans: [],
            current: {},
            editPlan: false,
            preloader: false,
            openMenu: false,
        }
    }

    loading = (bool) => {
        this.setState({preloader: bool})
    }

    fileSelector = (bool) => {
        showModalFileManager(false,async (item) => {
            let url = await storageGet(item.filepath);
            const type = item.filename.split(".").slice(-1)[0]
            switch (type) {
                case "jpg":
                    url = `data:image/jpeg;base64,${url}`;
                    break;
                case "svg":
                    url = `data:image/svg+xml;base64,${url}`;
                    break;
                case "png":
                    url = `data:image/png;base64,${url}`;
                    break;
                default:
                    url = `data:image/jpeg;base64,${url}`;
                    break;
            }
            if (!bool) this.addPlan(url);
            else {
                const {editPlan} = this.props
                editPlan.image = url
                createEdit(editPlan);
            }
        });
    }

    addPlan = (file) => {
        const plan = new Plan();
        plan.image = file;
        createEdit(plan);
    }

    closeOpenPlanList = () => {
            this.setState({openMenu: !this.state.openMenu});
    }

    render() {
        return (
          <div className={style.plans}>
            <Preloader position="absolute" show={this.state.preloader} />
            <div className={style.plan}>
              {this.props.editPlan
                        ? <PlanView edit plan={this.props.editPlan} updateImage={() => this.fileSelector(true)} />
                        : this.props.currentPlan
                            ? <PlanView plan={this.props.currentPlan} />
                            : (
                              <div className={style.addButton}>
                                {this.props.currentAccount.username !== "user" && (
                                <ButtonAddCam
                                  caption={worlds.addPlan}
                                  action={() => {
                                    this.fileSelector()
                                }}
                                />
)}
                              </div>
)}
            </div>
            <FooterMenu closeOpenPlanList={this.closeOpenPlanList} addPlan={this.fileSelector} plans={this.props.plans} currentPlan={this.props.currentPlan} editPlan={this.props.editPlan} openMenu={this.state.openMenu} />
          </div>
        )
    }
}
