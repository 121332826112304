import React from "react";
import style from "./Renewal.module.scss";
import ButtonRectangle from "../../../Components/Buttons/ButtonRectangle/ButtonRectangle";
import {worlds} from "../../../Utils/localization";
import RenewalItem from "./RenewalItem/RenewalItem";

export default class Renewal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            disabled: true,
            items: [
                {
                    id: 1,
                    caption: worlds.app,
                    version: "2.15",
                    check: false,
                },
                {
                    id: 2,
                    caption: "BRC",
                    version: "2.15",
                    check: false,
                },
                {
                    id: 3,
                    caption: "API",
                    version: "2.15",
                    check: false,
                },
            ],

        }
    }

    check = (e, item) => {
        const {items} = this.state;
        const editItem = items.find((i) => i.id === item.id);
        editItem.check = e.target.checked;
        this.setState({disabled: !items.find((i) => i.check === true),items})
    }

    checkAll = () => {
        const {items} = this.state;
        // eslint-disable-next-line no-return-assign
        items.map((item) => item.check = true)
        this.setState({items,disabled: false})
    }

    render() {
        return (
          <div className={style.renewal}>
            <div className={style.header}>
              <div className={style.caption}>{worlds.availableUpdates}</div>
              <ButtonRectangle action={this.checkAll} caption={worlds.chooseAll} dark width="7.55vw" />
            </div>
            <div className={style.content}>
              {this.state.items.map((item,i) => <RenewalItem item={item} version="2.15" check={this.check} />)}
            </div>
            <div className={style.button}>
              <ButtonRectangle width="10.31vw" height="2.92vw" caption={worlds.update} disabled={this.state.disabled} />
            </div>
          </div>
        )
    }
}
