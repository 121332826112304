import React from 'react';
import Popover from '@material-ui/core/Popover';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    paper: {
        backgroundColor: "#2A2F32",
        fontFamily: "Inter",
        fontSize: "0.73vw",
        color: "#EAEAEA",
        borderRadius: "0.23vw",
        border: "0.05vw solid #3C4347",
        padding: "0",
    },
}));

export default function PopoverContext(props) {
    const classes = useStyles();

    return (
      <Popover
        onMouseDown={() => { props.close() }}
        open={props.show}
        anchorEl={props.anchor}
        className={classes.popover}
        classes={{
                paper: classes.paper,
            }}
        anchorOrigin={{
                vertical: 'top',
                horizontal: props.position,
            }}
        transformOrigin={{
                vertical: 'bottom',
                horizontal: props.position,
            }}
        style={props.style}
      >
        {props.children}
      </Popover>

    );
}
