import {store} from "../../../App";
import {action} from "../../ReduxHelper";
import {
CURRENT_TAXONOMY_CREATE, EDIT_TAXONOMY_CREATE, EDIT_TAXONOMY_DELETE, TAXONOMY_LIST,
} from "./consts"
import TaxonomyDao from "./taxonomyDao";

let api = null;
let dao = null;

export const initiateApi = () => {
    api = store.getState().api;
    dao = new TaxonomyDao(api);
}

export const initiateTaxonomy = async () => {
    let taxonomies = await dao.list()
    taxonomies = taxonomies.sort((a, b) => {
        if (a.name > b.name) return 1
        if (a.name < b.name) return -1;
        return 0;
    });
    let taxonomiesFill = []
    const {cams} = store.getState();
    taxonomies.map((item) => {
        item.fillChild(taxonomies)
        item.children = item.children.concat(Object.values(cams).filter((i) => i.parents.includes(item.id)))
        if (!item.parent) {
            taxonomiesFill.push(item)
        }
    })
    taxonomiesFill = taxonomiesFill.concat(Object.values(cams).filter((i) => i.parents.length === 0))
    store.dispatch(action(TAXONOMY_LIST, taxonomiesFill))
    sortTaxonomy();
}

export const sortTaxonomy = () => {
    let {taxonomies} = store.getState();
    taxonomies = taxonomies.sort((a, b) => {
        if (a.name > b.name) return 1
        if (a.name < b.name) return -1;
        return 0;
    });
    taxonomies = taxonomies.sort((a, b) => {
        if (a.hasOwnProperty('isArchive') && !b.hasOwnProperty('isArchive')) return 1
        if (!a.hasOwnProperty('isArchive') && b.hasOwnProperty('isArchive')) return -1
        return 0;
    });
    for (const taxonomy of taxonomies) {
        sortTaxonomyUtil(taxonomy);
    }
    store.dispatch(action(TAXONOMY_LIST, taxonomies))
}

export const sortTaxonomyUtil = (item) => {
    if (item.hasOwnProperty('isArchive')) {
        return;
    }
    item.children = item.children.sort((a, b) => {
        if (a.name > b.name) return 1
        if (a.name < b.name) return -1;
        return 0;
    });
    item.children = item.children.sort((a, b) => {
        if (a.hasOwnProperty('isArchive') && !b.hasOwnProperty('isArchive')) return 1
        if (!a.hasOwnProperty('isArchive') && b.hasOwnProperty('isArchive')) return -1
        return 0;
    });
    for (const child of item.children) {
        sortTaxonomyUtil(child)
    }
}

export const createTaxonomy = async (taxonomy,isEvent) => {
    const {taxonomies} = store.getState();
    try {
        if (!isEvent) {
            dao.create(taxonomy)
            return;
        }
        const parent = findElemByIdInArray(taxonomies, taxonomy.parent)
        if (parent) parent.children.push(taxonomy)
        else taxonomies.push(taxonomy)
        store.dispatch(action(TAXONOMY_LIST, taxonomies))
        sortTaxonomy();
    } catch (e) {}
}

export const createCurrent = async (taxonomy) => {
    store.dispatch(action(CURRENT_TAXONOMY_CREATE,taxonomy))
}

export const createEdit = async (taxonomy) => {
    store.dispatch(action(EDIT_TAXONOMY_CREATE,taxonomy))
}

export const deleteEdit = async () => {
    store.dispatch(action(EDIT_TAXONOMY_DELETE,{}))
}

export const updateTaxonomy = async (taxonomy,isEvent) => {
    try {
        if (!isEvent) taxonomy = await dao.update(taxonomy)
        const {taxonomies} = store.getState();
        const parent = findElemByIdInArray(taxonomies, taxonomy.parent);
        if (parent) parent.children.splice(parent.children.findIndex((i) => i.id === taxonomy.id), 1, taxonomy);
        else taxonomies.splice(taxonomies.findIndex((i) => i.id === taxonomy.id), 1, taxonomy);
        await createEdit(null);
        store.dispatch(action(TAXONOMY_LIST,taxonomies))
        sortTaxonomy();
    } catch (e) {}
}

export const deleteTaxonomy = async (taxonomy,isEvent) => {
    try {
        if (!isEvent) {
            dao.delete(taxonomy)
            return;
        }
        const {taxonomies} = store.getState();
        const parent = findElemByIdInArray(taxonomies, taxonomy.parent);
        const oldTaxonomy = findElemByIdInArray(taxonomies, taxonomy.id);
        if (!oldTaxonomy) {
            return;
        }
        if (oldTaxonomy.children.length > 0) {
            oldTaxonomy.children.map((item) => {
                if (parent) {
                    item.parent = parent.id;
                    parent.children.push(item);
                } else {
                    item.parent = null;
                    taxonomies.push(item);
                }
            })
        }
        if (parent) parent.children.splice(parent.children.findIndex((i) => i.id === taxonomy.id), 1);
        else taxonomies.splice(taxonomies.findIndex((i) => i.id === taxonomy.id), 1);
        store.dispatch(action(TAXONOMY_LIST, taxonomies))
        sortTaxonomy();
    } catch (e) {}
}

export const findElemByIdInArray = (arr, itemId) => (
    arr.reduce((a, item) => {
        if (a) return a;
        if (item.id === itemId) return item;
        if (item.children) return findElemByIdInArray(item.children, itemId)
    }, null)
);

export const findParentsByNameInTaxonomy = (name) => {
    const taxonomy = store.getState().taxonomies;
    if (!name) {
        return [];
    }
    const searchTaxonomy = [];
    for (const item of taxonomy) {
        if (findElemByNameInTaxonomy(name,item)) {
            if (searchTaxonomy.includes(item)) searchTaxonomy.splice(searchTaxonomy.findIndex(item),1);
        } else {
            searchTaxonomy.push(item)
        }
    }
    return searchTaxonomy;
}

export const findElemByNameInTaxonomy = (name,taxonomy) => {
    let result = null;
    if (taxonomy.name.toUpperCase().includes(name.toUpperCase())) {
        result = taxonomy;
    } else if (taxonomy.children?.length > 0) {
            for (const item of taxonomy.children) {
                if (findElemByNameInTaxonomy(name, item)) {
                    result = item;
                }
            }
        }
    return result;
}
