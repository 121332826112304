import React from "react";
import {store} from "../../../App";
import {action} from "../../ReduxHelper";
import {LOAD_DISKS} from "./consts";
import {worlds} from "../../../Utils/localization";

let api = null;

export const initiateDisks = async () => {
    api = store.getState().api;
    await loadDisks()
}

export const loadDisks = async () => {
    try {
        const disks = await api.emit("strazh.filesystem.list", {});
        store.dispatch(action(LOAD_DISKS, {...disks}))
    } catch (e) {
        console.error(e)
    }
}

export const changeDisks = async (devices) => {
    try {
        const disks = await api.emit("strazh.filesystem.list", {});
        disks.devices = [...devices];
        store.dispatch(action(LOAD_DISKS, disks))
    } catch (e) {}
}

export const mountDisk = async (path) => {
    try {
        await api.emit("strazh.filesystem.create", {device: {path},restart: {brc: true}});
        await loadDisks()
        store.getState().snackbar("success",{header: worlds.diskMounted,description: ""});
    } catch (e) {}
}

export const unmountDisk = async (poolName) => {
    try {
        await api.emit("strazh.filesystem.delete", {pool: {name: poolName},restart: {brc: true}});
        await loadDisks()
        store.getState().snackbar("success",{header: worlds.diskUnmounted,description: ""});
    } catch (e) {}
}
