import {store} from "../App";
import {worlds} from "../Utils/localization";
import {emitter} from "../Utils/globalEmitter";
import {action} from "./ReduxHelper";
import Cam from "./models/Cam";
import {CAM_CREATE, CAM_DELETE, CAM_UPDATE} from "./state/cam/consts";
import {
    CURRENT_PLAN_CREATE,
    CURRENT_PLAN_DELETE,
    PLAN_CREATE,
    PLAN_DELETE,
    PLAN_LIST,
    PLAN_UPDATE,
} from "./state/plan/consts";
import Plan from "./models/Plan";
import Taxonomy from "./models/Taxonomy";
import {
    createTaxonomy, deleteTaxonomy, findElemByIdInArray, initiateTaxonomy, updateTaxonomy,
} from "./state/taxonomy/actions";
import {appendPath, loadFileManagerPath} from "./state/fileManager/actions";
import {savePlan} from "./state/plan/actions";

let api = null;

export const initiateApiSync = () => {
    api = store.getState().api;
    try {
         api.emit('camera.subscribe', {})
         api.emit('plan.subscribe', {})
         api.emit('taxonomy.subscribe', {})
    } catch (e) {}
}

export const getSync = (data) => {
    cameraSubscribe(data);
    planSubscribe(data)
    taxonomySubscribe(data)
    usbDeviceEvents(data)
}

const cameraSubscribe = async (payload) => {
    if (!payload.event?.includes("camera")) return;
    const cam = new Cam();
    cam.serialize(payload.object)
    const oldCam = store.getState().cams[cam.id];
    switch (payload.event) {
        case "camera.create":
            if (!store.getState().cams[cam.id]) {
                emitter.emit("camCreate",cam);
                store.dispatch(action(CAM_CREATE, cam))
                await initiateTaxonomy()
            }
            break;
        case "camera.update":
            if (oldCam !== cam) {
                cam.online = oldCam?.online;
                emitter.emit("camUpdate",cam);
                store.dispatch(action(CAM_UPDATE,cam))
                await initiateTaxonomy()
            }
            break;
        case "camera.delete":
            emitter.emit("camDelete",cam);
            if (store.getState().cams[cam.id]) {
                store.dispatch(action(CAM_DELETE, cam))
                // eslint-disable-next-line no-case-declarations
                const {plans} = store.getState();
                for (const plan of plans) {
                    plan.cams = plan.cams?.filter((i) => i.id !== cam.id);
                    // eslint-disable-next-line no-await-in-loop
                    await savePlan(plan)
                }
                store.dispatch(action(PLAN_LIST, plans))
                await initiateTaxonomy()
            }
            break;
        case "camera.detection.config.set":
            cam.online = oldCam?.online;
            emitter.emit("camUpdate",cam);
            break;
        case "camera.online" || "camera.offline":
            cam.online = payload.event === "camera.online";
            emitter.emit("camUpdate",cam);
            store.dispatch(action(CAM_UPDATE,cam));
            await initiateTaxonomy()
            break;
        default: break;
    }
}

const planSubscribe = async (payload) => {
    if (!payload.event?.includes("plan")) return;
    const plan = new Plan();
    plan.serialize(payload.object)
    switch (payload.event) {
        case "plan.create":
            if (!store.getState().plans.find((i) => i.id === plan.id)) {
                store.dispatch(action(PLAN_CREATE,plan))
                if (store.getState().plans.length === 1) {
                    store.dispatch(action(CURRENT_PLAN_CREATE,plan))
                }
            }
            break;
        case "plan.update":
            if (store.getState().plans.find((i) => i.id === plan.id) !== plan) {
                store.dispatch(action(PLAN_UPDATE,plan))
                if (store.getState().currentPlan?.id === plan.id) {
                    store.dispatch(action(CURRENT_PLAN_CREATE,plan))
                }
            }
            break;
        case "plan.delete":
            if (store.getState().plans.find((i) => i.id === plan.id)) {
                store.dispatch(action(PLAN_DELETE,plan))
                if (store.getState().currentPlan?.id === plan.id) {
                    if (store.getState().plans.length > 0) {
                        store.dispatch(action(CURRENT_PLAN_CREATE, store.getState().plans[0]))
                    } else {
                        store.dispatch(action(CURRENT_PLAN_DELETE,{}))
                    }
                }
            }
            break;
        default:
            break;
    }
}

const taxonomySubscribe = async (payload) => {
    if (!payload.event?.includes("taxonomy")) return;
    const {taxonomies} = store.getState();
    const taxonomy = new Taxonomy();
    taxonomy.serialize(payload.object)
    switch (payload.event) {
        case "taxonomy.create":
            if (!findElemByIdInArray(taxonomies,taxonomy.id)) {
                createTaxonomy(taxonomy, true);
            }
            break;
        case "taxonomy.update":
            if (findElemByIdInArray(taxonomies,taxonomy.id)) updateTaxonomy(taxonomy,true)
            break;
        case "taxonomy.delete":
            if (findElemByIdInArray(taxonomies,taxonomy.id)) deleteTaxonomy(taxonomy,true);
            break;
        default: break;
    }
}

const usbDeviceEvents = async (payload) => {
    switch (payload.event) {
        case "usb.mount":
            loadFileManagerPath();
            appendPath("/",true,true);
            store.getState().snackbar("success",{header: worlds.usbDeviceDetected,description: ""});
            break;
        case "usb.unmount":
            appendPath("/",true,true);
            store.getState().snackbar("warning",{header: worlds.usbDeviceRemoved,description: ""});
            break;
        default: break;
    }
}
