import React from "react";
import flv from "flvjs-megacam";
import {store} from "../../App";
import {camTakeScreenShot} from "../../api/state/cam/actions";

export const createPlayer = async (state,second) => new Promise((resolve,reject) => {
        try {
            if (state.player && !second) {
                state.player.unload()
            }
            if (state.playerSecond) {
                state.playerSecond.unload()
            }

            const {host} = new URL(window.location.hostname && window.location.hostname !== "localhost" ? `http://${window.location.hostname}:4080/` : window.config.host);
            let url = state.url.replace("localhost", host).replace("http", "ws").replace(":4080", "");
            if (!url.includes("videos")) {
                url = url.replace("8080","1090")
            }
            flv.LoggingControl.enableAll = false;
            const player = flv.createPlayer({
                type: 'flv',
                url,
                isLive: true,
                hasAudio: state.cam.sound,
                autoplay: true,
            }, {
                enableStashBuffer: false,
                enableWorker: false,
            })
            player.url = url;
            try {
                if (!second) player.attachMediaElement(state.ref.current)
                else player.attachMediaElement(state.secondRef.current)
            } catch (e) {
                reject("error");
            }
            try {
                player.load();
                player.play();
            } catch (e) {
            }
            if (!second) state.player = player;
            else state.playerSecond = player;
            resolve(state)
        } catch (e) {
            resolve(e)
        }
    })

export const takeScreenShot = (ref,cam,path,time) => new Promise(async (resolve) => {
        await camTakeScreenShot(cam,path,time)
        const snackBar = store.getState().snackbar
        const canvas = document.createElement('canvas');
        canvas.width = 640;
        canvas.height = 480;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(ref.current, 0, 0, canvas.width, canvas.height);
        const dataURI = canvas.toDataURL('image/jpeg');
        snackBar("success",{header: "Скриншот успешно сделан",description: <img style={{width: "13.3rem",height: "9rem",marginBottom: "1rem"}} src={dataURI} />});
        setTimeout(() => {
            resolve(true)
        },200)
    })

export const getDate = (currentTime) => `${currentTime.getFullYear()}-${(`0${currentTime.getMonth() + 1}`).slice(-2)}-${(`0${currentTime.getDate()}`).slice(-2)}`

export const getTime = (currentTime) => `${(`0${currentTime.getHours()}`).slice(-2)}:${(`0${currentTime.getMinutes()}`).slice(-2)}:${(`0${currentTime.getSeconds()}`).slice(-2)}`
