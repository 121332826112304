import React from 'react';
import Popover from '@material-ui/core/Popover';
import PopupState, {bindPopover, bindTrigger} from 'material-ui-popup-state';
import style from "./ProfileMenu.module.scss"
import ButtonPopup from "../../Buttons/ButtonPopup/ButtonPopup";
import {worlds} from "../../../Utils/localization";
import ButtonSquare from "../../Buttons/ButtonSquare/ButtonSquare";
import arrowDownIcon from "../../../Assets/icons/arrowDown.svg";
import {showModalLanguage, showModalLogon} from "../../Modals/actions";
import {exit} from "../../../api/state/account/actions";

export default function ProfileMenu(props) {
    return (
      <PopupState variant="popover">
        {(popupState) => (
          <div className={style.ProfileMenu}>
            <div {...bindTrigger(popupState)}>
              <ButtonSquare icon={arrowDownIcon} iconHeight="55%" />
            </div>
            <Popover
              onClick={() => popupState.close()}
              {...bindPopover(popupState)}
              anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
              transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
              PaperProps={{
                            className: style.menu,
                        }}
            >
              <ButtonPopup action={() => showModalLanguage()} caption={worlds.interfaceLanguage} />
              <ButtonPopup action={() => showModalLogon()} caption={worlds.changeUser} />
              {(props.user === "root" || props.user === "admin") && <ButtonPopup action={() => exit()} caption={worlds.exit} />}
            </Popover>
          </div>
            )}
      </PopupState>
    );
}
