import React from "react";
import {connect} from "react-redux";
import {InputAdornment} from "@material-ui/core";
import style from "./SMTP.module.scss";
import {worlds} from "../../../Utils/localization";
import ButtonRectangle from "../../../Components/Buttons/ButtonRectangle/ButtonRectangle";
import TextFieldM from "../../../Components/Inputs/TextFieldM";
import AutocompleteM from "../../../Components/Inputs/AutocompleteM";
import eyeIcon from "../../../Assets/icons/eye.svg";
import CheckText from "./CheckText/CheckText";
import HeaderSetup from "../HeaderSetup/HeaderSetup";

const mails = ["@gmail.com", "@mail.ru", "@outlook.com"]

class SMTP extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            toMail: mails[0],
            fromMail: mails[0],
            showPassword: false,
        }
    }

    render() {
        return (
          <div className={style.smtp}>
            <HeaderSetup caption={worlds.smtpSetup} />
            <div className={style.content}>
              <label>{worlds.to}</label>
              <div className={style.email}>
                <TextFieldM
                  name="to"
                  variant="outlined"
                  style={{width: "15.83vw", height: "2.92vw", margin: "0"}}
                />
                <AutocompleteM
                  value={this.state.toMail}
                  onChange={(e, newValue) => {
                                           this.setState({toMail: newValue})
                                       }}
                  disableClearable
                  inputValue={this.state.toMail}
                  options={mails}
                  style={{width: "10vw", height: "2.92vw"}}
                />
              </div>
              <CheckText caption={worlds.bindEmail} />
              <label>{worlds.from}</label>
              <div className={style.email}>
                <TextFieldM
                  name="from"
                  variant="outlined"
                  style={{width: "15.83vw", height: "2.92vw", margin: "0"}}
                />
                <AutocompleteM
                  value={this.state.fromMail}
                  onChange={(e, newValue) => {
                                           this.setState({fromMail: newValue})
                                       }}
                  disableClearable
                  inputValue={this.state.fromMail}
                  options={mails}
                  style={{width: "10vw", height: "2.92vw"}}
                />
              </div>
              <label>{worlds.password}</label>
              <TextFieldM
                type={this.state.showPassword ? "text" : 'password'}
                variant="outlined"
                style={{margin: "0",width: "100%",height: "2.92vw"}}
                placeholder={worlds.password}
                InputProps={{
                            endAdornment: (
                              <InputAdornment position="start">
                                <img className={style.passwordShow} src={eyeIcon} onClick={() => this.setState({showPassword: !this.state.showPassword})} />
                              </InputAdornment>
                            ),
                        }}
              />
              <label>{worlds.letterSubject}</label>
              <TextFieldM
                name="letterSubject"
                variant="outlined"
                style={{width: "100%", height: "2.92vw", margin: "0"}}
              />
              <label>
                {worlds.port}
                {' '}
                SMTP
              </label>
              <TextFieldM
                name="portSmtp"
                variant="outlined"
                style={{width: "100%", height: "2.92vw", margin: "0"}}
              />
              <CheckText caption="SSL" />
              <div className={style.button}>
                <ButtonRectangle caption={worlds.save} height="2.92vw" width="10.31vw" />
              </div>
            </div>
          </div>
        )
    }
}

export default connect((state) => state,null)(SMTP)
