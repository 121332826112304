import React from 'react';
import {connect} from "react-redux";
import style from './ReactionsTable.module.scss';
import {worlds} from "../../../../Utils/localization";
import ReactionsTableItem from './ReactionsTableItem/ReactionsTableItem';

function ReactionsTable(props) {
  return (
    <div className={style.reactionsTable}>
      <div className={style.header}>
        <div className={style.headerItem} style={{width: "13.38vw"}}>{worlds.dateTime}</div>
        <div className={style.headerItem} style={{width: "36.46vw"}}>{worlds.ruleName}</div>
        <div className={style.headerItem} style={{width: "36.46vw"}}>{worlds.reaction}</div>
        <div className={style.headerItem} style={{width: "2.5vw"}} />
      </div>
      {props.triggers.length > 0
            ? props.triggers.filter((i) => i.data.triggerType === "switching").map((data,i) => <ReactionsTableItem data={data} key={i} />)
            : <div className={style.noData}>{worlds.addReactionsOnEvent}</div>}
    </div>
  );
}

export default connect((state) => state,null)(ReactionsTable);
