import React from "react";
import {connect} from "react-redux";
import * as _ from "lodash";
import style from "./Cameras.module.scss";
import {worlds} from "../../../Utils/localization";
import ButtonRectangle from "../../../Components/Buttons/ButtonRectangle/ButtonRectangle";
import TextFieldM from "../../../Components/Inputs/TextFieldM";
import {showModalDeleteCams} from "../../../Components/Modals/actions";
import SwitchM from "../../../Components/SwitchM/SwitchM";
import {enableSettingsAllLocation, enableSettingsAllRecord} from "../../../api/state/cam/actions";

class Cameras extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            recordDay: 0,
            latlng: [],
            record: false,
            errors: [],
        }
    }

    editLatLng = (val,name) => {
        const latLng = this.state.latlng;
        _.set(latLng,name,val);
        this.setState({latLng});
    }

    saveAllCamerasArchive =async () => {
        const error = {property: "",constraints: ["ERROR"]}
        const errors = [];
        // eslint-disable-next-line no-restricted-globals
        if (isNaN(this.state.recordDay) || this.state.recordDay <= 0) {
            error.property = "archiveDays";
            errors.push(error);
        }
        this.setState({errors: [...errors]});
        if (errors.length > 0) {
            return;
        }
        const result = {recordDay: this.state.recordDay,record: this.state.record};
        await enableSettingsAllRecord(result);
    }

    saveAllCamerasLocation =async () => {
        const error = {property: "",constraints: ["ERROR"]}
        const errors = [];
        if (this.state.latlng.length < 2) {
            error.property = "lat";
            errors.push(error);
        }
        // eslint-disable-next-line no-restricted-globals
        if (isNaN(this.state.latlng[0])) {
            error.property = "lat";
            errors.push(error);
        }
        // eslint-disable-next-line no-restricted-globals
        if (isNaN(this.state.latlng[1])) {
            error.property = "lng";
            errors.push(error);
        }
        this.setState({errors: [...errors]});
        if (errors.length > 0) {
            return;
        }
        const result = {latlng: this.state.latlng};
        await enableSettingsAllLocation(result);
    }

    render() {
        return (
          <div className={style.cameras}>
            <div className={style.header}>
              <div className={style.caption}>{worlds.camerasSetup}</div>
              <div className={style.headerMenu}>
                <ButtonRectangle style={{margin: "0 0.5vw"}} action={() => showModalDeleteCams()} caption={worlds.removeAllCameras} width="8.96vw" dark />
              </div>
            </div>
            <div className={style.content}>
              <div className={style.record}>
                <div className={style.recordCaption}>{worlds.enableRecording}</div>
                <SwitchM checked={this.state.record} onChange={() => this.setState({record: !this.state.record})} />
              </div>
              <label>{worlds.archiveStoragePeriod}</label>
              <TextFieldM
                value={this.state.recordDay}
                onChange={(e) => this.setState({recordDay: e.target.value})}
                placeholder={worlds.archiveStoragePeriod}
                variant="outlined"
                name="archiveDays"
                errors={this.state.errors}
                style={{width: "100%", height: "2.92vw", margin: 0}}
              />
              <div className={style.button}>
                <ButtonRectangle action={() => this.saveAllCamerasArchive()} caption={worlds.save} height="2.92vw" width="10.31vw" style={{margin: "1vw 0"}} />
              </div>
              <label>{worlds.cameraCoordinates.slice(0,-1)}</label>
              <TextFieldM
                value={this.state.latlng?.[0]}
                onChange={(e) => this.editLatLng(e.target.value,"[0]")}
                placeholder={worlds.latitude}
                name="lat"
                variant="outlined"
                errors={this.state.errors}
                style={{width: "100%", height: "2.92vw", margin: 0}}
              />
              <TextFieldM
                value={this.state.latlng?.[1]}
                name="lng"
                onChange={(e) => { if (!this.state.latlng?.[0]) this.editLatLng(0,"latlng[0]"); this.editLatLng(e.target.value,"[1]") }}
                placeholder={worlds.longitude}
                variant="outlined"
                errors={this.state.errors}
                style={{width: "100%", height: "2.92vw", margin: "1vw 0"}}
              />
              <div className={style.button}>
                <ButtonRectangle action={() => this.saveAllCamerasLocation()} caption={worlds.save} height="2.92vw" width="10.31vw" />
              </div>
            </div>
          </div>
        )
    }
}

export default connect((state) => state,null)(Cameras)
