import {SNACKBAR} from "./actions";

const prevState = {
    snackbar: null,
}

export const snackbar = (state = prevState.snackbar, action) => {
    switch (action.type) {
        case SNACKBAR:
            return action.obj;
        default:
            return state;
    }
}
