import {plainToClass} from "class-transformer";
import {
    CURRENT_TRIGGER_CREATE,
    CURRENT_TRIGGER_DELETE,
    EDIT_TRIGGER_CREATE,
    EDIT_TRIGGER_DELETE,
    REACTIONS_LIST,
    TRIGGER_CREATE,
    TRIGGER_DELETE,
    TRIGGER_LIST,
    TRIGGER_UPDATE,
} from "./consts";

import Trigger from "../../models/Trigger";

const prevState = {
    triggers: [],
    currentTrigger: {},
    editTrigger: {},
    reactions: [],
}

export const triggers = (state = prevState.triggers, action) => {
    switch (action.type) {
        case TRIGGER_LIST:
            return plainToClass(Trigger,[...action.obj]);
        case TRIGGER_CREATE:
            if (state.find((i) => i.id === action.obj.id)) return state;
            state.push(action.obj);
            return plainToClass(Trigger,[...state]);
        case TRIGGER_UPDATE:
            state.splice(state.findIndex((i) => i.id === action.obj.id), 1, action.obj);
            return plainToClass(Trigger,[...state]);
        case TRIGGER_DELETE:
            state.splice(state.findIndex((i) => i.id === action.obj.id), 1);
            return plainToClass(Trigger,[...state]);
        default:
            return state;
    }
}

export const reactions = (state = prevState.reactions, action) => {
    switch (action.type) {
        case REACTIONS_LIST:
            return [...action.obj];
        default:
            return state;
    }
}

export const editTrigger = (state = prevState.editTrigger, action) => {
    switch (action.type) {
        case EDIT_TRIGGER_CREATE:
            return plainToClass(Trigger,{...state,...action.obj});
        case EDIT_TRIGGER_DELETE:
            return null;
        default:
            return state;
    }
}

export const currentTrigger = (state = prevState.currentTrigger, action) => {
    switch (action.type) {
        case CURRENT_TRIGGER_CREATE:
            return plainToClass(Trigger,{...state,...action.obj});
        case CURRENT_TRIGGER_DELETE:
            return null;
        default:
            return state;
    }
}
