import React from 'react';
import {InputAdornment} from "@material-ui/core";
import style from './ReactionsForm.module.scss';
import TextFieldM from "../../../../Components/Inputs/TextFieldM";
import {worlds} from "../../../../Utils/localization";
import SearchIcon from "../../../../Assets/icons/search.svg";
import ButtonAddCam from "../../../../Components/Buttons/ButtonAddCam/ButtonAddCam";

function ReactionsForm(props) {
  return (
    <div className={style.reactionsForm}>
      <TextFieldM
        type="text"
        variant="outlined"
        style={{height: "100%",margin: "0",width: "85%"}}
        placeholder={worlds.searchByReactions}
        InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img style={{width: "0.94vw"}} src={SearchIcon} />
                </InputAdornment>
              ),
            }}
      />
      <ButtonAddCam href="/createSwitchingReaction" caption={worlds.addReaction} width="12.24vw" height="100%" margin="0" />
    </div>
  );
}

export default ReactionsForm;
