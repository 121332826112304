import React from "react";
import {InputAdornment} from "@material-ui/core";
import style from "./PlanChoice.module.scss";
import {worlds} from "../../../../Utils/localization";
import searchIcon from "../../../../Assets/icons/search.svg";
import TextFieldM from "../../../../Components/Inputs/TextFieldM";
import PlanItem from "../PlanItem/PlanItem";

export default class PlanChoice extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            searchValue: "",
        }
    }

    openCloseMenu = () => {
        this.setState({searchValue: ""});
    }

    search = (e) => {
        this.setState({searchValue: e.target.value});
    }

    render() {
        return (
          <div>
            {this.props.openMenu && <div className={style.closePlanList} onClick={this.props.closeOpenPlanList} />}
            <div className={style.planChoice}>
              <div className={style.button} onClick={this.props.closeOpenPlanList}>
                <div className={style.planName}>{this.props.currentPlan.name}</div>
                <div className={`${style.icon} ${!this.props.openMenu ? style.iconDown : style.iconUp}`} />
              </div>
            </div>
            <div className={`${style.menu} ${!this.props.openMenu && style.displayNone}`}>
              <div className={style.searchInput}>
                <TextFieldM
                  type="text"
                  variant="outlined"
                  style={{height: "2.92vw", width: "100%", margin: "0"}}
                  placeholder={worlds.search}
                  value={this.state.searchValue}
                  onChange={this.search}
                  InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <img style={{width: "0.94vw"}} src={searchIcon} />
                                  </InputAdornment>
                                ),
                            }}
                />
              </div>
              {this.props.plans?.filter((i) => i.name.toLowerCase().indexOf(this.state.searchValue) >= 0).map((item, i) => <PlanItem openCloseMenu={this.openCloseMenu} plan={item} key={i} />)}
            </div>
          </div>
        )
    }
}
