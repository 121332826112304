import React from "react";
import style from "./PlayerDetection.module.scss"
import Player from "../../PlayerMSE/Player"
import sound from "../../../Assets/error.wav"

export default (props) => (
  <div className={style.PlayerDetection}>
    <audio src={sound} autoPlay />
    <Player cam={props.cam} guardPlayer videoKey={Math.random() * 10} />
  </div>
    )
