import React from "react";
import {InputAdornment} from "@material-ui/core";
import {connect} from "react-redux";
import style from "./RuleForm.module.scss";
import {worlds} from "../../../../Utils/localization";
import SearchIcon from "../../../../Assets/icons/search.svg";
import TextFieldM from "../../../../Components/Inputs/TextFieldM";
import ButtonAddCam from "../../../../Components/Buttons/ButtonAddCam/ButtonAddCam";
import ButtonRectangle from "../../../../Components/Buttons/ButtonRectangle/ButtonRectangle";
import {setGuard} from "../../../../api/state/guard/actions";

class RuleForm extends React.Component {
    changeGuard = async () => {
        const {guard} = this.props;
        await setGuard(!guard);
    }

    render() {
        return (
          <div className={style.ruleForm}>
            <TextFieldM
              type="text"
              variant="outlined"
              style={{height: "100%",margin: "0",width: "75%"}}
              placeholder={worlds.searchByRules}
              onChange={(e) => this.props.search(e)}
              InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img style={{width: "0.94vw"}} src={SearchIcon} />
                          </InputAdornment>
                        ),
}}
            />
            {this.props.currentAccount.username !== "user" && <ButtonAddCam href="/createRule" caption={worlds.addRule} margin="0 0.83vw" width="12.03vw" height="100%" />}
            <ButtonRectangle action={this.changeGuard} style={{margin: this.props.currentAccount.username === "user" ? "0 0.83vw" : "0"}} caption={!this.props.guard ? worlds.putOnGuard : worlds.removeGuard} height="95%" width="11.46vw" dark={this.props.guard && true} />
          </div>
        );
    }
}

export default connect((state) => state,null)(RuleForm)
