import React from "react";
import {connect} from "react-redux";
import style from "./ModalDeleteAudioFile.module.scss"
import ButtonRectangle from "../../Buttons/ButtonRectangle/ButtonRectangle";
import {worlds} from "../../../Utils/localization";
import {deleteCam} from "../../../api/state/cam/actions";
import {closeModal} from "../actions";
import {deleteAudioFile} from "../../../api/state/switching/actions";

class ModalDeleteAudioFile extends React.Component {
    delete = (cam,parent) => {
        deleteCam(cam,parent)
    }

    render() {
        return (
          <div className={style.modalDeleteAudioFile}>
            <div className={style.caption}>
              {`${worlds.deleteAudioFile} ${this.props.filename}?`}
            </div>
            <div className={style.controls}>
              <ButtonRectangle width="7.55rem" caption={worlds.delete} action={() => { deleteAudioFile(this.props.filename); closeModal() }} />
              <ButtonRectangle width="7.55rem" dark caption={worlds.cancel} action={() => closeModal()} />
            </div>
          </div>
        )
    }
}

export default connect((state) => state,null)(ModalDeleteAudioFile)
