import React from "react";
import style from "../SMTP.module.scss";
import CheckBox from "../../../../Components/CheckBox/CheckBox";

export default class CheckText extends React.Component {
    render() {
        return (
          <div className={style.checkText}>
            <CheckBox />
            <div className={style.text}>{this.props.caption}</div>
          </div>
        )
    }
}
