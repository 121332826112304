import React from "react";
import style from "./PopoverMenuItem.module.scss";

export default class PopoverMenuItem extends React.Component {
    constructor(props) {
        super(props);
    }

    onClick=() => {
        this.props.action()
    }

    render() {
        return (
          <div onClick={this.onClick} className={style.popoverMenuItem}>
            <div className={style.icon} style={{backgroundImage: `url(${this.props.icon}) `}} />
            <div className={style.caption}>{this.props.caption}</div>
          </div>
        );
    }
}
