import {plainToClass} from "class-transformer";
import {DHCP_CLIENT_LIST} from "./consts";
import DHCP from "../../models/DHCP";

const prevState = {
    dhcpClients: [],
}

export const dhcpClients = (state = prevState.dhcpClients, action) => {
    switch (action.type) {
        case DHCP_CLIENT_LIST:
            if (!action.obj) return state;
            return plainToClass(DHCP,[...action.obj]);
        default:
            return state;
    }
}
