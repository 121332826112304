import React from "react";
import {connect} from "react-redux";
import style from "./ModalDeleteCams.module.scss"
import ButtonRectangle from "../../Buttons/ButtonRectangle/ButtonRectangle";
import {worlds} from "../../../Utils/localization";
import {deleteAllCams} from "../../../api/state/cam/actions";
import {closeModal} from "../actions";

class ModalDeleteCams extends React.Component {
    delete = () => {
        deleteAllCams();
    }

    render() {
        return (
          <div className={style.modalDeleteCam}>
            <div className={style.caption}>
              {`${worlds.removeAllCameras} ?`}
            </div>
            <div className={style.controls}>
              <ButtonRectangle width="7.55rem" caption={worlds.delete} action={() => { this.delete(); closeModal() }} />
              <ButtonRectangle width="7.55rem" dark caption={worlds.cancel} action={() => closeModal()} />
            </div>
          </div>
        )
    }
}

export default connect((state) => state,null)(ModalDeleteCams)
