import {ADD_LOG, LOGS_LIST} from "./consts";

const prevState = {
    logs: {count: 0,loggers: []},
}

export const logs = (state = prevState.logs, action) => {
    switch (action.type) {
        case LOGS_LIST:
            return {...action.obj};
        case ADD_LOG:
            if (!action.obj) return state;
            if (state.loggers.count >= 7) {
                state.loggers.pop();
            }
            state.loggers.unshift({...action.obj})
            state.count++;
            return {...state};
        default:
            return state;
    }
}
