import React from "react";
import {connect} from "react-redux";
import {validate} from "class-validator";
import style from "./ModalCreateTaxonomy.module.scss"
import {worlds} from "../../../Utils/localization";
import ButtonRectangle from "../../Buttons/ButtonRectangle/ButtonRectangle";
import {closeModal} from "../actions";
import TextFieldM from "../../Inputs/TextFieldM";
import Taxonomy from "../../../api/models/Taxonomy";
import {createTaxonomy, deleteEdit, updateTaxonomy} from "../../../api/state/taxonomy/actions";

class ModalCreateTaxonomy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: null,
            taxa: this.props.edit ? this.props.parent : new Taxonomy(),
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.taxa !== this.props.parent && this.props.parent && this.props.edit) {
            this.setState({taxa: this.props.parent})
        }
        if (this.state.taxa === prevProps.parent && !this.props.edit) {
            this.setState({taxa: new Taxonomy()})
        }
    }

    saveTaxonomy = () => {
        this.setState({errors: null})
        const {taxa} = this.state;
        validate(taxa).then(async (errors) => { // errors is an array of validation errors
            if (errors.length > 0) {
                this.setState({errors});
            } else {
                if (!this.props.edit) {
                    taxa.parent = this.props.parent?.id;
                    await createTaxonomy(taxa);
                } else {
                    await updateTaxonomy(taxa)
                }
                closeModal()
                this.setState({taxa: new Taxonomy()});
            }
        });
    }

    componentWillUnmount() {
        this.setState({taxa: null});
    }

    render() {
        const taxonomy = this.state.taxa;
        return (
          <div className={style.modalCreateTaxonomy}>
            <div className={style.name}>
              <label>
                {worlds.groupName}
                <span>*</span>
                <small>{}</small>
              </label>
              <TextFieldM
                name="name"
                value={taxonomy.name}
                errors={this.state.errors}
                onChange={(e) => { taxonomy.name = e.target.value; this.setState({taxa: taxonomy}) }}
                placeholder={worlds.groupName}
                variant="outlined"
                style={{width: "20vw", height: "2.92vw", margin: 0}}
              />
            </div>
            <div className={style.controls}>
              <ButtonRectangle width="7.55rem" caption={worlds.save} action={() => { this.saveTaxonomy(); }} />
              <ButtonRectangle width="7.55rem" dark caption={worlds.cancel} action={() => { closeModal(); deleteEdit() }} />
            </div>
          </div>
        )
    }
}

export default connect((state) => state,null)(ModalCreateTaxonomy)
