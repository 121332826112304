import React from "react";
import {store} from "../../../App";
import {action} from "../../ReduxHelper";
import {
    ADD_GUARD_CAM_VIEW,
    ADD_GUARD_DETECTION,
    ADD_GUARD_DETECTION_LIST,
    CURRENT_GUARD,
    DELETE_GUARD_CAM_VIEW,
    SET_GUARD_COUNT,
    UPDATE_GUARD_DETECTION,
} from "./consts"
import GuardDetection from "../../models/GuardDetection";
import {emitter} from "../../../Utils/globalEmitter";
import {getCam} from "../cam/actions";
import PlayerDetection from "../../../Components/Player/PlayerDetection/PlayerDetection";
import {worlds} from "../../../Utils/localization";
import Trigger from "../../models/Trigger";
import {createTrigger, deleteTrigger, updateTrigger} from "../trigger/actions";
import {snackbarRef} from "../../../Router";

let api = null;

export const initiateApiGuard = async () => {
    api = store.getState().api;
    await initiateGuard();
    getGuardDetectionsWithPage([],7,0);
    try {
        api.emit("guard.subscribe", {})
        api.emit("lvs.alarm.subscribe", {})
    } catch (e) {}
}

export const initiateGuard = async () => {
    try {
        const guard = await api.emit("guard.get",{});
        store.dispatch(action(CURRENT_GUARD,guard.guard))
    } catch (e) {}
}

export const setGuard = async (bool) => {
    try {
        const guard = await api.emit("guard.set", {guard: bool});
        store.dispatch(action(CURRENT_GUARD, guard.guard))
    } catch (e) {}
}

export const addGuardCamView = async (cam) => {
    try {
        store.dispatch(action(ADD_GUARD_CAM_VIEW, cam))
    } catch (e) {}
}

export const deleteGuardCamView = async (cam) => {
    try {
        store.dispatch(action(DELETE_GUARD_CAM_VIEW, cam))
    } catch (e) {}
}

export const initiateEventTriggerGuard = async (data) => {
    switch (data.event) {
        case "guard.set":
            store.dispatch(action(CURRENT_GUARD,data.object.state))
            break;
        case "lvs.alarm.block":
            if (data.object?.type === "alarm") store.getState().snackbar("warning",{header: worlds.attentionAnxiety,description: ""});
            break;
        case "lvs.alarm.deblock":
            if (data.object?.type === "alarm") store.getState().snackbar("warning",{header: worlds.alarmIsOff,description: ""});
            break;
        case "guard.detection":
            // eslint-disable-next-line no-case-declarations
            const detections = store.getState().guardDetections;
            // eslint-disable-next-line no-case-declarations
            let detection = null;
            // eslint-disable-next-line no-case-declarations
            const cam = await getCam(data.object.camera.id);
            if (!cam) {
                return
            }
            if (data.object.payload.action === "start") {
                cam.detection = true;
            } else {
                cam.detection = false;
            }
            cam.guardEvent = true;
            if (detections.find((i) => i.id === data.object.payload.id)) {
                detection = {...detections.find((i) => i.id === data.object.payload.id)}
                detection.stop = data.object.payload.date;
                detection.url = data.object?.detection?.motion?.url;
                if (detection.image && data.object.payload.action === "stop") {
                    store.dispatch(action(UPDATE_GUARD_DETECTION, detection))
                }
                if (!detection.image && data.object.payload.action === "snapshot") {
                    detection.image = `data:image/jpeg;base64,${data.object.payload.base64}`;
                    store.dispatch(action(UPDATE_GUARD_DETECTION, detection))
                }
            } else {
                detection = new GuardDetection()
                detection.id = data.object.payload.id;
                detection.cameraId = data.object.camera.id;
                detection.url = data.object?.detection?.motion?.url;
                detection.name = worlds.security;
                detection.camName = cam.name;
                detection.start = data.object.payload.date;
                detection.date = new Date(data.object.payload.date);
                store.dispatch(action(ADD_GUARD_DETECTION, detection))
                if (!store.getState().guardCamView[cam.id]) {
                    addGuardCamView(cam)
                    store.getState().snackbar("info", {
                        header: cam.name,
                        description: <PlayerDetection cam={cam} close={snackbarRef?.current?.closeSnackbar()} />,
                        persist: true,
                        player: true,
                    });
                }
                emitter.emit("guardDetection", cam);
            }
            emitter.emit("camUpdate", cam);

            break;
        default:
            break
    }
}

export const getGuardDetectionsWithPage = async (cams,limit,offset,start,end) => {
    try {
        const serverId = store.getState().serverBrc.id;
        const res = await api.emit('server.detection.list', {
            sort: {date: "desc"},
            limit,
            offset,
            filter: {
                camera: {id: {$in: cams ?? []}},
                server: {id: serverId},
                date: {$gte: start || "", $lte: end || ""},
            },
        });
        store.dispatch(action(SET_GUARD_COUNT, res.count));
        const detections = [];
        for (const item of res.detections) {
            // eslint-disable-next-line no-await-in-loop
            const cam = await getCam(item.remote_id);
            const detection = new GuardDetection()
            detection.id = item.url;
            detection.cameraId = item.remote_id;
            detection.url = item.url;
            detection.name = worlds.security;
            detection.camName = worlds.noData;
            detection.start = item.start;
            detection.stop = item.end;
            detection.date = new Date(item.start);
            const {host} = new URL(window.config.host);
            const url = item.snapshot.replace("localhost", host).replace(":4080", "");
            detection.image = url;
            detections.push(detection);
            if (cam) {
                detection.camName = cam.name;
            }
        }
        store.dispatch(action(ADD_GUARD_DETECTION_LIST, detections))
    } catch (e) {}
}

export const deleteOpenScreenTriggerEgsv = async () => {
    const {triggers} = store.getState()
    const trigger = triggers.find((i) => i.data?.triggerType === "screenOpen") ?? new Trigger();
    trigger && await deleteTrigger(trigger)
}

export const createOpenScreenTriggerEgsv = async (screenId,host) => {
    if (!screenId) {
        await deleteOpenScreenTriggerEgsv()
        return
    }
    const {triggers} = store.getState()
    const trigger = triggers.find((i) => i.data?.triggerType === "screenOpen") ?? new Trigger();
    trigger.name = "Screen Open"
    trigger.events = ["external.lvs.alarm.block"]
    trigger.reactions = [{webhook: {screen_id: screenId,host},type: "webhook.screen"}]
    trigger.data = {triggerType: "screenOpen"}
    trigger.id ? await updateTrigger(trigger) : await createTrigger(trigger)
}
