import {store} from "../../../App";
import {action} from "../../ReduxHelper";
import {
ADD_DOWNLOAD_FILE, CLEAR_DOWNLOAD_FILES, FILE_MANAGER_CURRENT_PATH, FILE_MANAGER_STORAGE,
} from "./consts";

let api = null;

export const initiateFileManager = () => {
    api = store.getState().api;
    loadFileManagerPath()
}

export const loadFileManagerPath = async () => {
    try {
        const storage = await api.emit("storage.list", {});
        store.dispatch(action(FILE_MANAGER_STORAGE, storage.storage))
    } catch (e) {
        store.dispatch(action(FILE_MANAGER_STORAGE, []))
    }
}

export const appendPath = async (path,disableError,disableLoader) => {
    try {
        const storage = await api.emit("storage.list", {path},disableError,disableLoader);
        store.dispatch(action(FILE_MANAGER_STORAGE, storage.storage))
        store.dispatch(action(FILE_MANAGER_CURRENT_PATH, path))
    } catch (e) {
        store.dispatch(action(FILE_MANAGER_CURRENT_PATH, "/"))
        store.dispatch(action(FILE_MANAGER_STORAGE, []))
    }
}

export const storageGet = async (filepath) => {
    try {
        const storage = await api.emit("storage.get", {storage: {filepath}});
        return storage.base64;
    } catch (e) {}
}

export const addDownloadFile = async (file) => {
    store.dispatch(action(ADD_DOWNLOAD_FILE, file))
}

export const clearDownloadFiles = async () => {
    store.dispatch(action(CLEAR_DOWNLOAD_FILES,[]));
}
