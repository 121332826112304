import React, {useState} from "react";
import {InputAdornment} from "@material-ui/core";
import {connect} from "react-redux";
import style from "./CamsMonitoring.module.scss";
import {worlds} from "../../../Utils/localization";
import searchIcon from "../../../Assets/icons/search.svg";
import TextFieldM from "../../../Components/Inputs/TextFieldM";
import Cam from "./Cam/Cam";

const CamsMonitoring = (props) => {
    const [searchText,setSearchText] = useState("");

    const searchCams = () => {

    }

    return (
      <div className={style.camsMonitoring}>
        <div className={style.header}>
          <div className={style.searchInput}>
            <TextFieldM
              type="text"
              variant="outlined"
              style={{height: "2.5vw", width: "46.67vw", margin: "0"}}
              value={searchText}
              placeholder={worlds.searchByCameraName}
              onChange={(e) => setSearchText(e.target.value)}
              InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <img style={{width: "0.94vw"}} src={searchIcon} />
                              </InputAdornment>
                            ),
                        }}
            />
          </div>
        </div>
        <div className={style.eventList}>
          <div className={style.headList}>
            <div className={style.headItem} style={{minWidth: "10.31vw"}}>{worlds.dateTime}</div>
            <div className={style.headItem} style={{width: "100%"}}>{worlds.cameraName}</div>
            <div className={style.headItem} style={{minWidth: "13.475vw"}}>{worlds.state}</div>
            <div className={style.headItem} style={{minWidth: "13.475vw"}}>{worlds.recording}</div>
            <div className={style.headItem} style={{minWidth: "13.475vw"}}>{worlds.motionDetector}</div>
            <div className={style.headItem} style={{minWidth: "13.475vw"}}>{worlds.faceDetection}</div>
          </div>
          <div className={style.itemList}>
            {Object.values(props.cams) ? Object.values(props.cams).filter((i) => i.name.includes(searchText)).map((item, i) => <Cam cam={item} key={i} />) : <div className={style.addEvent}>{worlds.addReactionsOnEvent}</div>}
          </div>
        </div>
      </div>
    )
}

export default connect((state) => state,null)(CamsMonitoring);
