import React from "react";
import {connect} from "react-redux";
import style from "./ModalDeleteRule.module.scss"
import ButtonRectangle from "../../Buttons/ButtonRectangle/ButtonRectangle";
import {worlds} from "../../../Utils/localization";
import {closeModal} from "../actions";
import {deleteTrigger} from "../../../api/state/trigger/actions";

class ModalDeleteRule extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentLanguage: this.props.currentLanguage,
        }
    }

    delete = (trigger) => {
        deleteTrigger(trigger)
    }

    render() {
        return (
          <div className={style.modalDeleteRule}>
            <div className={style.caption}>
              {`${worlds.deleteRule} ${this.props.rule?.name}?`}
            </div>
            <div className={style.controls}>
              <ButtonRectangle width="7.55rem" caption={worlds.delete} action={() => { this.delete(this.props.rule); closeModal() }} />
              <ButtonRectangle width="7.55rem" dark caption={worlds.cancel} action={() => closeModal()} />
            </div>
          </div>
        )
    }
}

export default connect((state) => state,null)(ModalDeleteRule)
