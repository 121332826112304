import React from "react";
import {withRouter} from "react-router";
import {validate} from "class-validator";
import style from "./AddCam.module.scss"
import ButtonSquare from "../../../Components/Buttons/ButtonSquare/ButtonSquare";
import arrowIcon from "../../../Assets/icons/arrow-dark.svg"
import {worlds} from "../../../Utils/localization";
import ButtonRectangle from "../../../Components/Buttons/ButtonRectangle/ButtonRectangle";
import ButtonTopMenu from "../../../Components/Buttons/ButtonTopMenu/ButtonTopMenu";
import AddCamOne from "./AddCamOne";
import AddCamSome from "./AddCamSome";
import TaxonomyShort from "../../../Components/TaxonomyShort/TaxonomyShort";
import CamItem from "../CamItem/CamItem";
import {addCam, addCams} from "../../../api/state/cam/actions";
import {patterns} from "../../../api/consts";

class AddCam extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: null,
            moreCamsResult: [],
            singleSource: true,
        }
    }

    updateMoreCam = (moreCam) => {
        this.setState({moreCam})
    }

    checkInErrors = () => {
        this.setState({errors: null});
        const cam = this.props.editCam;
        if (cam.model === "other") {
            if (!cam.ip) cam.ip = "127.0.0.1";
        } else {
            cam.url = patterns.find((i) => i.name === cam.model).pattern
                .replace("{account}", cam.login ? `${cam.login}:${cam.password}@` : "")
                .replace("{ip}",cam.ip)
                .replace("{thread}","0")
                .replace("{user}", cam.login)
                .replace("{password}",cam.password)
            cam.secondaryUrl = patterns.find((i) => i.name === cam.model).pattern
                .replace("{account}", cam.login ? `${cam.login}:${cam.password}@` : "")
                .replace("{ip}",cam.ip)
                .replace("{thread}","1")
                .replace("{user}", cam.login)
                .replace("{password}",cam.password)
        }
        validate(cam).then(async (errors) => { // errors is an array of validation errors
            if (errors.length > 0) {
                this.setState({errors});
            } else if (this.state.singleSource) {
                    await addCam(cam)
                    this.props.history.goBack()
                } else {
                    addCams(cam,this.callBackSomeCams)
                }
        });
    }

    callBackSomeCams = (data) => {
        this.setState({moreCamsResult: data})
    }

    render() {
        return (
          <div className={style.addCam}>
            <div className={style.header}>
              <ButtonSquare width="2.15vw" height="2.15vw" icon={arrowIcon} back />
              <div className={style.caption}>{worlds.addCamcorder}</div>
              <div className={style.rightContent}>
                <ButtonRectangle action={this.checkInErrors} caption={worlds.add} />
              </div>
            </div>
            <div className={style.middle}>
              <ButtonTopMenu active={this.state.singleSource} action={() => this.setState({singleSource: true})} caption={worlds.singleSource} />
              {/* <ButtonTopMenu active={!this.state.singleSource} action={()=>{this.setState({singleSource:false})}} caption={worlds.multipleSources}/> */}
            </div>
            <div className={style.content}>
              <div className={style.footer}>
                {this.state.singleSource
                        ? <AddCamOne errors={this.state.errors} />
                        : <AddCamSome errors={this.state.errors} />}
                <div className={style.taxonomy}>
                  <div className={style.taxonomyCaption}>{worlds.taxonomy}</div>
                  <div className={style.taxonomyHelper}>{worlds.chooseWhichGroupAddCamera}</div>
                  <div className={style.taxonomyContent}>
                    <TaxonomyShort />
                  </div>
                </div>
              </div>
              {!this.state.singleSource && (
              <div className={style.camList}>
                <div className={style.caption}>{worlds.cameraList}</div>
                <div className={style.header}>
                  <div className={style.shortest}>#</div>
                  <div className={style.long}>{worlds.name}</div>
                  <div className={style.long}>{worlds.description}</div>
                  <div className={style.long}>{worlds.firstStreamURL}</div>
                  <div className={style.long}>{worlds.secondStreamURL}</div>
                  <div className={style.short}>{worlds.ptz}</div>
                  <div className={style.short}>{worlds.recording}</div>
                  <div className={style.status}>{worlds.status}</div>
                </div>
                {this.state.moreCamsResult.length > 0
                        ? this.state.moreCamsResult.map((item, i) => <CamItem cam={item} key={i + 1} />)
                        : <div className={style.noData}>{worlds.noData}</div>}
              </div>
)}
            </div>
          </div>
        )
    }
}

export default withRouter(AddCam)
