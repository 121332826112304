import React from "react";
import style from "./FooterMenu.module.scss"
import ButtonSquare from "../../../Components/Buttons/ButtonSquare/ButtonSquare";
import plusIcon from "../../../Assets/icons/plus.svg"
import clearScreenIcon from "../../../Assets/icons/clearScreen.svg"
import {worlds} from "../../../Utils/localization";
import ScreenPatternMenu from "../ScreenPatternMenu/ScreenPatternMenu";
import fullScreenIcon from "../../../Assets/icons/fullScreen.svg"

export default class FooterMenu extends React.Component {
    render() {
        return (
          <div className={`${style.footerMenu}`}>
            <ScreenPatternMenu acceptPattern={this.props.acceptPattern} />
            <ButtonSquare action={this.props.addItem} name={worlds.addCell} icon={plusIcon} width="1.87vw" height="1.87vw" />
            <ButtonSquare action={this.props.clearAll} name={worlds.clearScreen} icon={clearScreenIcon} width="1.87vw" height="1.87vw" />
            <ButtonSquare style={{marginLeft: "auto"}} name={worlds.fullScreen} icon={fullScreenIcon} action={this.props.goFull} width="1.87vw" height="1.87vw" />
          </div>
        )
    }
}
