import React from 'react';

import moment from "moment-timezone";
import style from './FaceDetectionListItem.module.scss';
import {showModalFaceApiFullImage} from "../../../Components/Modals/actions";

function FaceDetectionListItem(props) {
  return (
    <div onClick={() => showModalFaceApiFullImage(props.face)} className={style.listGroupItem}>
      <div className={style.faceImage} style={{backgroundImage: `url(${props.smallFace})`}} />
      <div className={style.info}>
        <div className={style.name}>{ props.face?.camera?.name}</div>
        <p className={style.date}>{moment(props.face.detected_at).format('DD.MM.YYYY HH:mm:ss')}</p>
      </div>
    </div>
  );
}

export default FaceDetectionListItem;
