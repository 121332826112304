import React from 'react';
import PropTypes from "prop-types";
import {createPlayer} from "./actions";
import style from "./PlayerMp4.module.scss"
import Preloader from "../Preloader/Preloader";
import TimeLine from "./TimeLine/TimeLine";
import iconFullScreen from "../../Assets/icons/fullScreen.svg"
import ButtonSquare from "../Buttons/ButtonSquare/ButtonSquare";
import iconCam from "../../Assets/icons/camLight.svg"

class PlayerMp4 extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      preloader: true,
      ref: React.createRef(),
      refParent: React.createRef(),
      isFull: false,
      cam: null,
      url: "",
      pauseTime: false,
      movePlayer: false,
      isTakeScreenShot: false,
    }
  }

  componentDidMount() {
    this.initPlayerCurrent()
    document.addEventListener("fullscreenchange",(el) => {
      if (!document.fullscreenElement) {
        this.setState({isFull: false})
      }
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.url !== this.props.url) {
      this.destroy()
      this.initPlayerCurrent()
    }
  }

  componentWillUnmount() {
    this.destroy()
  }

  initPlayerCurrent = async () => {
    this.setState({preloader: true})
    this.destroy()
    const {state} = this;
    state.url = this.props.url;
    try {
      await createPlayer(state)
    } catch (e) {
      console.error(e)
    }

    try {
      this.state.player._mediaElement.addEventListener("loadedmetadata", () => {
        this.setState({preloader: true});
      })
      this.state.player._mediaElement.addEventListener("seeking", () => {
        this.setState({preloader: true});
      })
      this.state.player._mediaElement.addEventListener("seeked", () => {
        this.setState({preloader: false});
      })
      this.state.player._mediaElement.addEventListener("loadeddata", () => {
        this.setState({preloader: false});
      })
      this.state.player._mediaElement.addEventListener("ended", () => {

      })
    } catch (e) {
      console.error(e);
    }
  }

  destroy=() => {
    clearInterval(this.timeInteval)
    clearInterval(this.archiveDataInterval)
    if (this.state.player) {
      try {
        this.state.player.unload();
        this.state.player.destroy();
      } catch (e) {
        console.error(e)
      }
    }
  }

  onMouseMove = () => {
    this.setState({movePlayer: true})
    clearTimeout(this.timeoutMove)
    this.timeoutMove = setTimeout(() => {
      this.setState({movePlayer: false})
    },1000)
  }

  goFull = async () => {
    if (!document.fullscreenElement) {
      this.setState({isFull: true})
      this.state.refParent.current.requestFullscreen();
    } else if (document.exitFullscreen) {
        await document.exitFullscreen();
        this.setState({isFull: false})
      }
  }

  onClose = () => {
    this.destroy()
    if (this.props.close) this.props.close();
  }

  seekPlayer=(time) => {
   this.state.player.currentTime = time;
  }

  render() {
    return (
      <div onDoubleClick={this.goFull} ref={this.state.refParent} style={{fontSize: this.state.isFull ? "2vw" : "1em"}} onMouseMove={this.onMouseMove} className={`${style.player} ${this.state.movePlayer ? style.controlsShow : ""}`}>
        {this.state.preloader && (
          <div className={style.preloader}>
            <Preloader position="absolute" background="rgba(0,0,0,0.3)" show={this.state.preloader} />
          </div>
)}
        <div className={`${style.controlsBottom}`}>
          {this.state.player && (
          <div className={style.dateTime}>
            <div className={style.time}>{`${this.state.player.currentTime.toFixed(2)} / ${this.state.player.duration.toFixed(2)}`}</div>
          </div>
)}
          <div className={style.timeline}>
            {this.state.player && <TimeLine seek={this.seekPlayer} currentTime={this.state.player.currentTime} duration={this.state.player.duration} />}
          </div>
        </div>
        <div className={`${style.controlsTop}`}>
          <div className={style.camName}>
            <ButtonSquare style={{transition: "none"}} fill="transparent" width="1em" height="1em" icon={iconCam} />
            <div className={style.camNameCaption}>
              {/* {this.props.cam.name} */}
            </div>
          </div>
          <div className={style.controlsTopButtons}>
            <ButtonSquare style={{transition: "none"}} width="1em" height="1em" icon={iconFullScreen} action={() => { this.goFull() }} />
          </div>
        </div>
        <video ref={this.state.ref} />

      </div>
    )
  }
}

export default PlayerMp4

PlayerMp4.propTypes = {
  time: PropTypes.string,
  close: PropTypes.func,
  cam: PropTypes.object,
};
