import React from "react";
import {withRouter} from "react-router-dom";
import PropTypes from 'prop-types';
import style from "./ButtonSquare.module.scss"
import PopoverCaption from "../../Popovers/PopoverCaption/PopoverCaption";

class ButtonSquare extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fullName: {open: false, anchor: null},
        }
    }

    enterCaption = (el) => {
        if (!this.props.name) return;
        const {fullName} = this.state;
        if (fullName.open) {
            return
        }
        fullName.open = true;
        fullName.anchor = el.currentTarget;
        this.setState({fullName})
    }

    leaveCaption = () => {
        const {fullName} = this.state;
        if (!fullName.open) {
            return
        }
        fullName.open = false;
        fullName.anchor = null;
        this.setState({fullName})
    }

    onClick = () => {
        if (this.props.back === true) this.props.history.goBack()
         else if (this.props.action) this.props.action()
    }

    render() {
        return (
          <div
            style={{
...this.props.style, width: this.props.width || "1.46vw", height: this.props.height || "1.46vw", backgroundColor: this.props.fill || "transparent",borderRadius: this.props.radius || 0,
}}
            onMouseEnter={this.enterCaption}
            onMouseLeave={this.leaveCaption}
            onClick={() => this.onClick()}
            className={style.buttonSquare}
          >
            {!this.props.text
                    ? (
                      <img
                        style={{width: this.props.iconWidth || "66%", height: this.props.iconHeight || "66%"}}
                        src={this.props.icon}
                        className={style.icon}
                      />
)
                    : <div className={style.text}>{this.props.text}</div>}
            <PopoverCaption position="left" open={this.state.fullName.open} anchor={this.state.fullName.anchor} caption={this.props.name} />
          </div>
        )
    }
}

export default withRouter(ButtonSquare)

ButtonSquare.propTypes = {
    action: PropTypes.func,
    height: PropTypes.string,
    width: PropTypes.string,
    back: PropTypes.bool,
    name: PropTypes.string,
    fill: PropTypes.string,
    icon: PropTypes.string,
    radius: PropTypes.string,
    text: PropTypes.string,
    iconHeight: PropTypes.string,
    iconWidth: PropTypes.string,
};
