import {TextField, withStyles} from "@material-ui/core";

export default withStyles({
    root: {
        margin: "1.25vw 1.46vw 1.25vw 0.94vw",
        height: "2.91vw",
        width: "15.73vw",
        '& label': {
            color: "#8C939B",
            // marginTop:"-0.35rem",
            marginTop: "-1.2vh",
            fontFamily: "Inter",
            fontSize: "0.93vw",
        },
        '& label.Mui-focused': {
            color: "#EAEAEA",
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#EAEAEA',
        },
        '& .MuiOutlinedInput-root': {
            height: "100%",
            backgroundColor: "#3C4347",
            borderRadius: 0,
            fontSize: "0.93vw",
            color: "#EAEAEA",
            paddingRight: "0.78vw",
            '& fieldset': {
                borderColor: "transparent",
                borderWidth: "0.05vw",
            },
            '&:hover fieldset': {
                borderColor: '#EAEAEA',
            },
            '&.Mui-focused fieldset': {
                borderWidth: "0.05vw",
                borderColor: '#EAEAEA',
            },
        },
    },
})(TextField)
