import React from "react";
import {connect} from "react-redux";
import style from "./ModalDeleteCamInPlan.module.scss"
import {worlds} from "../../../Utils/localization";
import ButtonRectangle from "../../Buttons/ButtonRectangle/ButtonRectangle";
import {closeModal} from "../actions";
import {createEdit} from "../../../api/state/plan/actions";
import {emitter} from "../../../Utils/globalEmitter";

class ModalDeleteCamInPlan extends React.Component {
    delete = async (cam,plan) => {
        plan.cams = plan.cams?.filter((i) => i.id !== cam.id);
        await createEdit(plan);
        emitter.emit("deleteCamInPlan", cam, plan);
    }

    render() {
        return (
          <div className={style.modalCamDeleteInPlan}>
            <div className={style.caption}>
              {`${worlds.removeCamera} ${this.props.cam?.name} ${worlds.offPlan} ${this.props.plan.name}?`}
            </div>
            <div className={style.controls}>
              <ButtonRectangle width="7.55rem" caption={worlds.delete} action={() => { this.delete(this.props.cam,this.props.plan); closeModal() }} />
              <ButtonRectangle width="7.55rem" dark caption={worlds.cancel} action={() => closeModal()} />
            </div>
          </div>
        )
    }
}

export default connect((state) => state,null)(ModalDeleteCamInPlan)
