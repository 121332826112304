import React from 'react';
import Popover from '@material-ui/core/Popover';
import PopupState, {bindPopover, bindTrigger} from 'material-ui-popup-state';
import style from "./MenuCamTree.module.scss"
import ButtonPopup from "../../Buttons/ButtonPopup/ButtonPopup";
import trash from "../../../Assets/icons/trash.svg"
import edit from "../../../Assets/icons/edit.svg"
import {worlds} from "../../../Utils/localization";
import {showModalAddPolygonInCam, showModalBrowser, showModalDeleteCam} from "../../Modals/actions";

export default function MenuCamTree(props) {
    return (
      <PopupState variant="popover">
        {(popupState) => (
          <div className={style.menuCamTree}>
            <div className={style.button} {...bindTrigger(popupState)} />
            <Popover
              onClick={popupState.close}
              {...bindPopover(popupState)}
              anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
              transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
              PaperProps={{
                            className: style.menu,
                        }}
            >
              <ButtonPopup popupState={popupState} href={`/editCam/${props.elem.id}`} icon={edit} caption={worlds.edit} />
              <ButtonPopup action={() => { showModalDeleteCam(props.elem,props.parent); popupState.close() }} icon={trash} caption={worlds.trash} />
              <ButtonPopup action={() => { showModalBrowser(props.elem); popupState.close() }} icon={edit} caption={worlds.webSettings} />
              {props.elem?.guard
                        && <ButtonPopup action={() => { showModalAddPolygonInCam(props.elem); popupState.close() }} icon={edit} caption={worlds.addMotionZone} />}
            </Popover>
          </div>
            )}
      </PopupState>
    );
}
