import React from "react";
import {store} from "../../App";
import {CREATE_MODAL_CONTENT, DELETE_MODAL_CONTENT, SHOW_MODAL} from "../../api/consts";
import {action} from "../../api/ReduxHelper";
import ModalLogon from "./ModalLogon/ModalLogon";
import ModalChangeLanguage from "./ModalChangeLanguage/ModalChangeLanguage";
import {worlds} from "../../Utils/localization";
import ModalDeleteCam from "./ModalDeleteCam/ModalDeleteCam";
import ModalCreateTaxonomy from "./ModalCreateTaxonomy/ModalCreateTaxonomy";
import ModalDeleteTaxonomy from "./ModalDeleteTaxonomy/ModalDeleteTaxonomy";
import ModalDeleteCamInPlan from "./ModalDeleteCamInPlan/ModalDeleteCamInPlan";
import ModalDateTimePicker from "./ModalDateTImePicker/ModalDateTimePicker";
import ModalDownloadFile from "./ModalDownloadFile/ModalDownloadFile";
import ModalDeletePlan from "./ModalDeletePlan/ModalDeletePlan";
import ModalFaceApiFullImage from "./ModalFaceApiFullImage/ModalFaceApiFullImage";
import ModalPlayer from "./ModalPlayer/ModalPlayer";
import ModalDeleteRule from "./ModalDeleteRule/ModalDeleteRule";
import ModalFileManager from "./ModalFileManager/ModalFileManager";
import ModalUpdateSystem from "./ModalUpdateSystem/ModalUpdateSystem";
import ModalPlayerMp4 from "./ModalPlayerMp4/ModalPlayerMp4";
import {appendPath} from "../../api/state/fileManager/actions";
import ModalAuthDHCP from "./ModalAuthDHCP/ModalAuthDHCP";
import ModalClearDhcpList from "./ModalClearDhcpList/ModalClearDhcpList";
import ModalDeleteCams from "./ModalDeleteCams/ModalDeleteCams";
import ModalResetAll from "./ModalResetAll/ModalResetAll";
import ModalBrowser from "./ModalBrowser/ModalBrowser";
import ModalDiskUnmount from "./ModalDiskUnmount/ModalDiskUnmount";
import ModalFilterLogs from "./ModalFilterLogs/ModalFilterLogs";
import ModalTechnicalWork from "./ModalTechnicalWork/ModalTechnicalWork";
import ModalDeleteAudioFile from "./ModalDeleteAudioFile/ModalDeleteAudioFile";
import ModalAddPolygonInCam from "./ModalAddPolygonInCam/ModalAddPolygonInCam";
import ModalTerminalCam from "./ModalTerminal/ModalTerminalCam";
import ModalChangePassword from "./ModalChangePassword/ModalChangePassword";
import ModalAddScreen from "./ModalAddScreen/ModalAddScreen";

export const closeModal = () => {
    if (store.getState().currentAccount) appendPath('/',true,true);
    store.dispatch(action(SHOW_MODAL,null))
    setTimeout(() => {
        modalClearContent();
    },500)
}

export const showModal = () => {
    store.dispatch(action(SHOW_MODAL,true))
}

export const modalCreateContent = (caption,content,offClose) => {
    modalClearContent();
    store.dispatch(action(CREATE_MODAL_CONTENT,{caption,content,offClose}))
}

export const modalClearContent = () => {
    store.dispatch(action(DELETE_MODAL_CONTENT,null))
}

export const showModalLogon = async (bool) => {
    modalCreateContent(worlds.authorization,<ModalLogon />, !store.getState().currentAccount)
    showModal()
}

export const showModalLanguage = () => {
    modalCreateContent(worlds.interfaceLanguage,<ModalChangeLanguage />)
    showModal()
}

export const showModalDeleteCam = (cam,parent) => {
    modalCreateContent(worlds.cameraRemoval,<ModalDeleteCam cam={cam} parent={parent} />)
    showModal()
}

export const showModalDeleteCams = () => {
    modalCreateContent(worlds.removeAllCameras,<ModalDeleteCams />)
    showModal()
}

export const showModalResetAllSettings = () => {
    modalCreateContent(worlds.resetSettings,<ModalResetAll />)
    showModal()
}

export const showModalBrowser = (elem) => {
    const url = new URL(elem.url.replace("rtsp","http"))
    modalCreateContent(elem.name,<ModalBrowser url={url.hostname} />)
    showModal()
}

export const showModalCreateTaxonomy = (parent,edit) => {
    modalCreateContent(edit ? worlds.editGroup : worlds.addGroup,<ModalCreateTaxonomy edit={edit} parent={parent} />)
    showModal()
}

export const showModalDeleteTaxonomy = (taxonomy) => {
    modalCreateContent(worlds.deleteGroup,<ModalDeleteTaxonomy taxonomy={taxonomy} />)
    showModal()
}

export const showModalDeleteCamInPlan = (cam,plan) => {
    modalCreateContent(worlds.cameraRemoval, <ModalDeleteCamInPlan cam={cam} plan={plan} />)
    showModal()
}

export const showModalDateTimePicker = (action) => {
    modalCreateContent(worlds.goToMoment,<ModalDateTimePicker action={action} />)
    showModal()
}

export const showModalDownloadFile = (cam) => {
    modalCreateContent(worlds.download,<ModalDownloadFile cam={cam} />)
    showModal()
}

export const showModalDeletePlan = (plan) => {
    modalCreateContent(worlds.deletePlan,<ModalDeletePlan plan={plan} />)
    showModal()
}

export const showModalFaceApiFullImage = (face) => {
    modalCreateContent("",<ModalFaceApiFullImage face={face} />)
    showModal()
}

export const showModalPlayer = (camera) => {
    modalCreateContent("",<ModalPlayer camera={camera} />)
    showModal()
}

export const showModalPlayerMp4 = (detection) => {
    modalCreateContent("",<ModalPlayerMp4 detection={detection} />)
    showModal()
}

export const showModalDeleteRule = (rule) => {
    modalCreateContent(worlds.deleteRule ,<ModalDeleteRule rule={rule} />)
    showModal()
}

export const showModalFileManager = (download,callBack) => {
    modalCreateContent(worlds.fileManager,<ModalFileManager download={download} callBack={callBack} />)
    showModal();
}

export const showModalUpdateSystem = () => {
    modalCreateContent(worlds.renewal,<ModalUpdateSystem />,true)
    showModal();
}

export const showModalAuthDHCP = (ip,mac) => {
    modalCreateContent(worlds.authorization,<ModalAuthDHCP ip={ip} mac={mac} />)
    showModal();
}

export const showModalClearDhcpList = () => {
    modalCreateContent(worlds.clearTheList,<ModalClearDhcpList />)
    showModal();
}

export const showModalDiskUnmount = (unmount) => {
    modalCreateContent(worlds.unmount,<ModalDiskUnmount unmount={unmount} />)
    showModal();
}

export const showModalFilterLogs = (applyFilter,resetFilter) => {
    modalCreateContent(worlds.filter,<ModalFilterLogs applyFilter={applyFilter} resetFilter={resetFilter} />)
    showModal();
}

export const showModalTechnicalWork = () => {
    modalCreateContent(worlds.attentionTechnicalWorkInProgress,<ModalTechnicalWork />)
    showModal();
}

export const showModalDeleteAudioFile = (filename) => {
    modalCreateContent(worlds.deleteAudioFile,<ModalDeleteAudioFile filename={filename} />)
    showModal();
}

export const showModalAddPolygonInCam = (cam) => {
    modalCreateContent(cam.name,<ModalAddPolygonInCam cam={cam} />)
    showModal();
}

export const showModalTerminal = () => {
    modalCreateContent(worlds.terminal,<ModalTerminalCam />)
    showModal();
}

export const showModalChangePassword = () => {
    modalCreateContent(worlds.passwordChange,<ModalChangePassword />)
    showModal();
}

export const showModalAddScreen = () => {
    modalCreateContent(worlds.addScreenID ,<ModalAddScreen />)
    showModal();
}
