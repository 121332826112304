import React from 'react';
import Popover from '@material-ui/core/Popover';
import {makeStyles} from '@material-ui/core/styles';
import Player from "../../PlayerMSE/Player";
import iconCancel from "../../../Assets/icons/cancel.svg";
import ButtonSquare from "../../Buttons/ButtonSquare/ButtonSquare";
import style from "./PopoverPlayer.module.scss"

const useStyles = makeStyles((theme) => ({
    root: {
      zIndex: 2,
    },
    paper: {
        position: "relative",
        display: "flex",
        overflow: "hidden",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: 0,
        width: "29.27vw",
        height: "19.21vw",
        backgroundColor: "#2A2F32",
        fontFamily: "Inter",
        color: "#EAEAEA",
        borderRadius: "0.23vw",
        border: "0.05vw solid #3C4347",
    },
}));

export default function PopoverPlayer(props) {
    const classes = useStyles();
    return (
      <Popover
        onClick={(el) => { if (el.target === el.currentTarget.firstChild)props.close() }}
        open={props.open}
        anchorEl={props.anchor}
        className={classes.popover}
        classes={{
                paper: classes.paper,
            }}
      >
        <div className={style.header}>
          <div className={style.caption}>{props.cam.name}</div>
          <ButtonSquare style={{borderLeft: "1px solid #3C4347"}} iconWidth="1vw" width="2.81vw" height="2.81vw" icon={iconCancel} action={() => { props.close() }} />
        </div>
        <div className={style.player}>
          <Player time={props.time} cam={props.cam} videoKey={Math.random() * 10} />
        </div>
      </Popover>
    );
}
