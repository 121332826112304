import React from "react";
import style from "./SystemMonitor.module.scss";
import SystemDetails from "./SystemDetails/SystemDetails";
import {worlds} from "../../../Utils/localization";

export default class SystemMonitor extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            processor: [{
                id: 1,
                caption: worlds.core,
                detail: "5.169%",
            },{
                id: 2,
                caption: worlds.core,
                detail: "5.169%",
            },{
                id: 3,
                caption: worlds.core,
                detail: "5.169%",
            },{
                id: 4,
                caption: worlds.core,
                detail: "5.169%",
            }],
            ram: [{
                caption: worlds.used,
                detail: "3.1 ГБ",
            }],
            hdd: [{
                caption: worlds.filled,
                detail: "84%",
            }, {
                caption: worlds.free,
                detail: "16%",
            }, {
                caption: worlds.capacity,
                detail: "50 ГБ",
            }],
            interface: [{
                caption: worlds.loading,
                detail: "24.1 кб/сек",
            }],

        }
    }

    openTerminal = () => {
        const electron = window.require('electron');
        electron.remote.getGlobal('openTerminal')();
    }

    render() {
        return (
          <div className={style.systemMonitor}>
            <div className={style.blocks}>
              <SystemDetails header={worlds.processor} item={this.state.processor} />
              <SystemDetails header={worlds.ram} item={this.state.ram} />
            </div>
            <div className={style.blocks}>
              <SystemDetails header="HDD" item={this.state.hdd} />
              <SystemDetails header={worlds.networkInterface} item={this.state.interface} />
            </div>
            {/* <div className={`${style.blocks} ${style.controls}`}> */}
            {/*    <ButtonRectangle caption={worlds.openTerminal} action={()=>this.openTerminal()}/> */}
            {/*    <ButtonRectangle caption={worlds.update} action={()=>globalUpdate()}/> */}
            {/*    <ButtonRectangle caption={"restart brc"} action={()=>restartBrc()}/> */}
            {/*    <ButtonRectangle caption={"status brc"} action={()=>statusBrc()}/> */}
            {/*    <ButtonRectangle caption={"stop brc"} action={()=>stopBrc()}/> */}
            {/*    <ButtonRectangle caption={"start brc"} action={()=>startBrc()}/> */}
            {/* </div> */}
          </div>
        )
    }
}
