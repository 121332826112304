import React from "react";
import style from "./WeekDayPicker.module.scss";
import {worlds} from "../../Utils/localization";
import WeekDay from "./WeekDay/WeekDay";

export default class WeekDayPicker extends React.Component {
    render() {
        return (
          <div className={style.weekDayPicker} style={{width: this.props.width ? this.props.width : "40.73vw"}}>
            {this.props.caption && <div className={style.caption}>{this.props.caption}</div>}
            <div className={style.weekDays}>
              {worlds.dateDays.map((item,i) => {
                        let active = false;
                        this.props.days.forEach((day) => {
                            if (day === i + 1) active = true;
                        })
                        return <WeekDay caption={item} active={active} index={i + 1} key={i} action={this.props.action} />
                    })}
            </div>
          </div>
        )
    }
}
