import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import style from "./ModalChangePassword.module.scss"
import ButtonRectangle from "../../Buttons/ButtonRectangle/ButtonRectangle";
import {worlds} from "../../../Utils/localization";
import {closeModal} from "../actions";
import TextFieldM from "../../Inputs/TextFieldM";
import {accountUpdate} from "../../../api/state/account/actions";
import {store} from "../../../App";
import {action} from "../../../api/ReduxHelper";
import {SERVER_DISCONNECT} from "../../../api/consts";

class ModalChangePassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            passwordOne: "",
            passwordTwo: "",
        }
    }

    changePassword = async () => {
        if (this.state.passwordOne === this.state.passwordTwo) {
            await accountUpdate({id: this.props.currentAccount.id,password: this.state.passwordOne})
            store.dispatch(action(SERVER_DISCONNECT,false))
        } else {
            this.props.snackbar('warning',{header: `${worlds.error} ${worlds.passwordChange}`,description: ""})
        }
    }

    render() {
        return (
          <div className={style.modalAuthDHCP}>
            <div className={style.content}>
              <TextFieldM
                style={{margin: "0.42vw 0", width: "100%"}}
                variant="outlined"
                value={this.state.passwordOne}
                placeholder={worlds.password}
                onChange={(e) => this.setState({passwordOne: e.target.value})}
              />
              <TextFieldM
                variant="outlined"
                style={{margin: "0.42vw 0 1.87vw", width: "100%"}}
                value={this.state.passwordTwo}
                placeholder={worlds.password}
                onChange={(e) => this.setState({passwordTwo: e.target.value})}
              />
            </div>
            <div className={style.controls}>
              <ButtonRectangle width="15.25rem" caption={worlds.passwordChange} action={() => { this.changePassword(); closeModal() }} />
              <ButtonRectangle width="7.55rem" dark caption={worlds.cancel} action={() => closeModal()} />
            </div>
          </div>
        )
    }
}

export default withRouter(connect((state) => state,null)(ModalChangePassword))
