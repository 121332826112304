import {store} from "../../../App";
import {action} from "../../ReduxHelper";
import {KEYBOARD_CURRENT_INPUT, KEYBOARD_ENABLE, KEYBOARD_SHOW} from "./consts";

export const setKeyBoardShow = (bool,ref,callBack) => {
    if (!ref || !callBack || !bool) {
        setKeyBoardInputRef(null,null);
        store.dispatch(action(KEYBOARD_SHOW,false))
    } else {
        setKeyBoardInputRef(ref,callBack);
        store.dispatch(action(KEYBOARD_SHOW,true))
    }
}

export const setKeyBoardEnable = (bool) => {
    store.dispatch(action(KEYBOARD_ENABLE,bool));
}

export const setKeyBoardInputRef = (ref,callBack) => {
    store.dispatch(action(KEYBOARD_CURRENT_INPUT,{ref,callBack}));
}
