import React from "react";
import {connect} from "react-redux";
import style from "./LogoTop.module.scss"
import Logo from "../../../Assets/logo.svg"
import 'moment/locale/es'
import 'moment/locale/ru'
import 'moment/locale/kk'
// eslint-disable-next-line import/order
import moment from "moment-timezone";

class LogoTop extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            time: new Date(Date.parse(this.props.serverTime)),
        }
    }

    componentDidMount() {
       this.interval = setInterval(() => {
            const {time} = this.state
            time.setSeconds(time.getSeconds() + 1);
            this.setState({time});
        },1000)
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        return (
          <div className={style.logoTop}>
            <img src={Logo} alt="" />
            {this.state.time && (
            <div className={style.datetime}>
              <div className={style.date}>
                {moment(this.state.time).locale(this.props.currentLanguage).format('DD MMM YYYY, dd')}
              </div>
              <div className={style.time}>
                {moment(this.state.time).format('HH:mm:ss')}
              </div>
            </div>
)}
          </div>
        )
    }
}

export default connect((state) => state,null)(LogoTop);
