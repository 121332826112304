import React from "react";
import {connect} from "react-redux";
import {Terminal} from 'xterm';
import {FitAddon} from 'xterm-addon-fit';
import style from "./ModalTerminalCam.module.scss"
import "xterm/css/xterm.css"
import {electronApi} from "../../../api/state/electron/actions";

// import "./terminal.css"
class ModalTerminalCam extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: "",
            term: new Terminal(),
            history: [],
        }
    }

    async componentDidMount() {
        const {term} = this.state;
        const fitAddon = new FitAddon();
        term.loadAddon(fitAddon);
        term.open(document.getElementById('terminal'));
        fitAddon.fit();
        const conn = await electronApi?.('get-ssh')

        conn.on('ready', () => {
            console.log('Client :: ready');
            conn.shell((err, stream) => {
                if (err) throw err;
                stream.on('close', () => {
                    console.log('Stream :: close');
                    term.write('Connection closed')
                    conn.end();
                }).on('data', (data) => {
                    term.write(data.toString());
                }).stderr.on('data', (data) => {
                    term.write(data.toString());
                });

                term.onKey((key) => {
                    stream.write(key.key);
                });
            });
        }).connect({
            host: '127.0.0.1',
            port: window.config.ssh.port,
            username: window.config.ssh.user,
            password: window.config.ssh.password,
        });
    }

    componentWillUnmount() {
        const {term} = this.state;
        term.dispose()
    }

    render() {
        return (
          <div className={style.modalTerminal}>
            <div style={{width: "730px",height: "410px"}} id="terminal" />

          </div>
        )
    }
}

export default connect((state) => state,null)(ModalTerminalCam)
