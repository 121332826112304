import React from "react";
import {connect} from "react-redux";
import style from "./FooterMenu.module.scss"
import ButtonSquare from "../../../Components/Buttons/ButtonSquare/ButtonSquare";
import iconAddPlan from "../../../Assets/icons/addPlan.svg"
import {worlds} from "../../../Utils/localization";
import PlanEdit from "./PlanEdit/PlanEdit";
import PlanCurrent from "./PlanCurrent/PlanCurrent";

class FooterMenu extends React.Component {
    render() {
        const {currentPlan} = this.props
        const {plans} = this.props;
        const {editPlan} = this.props
        return (
          <div className={style.footerMenu}>
            {editPlan
                    ? this.props.currentAccount?.username !== "user" && <PlanEdit editPlan={editPlan} />
                    : currentPlan
                        ? <PlanCurrent closeOpenPlanList={this.props.closeOpenPlanList} addPlan={this.props.addPlan} currentPlan={currentPlan} plans={plans} openMenu={this.props.openMenu} />
                        : this.props.currentAccount?.username !== "user" && (
                        <ButtonSquare
                          style={{marginLeft: "1.93vw", marginRight: "1.56vw"}}
                          action={this.props.addPlan}
                          name={worlds.addPlan}
                          icon={iconAddPlan}
                          width="1.87vw"
                          height="1.87vw"
                        />
)}
          </div>
        )
    }
}

export default connect((state) => state,null)(FooterMenu)
