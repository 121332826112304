import React from "react";
import {connect} from "react-redux";
import style from "./ModalPlayerMp4.module.scss"
import ButtonSquare from "../../Buttons/ButtonSquare/ButtonSquare";
import iconClose from "../../../Assets/icons/cancel.svg"
import {closeModal} from "../actions";
import Player from "../../PlayerMp4/PlayerMp4";
import {getCam} from "../../../api/state/cam/actions";
import {worlds} from "../../../Utils/localization";

class ModalPlayerMp4 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cam: null,
            url: "",
        }
    }

    async componentDidMount() {
        const cam = await getCam(this.props.detection.cameraId);
        if (cam) this.setState({cam})
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.cam?.id !== this.props.detection.cameraId) {
            const cam = await getCam(this.props.detection.cameraId);
            if (cam) this.setState({cam})
        }
    }

    render() {
        return (
          <div className={style.ModalPlayer}>
            <div className={style.close}>
              <ButtonSquare width="2.81vw" height="2.81vw" icon={iconClose} action={() => closeModal()} />
            </div>
            <div className={style.info}>
              <div className={style.name}>{ this.state.cam?.name ?? worlds.noData}</div>
            </div>
            <div className={style.player}>
              <Player cam={this.state.cam} url={this.props.detection.url} />
            </div>
          </div>
        )
    }
}

export default connect((state) => state,null)(ModalPlayerMp4)
