import React from "react";
import style from "./Profile.module.scss"
import ButtonSquare from "../Buttons/ButtonSquare/ButtonSquare";
import userIcon from "../../Assets/icons/user.svg"
import ProfileMenu from "./ProfileMenu/ProfileMenu";

export default class Profile extends React.Component {
    render() {
        return (
          <div className={style.profile}>
            <ButtonSquare icon={userIcon} fill="#3C4347" iconHeight="55%" />
            <div className={style.role}>{this.props.userName}</div>
            <ProfileMenu user={this.props.userName} />
          </div>
        )
    }
}
