import React, {useLayoutEffect} from 'react';
import {Provider} from "react-redux";
import {applyMiddleware, createStore} from "redux";
import thunk from 'redux-thunk';
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import reducers from './reducers';
import Router from "./Router";
import "./App.css"

export const store = createStore(reducers(), applyMiddleware(thunk));

function App() {
    return (
        <Provider store={store}>
          <DndProvider backend={HTML5Backend}>
            <Router />
          </DndProvider>
        </Provider>
    );
}

export default App;
