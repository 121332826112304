import React from "react";
import {connect} from "react-redux";
import style from "./ModalPlayer.module.scss"
import ButtonSquare from "../../Buttons/ButtonSquare/ButtonSquare";
import iconClose from "../../../Assets/icons/cancel.svg"
import {closeModal} from "../actions";
import Player from "../../PlayerMSE/Player";
import {getCam} from "../../../api/state/cam/actions";

class ModalPlayer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cam: null,
        }
    }

    async componentDidMount() {
        this.setState({cam: await getCam(this.props.camera.id)})
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.cam?.id !== this.props.camera.id) {
            this.setState({cam: await getCam(this.props.camera.id)})
        }
    }

    render() {
        return (
          <div className={style.ModalPlayer}>
            <div className={style.close} onClick={() => closeModal()}>
              <ButtonSquare width="2.81vw" height="2.81vw" icon={iconClose} onClick={() => closeModal()} />
            </div>
            <div className={style.info}>
              <div className={style.name}>{ this.props.camera?.name}</div>
            </div>
            <div className={style.player}>
              {this.state.cam && <Player cam={this.state.cam} videoKey={Math.random() * 10} />}
            </div>
          </div>
        )
    }
}

export default connect((state) => state,null)(ModalPlayer)
