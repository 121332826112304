import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import style from "./Taxonomy.module.scss";
import {worlds} from "../../../Utils/localization";
import Folder from "./Folder/Folder";
import Header from "./Header/Header";
import Camera from "./Cam/Camera";
import PopoverContext from "../../../Components/Popovers/PopoverContext/PopoverContext";
import PopoverMenuItem from "./PopoverMenuItem/PopoverMenuItem";
import plus from "../../../Assets/icons/plus.svg";
import {findParentsByNameInTaxonomy, initiateApi, initiateTaxonomy} from "../../../api/state/taxonomy/actions";
import {showModalCreateTaxonomy} from "../../../Components/Modals/actions";

class Taxonomy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tree: [],
            searchCam: "",
            searchTaxa: "",
            showPopup: {show: false,anchor: null,position: {x: 0,y: 0}},
            showPopupCam: {show: false,anchor: null,position: {x: 0,y: 0}},
        }
    }

    async componentDidMount() {
        initiateApi(this.loading)
        initiateTaxonomy()
    }

    loading = (bool) => {
        this.setState({preloader: bool})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.cams !== this.props.cams || prevProps.taxonomy !== this.props.taxonomy) {
            initiateTaxonomy()
        }
    }

    searchTaxa = (el) => {
        const val = el.target.value;
        this.setState({searchTaxa: val})
    }

    searchCam = (el) => {
        const val = el.target.value;
        this.setState({searchCam: val.toUpperCase()})
    }

    leftMouseDown = (el,cam) => {
        this.closeContextMenu()
        const popup = cam ? this.state.showPopupCam : this.state.showPopup;
        if (el.target === el.currentTarget) {
            const buttonPressed = el.button;
            if (buttonPressed === 2) {
                popup.show = true;
                popup.anchor = el;
                popup.position.x = el.clientX;
                popup.position.y = el.clientY;
            } else {
                popup.show = false;
                popup.anchor = null;
            }
        }
        if (cam) this.setState({showPopupCam: popup})
        else this.setState({showPopup: popup})
    }

    closeContextMenu = () => {
        const popup = this.state.showPopup;
        popup.show = false;
        this.setState({showPopup: popup})
        popup.anchor = null;
    }

    closeContextMenuCam = () => {
        const popup = this.state.showPopupCam;
        popup.show = false;
        this.setState({showPopupCam: popup})
        popup.anchor = null;
    }

    render() {
        return (
          <div className={style.taxonomy}>
            <PopoverContext
              close={this.closeContextMenu}
              style={{
left: this.state.showPopup.position.x,top: this.state.showPopup.position.y,marginTop: "-1vw",marginLeft: "-1vw",
}}
              anchor={this.state.showPopup.anchor}
              show={this.state.showPopup.show}
            >
              <PopoverMenuItem action={() => showModalCreateTaxonomy(null)} caption={worlds.addGroup} icon={plus} />
            </PopoverContext>
            <PopoverContext
              close={this.closeContextMenuCam}
              style={{
left: this.state.showPopupCam.position.x,top: this.state.showPopupCam.position.y,marginTop: "-1vw",marginLeft: "-1vw",
}}
              anchor={this.state.showPopupCam.anchor}
              show={this.state.showPopupCam.show}
            >
              <PopoverMenuItem action={() => this.props.history.push("/addCam")} caption={worlds.addCamera} icon={plus} />
            </PopoverContext>
            <div className={style.taxonomyList}>
              <Header caption={worlds.taxonomy} searchAction={this.searchTaxa} />
              <div onMouseOut={this.mouseOut} onMouseDown={(e) => this.leftMouseDown(e,false)} className={style.contentFolders}>
                {this.props.taxonomies.map((item,i) => {
                            if (!findParentsByNameInTaxonomy(this.state.searchTaxa)?.includes(item)) {
                                if (item.hasOwnProperty("isArchive")) return <Camera noParents item={item} key={i} />

                                    return <Folder item={item} key={i} />
                            }
                        })}
              </div>
            </div>
            <div className={style.taxonomyList} style={{margin: "0"}}>
              <Header caption={worlds.cameras} searchAction={this.searchCam} />
              <div className={style.contentCams} onMouseOut={this.mouseOut} onMouseDown={(e) => this.leftMouseDown(e,true)}>
                <div className={style.moveCam}>{worlds.dragCameraOrCamGroup}</div>
                {Object.values(this.props.cams).filter((i) => i.name.toUpperCase().indexOf(this.state.searchCam) >= 0).reverse().map((item,i) => <Camera noParents item={item} key={i} />)}
              </div>
            </div>
          </div>
        )
    }
}

export default withRouter(connect((state) => state,null)(Taxonomy))
