import React from "react";
import style from "./SnackBar.module.scss"

export default class SnackBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            variant: "",
            show: true,
        }
    }

    componentDidMount() {
        switch (this.props.variant) {
            case "warning":
                this.setState({variant: style.warning})
                break;
            case "success":
                this.setState({variant: style.success})
                break;
            default:
                this.setState({variant: style.info})
                break
        }
    }

    render() {
        return (
          <div className={`${style.snackBar} ${!this.state.show ? style.hide : ""} ${this.props.message.player ? style.snackBarPlayer : ""}`}>
            <div className={`${style.icon} ${this.state.variant}`} />
            <div className={style.message}>
              <div className={style.header}>{this.props.message.header}</div>
              <div className={style.description}>{this.props.message.description}</div>
            </div>
            <div className={style.close} onClick={() => this.props.message.close(this.props.id)} />
          </div>
        )
    }
}
