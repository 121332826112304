import {IBase} from "../iBase";
import Cam from "../../models/Cam";
import {getDhcpClientList} from "../dhcp/actions";

export default class CamDao implements IBase<Cam> {
    api:any;

    constructor(api:any) {
        this.api = api;
    }

    async create(obj: Cam): Promise<Cam> {
        const cam = obj.deserialize();
        const newCam = new Cam();
        newCam.serialize((await this.api.emit("camera.create",{camera: cam})).camera)
        if (obj.mac && obj.ip) {
            await getDhcpClientList();
            await this.api.emit("strazh.dhcp.mapping.push", {mapping: {ip: obj.ip, mac: obj.mac}});
        }
        return newCam;
    }

    async delete(obj: Cam): Promise<Cam> {
        const cam = obj.deserialize();
        const newCam = new Cam();
        newCam.serialize((await this.api.emit("camera.delete",{camera: cam})).camera)
        const {hostname} = new URL(obj.url.replace(/^rtsp/, 'http'));
        await getDhcpClientList();
        await this.api.emit("strazh.dhcp.mapping.delete",{mapping: {ip: hostname}});
        return newCam;
    }

    async get(id: string): Promise<Cam> {
        const cam = new Cam();
        cam.id = id;
        const res = (await this.api.emit("camera.get",{camera: cam})).camera
        cam.serialize(res)
        cam.player = (await this.api.emit("camera.get",{camera: cam,include: ['player']}))?.player?.url
        cam.online = (await this.api.emit("camera.get",{camera: cam,include: ['is_online']}))?.is_online
        cam.polygon = (await this.api.emit("camera.get",{camera: cam,include: ['motion_mask']}))?.motion_mask
        return cam;
    }

    async getPlayer(id:string):Promise<string> {
        const cam = new Cam();
        cam.id = id;
        return (await this.api.emit("camera.get",{camera: cam,include: ['player']},false,true))?.player?.mse;
    }

    async getArchive(id:string):Promise<any> {
        const cam = new Cam();
        cam.id = id;
        try {
            const arr = (await this.api.emit("camera.get",{camera: cam,include: ['archive']},false,true)).archive ?? null
            return arr;
        } catch (e) {
            console.error(e);
            return null;
        }
    }

    async getHistoryDetection(id:string):Promise<any> {
        const cam = new Cam();
        cam.id = id;
        if (!id) {
            return []
        }
        try {
            const res = (await this.api.emit("camera.detection.list",{filter: {camera: {id: cam.id}}},false,true)).detections ?? [];
            return res;
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    async downloadArchive(camId:string,path:string,start:string,end:string):Promise<any> {
        try {
            const res = await this.api.emit("camera.archive.store",{camera: {id: camId},storage: {path},time: {start,end}},false,true);
            return res?.store ?? null;
        } catch (e) {
            console.error(e);
            return {error: true}
        }
    }

    async playArchive(id:string,time:string,disableError:boolean):Promise<any> {
        const cam = new Cam();
        cam.id = id;
        try {
            const res = (await this.api.emit("camera.archive.play",{camera: {id: cam.id},time: {start: time}},disableError,true))?.play?.mse?.url ?? null
            if (!res) {
                const test = await this.api.emit("camera.archive.play",{camera: {id: cam.id},time: {start: time}},disableError,true);
                console.error("ERR PLAY ARCHIVE: " ,test)
            }
            return res;
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    async getSnapshot(id:string,time:string):Promise<any> {
        const cam = new Cam();
        cam.id = id;
        try {
            return (await this.api.emit("camera.archive.snapshot",{camera: {id: cam.id},time: {start: time}}))?.snapshot?.url;
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    async list(filter:any = {},disableError:boolean,disableLoading:boolean): Promise<Array<Cam>> {
        try {
            const camsDeserialize = await this.api.emit("camera.list", {
                filter,
                include: ['is_online'],
            }, disableError, disableLoading)
            // eslint-disable-next-line no-array-constructor
            const cams = Array<Cam>();
            camsDeserialize.cameras.map((item: any) => {
                const cam = new Cam();
                cam.serialize(item);
                cam.online = camsDeserialize?.is_online?.some((el: any) => el.id === item.id && el.is_online)
                return cams.push(cam);
            })
            return cams;
        } catch (e) {
            return []
        }
    }

    async update(obj: Cam): Promise<Cam> {
        const cam = obj.deserialize();
        const newCam = new Cam();
        newCam.serialize((await this.api.emit("camera.update",{camera: cam})).camera)
        return newCam;
    }
}
