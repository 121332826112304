import React from "react";
import moment from "moment-timezone";
import style from "./DatePicker.module.scss"
import iconArrowLeft from "../../../Assets/icons/arrow-dark.svg"
import iconArrowRight from "../../../Assets/icons/arrowLeft.svg"
import ButtonSquare from "../../Buttons/ButtonSquare/ButtonSquare";

export default class DatePicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            date: new Date(moment().format()),
            currentDay: new Date(moment().format()).getDate(),
            days: [],
        }
    }

    componentDidMount() {
        this.fillDays()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.isReset !== this.props.isReset) {
            this.setState({date: new Date(moment().format())})
            this.setState({currentDay: new Date(moment().format()).getDate()})
            this.fillDays()
            this.setCurrentDay(new Date(moment().format()).getDate())
            this.props.reset(false)
        }
    }

    getDaysInMonth = (month,year) => new Date(year, month, 0).getDate();

    fillDays = () => {
        const {date} = this.state;
        const prevMonth = new Date(date.getFullYear(),date.getMonth() > 0 ? date.getMonth() : 12,0);
        const firstDay = new Date(date.getFullYear(),date.getMonth(),1).getDay()
        const premMonthCount = prevMonth.getDate()
        const countDays = Array.from(Array(7 * 6).keys())
        let days = []
        const currentMonthCount = this.getDaysInMonth(date.getMonth() + 1,date.getFullYear());
        countDays.map((item,i) => {
            if (firstDay > item + 1) {
                days.push({enable: false,day: premMonthCount - firstDay + item + 2})
            } else if (currentMonthCount + firstDay > (item + 1)) {
                days.push({enable: true,day: (item + 2 - firstDay)})
            } else {
                 days.push({enable: false,day: (item + 2 - (currentMonthCount + firstDay))})
            }
        })
        if (firstDay === 0 && date.getDate() !== 1) {
            const newDays = Array.from(Array(7).keys())
            const firstDate = days[0];
            newDays.map((item,i) => {
                if (firstDate.day > item + 1) {
                    days.unshift({enable: true,day: firstDate.day - item - 1})
                } else {
                    days.unshift({enable: false,day: premMonthCount - (item + 1 - firstDate.day)})
                }
            });
            days = days.slice(0,7 * 6)
        }

        this.setState({days})
    }

    setCurrentDay = (day) => {
        this.props.date({day: (`0${day}`).slice(-2),month: (`0${this.state.date.getMonth() + 1}`).slice(-2),year: this.state.date.getFullYear()})
        this.setState({currentDay: day})
    }

    next = () => {
        const {date} = this.state;
        date.setMonth(date.getMonth() + 1);
        this.setState({date})
        this.fillDays()
    }

    back = () => {
        const {date} = this.state;
        date.setMonth(date.getMonth() - 1);
        this.setState({date})
        this.fillDays()
    }

    render() {
        return (
          <div className={style.datePicker}>
            <div className={style.header}>
              <div className={style.monthYear}>{`${this.props.months[this.state.date.getMonth()]} ${this.state.date.getFullYear()}`}</div>
              <div className={style.controls}>
                <ButtonSquare width="2rem" height="2rem" iconWidth="0.5rem" iconHeight="0.96rem" icon={iconArrowLeft} action={() => this.back()} />
                <ButtonSquare width="2rem" height="2rem" icon={iconArrowRight} action={() => this.next()} />
              </div>
            </div>
            <div className={style.week}>
              {Array.from(Array(7).keys()).map((item) => <div key={item} className={style.weekDay}>{this.props.days[item]}</div>)}
            </div>
            <div className={style.days}>
              {this.state.days.map((item,i) => {
                        if (item.day === this.state.currentDay && item.enable) return <div key={i} className={`${style.day} ${style.activeDay}`}>{item.day}</div>
                        if (item.enable) return <div key={i} onClick={() => this.setCurrentDay(item.day)} className={style.day}>{item.day}</div>
                        return <div key={i} className={`${style.day} ${style.dayDisable}`}>{item.day}</div>
                    })}
            </div>
          </div>
        )
    }
}
