import React from "react";
import {connect} from "react-redux";
import style from "./ModalDeleteTaxonomy.module.scss"
import {worlds} from "../../../Utils/localization";
import ButtonRectangle from "../../Buttons/ButtonRectangle/ButtonRectangle";
import {closeModal} from "../actions";
import {deleteTaxonomy} from "../../../api/state/taxonomy/actions";

class ModalDeleteTaxonomy extends React.Component {
    render() {
        return (
          <div className={style.modalDeleteTaxonomy}>
            <div className={style.caption}>
              {`${worlds.deleteGroup} ${this.props.taxonomy?.name}?`}
            </div>
            <div className={style.controls}>
              <ButtonRectangle width="7.55rem" caption={worlds.delete} action={() => { deleteTaxonomy(this.props.taxonomy); closeModal() }} />
              <ButtonRectangle width="7.55rem" dark caption={worlds.cancel} action={() => closeModal()} />
            </div>
          </div>
        )
    }
}

export default connect((state) => state,null)(ModalDeleteTaxonomy)
