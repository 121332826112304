export const SHOW_MODAL = "SHOW_MODAL"
export const SET_TIME = "SET_TIME"
export const RECONNECT_API = "RECONNECT_API"
export const AUTHORIZATION_SUCCESS = "AUTHORIZATION_SUCCESS"
export const CREATE_MODAL_CONTENT = "CREATE_MODAL_CONTENT";
export const DELETE_MODAL_CONTENT = "DELETE_MODAL_CONTENT";
export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE"
export const SET_API = "SET_API"
export const SERVER_DISCONNECT = "SERVER_DISCONNECT";
export const LOADER = "LOADER";
export const SERVER_BRC = "SERVER_BRC";

export const patterns = [
    {
        name: "MegaCam",
        pattern: "rtsp://{account}{ip}:554/av0_{thread}",
    },
    {
        name: "Dahua",
        pattern: "rtsp://{account}{ip}:554/cam/realmonitor?channel=1&subtype={thread}",
    },
    {
        name: "Vesta",
        pattern: "rtsp://{ip}:554/user={user}&password={password}&channel=1&stream={thread}.sdp",
    },
    {
        name: "other",
        pattern: null,
    },
]
