import {plainToClass} from "class-transformer";
import {
    CAM_CREATE,
    CAM_DELETE,
    CAM_LIST,
    CAM_UPDATE,
    CURRENT_CAM_CREATE,
    CURRENT_CAM_DELETE,
    EDIT_CAM_CREATE,
    EDIT_CAM_DELETE,
} from "./consts";
import Cam from "../../models/Cam";

const prevState = {
    cams: {},
    currentCam: null,
    editCam: null,
}

export const cams = (state = prevState.cams, action) => {
    switch (action.type) {
        case CAM_LIST:
            for (const index in action.obj) {
                state[action.obj[index].id] = plainToClass(Cam,action.obj[index])
            }
            return {...state}
        case CAM_CREATE:
            if (state[action.obj.id]) return state;
            state[action.obj.id] = plainToClass(Cam,action.obj)
            return {...state};
        case CAM_UPDATE:
            state[action.obj.id] = plainToClass(Cam,action.obj);
            return {...state};
        case CAM_DELETE:
            state[action.obj.id] = null;
            delete state[action.obj.id]
            return {...state};
        default:
            return state;
    }
}

export const editCam = (state = prevState.editCam, action) => {
    switch (action.type) {
        case EDIT_CAM_CREATE:
            return plainToClass(Cam,{...state,...action.obj});
        case EDIT_CAM_DELETE:
            return null;
        default:
            return state;
    }
}

export const currentCam = (state = prevState.currentCam, action) => {
    switch (action.type) {
        case CURRENT_CAM_CREATE:
            return plainToClass(Cam,{...state,...action.obj});
        case CURRENT_CAM_DELETE:
            return null;
        default:
            return state;
    }
}
