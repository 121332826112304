import React from "react";
import {InputAdornment} from "@material-ui/core";
import {connect} from "react-redux";
import moment from "moment-timezone";
import style from "./ModalFilterLogs.module.scss"
import AutocompleteM from "../../Inputs/AutocompleteM";
import TextFieldM from "../../Inputs/TextFieldM";
import iconCalendar from "../../../Assets/icons/calendar.svg";
import DateTimePicker from "../../DateTimePicker/DateTimePicker";
import {worlds} from "../../../Utils/localization";
import ButtonRectangle from "../../Buttons/ButtonRectangle/ButtonRectangle";
import {closeModal} from "../actions";

const level = ['info','warning','error'];
const type = [
    'account',
    'acl',
    'bulk',
    'camera',
    'checkpoint',
    'faceapi',
    'global',
    'group',
    'guard',
    'logger',
    'plan',
    'screen',
    'server',
    'system',
    'storage',
    'taxonomy',
    'trigger',

];

const users = ['user','root','admin']

class ModalFilterLogs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dateRange: [new Date(moment().format()),new Date(moment().format())],
            showDateTime: {show: false,index: 0},
            filter: {
user: users[0],level: level[0],dateStart: new Date(moment().format()),dateEnd: new Date(moment().format()),type: type[0],
},
        }
    }

    onChangeDate = (date,index) => {
        const {dateRange} = this.state;
        dateRange[index] = date;
        if (new Date(Date.parse(dateRange[1])) < new Date(Date.parse(dateRange[0]))) {
            dateRange[0] = new Date(Date.parse(dateRange[1])).setMinutes(new Date(Date.parse(dateRange[1])).getMinutes() - 1)
        }
        this.setState({dateRange})
    }

    reset = (bool) => {
        this.setState({isReset: bool,dateRange: [new Date(moment().format()),new Date(moment().format())]})
    }

    showAndCloseDateTimeIndex = (index) => {
        const {showDateTime} = this.state;
        if (showDateTime.show && showDateTime.index !== index) {
            showDateTime.index = index;
        } else {
            showDateTime.show = !showDateTime.show;
            showDateTime.index = index;
        }
        this.setState({showDateTime})
    }

    apply = () => {
        const {filter} = this.state;
        filter.dateStart = moment(this.state.dateRange[0]).format();
        filter.dateEnd = moment(this.state.dateRange[1]).format();
        this.props.applyFilter(this.state.filter);
        closeModal();
    }

    render() {
        return (
          <div className={style.modalFilterLogs}>
            {this.state.showDateTime.show && (
            <div style={{right: this.state.showDateTime.index === 1 && "2.5vw"}} className={style.dateTime}>
              <div className={style.dateTimeClose} onClick={() => this.showAndCloseDateTimeIndex(this.state.showDateTime.index)} />
              <DateTimePicker reset={this.reset} isReset={this.state.isReset} dateTime={(date) => this.onChangeDate(date,this.state.showDateTime.index)} />
            </div>
)}
            <div className={style.label}>Уровень</div>
            <AutocompleteM
              value={this.state.filter.level}
              onChange={(event, newValue) => {
                                const {filter} = this.state;
                                filter.level = newValue;
                                this.setState({filter})
                        }}
              inputValue={this.state.filter.level}
              onInputChange={(event, newInputValue) => {

                        }}
              options={level}
              disableKeyboard
            />
            <div className={style.date}>
              <div>
                <div className={style.label}>{worlds.startDateRecorder}</div>
                <TextFieldM
                  variant="outlined"
                  style={{width: "11.45vw",margin: 0}}
                  value={moment(this.state.dateRange[0]).format('DD.MM.YYYY HH:mm')}
                  disableKeyboard
                  InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end" onClick={() => this.showAndCloseDateTimeIndex(0)}>
                                    <img className={style.iconCalendar} src={iconCalendar} />
                                  </InputAdornment>
                                ),
                            }}
                />
              </div>
              <div>
                <div className={style.label}>{worlds.endDateRecording}</div>
                <TextFieldM
                  variant="outlined"
                  style={{width: "11.45vw",margin: 0}}
                  value={moment(this.state.dateRange[1]).format('DD.MM.YYYY HH:mm')}
                  disableKeyboard
                  InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end" onClick={() => this.showAndCloseDateTimeIndex(1)}>
                                    <img className={style.iconCalendar} src={iconCalendar} />
                                  </InputAdornment>
                                ),
                            }}
                />
              </div>
            </div>
            <div className={style.label}>Тип события</div>
            <AutocompleteM
              value={this.state.filter.type}
              disableKeyboard
              onChange={(event, newValue) => {
                            const {filter} = this.state;
                            filter.type = newValue;
                            this.setState({filter})
                        }}
              inputValue={this.state.filter.type}
              onInputChange={(event, newInputValue) => {

                        }}
              options={type}
              style={{marginBottom: "1.25vw"}}
            />
            {/* <div className={style.label}>Система</div> */}
            {/* <AutocompleteM */}
            {/*    defaultValue={""} */}
            {/*    value={""} */}
            {/*    onChange={(event, newValue) => { */}

            {/*    }} */}
            {/*    inputValue={""} */}
            {/*    onInputChange={(event, newInputValue) => { */}

            {/*    }} */}
            {/*    options={["1"]} */}
            {/* /> */}
            <div className={style.label}>Пользователь</div>
            <AutocompleteM
              value={this.state.filter.user}
              disableKeyboard
              onChange={(event, newValue) => {
                            const {filter} = this.state;
                            filter.user = newValue;
                            this.setState({filter})
                        }}
              inputValue={this.state.filter.user}
              onInputChange={(event, newInputValue) => {

                        }}
              options={users}
            />
            <div className={style.buttons}>
              <ButtonRectangle height="2.18vw" width="16.97vw" caption={worlds.apply} action={() => this.apply()} />
              <ButtonRectangle dark height="2.18vw" width="6.35vw" caption={worlds.reset} action={() => { this.props.resetFilter(); closeModal() }} />
            </div>
          </div>
        )
    }
}

export default connect((state) => state,null)(ModalFilterLogs)
