import React from "react";
import moment from "moment-timezone";
import {connect} from "react-redux";
import style from "./ModalDateTimePicker.module.scss"
import DateTimePicker from "../../DateTimePicker/DateTimePicker";
import {worlds} from "../../../Utils/localization";
import CheckBox from "../../CheckBox/CheckBox";
import ButtonRectangle from "../../Buttons/ButtonRectangle/ButtonRectangle";
import {closeModal} from "../actions";

class ModalDateTimePicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checkAll: false,
            dateTime: null,
            isReset: false,
        }
    }

    componentDidMount() {
        const dateTime = new Date(moment().format());
        dateTime.setMonth(new Date(moment().format()).getMonth() + 1);
        this.setState({dateTime: dateTime.toISOString})
    }

    onChangeDate = (date) => {
        this.setState({dateTime: date})
    }

    reset = (bool) => {
        this.setState({isReset: bool})
    }

    apply = () => {
        this.props.action({date: this.state.dateTime,checkAll: this.state.checkAll})
        closeModal()
    }

    render() {
        return (
          <div className={style.dateTimePicker}>
            <DateTimePicker reset={this.reset} isReset={this.state.isReset} dateTime={this.onChangeDate} />
            <div className={style.controls}>
              <div className={style.check}>
                <CheckBox checked={this.state.checkAll} onChange={() => this.setState({checkAll: !this.state.checkAll})} />
                <div className={style.checkCaption}>Применить для всех камер</div>
              </div>
              <div className={style.buttons}>
                <ButtonRectangle height="2.33rem" width="15.94rem" caption={worlds.apply} action={() => this.apply()} />
                <ButtonRectangle dark height="2.33rem" width="7.55rem" caption={worlds.reset} action={() => this.reset(true)} />
              </div>
            </div>
          </div>
        )
    }
}

export default connect((state) => state,null)(ModalDateTimePicker)
