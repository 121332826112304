import React from "react";
import {connect} from "react-redux";
import style from "./HardManager.module.scss";
import Drive from "./Drive/Drive";
import {changeDisks} from "../../../api/state/fileSystem/actions";

const HardManager = (props) => {
    const moveDisk = (dragIndex, hoverIndex) => {
            const disks = props.disks?.devices;
            const temp = {...disks[dragIndex]};
            const next = {...disks[hoverIndex]};
            disks[dragIndex] = next;
            disks[hoverIndex] = temp;
            changeDisks(disks);
    }

    return (
      <div className={style.hardManager}>
        {props.disks?.devices && props.disks?.devices.map((item, i) => <Drive unmount={props.disks?.uninitialized.find((disk) => disk.path === item.path)?.path ?? null} mount={props.disks?.initialized.find((disk) => disk.path.includes(item.path))?.pool?.name ?? null} id={item.path} index={i} moveDisk={moveDisk} item={item} key={i} />)}
      </div>
    )
}

export default connect((store) => store,null)(HardManager);
