// eslint-disable-next-line max-classes-per-file
import {IsNotEmpty, IsNumberString, ValidateNested} from "class-validator";
import {worlds} from "../../Utils/localization";

export class FaceModelOptions {
    @IsNumberString({},{
        message: worlds.thisFieldMustContainOnlyNumericValue,
    })
    @IsNotEmpty({
        message: worlds.thisFieldCannotBeEmpty,
    })
    minConfidence: number = 0;
}

export default class FaceApi {
    accessLogFile: string = "";

    accessLogFileEnable: boolean = false;

    accessLogFormat: string = "";

    accessLogRotatingInterval: string = "";

    accessLogStdoutEnable: boolean = false;

    faceDescriptionPath: string = "";

    @IsNumberString({},{
        message: worlds.thisFieldMustContainOnlyNumericValue,
    })
    @IsNotEmpty({
        message: worlds.thisFieldCannotBeEmpty,
    })
    faceDetectionImagesBoxExpand: number = 0;

    faceDetectionImagesDateFormat: string = "";

    faceDetectionImagesFullDraw: boolean = false;

    faceDetectionImagesGroupResult: boolean = false;

    faceDetectionImagesIndexFile: string = "";

    faceDetectionImagesIndexFileEnable: boolean = false;

    @IsNumberString({},{
        message: worlds.thisFieldMustContainOnlyNumericValue,
    })
    @IsNotEmpty({
        message: worlds.thisFieldCannotBeEmpty,
    })
    faceDetectionImagesLimitScreening: number = 0;

    faceDetectionImagesPath: string = "";

    @IsNumberString({},{
        message: worlds.thisFieldMustContainOnlyNumericValue,
    })
    @IsNotEmpty({
        message: worlds.thisFieldCannotBeEmpty,
    })
    faceDetectionImagesScreeningTime: number = 0;

    @IsNumberString({},{
        message: worlds.thisFieldMustContainOnlyNumericValue,
    })
    @IsNotEmpty({
        message: worlds.thisFieldCannotBeEmpty,
    })
    faceDetectionImagesScreeningToSize: number = 0;

    @IsNumberString({},{
        message: worlds.thisFieldMustContainOnlyNumericValue,
    })
    @IsNotEmpty({
        message: worlds.thisFieldCannotBeEmpty,
    })
    faceDetectionImagesTimeout: number = 0;

    @ValidateNested()
    faceModelOptions: FaceModelOptions = {minConfidence: 0};

    faceModelsPath: string = "";

    @IsNumberString({},{
        message: worlds.thisFieldMustContainOnlyNumericValue,
    })
    @IsNotEmpty({
        message: worlds.thisFieldCannotBeEmpty,
    })
    ffmpegFps: number = 0;

    ffmpegMinimizeDelay: boolean = false;

    idFormat: string = "";

    idLength: number = 0;

    initTimeout: number = 0;

    lang: string = "";

    logsPath: string = "";

    name: string = "";

    @IsNumberString({},{
        message: worlds.thisFieldMustContainOnlyNumericValue,
    })
    @IsNotEmpty({
        message: worlds.thisFieldCannotBeEmpty,
    })
    port: number = 0;

    tmpPath: string = "";

    workLogFile: string = "";

    workLogFileEnable: boolean = false;

    workLogLevel: string = "";

    workLogRotatingInterval: string = "";

    workLogStdoutEnable: boolean = false;
}
