export default class GuardDetection {
    id:string = ""

    name:string = ""

    cameraId:string = ""

    camName:string = ""

    image:string = "";

    start:string = ""

    stop:string = ""

    url:string = ""
}
