import {connect} from 'react-redux';
import EditCam from './EditCam';

const mapStateToProps = (state) => state

const mapDispatchToProps = (dispatch) => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(EditCam);
