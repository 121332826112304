import React from "react";
import {store} from "../../../App";
import {action} from "../../ReduxHelper";
import {ADD_LOG, LOGS_LIST} from "./consts"

let api = null;

export const initiateApiLogs = () => {
    try {
        api = store.getState().api;
        initiateLogs({limit: 16, offset: 0, sort: {updated_at: -1}});
        api.emit("logger.subscribe", {})
    } catch (e) {}
}

export const initiateLogs = async (filter) => {
    try {
        const logs = await api.emit("logger.list",filter);
        store.dispatch(action(LOGS_LIST,logs))
    } catch (e) {}
}

export const initiateEventLogs = async (data) => {
    if (data.event === "logger.event") {
        store.dispatch(action(ADD_LOG,data.object))
    }
}
