import React, {useEffect, useRef, useState} from "react";
import style from "./Folder.module.scss";
import TaxonomyCam from "../TaxonomyCam/TaxonomyCam";

const Folder = (props) => {
    const [open,setOpen] = useState(true)
    const [height,setHeight] = useState()
    const ref = useRef()

    useEffect(() => {
        setHeight(ref.current.scrollHeight)
    })

    const closeOpen = () => {
        setOpen(!open)
    }

    return (
      <div ref={ref} className={style.folder}>
        <div onClick={() => closeOpen()} className={`${style.info}`}>
          {props.item.parent && <div className={style.dotsHor} />}
          {props.item.parent && <div style={{height: props.last && "1.85vw"}} className={style.dots} />}
          <div className={`${style.icon} ${open ? style.iconOpen : style.iconClose}`} />
          <div className={style.caption}>{props.item.name}</div>
        </div>
        <div className={style.background} />
        <div className={`${style.children} ${!open && style.close}`}>
          {props.item?.children?.map((child,i) => {
                    const last = props.item.children.length === (i + 1)
                    if (child.hasOwnProperty("isArchive")) {
 return (
   <TaxonomyCam
     currentAccount={props.currentAccount}
     parent={props.item.id}
     showDelete={props.showDelete}
     plan={props.plan}
     last={last}
     item={child}
     key={i}
   />
)
}
                    return <Folder currentAccount={props.currentAccount} showDelete={props.showDelete} plan={props.plan} last={last} item={child} key={i} />
                })}
        </div>
      </div>
    )
}

export default Folder;
