import React from "react";
import Radio from '@material-ui/core/Radio';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
        "&.MuiTypography-body1": {
            color: "white !important",
        },
    },

    icon: {
        borderRadius: '50%',
        width: "1.25vw",
        height: "1.25vw",
        backgroundColor: '#3C4347',
        border: "0.05vw solid transparent",
        'input:hover ~ &': {
            border: "0.05vw solid #FB3C4A",
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#FB3C4A',
        '&:before': {
            display: 'block',
            width: "0.62vw",
            height: "0.62vw",
            borderRadius: '50%',
            margin: "0.31vw auto",
            backgroundColor: "white",
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#FB3C4A',
        },
    },
});

export default function RadioButton(props) {
    const classes = useStyles();

    return (
      <Radio
        className={classes.root}
        disableRipple
        color="default"
        checkedIcon={<span className={`${classes.icon} ${classes.checkedIcon}`} />}
        icon={<span className={classes.icon} />}
        {...props}
      />
    );
}
