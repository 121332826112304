import React, {useState} from 'react';

import {connect} from "react-redux";
import Pagination from "@material-ui/lab/Pagination";
import style from './Alarm.module.scss';
import {worlds} from "../../../Utils/localization";

import AlarmForm from './AlarmForm/AlarmForm';
import AlarmListItem from './AlarmListItem/AlarmListItem';
import {getGuardDetectionsWithPage} from "../../../api/state/guard/actions";
import {searchCamsIdByName} from "../../../api/state/cam/actions";

function Alarm(props) {
    const [page,setPage] = useState(1);
    const [searchText,setSearchText] = useState("");
    const [dateRange,setDateRange] = useState([null,null])
    const [camsIds,setCamsIds] = useState([]);

    const searchCams = (e) => {
        e ? setCamsIds([...searchCamsIdByName(e)]) : setCamsIds([]);
        setSearchText(e);
    }

    const changePage = (el,pageNum) => {
        setPage(pageNum)
        if (dateRange[1] && dateRange[0]) {
            getGuardDetectionsWithPage(props.camsIds,7,(7 * pageNum) - 7,new Date(Date.parse(dateRange[0])),new Date(Date.parse(dateRange[1])));
        } else {
            getGuardDetectionsWithPage(props.camsIds,7, (7 * pageNum) - 7);
        }
    }

  return (
    <div className={style.alarmContainer}>
      <AlarmForm camsIds={camsIds} setDateRangeCB={setDateRange} searchText={searchText} searchTextChange={searchCams} />
      {(props.guardDetections.length > 0)
          ? props.guardDetections.map((data,key) => <AlarmListItem key={key} data={data} />)
          : <div className={style.emptyList}><div>{worlds.emptyList}</div></div>}
      <div className={style.pagination}>
        <Pagination outlined="false" onChange={changePage} count={Math.round(props.guardCount / 7)} shape="rounded" />
      </div>
    </div>
  );
}

export default connect((state) => state,null)(Alarm);
