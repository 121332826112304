import {IBase} from "../iBase";
import Plan from "../../models/Plan";

export default class PlanDao implements IBase<Plan> {
    api:any;

    constructor(api:any) {
        this.api = api;
    }

    async create(obj: Plan): Promise<Plan> {
        const plan = obj.deserialize();
        const newPlan = new Plan();
        newPlan.serialize((await this.api.emit("plan.create",{plan})).plan)
        return newPlan;
    }

    async delete(obj: Plan): Promise<Plan> {
        const plan = obj.deserialize();
        const newPlan = new Plan();
        newPlan.serialize((await this.api.emit("plan.delete",{plan})).plan)
        return newPlan;
    }

    async get(id: string): Promise<Plan> {
        const plan = new Plan();
        plan.id = id;
        plan.serialize((await this.api.emit("plan.get",{plan})).plan)
        return plan;
    }

    async list(filter:any = {}): Promise<Array<Plan>> {
        const plansDeserialize = await this.api.emit("plan.list", {filter});
        // eslint-disable-next-line no-array-constructor
        const plans = Array<Plan>();
        plansDeserialize.plans.map((item:any) => {
            const plan = new Plan();
            plan.serialize(item);
            return plans.push(plan);
        })
        return plans;
    }

    async update(obj: Plan): Promise<Plan> {
        const plan = obj.deserialize();
        const newPlan = new Plan();
        newPlan.serialize((await this.api.emit("plan.update",{plan})).plan)
        return newPlan;
    }
}
