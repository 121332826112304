import React from 'react';
import moment from "moment-timezone";
import style from './ReactionsTableItem.module.scss';
import ReactionsMenu from "../ReactionsMenu/ReactionsMenu";

function ReactionsTableItem(props) {
  return (
    <div className={style.reactionsTableItem}>
      <div className={style.item} style={{width: "13.38vw"}}>{moment(props.data.dateTime).format('DD.MM.YYYY HH:mm:ss')}</div>
      <div className={style.item} style={{width: "36.46vw"}}>{props.data.name}</div>
      <div className={style.item} style={{width: "36.46vw"}}>{props.data.events[0]}</div>
      <div className={style.options}>
        <ReactionsMenu item={props.data} />
      </div>
    </div>
  );
}

export default ReactionsTableItem;
