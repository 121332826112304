import React from 'react';
import moment from "moment-timezone";
import style from './AlarmListItem.module.scss';
import placeholder from "../../../../Assets/placeholder.png";
import OpenVideoButton from "../OpenVideoButton/OpenVideoButton";
import {showModalFaceApiFullImage, showModalPlayerMp4} from "../../../../Components/Modals/actions";

function AlarmListItem(props) {
    const image = window.location.hostname && window.location.hostname !== "localhost" ? props.data.image.replace("localhost",window.location.hostname) : props.data.image
  return (
    <div className={style.alarmListItem}>
      <div onClick={() => showModalFaceApiFullImage({camera: {name: props.data.name},full_image: image || placeholder})} className={style.camPhoto}>
        <img width="100%" height="100%" src={image || placeholder} />
      </div>
      <div className={style.camName}>{props.data.camName}</div>
      <div className={style.description}>{props.data.name}</div>
      <div className={style.dateTime}>{moment(props.data.date).format('DD.MM.YYYY HH:mm:ss')}</div>
      <div className={style.button}>
        {props.data.stop
              && <OpenVideoButton action={() => showModalPlayerMp4(props.data)} />}
      </div>
    </div>
  );
}

export default AlarmListItem;
