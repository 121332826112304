import {connect} from "react-redux";
import CacheRoute, {CacheSwitch} from "react-router-cache-route";
import React from "react";
import style from "./Monitoring.module.scss";
import ButtonTopMenu from "../../Components/Buttons/ButtonTopMenu/ButtonTopMenu";
import {worlds} from "../../Utils/localization";
import SystemMonitor from "./SystemMonitor/SystemMonitor";
import CamsMonitoring from "./CamsMonitoring/CamsMonitoring";

const Monitoring = (props) => (
  <div className={style.monitoring}>
    <div className={style.menu}>
      <ButtonTopMenu caption={worlds.systemMonitor} href="/monitoring" />
      <ButtonTopMenu caption={worlds.cameras} href="/monitoring/cams" />
    </div>
    <CacheSwitch>
      <CacheRoute path="/monitoring/" exact>
        <SystemMonitor />
      </CacheRoute>
      <CacheRoute path="/monitoring/cams" exact>
        <CamsMonitoring />
      </CacheRoute>
    </CacheSwitch>
  </div>
    )

export default connect((state) => state,null)(Monitoring);
