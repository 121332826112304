import React from "react";
import {connect} from "react-redux";
import style from "./ModalUpdateSystem.module.scss"
import {worlds} from "../../../Utils/localization";
import Preloader from "../../Preloader/Preloader";

class ModalUpdateSystem extends React.Component {
    render() {
        return (
          <div className={style.modalUpdateSystem}>
            <div className={style.caption}>
              {`${worlds.thereWillBeAnUpdateOfTheSystem}`}
            </div>
            <Preloader show position="static" background="transparent" />
            <div style={{marginBottom: "1vw"}} />
          </div>
        )
    }
}

export default connect((state) => state,null)(ModalUpdateSystem)
