import React from "react";
import style from "./SystemDetails.module.scss";
import DetailItem from "./DetailItem/DetailItem";

class SystemDetails extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
          <div className={style.systemDetails}>
            <div className={style.header}>{this.props.header}</div>
            <div className={style.content}>
              {this.props.item.map((item, i) => (
                <DetailItem
                  caption={item.caption}
                  id={item.id}
                  key={i}
                  length={this.props.item.length}
                  detail={item.detail}
                />
))}
            </div>
          </div>
        )
    }
}

export default SystemDetails;
