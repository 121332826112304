import React from "react";
import {connect} from "react-redux";
import style from "./FaceDetectionDahua.module.scss";
import {worlds} from "../../Utils/localization";
import FaceDahuaForm from "./FaceDahuaForm/FaceDahuaForm";
import FaceDahuaListItem from "./FaceDahuaListItem/FaceDahuaListItem";

class FaceDetectionDahua extends React.Component {
    test = [{
        date: "18.08.2020 10:29:00",
        gender: "man",
        age: 22,
        emotion: "neutral",
        nationality: "kazakh",
    }];

    constructor(props) {
        super(props);
    }

    render() {
        return (
          <div className={style.faceDetectionDahua} onClick={() => this.setState({clicked: !this.state.clicked})}>
            <div className={style.header}>
              <div className={style.caption}>
                {worlds.faceDetection}
                {' '}
                Dahua
              </div>
            </div>
            <FaceDahuaForm />
            <div className={style.faces}>
              <div className={style.headItem}>
                <div className={style.head} style={{width: "22.24vw"}}>{worlds.dateTime}</div>
                <div className={style.head} style={{width: "11.12vw"}}>{worlds.gender}</div>
                <div className={style.head} style={{width: "11.12vw"}}>{worlds.age}</div>
                <div className={style.head} style={{width: "22.24vw"}}>{worlds.emotion}</div>
                <div className={style.head} style={{width: "22.24vw"}}>{worlds.nationality}</div>
              </div>
              {this.test.length > 0 ? this.test.map((item, i) => <FaceDahuaListItem face={item} key={i} />) : <div className={style.emptyList}>{worlds.emptyList}</div>}
            </div>
          </div>
        )
    }
}

export default connect((state) => state,null)(FaceDetectionDahua)
