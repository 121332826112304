import React from "react";
import {connect} from "react-redux";
import style from "./Monitor.module.scss";
import {worlds} from "../../../Utils/localization";
import ButtonSquare from "../../Buttons/ButtonSquare/ButtonSquare";
import cancelIcon from "../../../Assets/icons/cancel.svg";
import MonitorItem from "./MonitorItem/MonitorItem";

class Monitor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hideDetections: [],
        }
    }

    addHideDetections = (id) => {
        const {hideDetections} = this.state;
        hideDetections.push(id);
        this.setState({hideDetections});
    }

    render() {
        return (
          <div className={`${style.monitor} ${!this.props.show && style.monitorHide}`}>
            <div className={style.header}>
              <div className={style.caption}>{worlds.alarmMonitor}</div>
              <ButtonSquare width="1.56vw" height="1.56vw" icon={cancelIcon} action={this.props.cancel} />
            </div>
            <div className={style.alarms}>
              {this.props.guardDetections.filter((i) => !this.state.hideDetections.includes(i.id)).map((item, i) => <MonitorItem addHideDetections={this.addHideDetections} detection={item} key={i} />)}
            </div>
          </div>
        );
    }
}

export default connect((state) => state,null)(Monitor)
