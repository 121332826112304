import {plainToClass} from "class-transformer";
import {
    CURRENT_PLAN_CREATE,
    CURRENT_PLAN_DELETE,
    EDIT_PLAN_CREATE,
    EDIT_PLAN_DELETE,
    PLAN_CREATE,
    PLAN_DELETE,
    PLAN_LIST,
    PLAN_UPDATE,
} from "./consts";
import Plan from "../../models/Plan";

const prevState = {
    plans: [],
    currentPlan: null,
    editPlan: null,
}

export const plans = (state = prevState.plans, action) => {
    switch (action.type) {
        case PLAN_LIST:
            return plainToClass(Plan,[...action.obj]);
        case PLAN_CREATE:
            if (state.find((i) => i.id === action.obj.id)) return state;
            state.push(action.obj)
            return plainToClass(Plan,[...state]);
        case PLAN_UPDATE:
            state.splice(state.findIndex((i) => i.id === action.obj.id),1,action.obj);
            return plainToClass(Plan,[...state]);
        case PLAN_DELETE:
            state.splice(state.findIndex((i) => i.id === action.obj.id),1);
            return plainToClass(Plan,[...state]);
        default:
            return state;
    }
}

export const editPlan = (state = prevState.editPlan, action) => {
    switch (action.type) {
        case EDIT_PLAN_CREATE:
            if (!action.obj) return state;
            return plainToClass(Plan,{...state,...action.obj});
        case EDIT_PLAN_DELETE:
            return null;
        default:
            return state;
    }
}

export const currentPlan = (state = prevState.currentPlan, action) => {
    switch (action.type) {
        case CURRENT_PLAN_CREATE:
            if (!action.obj) return state;
            return plainToClass(Plan,{...state,...action.obj});
        case CURRENT_PLAN_DELETE:
            return null;
        default:
            return state;
    }
}
