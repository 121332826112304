import React from "react";
import style from "./RuleItem.module.scss";
import {worlds} from "../../../../Utils/localization";

export default class RuleItem extends React.Component {
    constructor(props) {
        super(props);
    }

    dateConvert = (dateTime) => {
        let month = dateTime.getMonth() + 1;
        if (month / 10 < 1) month = `0${month}`;
        let date = dateTime.getDate();
        if (date / 10 < 1) date = `0${date}`;
        let hours = dateTime.getHours() + 1;
        if (hours / 10 < 1) hours = `0${hours}`;
        let mins = dateTime.getMinutes();
        if (mins / 10 < 1) mins = `0${mins}`;
        let secs = dateTime.getSeconds();
        if (secs / 10 < 1) secs = `0${secs}`;
        return `${date}.${month}.${dateTime.getFullYear()} ${hours}:${mins}:${secs}`;
    }

    render() {
        return (
          <div className={style.ruleItem}>
            <div className={style.item} style={{width: "13.38vw"}}>{this.dateConvert(this.props.rule.date)}</div>
            <div className={style.item} style={{width: "14.79vw"}}>{worlds.device}</div>
            <div className={style.item} style={{width: "23.44vw"}}>{worlds.ruleName}</div>
            <div className={style.item} style={{width: "23.44vw"}}>{worlds.reaction}</div>
            <div className={style.options} />
          </div>
        )
    }
}
