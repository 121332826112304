import React from "react";
import style from "./WeekDay.module.scss";

export default class WeekDay extends React.Component {
    render() {
        return (
          <div onClick={() => this.props.action(this.props.index)} className={`${style.weekday} ${this.props.active && style.active}`}>
            <span>{this.props.caption}</span>
          </div>
        )
    }
}
