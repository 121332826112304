import React from "react";
import {store} from "../../../App";
import {action} from "../../ReduxHelper";
import {ADD_SWITCHING_EVENT, ADD_SWITCHING_EVENT_LIST, SOUND_LIST} from "./consts"

let api = null;

export const initiateApiSwitching = () => {
    api = store.getState().api;
    initiateSwitching();
    getAudioList();
    // deleteAudioFile('sound22.mp3')
    // getAudioList();
    // uploadAudioFile("/test.mp3")
    // getAudioList();
}

export const initiateSwitching = async (filter,limit,offset) => {
    const newFilter = {type: 'external'}
    if (filter?.updated_at?.$gte) {
        newFilter.updated_at = filter.updated_at;
    }
    if (filter?.["event.name"]?.$regex) {
        newFilter["event.name"] = filter["event.name"];
    }
    try {
        const logs = await api.emit("logger.list",{
filter: newFilter,sort: {updated_at: -1}, limit: limit ?? 7, offset: offset ?? 0,
});
        store.dispatch(action(ADD_SWITCHING_EVENT_LIST,logs))
    } catch (e) {}
}

export const initiateSwitchingEvent = async (data) => {
    if (data.event === "logger.event") {
        if (data.object.event.name.includes('external')) store.dispatch(action(ADD_SWITCHING_EVENT,data.object))
    }
}

export const getAudioList = async () => {
    try {
        const res = await api.emit("strazh.switchboard.audio.list",{});
        store.dispatch(action(SOUND_LIST,res.files));
    } catch (e) {}
}

export const deleteAudioFile = async (filename) => {
    try {
        await api.emit("strazh.switchboard.audio.delete",{filename});
        await getAudioList()
    } catch (e) {}
}

export const uploadAudioFile = async (path) => {
    try {
        const res = await api.emit("strazh.switchboard.audio.upload",{filepath: path});
    } catch (e) {}
}
