import React from "react";
import style from "./VirtualKeyboard.module.scss"
import "./keyBoard.css";
// eslint-disable-next-line import/order
import {connect} from "react-redux";
import iconArrowDown from "../../Assets/icons/arrowDown.svg"
import {setKeyBoardShow} from "../../api/state/keyboard/actions";
// eslint-disable-next-line import/order
import Keyboard, {CyrillicLayout, KeyboardButton, LatinLayout} from 'react-screen-keyboard';

class VirtualKeyboard extends React.Component {
    constructor(props) {
        super(props);
        this.ref = React.createRef();
    }

    componentDidMount() {
        window.onclick = (e) => {
            if (e.target.closest('.keyboard') || e.target.className === "keyboard-button ") {
                return;
            }

            if (e.target.getAttribute("keyboard") !== "true" && e.target !== this.props.keyboardInputRef?.ref?.current) {
                setKeyBoardShow(false,null,null)
            }
        }
    }

    checkChildren = (target,child) => {
        if (target === child) {
            return true;
        }

        for (const item of child?.children || []) {
            return this.checkChildren(target,item);
        }
    }

    render() {
        if (!this.props.keyboardShow || !this.props.keyboardInputRef?.ref?.current || !this.props.keyboardInputRef?.callBack) {
            return null;
        }
        const targetCoords = this.props.keyboardInputRef.ref.current.getBoundingClientRect();
        const {left} = targetCoords;
        let top = targetCoords.top + (window.innerHeight * 5.5 / 100);
        if (window.innerHeight < top + 200) {
            top = targetCoords.top - 200 - (window.innerHeight * 5.5 / 100);
        }
        return (
          <div ref={this.ref} style={{top,left}} className={`${style.virtualKeyboard} ${this.props.keyboardShow ? style.virtualKeyboardShow : style.virtualKeyboardHide}`}>
            <Keyboard
              inputNode={this.props.keyboardInputRef?.ref?.current}
              rightButtons={[
                <KeyboardButton
                  onClick={() => setKeyBoardShow(false,null,null)}
                  value={<img src={iconArrowDown} style={{filter: "brightness(1000)"}} />}
                />,
                    ]}
              onClick={(e) => this.props?.keyboardInputRef?.callBack({target: {value: e}})}
              layouts={[LatinLayout,CyrillicLayout]}
            />
          </div>
        )
    }
}

export default connect((state) => state,null)(VirtualKeyboard);
