import React from "react";
import style from "../SystemDetails.module.scss";

class DetailItem extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
          <div className={style.item} style={{width: `${100 / this.props.length}%`}}>
            <div className={style.caption}>
              {this.props.caption}
              {this.props.id}
            </div>
            <div className={style.detail}>{this.props.detail}</div>
          </div>
        )
    }
}

export default DetailItem;
