import React from "react";
import style from "./FaceDahuaListItem.module.scss";

export default class FaceDahuaListItem extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
          <div className={style.faceDahuaListItem}>
            <div className={style.face} style={{width: "22.24vw"}}>{this.props.face.date}</div>
            <div className={style.face} style={{width: "11.12vw"}}>{this.props.face.gender}</div>
            <div className={style.face} style={{width: "11.12vw"}}>{this.props.face.age}</div>
            <div className={style.face} style={{width: "22.24vw"}}>{this.props.face.emotion}</div>
            <div className={style.face} style={{width: "22.24vw"}}>{this.props.face.nationality}</div>
          </div>
        )
    }
}
