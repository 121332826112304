import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import style from "./Folder.module.scss";
import {createEdit} from "../../../api/state/cam/actions";

const Folder = (props) => {
    const [open,setOpen] = useState(true)
    const [check,setCheck] = useState(false)

    useEffect(() => {
        setCheck((prevState) => {
            const cam = props.editCam;
            prevState = cam?.parents.includes(props.item.id)
            return prevState;
        })
    })

    const closeOpen = (el) => {
        if (el.target === el.currentTarget) {
            setOpen(!open)
        }
    }

    const click = (el) => {
        const cam = props.editCam;
        if (!check) {
            cam.parents.push(props.item.id);
        } else {
            cam.parents = cam.parents.filter((i) => i !== props.item.id);
        }
        setCheck((prevState) => {
            prevState = cam?.parents.includes(props.item.id)
            return prevState;
        })
        createEdit(cam);
    }
    return (
      <div className={style.folder}>
        <div onClick={closeOpen} className={`${style.info}`}>
          {props.item.parent && <div className={style.dotsHor} />}
          {props.item.parent && <div style={{height: props.last && "1.85vw"}} className={style.dots} />}
          <div onClick={closeOpen} className={`${style.icon} ${open ? style.iconOpen : style.iconClose}`} />
          <div className={`${style.check} ${check && style.checked}`} onClick={click}>
            <div className={style.dot} />
          </div>
          <div onClick={closeOpen} className={style.caption}>{props.item.name}</div>
        </div>
        <div onClick={closeOpen} className={style.background} />
        <div className={`${style.children} ${!open && style.close}`}>
          {props.item.children?.filter((i) => !i.hasOwnProperty("isArchive")).map((child,i) => {
                    const last = props.item.children.filter((i) => !i.hasOwnProperty("isArchive")).length === (i + 1)
                        return <Folder {...props} last={last} item={child} key={i} />
                })}
        </div>
      </div>
    )
}

export default connect((state) => state,null)(Folder);
