import Taxonomy from "../../models/Taxonomy";
import {IBase} from "../iBase";

export default class TaxonomyDao implements IBase<Taxonomy> {
    api: any;

    constructor(api:any) {
        this.api = api;
    }

    async create(obj: Taxonomy): Promise<Taxonomy> {
        const taxonomy = obj.deserialize();
        const newTaxonomy = new Taxonomy();
        newTaxonomy.serialize((await this.api.emit("taxonomy.create",{taxonomy})).taxonomy);
        return newTaxonomy;
    }

    async delete(obj: Taxonomy): Promise<Taxonomy> {
        const taxonomy = obj.deserialize();
        const newTaxonomy = new Taxonomy();
        newTaxonomy.serialize((await this.api.emit("taxonomy.delete",{taxonomy})).taxonomy);
        return newTaxonomy;
    }

    async get(id: string): Promise<Taxonomy> {
        const taxonomy = new Taxonomy();
        taxonomy.id = id;
        taxonomy.serialize((await this.api.emit("taxonomy.get",{taxonomy})).taxonomy);
        return taxonomy;
    }

    async list(): Promise<Array<Taxonomy>> {
        const taxonomiesDeserializes = (await this.api.emit("taxonomy.list", {},true,true)).taxonomies;
        // eslint-disable-next-line no-array-constructor
        const taxonomies = Array<Taxonomy>();
        taxonomiesDeserializes.map((item:any) => {
            const taxonomy = new Taxonomy();
            taxonomy.serialize(item);
            taxonomies.push(taxonomy);
        })
        return taxonomies;
    }

    async update(obj: Taxonomy): Promise<Taxonomy> {
        const taxonomy = obj.deserialize();
        const newTaxonomy = new Taxonomy();
        newTaxonomy.serialize((await this.api.emit("taxonomy.update",{taxonomy})).taxonomy);
        return newTaxonomy;
    }
}
