import React from "react";
import {connect} from "react-redux";
import style from "./ModalClearDhcpList.module.scss"
import ButtonRectangle from "../../Buttons/ButtonRectangle/ButtonRectangle";
import {worlds} from "../../../Utils/localization";
import {closeModal} from "../actions";
import {clearDhcpClientList} from "../../../api/state/dhcp/actions";

class ModalClearDhcpList extends React.Component {
    render() {
        return (
          <div className={style.modalDeleteCam}>
            <div className={style.caption}>
              {`${worlds.afterThisProcedureYouNeedToRestartTheCameras}`}
            </div>
            <div className={style.controls}>
              <ButtonRectangle width="100%" caption={worlds.clearTheList} action={() => { clearDhcpClientList(); closeModal() }} />
              <ButtonRectangle width="7.55vw" dark caption={worlds.cancel} action={() => closeModal()} />
            </div>
          </div>
        )
    }
}

export default connect((state) => state,null)(ModalClearDhcpList)
