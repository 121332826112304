export default class Mse {
  constructor(url, video) {
    this.currentTime = 0;
    this.url = url;
    this.video = video;
    this.mseSourcebuferType = '';
    this.ws = null;
    this.mse = null;
    this.mseQueue = {};
    this.mimeCodec = '';
    this.onReconnect = null;
    this.onConnect = null;
    this.onDisconnect = null;
    this.onError = null;
    this.onSeek = null;
    this.checkVideoInterval = null;

    if (!Uint8Array.prototype.slice) {
      // eslint-disable-next-line
      Object.defineProperty(Uint8Array.prototype, 'slice', {
        enumerable: false,
        value(begin, end) {
          return new Uint8Array(Array.prototype.slice.call(this, begin, end));
        },
      });
    }
  }

  isPlaying = () => this.video.currentTime > 0 && !this.video.paused && !this.video.ended
    && this.video.readyState > this.video.HAVE_CURRENT_DATA

  checkVideo = () => {
    clearInterval(this.checkVideoInterval)
    let time = this.video.currentTime
    this.checkVideoInterval = setInterval(async () => {
      if (time === this.video.currentTime) {
        await this.restart()
      }
      time = this.video.currentTime;
    },10000)
  }

  restart = async () => {
    await this.stop();
    await new Promise((resolve) => setTimeout(resolve, 1000))
    await this.start()
  }

  start = async () => {
    clearInterval(this.checkVideoInterval)
    this.mse = new MediaSource();
    this.video.src = URL.createObjectURL(this.mse);
    this.video.addEventListener('error', this.error, false)
    this.video.addEventListener('timeupdate', this.timeupdate, false);
    this.video.addEventListener('loadedmetadata', this.loadedmetadata, false);
    this.mse.addEventListener('sourceopen', this.sourceOpen, false);
    this.video.play();
    this.checkVideo();
  };

  sourceOpen = async () => {
    try {
      this.ws = new WebSocket(this.url);
      this.ws.binaryType = 'arraybuffer';
      this.ws.onopen = this.onOpen;
      this.ws.onclose = this.onClose;
      this.ws.onmessage = this.message;
    } catch (e) {
      this.error(e);
    }
  };

  message = async (event) => {
    const data = new Uint8Array(event.data);
    if (data[0] === 9) {
      const decodedArr = data.slice(1);
      let mimeCodec = '';
      if (window.TextDecoder) {
        mimeCodec = new TextDecoder('utf-8').decode(decodedArr);
      } else if (window.Utf8ArrayToStr) {
        mimeCodec = window.Utf8ArrayToStr(decodedArr);
      }
      this.mimeCodec = mimeCodec;
      if (!this.mseQueue[mimeCodec]) {
        this.mseQueue[mimeCodec] = [];
      }
    } else {
      await this.readPacket(event.data);
    }
  };

  readPacket = async (packet) => {
    const mimeCodec = Object.keys(this.mseQueue)[0];
    this.mseQueue[this.mimeCodec].push(packet);
    if (this.mimeCodec !== mimeCodec) {
      if (!this.mseQueue[mimeCodec].length) {
        delete this.mseQueue[mimeCodec];
      }
    }
    await this.pushPacket();
  }

  pushPacket = async () => {
    const mimeCodec = Object.keys(this.mseQueue)[0];
    const mseSourceBuffer = this.getSourceBuffer();
    if (mseSourceBuffer) {
      if (this.mseQueue[mimeCodec].length > 0) {
        const packet = this.mseQueue[mimeCodec].shift();
        try {
          mseSourceBuffer.appendBuffer(packet);
        } catch (e) {
          if (this.mseQueue[mimeCodec].length > 20) {
            await this.restart();
            return;
          }
          this.mseQueue[mimeCodec].unshift(packet)
        }
      }
    }
    this.cleanupSourceBuffer();
  };

  loadedmetadata = async (message) => {
    await new Promise((resolve) => setTimeout(resolve, 5000));
    if (this.video.currentTime < 1 && this.ws && this.ws.OPEN) {
      this.getSourceBuffer();
    }
  };

  error = (message) => {
    console.error(message);
  };

  stop = () => {
    clearInterval(this.checkVideoInterval)
    this.video.pause()
    if (this.ws) {
      this.ws.onclose = null;
      this.ws.onerror = null;
      this.ws.close();
      this.ws = null;
    }
    if (this.mse) {
      if (this.mse.sourceBuffers.length > 0) {
        const mseSourceBuffer = this.mse.sourceBuffers[0];
        if (mseSourceBuffer) {
          mseSourceBuffer.removeEventListener('updateend', this.pushPacket);
          if (!mseSourceBuffer.updating) {
            this.mse.removeSourceBuffer(mseSourceBuffer);
          } else {
            mseSourceBuffer.abort();
          }
        }
      }
      this.mse.removeEventListener('sourceopen', this.sourceOpen);
    }
    this.video?.removeEventListener('error', this.error, false);
    this.mimeCodec = '';
    this.mseQueue = {};
    this.mseSourcebuferType = '';
    this.currentTime = 0;
  };

  onClose = async () => {
      if (this.onDisconnect) {
        this.onDisconnect(this);
      }
  };

  onOpen = () => {
    if (this.onConnect) {
      this.onConnect(this);
    }
  };

  timeupdate = () => {
    if (this.currentTime < parseInt(this.video.currentTime, 10)) {
      this.currentTime = parseInt(this.video.currentTime, 10);
      if (this.onSeek) {
        this.onSeek(this.currentTime);
      }
    }
  };

  cleanupSourceBuffer = () => {
    if (!this.video || !this.mse) {
      return;
    }
    const mseSourceBuffer = this.getSourceBuffer();
    if (mseSourceBuffer) {
      try {
        const {buffered} = mseSourceBuffer;
        if (buffered.length > 0) {
          const start = buffered.start(0);
          const end = buffered.end(mseSourceBuffer.buffered.length - 1);
          if (this.video.currentTime > 0 && this.video.currentTime < end - 5) {
            this.video.currentTime = end - 5;
            mseSourceBuffer.remove(start, end - 4);
            return;
          }
          if (this.video.currentTime > start + 2) {
            mseSourceBuffer.remove(start, this.video.currentTime - 2);
          }
        }
      } catch (e) {}
    }
  };

  getSourceBuffer = () => {
    const mimeCodec = Object.keys(this.mseQueue)[0]
    if (!mimeCodec) {
      return null;
    }
    if (this.mse) {
      let mseSourceBuffer = this.mse.sourceBuffers[0];
      if (mseSourceBuffer) {
        if (this.mseSourcebuferType !== mimeCodec && !this.mseQueue[this.mseSourcebuferType]) {
          try {
            if (!mseSourceBuffer.updating) {
              this.video.currentTime = 0;
              mseSourceBuffer.changeType(`video/mp4; codecs="${mimeCodec}"`);
              delete this.mseQueue[this.mseSourcebuferType];
              this.mseSourcebuferType = mimeCodec;
              this.video.play();
            }
          } catch (e) {
            console.error(e);
          }
          return null;
        }
      }
      if (!mseSourceBuffer) {
        try {
          mseSourceBuffer = this.mse.addSourceBuffer(`video/mp4; codecs="${mimeCodec}"`);
          mseSourceBuffer.mode = 'segments';
          mseSourceBuffer.addEventListener('updateend', this.pushPacket, {once: true});
          this.mseSourcebuferType = mimeCodec;
          this.video.play();
        } catch (e) {
          console.error(e);
        }
      }
      return mseSourceBuffer;
    }
    return null;
  }
}
