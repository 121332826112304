import React from "react";
import {connect} from "react-redux";
import style from "./ModalDeletePlan.module.scss"
import {worlds} from "../../../Utils/localization";
import ButtonRectangle from "../../Buttons/ButtonRectangle/ButtonRectangle";
import {closeModal} from "../actions";
import {deletePlan} from "../../../api/state/plan/actions";

class ModalDeletePlan extends React.Component {
    deleteCurrentPlan = async () => {
        await deletePlan(this.props.currentPlan);
    }

    render() {
        return (
          <div className={style.modalDeletePlan}>
            <div className={style.caption}>
              {`${worlds.deletePlan} ${this.props.plan?.name}?`}
            </div>
            <div className={style.controls}>
              <ButtonRectangle width="7.55rem" caption={worlds.delete} action={() => { this.deleteCurrentPlan(this.props.plan); closeModal() }} />
              <ButtonRectangle width="7.55rem" dark caption={worlds.cancel} action={() => closeModal()} />
            </div>
          </div>
        )
    }
}

export default connect((state) => state,null)(ModalDeletePlan)
