import React from "react";
import {DragSource} from 'react-dnd'
import style from "./TaxonomyCam.module.scss"
import MenuCamTree from "../MenuCamTree/MenuCamTree";
import PopoverCaption from "../../Popovers/PopoverCaption/PopoverCaption";
import iconDragCam from "../../../Assets/icons/pin.svg";
import {showModalPlayer} from "../../Modals/actions";

const Types = {
    ITEM: 'cam',
}

const itemSource = {
    beginDrag(props,monitor, component) {
        component.changeOnDrag(true)
        return {...props.item}
    },
    endDrag(props, monitor, component) {
        component.changeOnDrag(false)
        if (!monitor.didDrop()) {
            return
        }
        const item = monitor.getItem()
        const dropResult = monitor.getDropResult()
    },
}
function collect(connect, monitor) {
    return {
        connectDragSource: connect.dragSource(),
        connectDragPreview: connect.dragPreview(),
        isDragging: monitor.isDragging(),
    }
}

class TaxonomyCam extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fullName: {open: false, anchor: null},
            drag: false,
        }
    }

    componentDidMount() {
        if (this.props.plan) {
            const {connectDragPreview} = this.props;
            const img = new Image();
            img.src = iconDragCam;
            img.onload = () => connectDragPreview(img);
        }
    }

    enterCaption = (el) => {
        const {fullName} = this.state;
        if (fullName.open) {
            return
        }
        fullName.open = true;
        fullName.anchor = el.currentTarget;
        this.setState({fullName})
    }

    leaveCaption = () => {
        const {fullName} = this.state;
        if (!fullName.open) {
            return
        }
        fullName.open = false;
        fullName.anchor = null;
        this.setState({fullName})
    }

    changeOnDrag = (bool) => {
        this.setState({drag: bool})
    }

    openPlayer = (e) => {
        if (!e.target.classList.toString().includes("Button")) {
                e.preventDefault();
                e.stopPropagation();
                showModalPlayer(this.props.item);
        }
    }

    render() {
        const { isDragging, connectDragSource, src } = this.props
        return connectDragSource(
          <div onDoubleClick={this.openPlayer} className={`${style.taxonomyChildren} `}>
            {!this.props.search && this.props.item.parents.length > 0
                && (
                <>
                  <div className={style.dotsHor} />
                  <div style={{height: this.props.last && "2.1vw"}} className={style.dots} />
                </>
)}
            <div className={`${this.props.item.online ? this.props.item.isArchive ? style.iconRecord : style.icon : style.iconOff}`} />
            <div onMouseEnter={this.enterCaption} onMouseLeave={this.leaveCaption} className={style.caption}>{this.props.item.name}</div>
            {this.props.currentAccount?.username !== "user" && <MenuCamTree parent={this.props.parent} showDelete={this.props.showDelete} elem={this.props.item} />}
            <PopoverCaption position="left" open={this.state.fullName.open} anchor={this.state.fullName.anchor} caption={this.props.item.name} />
            {!this.state.drag && <div className={style.background} />}
          </div>,
        )
    }
}

export default DragSource(Types.ITEM, itemSource, collect)(TaxonomyCam)
