import {store} from "../../../App";
import {action} from "../../ReduxHelper";
import {CURRENT_NTP_CONFIG} from "./consts";
import {worlds} from "../../../Utils/localization";

let api = null;

export const initiateNtpConfig = () => {
    api = store.getState().api;
    loadNtpConfig()
}

export const loadNtpConfig = async () => {
    try {
        const config = (await api.emit("system.ntp.get", {}))?.pool;
        store.dispatch(action(CURRENT_NTP_CONFIG, config))
    } catch (e) {}
}

export const editNtpConfig = async (config) => {
    store.dispatch(action(CURRENT_NTP_CONFIG,config))
}

export const saveNtpConfig = async (config) => {
    try {
        api.emit("system.ntp.set",{pool: config});
        store.getState().snackbar("success",{header: worlds.settingsSavedSuccessfully,description: ""});
    } catch (e) {}
}
