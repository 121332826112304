import React from 'react';

import style from './Switching.scss';
import ReactionsForm from "./ReactionsForm/ReactionsForm";
import ReactionsTable from "./ReactionsTable/ReactionsTable";

function Switching(state, props) {
  return (
    <div className={style.reactionsContainer}>
      <ReactionsForm />
      <ReactionsTable />
    </div>
  );
}

export default Switching;
