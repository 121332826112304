import React from "react";
import {InputAdornment} from "@material-ui/core";
import {connect} from "react-redux";
import moment from "moment-timezone";
import style from "./ModalDownloadFile.module.scss"
import AutocompleteM from "../../Inputs/AutocompleteM";
import TextFieldM from "../../Inputs/TextFieldM";
import iconCalendar from "../../../Assets/icons/calendar.svg";
import DateTimePicker from "../../DateTimePicker/DateTimePicker";
import {worlds} from "../../../Utils/localization";
import SliderM from "../../Inputs/SliderM";
import ButtonRectangle from "../../Buttons/ButtonRectangle/ButtonRectangle";
import {showModalFileManager} from "../actions";
import {downloadArchive} from "../../../api/state/cam/actions";

const types = ['mp4','avi'];

class ModalDownloadFile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cam: this.props.cam || "",
            inputCam: this.props.cam.name || "",
            type: types[0],
            duration: "00:00:00",
            rangeSlider: 0,
            dateRange: [new Date(moment().format()),new Date(moment().format())],
            showDateTime: {show: false,index: 0},
        }
    }

    onChangeDate = (date,index) => {
        const {dateRange} = this.state;
        dateRange[index] = date;
        if (new Date(Date.parse(dateRange[1])) < new Date(Date.parse(dateRange[0]))) {
            dateRange[0] = new Date(Date.parse(dateRange[1])).setMinutes(new Date(Date.parse(dateRange[1])).getMinutes() - 1)
        }
        this.setState({dateRange})
    }

    onDurationChange = (value) => {
        this.setState({rangeSlider: value})
        const {dateRange} = this.state;
        const dateStart = new Date(moment(dateRange[0]).isValid() ? dateRange[0] : Date.now());
        const date = new Date(2020,1,1);
        date.setSeconds(value);
        dateStart.setSeconds(dateStart.getSeconds() + value);
        dateRange[1] = dateStart;
        if (new Date(Date.parse(dateStart)) > new Date(Date.now())) {
            dateRange[1] = new Date(Date.now());
            return;
        }
        this.setState({duration: `${(`0${date.getHours()}`).slice(-2)}:${(`0${date.getMinutes()}`).slice(-2)}:${(`0${date.getSeconds()}`).slice(-2)}`,dateRange})
    }

    reset = (bool) => {
        this.setState({
isReset: bool,duration: "00:00:00",rangeSlider: 0,dateRange: [new Date(moment().format()),new Date(moment().format())],
})
    }

    showAndCloseDateTimeIndex = (index) => {
        const {showDateTime} = this.state;
        if (showDateTime.show && showDateTime.index !== index) {
            showDateTime.index = index;
        } else {
            showDateTime.show = !showDateTime.show;
            showDateTime.index = index;
        }
        this.setState({showDateTime})
    }

    apply = () => {
        showModalFileManager(true,async (item) => {
            const start = moment(this.state.dateRange[0]).format();
            const end = moment(this.state.dateRange[1]).format();
            downloadArchive(this.state.cam.id,item.filepath,start,end);
        });
    }

    render() {
        return (
          <div className={style.modalDownloadFile}>
            {this.state.showDateTime.show && (
            <div style={{right: this.state.showDateTime.index === 1 && "2.5vw"}} className={style.dateTime}>
              <div className={style.dateTimeClose} onClick={() => this.showAndCloseDateTimeIndex(this.state.showDateTime.index)} />
              <DateTimePicker reset={this.reset} isReset={this.state.isReset} dateTime={(date) => this.onChangeDate(date,this.state.showDateTime.index)} />
            </div>
)}
            <div className={style.label}>{worlds.cameraName}</div>
            <AutocompleteM
              defaultValue={this.state.cam?.name}
              value={this.state.cam?.name}
              onChange={(event, newValue) => {
                            this.setState({cam: Object.values(this.props.cams)?.find((i) => i.name === newValue)})
                        }}
              inputValue={this.state.inputCam}
              onInputChange={(event, newInputValue) => {
                            this.setState({inputCam: newInputValue})
                        }}
              options={Object.values(this.props.cams)?.filter((i) => i.isArchive).map((i) => i.name)}
            />
            <div className={style.date}>
              <div>
                <div className={style.label}>{worlds.startDateRecorder}</div>
                <TextFieldM
                  variant="outlined"
                  style={{width: "11.45vw",margin: 0}}
                  value={moment(this.state.dateRange[0]).format('DD.MM.YYYY HH:mm')}
                  disableKeyboard
                  InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end" onClick={() => this.showAndCloseDateTimeIndex(0)}>
                                    <img className={style.iconCalendar} src={iconCalendar} />
                                  </InputAdornment>
                                ),
                            }}
                />
              </div>
              <div>
                <div className={style.label}>{worlds.endDateRecording}</div>
                <TextFieldM
                  variant="outlined"
                  style={{width: "11.45vw",margin: 0}}
                  value={moment(this.state.dateRange[1]).format('DD.MM.YYYY HH:mm')}
                  disableKeyboard
                  InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end" onClick={() => this.showAndCloseDateTimeIndex(1)}>
                                    <img className={style.iconCalendar} src={iconCalendar} />
                                  </InputAdornment>
                                ),
                            }}
                />
              </div>
            </div>
            <div className={style.label}>
              {worlds.videoDuration}
              {' '}
              <span>{this.state.duration}</span>
            </div>
            <SliderM
              max={10800}
              value={this.state.rangeSlider}
              onChange={(e,val) => this.onDurationChange(val)}
            />
            <div style={{marginTop: "1vw"}} className={style.label}>{worlds.videoFormat}</div>
            <AutocompleteM
              value={this.state.type}
              onChange={(event, newValue) => {
                            this.setState({type: newValue})
                        }}
              inputValue={this.state.type}
              disableClearable
              options={types}
            />
            <div className={style.buttons}>
              <ButtonRectangle height="2.18vw" width="16.97vw" caption={worlds.download} action={() => this.apply()} />
              <ButtonRectangle dark height="2.18vw" width="6.35vw" caption={worlds.reset} action={() => this.reset(true)} />
            </div>
          </div>
        )
    }
}

export default connect((state) => state,null)(ModalDownloadFile)
