import React, {useState} from "react";
import style from "./DownloadManagerFile.module.scss"
import ButtonSquare from "../../Buttons/ButtonSquare/ButtonSquare";
import closeIcon from "../../../Assets/icons/cancel.svg"
import BorderLinearProgress from "../../BorderLinearProgress/BorderLinearProgress";
import iconStrazh from "../../../Assets/logo.svg"

export const DownloadManagerFile = (props) => {
    const [error,setError] = useState(false);
    return (
      <div className={style.downloadManagerFile}>
        <div className={style.close}>
          <ButtonSquare icon={closeIcon} />
        </div>
        <img className={style.preview} src={error ? iconStrazh : props.file.snapshot} onError={() => { setError(true) }} />
        <div className={style.content}>
          <div className={style.caption}>{props.file.filename}</div>
          <div className={style.size}>
            {props.file?.size ? (props.file?.size / 1024 / 1024).toFixed(2) : 0}
            {' '}
            МБ
            {' '}
          </div>
          {!props.file.error
                    ? props.file?.percent !== 100
                        ? <BorderLinearProgress variant="determinate" value={props.file?.percent ?? 0} />
                        : <div className={style.caption}>Файл скачан</div>
                    : <div className={style.caption} style={{color: "red"}}>Ошибка скачивания</div>}
        </div>

      </div>
    )
}
