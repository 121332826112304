import React, {useState} from "react";
import {connect} from "react-redux";
import style from "./DownLoadManager.module.scss"
import ButtonRectangle from "../Buttons/ButtonRectangle/ButtonRectangle";
import ButtonSquare from "../Buttons/ButtonSquare/ButtonSquare";
import cancelIconRed from "../../Assets/icons/cancelRed.svg"
import {worlds} from "../../Utils/localization";
import {DownloadManagerFile} from "./DownloadManagerFile/DownloadManagerFile";
import {clearDownloadFiles} from "../../api/state/fileManager/actions";

const DownloadManager = (props) => {
    const [showDownloads,setShowDownloads] = useState(true)

    const closeManager = () => {
        clearDownloadFiles();
    }
    if (props.downloadFiles.length > 0) {
 return (
   <div className={style.downloadManager}>
     <div className={style.header}>
       <div className={style.caption}>Загрузка</div>
       <div className={style.controls}>
         <ButtonRectangle action={() => setShowDownloads(!showDownloads)} caption={showDownloads ? worlds.hide : worlds.show} dark height="2.18vw" width="6.45vw" />
         <ButtonSquare action={() => closeManager()} style={{border: `0.05vw solid #3C4347`}} icon={cancelIconRed} width="2.18vw" height="2.18vw" iconWidth="1vw" name={worlds.close} />
       </div>
     </div>
     {showDownloads
                && (
                <div className={style.content}>
                  {props.downloadFiles.map((item, i) => <DownloadManagerFile file={item} key={i} />)}
                </div>
)}
   </div>
        )
}
    return (<></>)
}

export default connect((state) => state,null)(DownloadManager);
