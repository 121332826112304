import {store} from "../../../App";
import {changeCells} from "../actions";

export const ItemTypes = {
    CAM: 'cam',
}

export const changeSize = (cell,bool) => {
    const {cells} = store.getState()
    if (cell.size === 2 && bool && cells.length > 8) {
        return
    }
    if (cell.size === 1 && bool && cells.length > 13) {
        return
    }

    if (cells.filter((i) => i.size === 2 && i.id !== cell.id).length === 3 && bool && cells.length > 4) {
        return
    }
    if (cells.filter((i) => i.size === 2 && i.id !== cell.id).length === 2 && bool && cells.length > 7) {
        return
    }
    if (cells.filter((i) => i.size === 2 && i.id !== cell.id).length > 0 && bool && cells.length > 10) {
        return
    }
    if (cells.filter((i) => i.size === 2 && i.id !== cell.id).length > 0 && cell.size === 2 && bool) {
        return
    }
    if (cells.length > 1 && cell.size === 3 && bool) {
        return
    }
    if (cells.filter((i) => i.size > 2 && i.id !== cell.id).length > 0 && bool) {
        return
    }
    if (bool) {
        if (cell.size === 4) {
            return
        }
        cell.width += 25;
        cell.height += 25.7;
        cell.size++;
        if (cell.size > 2) {
            cell.height += 0.1
        }
    } else {
        if (cell.size === 1) {
            return
        }
        if (cell.size > 2) {
            cell.height -= 0.1
        }
        cell.width -= 25;
        cell.height -= 25.7;
        cell.size--;
    }

    cells.sort((l, r) => {
        if (l.size > r.size) return -1;
        if (l.size < r.size) return 1;
        return 0;
    });
    cells.splice(cells.indexOf(cells.find((i) => i.id === cell.id)), 1, cell)
    store.dispatch(changeCells(cells))
}

export const changeCell = (cell) => {
    const {cells} = store.getState()
    cells.splice(cells.indexOf(cells.find((i) => i.id === cell.id)),1,{...cell})
    store.dispatch(changeCells(cells))
}
