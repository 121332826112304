import React from "react";
import {DropTarget} from "react-dnd";
import style from "./PlanView.module.scss"
import iconUpdate from "../../../Assets/icons/attach.svg"
import {createEdit} from "../../../api/state/plan/actions";
import ButtonSquare from "../../../Components/Buttons/ButtonSquare/ButtonSquare";
import Cam from "./Cam/Cam";
import {worlds} from "../../../Utils/localization";

const Types = {
    ITEM: ['cam','camEdit'],
}
const camTarget = {
    canDrop(props, monitor) {
        const item = monitor.getItem()
        return item;
    },

    hover(props, monitor, component) {
        const clientOffset = monitor.getClientOffset()
        const isOnlyThisOne = monitor.isOver({ shallow: true })
        const canDrop = monitor.canDrop()
        component.MousePosition(clientOffset)
    },

    drop(props, monitor, component) {
        if (monitor.didDrop()) {
            return
        }
        const item = monitor.getItem()
        return { moved: true, plan: component , item}
    },
}

function collect(connect, monitor) {
    return {
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
        isOverCurrent: monitor.isOver({ shallow: true }),
        canDrop: monitor.canDrop(),
        itemType: monitor.getItemType(),
        cam: monitor.getItem(),
        dropResult: monitor.getDropResult(),

    }
}

class PlanView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mouseX: 0,
            mouseY: 0,
        }
        this.ref = React.createRef()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.dropResult !== this.props.dropResult && this.props.dropResult && this.props.dropResult.plan) {
            if (this.props.dropResult.plan.ref === this.ref) this.dropItem(this.props.cam,this.props.itemType)
        }
    }

    dropItem = (cam,type) => {
        const targetCoords = this.ref.current.getBoundingClientRect()
        if (!this.props.edit) {
            return
        }
        const {plan} = this.props;
        const camCheck = plan.cams.find((i) => i.id === cam.id);
        if (type === Types.ITEM[0] && camCheck) {
            return;
        }
        if (this.state.mouseX > targetCoords.left
        || this.state.mouseY > targetCoords.top
        || this.state.mouseY <= 0 || this.state.mouseX <= 0) {
            return;
        }
        const camInsert = {
            x: this.state.mouseX,
            y: this.state.mouseY,
            id: cam.id,
            type: "Camera",
        }
        if (camCheck) {
            plan.updateCam(camInsert)
        } else {
            plan.cams.push(camInsert)
        }
            createEdit(plan)
    }

    MousePosition = (mouse) => {
        const targetCoords = this.ref.current.getBoundingClientRect()
        const x = (mouse.x - targetCoords.left) / (window.innerWidth / 100);
        const y = (mouse.y - targetCoords.top) / (window.innerHeight / 100);
        this.setState({mouseX: x,mouseY: y})
    }

    render() {
        const { connectDropTarget } = this.props
        return connectDropTarget(
          <div style={{backgroundImage: `url(${this.props.plan.image})`}} ref={this.ref} className={style.planView}>
            {this.props.edit && (
            <ButtonSquare
              width="2.15vw"
              height="2.15vw"
              name={worlds.replaceImage}
              icon={iconUpdate}
              action={() => this.props.updateImage()}
              style={{
position: "absolute",top: "0.5vw",right: "0.5vw",color: "red",
}}
            />
)}
            {this.props.plan?.cams?.map((item,i) => <Cam plan={this.props.plan} edit={this.props.edit} cam={item} key={i} />)}
          </div>,
        )
    }
}

export default DropTarget(Types.ITEM, camTarget, collect)(PlanView)
