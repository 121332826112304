import React from "react";
import style from "./Preloader.module.scss"
import {worlds} from "../../Utils/localization";

export default class Preloader extends React.Component {
    render() {
        return (
            this.props.show
                ? (
                  <div style={{background: this.props.background || "rgba(34,35,36,0.8)",position: this.props.position || "fixed"}} className={style.preloader}>
                    <div className={style.preloaderSpiner}>
                      <div className={style.image} />
                    </div>
                    {this.props.text && <div className={style.preloaderInfo}>{`${worlds.loading}: ${this.props.text}`}</div>}
                  </div>
)
                : <></>
        )
    }
}
