import {CURRENT_NTP_CONFIG} from "./consts";

const prevState = {
    ntpConfig: [],
}

export const ntpConfig = (state = prevState.ntpConfig, action) => {
    switch (action.type) {
        case CURRENT_NTP_CONFIG:
            if (!action.obj) return state;
            return [...action.obj];
        default:
            return state;
    }
}
