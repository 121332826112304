import {store} from "../../../App";
import {action} from "../../ReduxHelper";
import {CURRENT_NETWORK_CONFIG} from "./consts";
import {worlds} from "../../../Utils/localization";

let api = null;

export const initiateNetworkConfig = () => {
    api = store.getState().api;
    loadNetworkConfig()
}

export const loadNetworkConfig = async () => {
    try {
        const config = await api.emit("strazh.network.get", {include: ["ping"]});
        store.dispatch(action(CURRENT_NETWORK_CONFIG, config.network))
    } catch (e) {}
}

export const editNetworkConfig = async (config) => {
    store.dispatch(action(CURRENT_NETWORK_CONFIG,config))
}

export const networkStatus = async () => {
    // let config = await api.emit("strazh.network.get", {include:["ping"]});
}

export const saveNetworkConfig = async (config) => {
    try {
        const rangeStart = config.lan.address.split(".");
        rangeStart[3] = parseInt(rangeStart[3], 10) + 100;
        const rangeEnd = config.lan.address.split(".")
        rangeEnd[3] = "254";

        const dhcp = {
            dns: config.lan.address,
            netmask: config.lan.netmask,
            listen: config.lan.address,
            ntp: config.lan.address,
            interface: "eth1",
            range: {start: rangeStart.join("."), end: rangeEnd.join("."), lease: 24},
            router: config.lan.address,
        }
        api.emit("strazh.dhcp.config.set",{dhcp})
        api.emit("strazh.network.set",{network: config});
        store.getState().snackbar("success",{header: worlds.settingsSavedSuccessfully,description: ""})
    } catch (e) {}
}
