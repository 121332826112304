import React from "react";
import {store} from "../../../App";
import AccountDao from "./accountDao";
import {CURRENT_ACCOUNT_CREATE, CURRENT_ACCOUNT_DELETE} from "./consts"
import {action} from "../../ReduxHelper";
import {worlds} from "../../../Utils/localization";
import {initiateApi as initiateApiCam, initiateCams} from "../cam/actions";
import {LOADER, SET_API} from "../../consts";
import {getSync, initiateApiSync} from "../../sync";
import {initializeEventDetections, initiateFaceApiConfig, loadFaceApiConfig} from "../faceApi/actions";
import {initiateNetworkConfig} from "../network/actions";
import {initiateApiTrigger, initiateTriggers} from "../trigger/actions";
import {initiateApiGuard, initiateEventTriggerGuard} from "../guard/actions";
import {emitter} from "../../../Utils/globalEmitter";
import {initiateNtpConfig} from "../ntp/actions";
import {getServerBrc, getServerTime} from "../../globalActions";
import {initiateFileManager} from "../fileManager/actions";
import {
    closeModal,
    showModalLogon,
    showModalTechnicalWork,
    showModalUpdateSystem,
} from "../../../Components/Modals/actions";
import debug from "../../../Utils/debug";
import {initiateDhcp} from "../dhcp/actions";
import {initiateDisks} from "../fileSystem/actions";
import {initiateApiLogs, initiateEventLogs} from "../logs/actions";
import {initiateApiSwitching, initiateSwitchingEvent} from "../switching/actions";
import {initiateApiPlans} from "../plan/actions";
import {snackbarRef} from "../../../Router";
import {initiateSystemApi} from "../system/actions";
import {electron, electronApi} from "../electron/actions";
import Api from "../../Api";

let api = null;
let dao = null;
let timerRoot = null;

export const initiateApi = async () => {
    store.dispatch(action(SET_API, new Api(window.location.hostname && window.location.hostname !== "localhost" ? `http://${window.location.hostname}:4080/` : window.config.host)))
    api = store.getState().api;
    dao = new AccountDao(api);
}

export const authorization = async (user) => {
    snackbarRef?.current?.closeSnackbar()
    clearTimeout(timerRoot)
    try {
        const auth = await dao.authorization(user);
        await initiateApplication(auth);
        await exitSessionRoot();
    } catch (e) {}
}

export const authorizationWithTokenByUrl = async (token) => {
    const auth = await dao.authorizationWithToken(token.trim());
    await initiateApplication(auth);
}

export const authorizationWithToken = async () => {
    if (snackbarRef.current) snackbarRef?.current?.closeSnackbar()
    if (timerRoot) clearTimeout(timerRoot)
    const { ipcRenderer } = electron
    ipcRenderer.removeAllListeners()
    ipcRenderer.on('show-modal-technical-work', () => {
        if (store.getState().currentAccount) showModalTechnicalWork();
    });
    ipcRenderer.send('asynchronous-message', 'ping')
    ipcRenderer.on('asynchronous-reply', (event, arg) => {
        setTimeout(() => {
            ipcRenderer.send('asynchronous-message', 'ping')
        },2000)
    })
    let token = ""
    try {
        token = await electronApi('get-token');
    } catch (e) {
        showModalLogon();
        return
    }
    if (token) {
        try {
            await exitSessionRoot();
            const auth = await dao.authorizationWithToken(token.trim());
            await initiateApplication(auth);
        } catch (e) {
        }
    }
}

export const initiateApplication = async (auth) => {
    try {
        api?.socket?.removeAllListeners();
        await initiateSystemApi()
        await getServerBrc();
        await getServerTime();
        await initiateApiCam()
        await initiateCams()
        if (store.getState().systemConfig.services.logger) {
            await initiateApiLogs();
        }
        if (store.getState().systemConfig.services.switchboard) {
            await initiateApiSwitching();
        }
        store.dispatch(action(CURRENT_ACCOUNT_CREATE,auth))
        store.getState().snackbar("success",{header: "Успешное подключение к серверу",description: ""});
        if (store.getState().systemConfig.services.faceapi) await initiateFaceApiConfig()
        if (auth?.username !== "user") {
            if (store.getState().systemConfig.services.faceapi) {
                await loadFaceApiConfig();
            }
            await initiateNetworkConfig()
            await initiateNtpConfig();
            await initiateFileManager();
            await initiateDhcp();
            await initiateDisks();
        }
        await initiateApiTrigger()
        await disconnectEvent()
        await initiateTriggers()
        await initiateApiGuard()
        await initiateApiSync()
        await initializeEvents();
        await initiateApiPlans()
        console.log("LOADED")
    } catch (e) {
        console.log(e)
    }
}

export const globalLoader = async (bool,text) => {
 store.dispatch(action(LOADER,{show: bool,text}))
}

export const disconnectEvent = () => {
    emitter.removeAllListeners('server_disconnect');
    emitter.addListener('server_disconnect',() => {
        snackbarRef?.current?.closeSnackbar()
    })
    snackbarRef?.current?.closeSnackbar()
}

export const initializeEvents = () => {
    api.socket.removeAllListeners('api-event');
    try {
        api.event('api-event',async (data) => {
            debug(`api-event ${JSON.stringify(data)}`)
            if (data.event === "broadcast.update") {
                showModalUpdateSystem();
            }
            initializeEventDetections(data)
            initiateEventTriggerGuard(data);
            initiateEventLogs(data);
            initiateSwitchingEvent(data);
            getSync(data);
        })
    } catch (e) {}
}

export const exit = async () => {
    try {
        store.dispatch(action(CURRENT_ACCOUNT_DELETE, null));
        snackbarRef?.current?.closeSnackbar()
        authorizationWithToken()
    } catch (e) {}
}

export const exitSessionRoot = async () => {
    const user = store.getState().currentAccount;
    if (user?.username === "root" || user?.username === "admin") {
        clearTimeout(timerRoot);
        timerRoot = setTimeout(async () => {
            if (document.fullscreenElement && document.exitFullscreen) {
                await document.exitFullscreen();
            }
            closeModal();
            store.dispatch(action(CURRENT_ACCOUNT_DELETE, null))
            authorizationWithToken()
        }, 60000 * 20)
    } else {
        clearTimeout(timerRoot);
    }
}

export const accountGet = async (id) => {
    try {
        return (await api.emit('account.get', {account: {id}})).account;
    } catch (e) {
        return "";
    }
}

export const accountUpdate = async (account) => {
    try {
        await api.emit('account.update', {account})
        store.getState().snackbar("success",{header: worlds.settingsSavedSuccessfully,description: ""});
    } catch (e) {
        return "";
    }
}
