import React, {useState} from "react";
import {InputAdornment} from "@material-ui/core";
import {connect} from "react-redux";
import Pagination from "@material-ui/lab/Pagination";
import style from "./Logs.module.scss";
import ButtonSquare from "../../../Components/Buttons/ButtonSquare/ButtonSquare";
import iconFilter from "../../../Assets/icons/filter.svg";
import {worlds} from "../../../Utils/localization";
import searchIcon from "../../../Assets/icons/search.svg";
import TextFieldM from "../../../Components/Inputs/TextFieldM";
import ButtonRectangle from "../../../Components/Buttons/ButtonRectangle/ButtonRectangle";
import LogItem from "./LogItem/LogItem";
import arrowIcon from "../../../Assets/icons/arrowDown.svg"
import {initiateLogs} from "../../../api/state/logs/actions";
import {showModalFilterLogs} from "../../../Components/Modals/actions";

const Logs = (props) => {
    const [page,setPage] = useState(1);
    const [searchText,setSearchText] = useState("");
    const [dateRange,setDateRange] = useState([null,null])
    const [filter,setFilter] = useState({
user: "",level: "",dateStart: "",dateEnd: "",type: "",
})
    const searchLogs = (e) => {
        setSearchText(e);
        if (!e) {
            initiateLogs({
filter: getFilterResult(),sort: {updated_at: -1},limit: 16,offset: 0,
});
        }
    }

    const sort = (ask) => {
        initiateLogs({
filter: getFilterResult(),sort: {updated_at: ask ? -1 : 1},limit: 16,offset: 16 * page - 16,
});
    }
    const search = () => {
        initiateLogs({
filter: getFilterResult(),sort: {updated_at: -1},limit: 16,offset: 0,
});
    }
    const changePage = (el,pageNum) => {
        setPage(pageNum)
        initiateLogs({
filter: getFilterResult(),sort: {updated_at: -1},limit: 16,offset: 16 * pageNum - 16,
});
    }

    const getFilterResult = () => {
        const filterResult = {};
        if (filter.dateStart && filter.dateEnd) {
            filterResult.updated_at = {$gte: `${filter.dateStart}`,$lte: `${filter.dateEnd}`};
        }
        if (filter.level) {
            filterResult.level = filter.level;
        }
        if (filter.type) {
            filterResult.type = filter.type;
        }
        if (searchText) {
            filterResult["event.name"] = {$regex: searchText}
        }
        return filterResult;
    }
    const applyFilter = (newFilter) => {
        initiateLogs({
filter: {
updated_at: {$gte: `${newFilter.dateStart}`,$lte: `${newFilter.dateEnd}`},level: newFilter.level,type: newFilter.type,"event.name": {$regex: ""},
},
sort: {created_at: -1},
limit: 16,
offset: 16 * page - 16,
});
        setFilter(newFilter);
    }

    const resetFilter = () => {
        initiateLogs({sort: {updated_at: -1},limit: 16,offset: 16 * page - 16});
        setFilter({
user: "",level: "",dateStart: "",dateEnd: "",type: "",
});
    }

    return (
      <div className={style.logs}>
        <div className={style.header}>
          <div className={style.searchInput}>
            <TextFieldM
              type="text"
              variant="outlined"
              style={{height: "2.5vw", width: "46.67vw", margin: "0"}}
              value={searchText}
              placeholder={worlds.searchByDescription}
              onChange={(e) => searchLogs(e.target.value)}
              InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <img style={{width: "0.94vw"}} src={searchIcon} />
                              </InputAdornment>
                            ),
                        }}
            />
          </div>
          <ButtonSquare action={() => search()} name={worlds.search} width="2.5vw" height="2.5vw" icon={searchIcon} />
          <ButtonSquare action={() => showModalFilterLogs(applyFilter,resetFilter)} name={worlds.filter} width="2.5vw" height="2.5vw" icon={iconFilter} style={{margin: "0 0.4vw"}} />
          <ButtonRectangle width="7.08vw" height="2.5vw" dark caption={worlds.export} />
          <div className={style.pagination}>
            <Pagination siblingCount={0} outlined="false" onChange={changePage} count={Math.round(props.logs.count / 16)} shape="rounded" />
          </div>
        </div>
        <div className={style.eventList}>
          <div className={style.headList}>
            <div className={style.headItem} style={{width: "10.31vw"}}>
              <div>{worlds.dateTime}</div>
              <div className={style.sort}>
                <img src={arrowIcon} onClick={() => sort(true)} />
                <img src={arrowIcon} onClick={() => sort(false)} />
              </div>
            </div>
            <div className={style.headItem} style={{width: "13.475vw"}}>{worlds.eventType}</div>
            <div className={style.headItem} style={{width: "20.29vw"}}>{worlds.eventDescription}</div>
            <div className={style.headItem} style={{width: "13.475vw"}}>{worlds.system}</div>
            <div className={style.headItem} style={{width: "13.475vw"}}>{worlds.user}</div>
            <div className={style.headItem} style={{width: "6.198vw"}}>{worlds.level}</div>
          </div>
          {props.logs.loggers.length > 0 ? props.logs.loggers.map((item, i) => <LogItem log={item} key={i} />) : <div className={style.addEvent}>{worlds.addReactionsOnEvent}</div>}
        </div>
      </div>
    )
}

export default connect((state) => state,null)(Logs);
