import React from "react";
import style from "./TimeLineEvent.module.scss";

export default class TimeLineEvent extends React.Component {
    componentDidMount() {

    }

    getScale = (time) => {
        const timeLine = (this.props.parent.offsetWidth / (24 * 60)).toFixed(3);
        const currentTime = {hour: new Date(Date.parse(time)).getHours(),minute: new Date(Date.parse(time)).getMinutes()}
        const hour = (currentTime.hour * 1) > 0 ? currentTime.hour * 60 : 0;
        return (timeLine * (hour + (currentTime.minute * 1))).toFixed(0);
    }

    render() {
        return (
          <div
            onClick={this.props.onClick}
            style={{
                left: `${this.getScale(this.props.archive.start)}px`,
                width: `${this.getScale(this.props.archive.end) - this.getScale(this.props.archive.start)}px`,
            }}
            className={style.timeLineEvent}
          />
        )
    }
}
