import moment from "moment-timezone";
import Api from "./Api";
import {
    CHANGE_LANGUAGE,
    CREATE_MODAL_CONTENT,
    DELETE_MODAL_CONTENT,
    LOADER,
    SERVER_BRC,
    SERVER_DISCONNECT,
    SET_TIME,
    SHOW_MODAL,
    SET_API,
} from "./consts";

const prevState = {
    api: null,
    modal: null,
    serverDisconnect: true,
    loader: false,
    serverBrc: {},
    serverTime: new Date(moment().format()),
    modalContent: null,
    currentLanguage: localStorage.getItem("language") || "ru",
}

export const serverTime = (state = prevState.serverTime, action) => {
    switch (action.type) {
        case SET_TIME:
            return action.obj;
        default:
            return state;
    }
}

export const serverBrc = (state = prevState.serverBrc, action) => {
    switch (action.type) {
        case SERVER_BRC:
            return action.obj;
        default:
            return state;
    }
}

export const loader = (state = prevState.loader, action) => {
    switch (action.type) {
        case LOADER:
            return action.obj;
        default:
            return state;
    }
}

export const serverDisconnect = (state = prevState.serverDisconnect, action) => {
    switch (action.type) {
        case SERVER_DISCONNECT:
            return action.obj;
        default:
            return state;
    }
}

export const currentLanguage = (state = prevState.currentLanguage, action) => {
    switch (action.type) {
        case CHANGE_LANGUAGE:
            localStorage.setItem("language",action.obj)
            return action.obj;
        default:
            return state;
    }
}

export const api = (state = prevState.api, action) => {
    switch (action.type) {
        case SET_API:
            return action.obj;
        default:
            return state;
    }
}

export const modal = (state = prevState.modal, action) => {
    switch (action.type) {
        case SHOW_MODAL:
            return action.obj;
        default:
            return state;
    }
}

export const modalContent = (state = prevState.modalContent, action) => {
    switch (action.type) {
        case CREATE_MODAL_CONTENT:
            return action.obj;
        case DELETE_MODAL_CONTENT:
            return null;
        default:
            return state;
    }
}
