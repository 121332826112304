import React from "react";
import style from "./TimeShow.module.scss";
import TimePicker from "../../../../../Components/DateTimePicker/TimePicker/TimePicker";
import {worlds} from "../../../../../Utils/localization";
import ButtonRectangle from "../../../../../Components/Buttons/ButtonRectangle/ButtonRectangle";

export default class TimeShow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isReset: false,
        }
    }

    reset = (bool) => {
        this.setState({isReset: bool});
    }

    render() {
        return (
          <div className={style.timeShow} onClick={(e) => this.props.click(e)}>
            <div className={style.timePick}>
              <div className={style.caption}>{worlds.time}</div>
              <TimePicker reset={this.reset} isReset={this.state.isReset} time={(time) => this.props.time(time)} />
              <ButtonRectangle action={this.props.choose} caption={worlds.choose} width="100%" dark />
            </div>
          </div>
        )
    }
}
