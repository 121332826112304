import React from "react";
import style from "./HeaderSetup.module.scss";

export default class HeaderSetup extends React.Component {
    render() {
        return (
          <div className={style.header}>
            <div className={style.caption}>{this.props.caption}</div>
            {/* <ButtonRectangle action={this.props.default} caption={worlds.default} width={"8.96vw"} disabled={true} dark={true} /> */}
          </div>
        )
    }
}
