import {store} from "../../../App";
import {action} from "../../ReduxHelper";
import {DHCP_CLIENT_LIST} from "./consts";

let api = null;

export const initiateDhcp = async () => {
    api = store.getState().api;
    await getDhcpClientList()
}

export const getDhcpClientList = async () => {
    try {
        const newDhcpClients = (await api.emit("strazh.dhcp.clients.list", {})).clients;
        const dhcpClients = (await api.emit('strazh.dhcp.mapping.get', {})).mapping;
        dhcpClients.map((client) => {
            if (!newDhcpClients.find((i) => i.mac === client.mac)) {
                newDhcpClients.push(client);
            }
        })
        store.dispatch(action(DHCP_CLIENT_LIST, newDhcpClients ?? []))
    } catch (e) {}
}

export const clearDhcpClientList = async () => {
    try {
        await api.emit("strazh.dhcp.clients.clear", {});
        store.dispatch(action(DHCP_CLIENT_LIST, []))
    } catch (e) {}
}
