import React from "react";
import {connect} from "react-redux";
import style from "./SettingsMenu.module.scss";
import {worlds} from "../../../Utils/localization";
import Controls from "./Controls/Controls";
import diskManagerIcon from "../../../Assets/icons/diskManager.svg";
import taxonomyIcon from "../../../Assets/icons/taxonomy.svg";
import logsIcon from "../../../Assets/icons/logs.svg";
import settingsIcon from "../../../Assets/icons/settings.svg";
import licenseIcon from "../../../Assets/icons/license.svg";
import systemCheckIcon from "../../../Assets/icons/successGrey.svg";
import Versions from "./Versions/Versions";
import {getVersionFaceApi} from "../../../api/globalActions";
import iconAdvanced from "../../../Assets/icons/advanced.svg"
import iconSwitch from "../../../Assets/icons/switch.svg"

const settingsChild = [{name: "NTP", href: "/settings/ntp"},
    {name: "SMTP", href: "/settings/smtp"},
    {name: "Dyn DNS"},
    {name: "DHCP", href: "/settings/dhcp"},
    {name: worlds.faceDetection, href: "/settings/faceapi"},
    {name: worlds.motionDetector, href: "/settings/motionDetector"},
    {name: worlds.network, href: "/settings/network"}]

class SettingsMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            apiVersion: "",
            faceApiVersion: "",
            overflowSystemCheck: false,
            overflowSettings: false,
        }
    }

    async componentDidMount() {
        this.setState({apiVersion: this.props.systemConfig.api_version});
        this.props.systemConfig.services.faceapi && this.setState({faceApiVersion: await getVersionFaceApi()});
    }

    close = (caption) => {
        if (caption === worlds.systemCheck) {
            this.setState({overflowSystemCheck: !this.state.overflowSystemCheck});
        } else {
            this.setState({overflowSettings: !this.state.overflowSettings});
        }
    }

    render() {
        return (
          <div className={style.settingsMenu}>
            <div className={style.settingsControls}>
              <Controls caption={worlds.diskManager} icon={diskManagerIcon} href="/settings" />
              <Controls caption={worlds.taxonomy} icon={taxonomyIcon} href="/settings/taxonomy" />
              {this.props.systemConfig.services.logger && <Controls caption={worlds.logs} icon={logsIcon} href="/settings/logs" />}
              {/* <Controls caption={worlds.devices} icon={screenPatternsIcon} href="/settings/devices"/> */}
              {/* <Controls caption={worlds.renewal} icon={renewalIcon} href="/settings/renewal"/> */}
              {this.props.systemConfig.services.switchboard && <Controls caption={worlds.switchingModule} icon={iconSwitch} href="/settings/switching" />}
              <Controls caption={worlds.licensing} icon={licenseIcon} href="/settings/licensing" />
              <Controls caption={worlds.systemCheck} close={this.close} icon={systemCheckIcon} vertical={!this.state.overflowSystemCheck && true} />
              <Controls caption={worlds.securityDrill} overflow={this.state.overflowSystemCheck} horizontal href="/settings/drill" />
              <Controls caption={worlds.settings} close={this.close} icon={settingsIcon} vertical={!this.state.overflowSettings && true} count={this.props.systemConfig.services.faceapi ? 8 : 7} />
              <Controls caption="NTP" overflow={this.state.overflowSettings} horizontal href="/settings/ntp" />
              {this.props.systemConfig.services.smtp && <Controls caption="SMTP" overflow={this.state.overflowSettings} horizontal href="/settings/smtp" />}
              <Controls caption="DYNDNS" overflow={this.state.overflowSettings} horizontal />
              <Controls caption="DHCP" overflow={this.state.overflowSettings} horizontal href="/settings/dhcp" />
              {this.props.systemConfig.services.faceapi && <Controls caption={worlds.faceDetection} overflow={this.state.overflowSettings} horizontal href="/settings/faceapi" />}
              <Controls caption={worlds.motionDetector} overflow={this.state.overflowSettings} horizontal href="/settings/motionDetector" />
              <Controls caption={worlds.network} overflow={this.state.overflowSettings} horizontal href="/settings/network" />
              <Controls caption={worlds.cameras} overflow={this.state.overflowSettings} horizontal href="/settings/cameras" />
              <Controls caption={worlds.additionalSettings} icon={iconAdvanced} href="/settings/additionalSettings" />
            </div>
            <div className={style.versions}>
              <Versions caption={worlds.app} version={process.env.REACT_APP_VERSION} />
              <Versions caption="BRC" version={this.props.serverBrc.version} />
              <Versions caption="API" version={this.state.apiVersion} />
              {this.props.systemConfig.services.faceapi && <Versions caption="FaceApi" version={this.state.faceApiVersion} />}
            </div>
          </div>
        )
    }
}

export default connect((state) => state,null)(SettingsMenu);
