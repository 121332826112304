import React from "react";
import style from "./CamItem.module.scss";
import {worlds} from "../../../Utils/localization";
import CheckBox from "../../../Components/CheckBox/CheckBox";

class CamItem extends React.Component {
    render() {
        return (
          <div className={style.itemList}>
            <div className={style.shortest}>{(this._reactInternalFiber.key)}</div>
            <div className={style.long}><span className={style.name}>{this.props.cam.name}</span></div>
            <div className={style.long}>{this.props.cam.data}</div>
            <div className={style.long}>{this.props.cam.url}</div>
            <div className={style.long}>{this.props.cam.url}</div>
            <div className={style.short}><CheckBox checked={this.props.cam.isPtz} /></div>
            <div className={style.short}><CheckBox checked={this.props.cam.isArchive} /></div>
            <div className={style.status}>{this.props.cam ? <span className={style.success}>{worlds.success}</span> : <span className={style.error}>{worlds.error}</span>}</div>
          </div>
        )
    }
}

export default CamItem;
