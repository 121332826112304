import React from "react";
import {withStyles} from "@material-ui/core";
import {connect} from "react-redux";
import style from "./AddCam.module.scss"
import {worlds} from "../../../Utils/localization";
import TextFieldM from "../../../Components/Inputs/TextFieldM";
import SwitchM from "../../../Components/SwitchM/SwitchM";
import {createEdit} from "../../../api/state/cam/actions";
import TextFieldAutoComplete from "../../../Components/Inputs/TextFieldAutoComplete";
import Cam from "../../../api/models/Cam";

const TextAreaM = withStyles({
    root: {
        margin: "1.5rem 2rem 1.4rem 1rem",
        height: "9.1vw",
        width: "100%",
        alignItems: "flex-start",
        '& label': {
            color: "#8C939B",
            marginTop: "-0.29vw", // -0.35rem
            fontFamily: "Inter",
            fontSize: "0.73vw",
        },
        '& label.Mui-focused': {
            color: "#EAEAEA",
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#EAEAEA',
        },
        '& .MuiOutlinedInput-root': {
            height: "100%",
            width: "100%",
            alignItems: "flex-start",
            backgroundColor: "#3C4347",
            borderRadius: 0,
            color: "#EAEAEA",
            padding: "0.88vw 0.83vw",
            '& fieldset': {
                borderColor: "transparent",
                borderSize: "0.05vw",
            },
            '&:hover fieldset': {
                borderColor: '#EAEAEA',
            },
            '&.Mui-focused fieldset': {
                borderSize: "0.05vw",
                borderColor: '#EAEAEA',
            },
        },
    },
})(TextFieldAutoComplete);

class AddCamSome extends React.Component {
    async componentDidMount() {
        const cam = new Cam();
        cam.url = "rtsp://test";
        await createEdit(cam);
    }

    handleChange = (name,val) => {
        const cam = this.props.editCam;
        if (name === "urls") {
            const urls = val.split('\n');
            cam[name] = urls;
        } else cam[name] = val;
        createEdit(cam)
    }

    switch = () => {
        const cam = this.props.editCam;
        cam.isArchive = !cam.isArchive;
        createEdit(cam)
    }

    render() {
        const cam = this.props.editCam;
        const {errors} = this.props;
        if (!cam) {
            return null;
        }
        return (
          <div className={style.addCamForm}>
            <label>
              {worlds.camcorderName}
              <span>*</span>
              <small />
            </label>
            <TextFieldM
              name="name"
              errors={errors}
              value={cam.name}
              onChange={(e) => { cam.name = e.target.value; createEdit(cam) }}
              placeholder={worlds.camcorderName}
              variant="outlined"
              style={{width: "100%", height: "2.92vw"}}
            />
            <label>
              {worlds.archiveStoragePeriod}
              <small>{worlds.days}</small>
            </label>
            <TextFieldM
              value={cam.archiveDays}
              onChange={(e) => this.handleChange(e.target.value,"archiveDays")}
              placeholder={worlds.archiveStoragePeriod}
              variant="outlined"
              name="archiveDays"
              errors={errors}
              style={{width: "100%", height: "2.92vw"}}
            />
            <div className={style.record}>
              <div className={style.recordCaption}>{worlds.enableRecording}</div>
              <SwitchM checked={cam.isArchive} onChange={() => this.switch()} />
            </div>
            <label>
              {worlds.streamURLorStreamList}
              <span>*</span>
              <small />
            </label>
            <TextAreaM value={cam.urls?.join('\n')} onChange={(e) => this.handleChange("urls",e.target.value)} variant="outlined" multiline rowsMin={3} rows={7} rowsMax={7} placeholder="rtsp://" />
          </div>
        )
    }
}

export default connect((state) => state,null)(AddCamSome)
