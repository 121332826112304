import React from "react";
import {connect} from "react-redux";
import {InputAdornment} from "@material-ui/core";
import style from "./ModalFileManager.module.scss"
import iconFolder from "../../../Assets/icons/folderCloseLight.svg"
import iconFile from "../../../Assets/icons/image.svg"
import {appendPath} from "../../../api/state/fileManager/actions";
import TextFieldM from "../../Inputs/TextFieldM";
import {worlds} from "../../../Utils/localization";
import iconArrowLeft from "../../../Assets/icons/arrow-dark.svg";
import {closeModal} from "../actions";
import ButtonRectangle from "../../Buttons/ButtonRectangle/ButtonRectangle";

class ModalFileManager extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            select: null,
        }
    }

    goTo = (item) => {
        appendPath(`${this.props.fileManagerPath + item.filename}/`)
        if (this.props.download) {
            this.setState({select: item});
        }
    }

    callBack = (item) => {
        if (!item) {
            return;
        }
        this.props.callBack(item)
        closeModal();
    }

    goBack = () => {
        let path = this.props.fileManagerPath;
        path = path.split("/").filter((i) => i);
        path.pop();
        path = path.join("/")
        if (path.length > 0) path = `/${path}/`;
        else path = "/"
        appendPath(path)
    }

    render() {
        return (
          <div className={style.modalFileManager}>
            <TextFieldM
              variant="outlined"
              style={{width: "53.17vw",margin: "0",height: "2.5vw"}}
              value={this.props.fileManagerPath}
              disableKeyboard
              InputProps={{
                               startAdornment: (
                                 <InputAdornment position="start">
                                   <img className={style.backButton} src={iconArrowLeft} onClick={this.goBack} />
                                 </InputAdornment>
                               ),
                           }}
            />
            <div className={style.content}>
              {this.props.fileManagerStore.reverse().map((item,i) => {
                   if (item.is_dir) {
                         return (
                           <div key={i} onClick={() => this.goTo(item)} className={style.file}>
                             <img src={iconFolder} className={style.icon} />
                             {item.filename}
                           </div>
                        )
                    }
                   if (!this.props.download) {
                           return (
                             <div key={i} onClick={() => this.callBack(item)} className={style.file}>
                               <img src={iconFile} className={style.icon} />
                               {item.filename}
                             </div>
)
                       }
               })}
              <div className={style.saveButton}>
                {this.props.download && <ButtonRectangle caption={worlds.save} action={() => this.callBack(this.state.select)} />}
              </div>
            </div>
          </div>
        )
    }
}

export default connect((state) => state,null)(ModalFileManager);
