import React, {useRef, useState} from "react";
import {useDrag, useDrop} from "react-dnd";
import {connect} from "react-redux";
import style from "./Drive.module.scss";
import iconDrag from "../../../../Assets/icons/drag.svg";
import hardDrive from "../../../../Assets/icons/hardDrive1.svg";
import usb from "../../../../Assets/icons/usbStick.svg";
import {worlds} from "../../../../Utils/localization";
import MenuDrive from "../MenuDrive/MenuDrive";
import SliderM from "../../../../Components/Inputs/SliderM";
import {mountDisk, unmountDisk} from "../../../../api/state/fileSystem/actions";

export const ItemTypes = {
    DISK: 'disk',
}

const Drive = (props) => {
    const [diskRatioShow,setDiskRatioShow] = useState(false);
    const ref = useRef(null)
    const [, drop] = useDrop({
        accept: ItemTypes.DISK,
        hover(item, monitor) {
            if (!ref.current) {
                return
            }
            const dragIndex = item.index
            const hoverIndex = props.index
            if (dragIndex === hoverIndex) {
                return
            }
            const hoverBoundingRect = ref.current?.getBoundingClientRect()
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            const clientOffset = monitor.getClientOffset()
            const hoverClientY = clientOffset.y - hoverBoundingRect.top
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }
            props.moveDisk(dragIndex, hoverIndex)
            item.index = hoverIndex
        },
    })
    const [{ isDragging }, drag] = useDrag({
        item: { type: ItemTypes.DISK, id: props.id, index: props.index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    })
    const opacity = isDragging ? 0 : 1
    drag(drop(ref))

    const mount = () => {
        mountDisk(props.unmount);
    }

    const unmount = () => {
        unmountDisk(props.mount);
    }

    return (
      <div className={style.drive}>
        <div ref={ref} className={style.content}>
          <img className={style.drag} src={iconDrag} alt="" />
          <img className={style.icon} src={props.item.type === "hdd" ? hardDrive : usb} alt="" />
          <div className={style.caption}>
            {props.item.type === "hdd" ? "HDD " : "USB "}
            {props.id ?? ""}
          </div>
          {props.index === 0 && <div className={style.priority}>{worlds.priority}</div>}
          <div className={style.mount}>{props.mount ? `${worlds.diskMounted} ${props.mount}` ?? "" : worlds.diskUnmounted}</div>
          <div className={style.detail} style={{marginLeft: "auto", marginRight: "1.82vw"}}>
            {worlds.capacity}
            :
            {' '}
            {(props.item.size / 1024 / 1024 / 1024).toFixed(2)}
            gb
          </div>
          <div className={style.detail} style={{marginLeft: "1.82vw", marginRight: "3.3vw"}}>
            {worlds.free}
            :
            {' '}
            {(props.item.size / 1024 / 1024 / 1024).toFixed(2)}
            gb
          </div>
          <MenuDrive mount={mount} unmount={unmount} elem={props.item} isMount={props.mount} />
        </div>
        <div onClick={() => setDiskRatioShow(!diskRatioShow)} className={style.diskRatioView}>
          <label>{worlds.diskSpace}</label>
          <div className={`${style.icon} ${!diskRatioShow ? style.iconDown : style.iconUp}`} />
        </div>
        <div className={`${style.diskRatio} ${!diskRatioShow && style.diskRatioHide}`}>
          <div className={style.diskRatioInfo}>
            <label>{worlds.faceDetection}</label>
            <label>20%</label>
          </div>
          <SliderM />
          <div className={style.diskRatioInfo}>
            <label>BRC</label>
            <label>20%</label>
          </div>
          <SliderM />
        </div>
      </div>
    )
}

export default connect((state) => state,null)(Drive);
