import React from "react";
import style from "../Monitor.module.scss";
import warningIcon from "../../../../Assets/icons/warning.svg";
import placeholder from "../../../../Assets/placeholder.png";
import {showModalFaceApiFullImage} from "../../../Modals/actions";

export default class MonitorItem extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
          <div onClick={() => showModalFaceApiFullImage({camera: {name: this.props.detection.camName},full_image: this.props.detection.image ? this.props.detection.image : placeholder})} className={style.monitorItem}>
            <div className={style.head}>
              <div className={style.warning}>
                <img src={warningIcon} className={style.icon} />
                <div className={style.caption}>{this.props.detection.name}</div>
              </div>
              <div className={style.cancel} onClick={() => this.props.addHideDetections(this.props.detection.id)} />
            </div>
            <div className={style.camImage}>
              <img src={this.props.detection.image ? this.props.detection.image : placeholder} width="100%" height="100%" />
            </div>
            <div className={style.camName}>{this.props.detection.camName}</div>
          </div>
        )
    }
}
