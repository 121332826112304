import React from "react";
import {connect} from "react-redux";
import style from "./RuleListItem.module.scss";
import {worlds} from "../../../../Utils/localization";
import RuleMenu from "./RuleMenu/RuleMenu";

class RuleListItem extends React.Component {
    render() {
        const {trigger} = this.props;
        const time = `${(`0${trigger.filter.$and[0]['moment.hour']}`).slice(-2)}:${(`0${trigger.filter.$and[1]['moment.minute']}`).slice(-2)} `
        const days = trigger.filter?.$or?.map((item) => item['moment.dow']) ?? [];
        return (
          <div className={style.ruleListItem}>
            <div className={style.rule} style={{width: "100%"}}>{this.props.trigger.name}</div>
            <div className={`${style.rule} ${style.dark}`} style={{minWidth: "16.45vw",width: "16.45vw"}}>
              {time}
            </div>
            <div className={`${style.rule} ${style.dark}`} style={{minWidth: "16.45vw",width: "16.45vw"}}>
              {days.map((item) => worlds.dateDays[item - 1]).join(', ')}
            </div>
            {this.props.currentAccount.username !== "user" && (
              <div className={style.options}>
                <RuleMenu trigger={this.props.trigger} />
              </div>
)}
          </div>
        )
    }
}

export default connect((store) => store,null)(RuleListItem)
