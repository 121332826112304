import React from "react";
import style from "./TimeLineArchive.module.scss"

export default class TimeLineArchive extends React.Component {
    componentDidMount() {
    }

    getScale = (time) => {
        const timeLine = (this.props.parent.offsetWidth / (24 * 60)).toFixed(3);
        const currentTime = {
            hour: new Date(Date.parse(time)).getHours(),
            minute: new Date(Date.parse(time)).getMinutes(),
        }
        const hour = +currentTime.hour > 0 ? currentTime.hour * 60 : 0;
        return (timeLine * (hour + +currentTime.minute)).toFixed(0);
    }

    render() {
        const timeString = new Date(this.props.time).toDateString();
        const startTimeString = new Date(this.props.archive.start).toDateString()
        const endTimeString = new Date(this.props.archive.end).toDateString()
        const isCurrentDateStart = timeString === startTimeString
        const isCurrentDateEnd = timeString === endTimeString
        const isAllDay = +new Date(timeString) > +new Date(startTimeString) && +new Date(timeString) < +new Date(endTimeString)
        return (
          <div
            onClick={this.props.onClick}
            style={isAllDay
                ? {
                    left: 0,
                    width: this.props.parent.offsetWidth,
                }
                : {
                    left: `${isCurrentDateStart ? this.getScale(this.props.archive.start) : 0}px`,
                    width: `${(isCurrentDateEnd ? this.getScale(this.props.archive.end) : this.props.parent.offsetWidth) - (isCurrentDateStart ? this.getScale(this.props.archive.start) : 0)}px`,
                }}
            className={style.timeLineArchive}
          />
        )
    }
}
