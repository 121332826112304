import React, {useEffect, useLayoutEffect, useState} from "react";
import {MemoryRouter, withRouter} from 'react-router-dom'
import CacheRoute, {CacheSwitch} from 'react-router-cache-route'
import {withSnackbar} from 'notistack';
import {HTML5Backend} from 'react-dnd-html5-backend'
import {DndProvider} from 'react-dnd'
import style from "./Main.module.scss"
import ScreensContainer from "../Screens/Screens";
import Header from "../Header/HeaderContainer";
import LeftMenu from "../../Components/LeftMenu/LeftMenu";
import AddCamContainer from "../Cam/AddCam/AddCamContainer";
import Preloader from "../../Components/Preloader/Preloader";
import EditCamContainer from "../Cam/EditCam/EditCamContainer";
import DownloadManager from "../../Components/DownloadManager/DownloadManager";
import SettingsMenu from "../Settings/SettingsMenu/SettingsMenu";
import SettingsContainer from "../Settings/SettingsContainer";
import PlansContainer from "../Plans/PlansContainer";
import FaceDetection from "../FaceDetection/FaceDetection";
import Events from "../Events/Events";
import Modal from "../../Components/Modals/Modal";
import {closeModal, showModalLogon} from "../../Components/Modals/actions";
import {
    authorizationWithToken, authorizationWithTokenByUrl,
    exit,
    exitSessionRoot,
    initiateApi,
} from "../../api/state/account/actions";
import Security from "../Security/Security";
import EditCreateEvent from "../Events/Reactions/EditCreateReaction/EditCreateReaction";
import EditCreateSwitchingEvent from "../Events/Switching/EditCreateReaction/EditCreateReaction";
import FaceDetectionDahua from "../FaceDetectionDahua/FaceDetectionDahua";
import EditCreateRule from "../Security/RuleTable/EditCreateRule/EditCreateRule";
import VirtualKeyboard from "../../Components/VirtualKeyboard/VirtualKeyboard";
import {initiateApiEvents} from "../../api/globalActions";
import {deleteEditTrigger} from "../../api/state/trigger/actions";
import Monitoring from "../Monitoring/Monitoring";

export const Main = (props) => {
    const [preloader,setPreloader] = useState(false)

    useLayoutEffect(() => {
        props.addSnackBar(openSnackBar)
        exit()
        initiateApiEvents();
        initiateApi()
        const token = new URL(window.location).searchParams.get('token');
        if (token) {
            authorizationWithTokenByUrl(token)
            new URL(window.location).searchParams.delete('token');
        }
    },[])

    useEffect(() => {
        if (props.currentAccount?.id) {
            closeModal()
        }
        if (!props.currentAccount || !props.currentAccount?.id) {
            (async () => {
                if (document.fullscreenElement && document.exitFullscreen) {
                    await document.exitFullscreen();
                }
                await showModalLogon()
            })()
        }
    },[props.currentAccount])

    const deleteCurrentEditWidthUpdate = async () => {
        await deleteEditTrigger()
    }
    const openSnackBar = (key,message) => {
        try {
            message.close = props.closeSnackbar;
            props.enqueueSnackbar(message, {
                autoHideDuration: message.persist ? 3000000 : 3000,
                key: key + (Math.trunc(Math.random() * 1000)),
            });
        } catch (e) {}
    };

    return (
      <MemoryRouter>
        <VirtualKeyboard />
        <Preloader show={props.loader.show} text={props.loader.text} />
        <Modal />
        <DndProvider backend={HTML5Backend}>
          <div className={style.main} onMouseMove={() => exitSessionRoot()}>
            {props.currentAccount && (
            <>
              <DownloadManager />
              <Header />
              <div className={style.content}>
                <CacheSwitch>
                  <CacheRoute className={style.cacheRouteFlex} exact path="/">
                    <LeftMenu editCamTaxonomy={props.editCamTaxonomy} deleteCam={props.deleteCam} cams={props.cams} taxonomy={props.taxonomy && props.taxonomy.taxonomies} />
                    <ScreensContainer />
                  </CacheRoute>
                  <CacheRoute className={style.cacheRouteFlex} path="/plans">
                    <LeftMenu editCamTaxonomy={props.editCamTaxonomy} plan deleteCam={props.deleteCam} cams={props.cams} taxonomy={props.taxonomy && props.taxonomy.taxonomies} />
                    <PlansContainer />
                  </CacheRoute>
                  <CacheRoute className={style.cacheRoute} path="/addCam/:rtsp/:rtsp2/:ip/:mac" cacheKey="addCam">
                    <AddCamContainer />
                  </CacheRoute>
                  <CacheRoute className={style.cacheRoute} path="/monitoring">
                    <Monitoring />
                  </CacheRoute>
                  <CacheRoute className={style.cacheRoute} path="/addCam" cacheKey="addCam">
                    <AddCamContainer />
                  </CacheRoute>
                  <CacheRoute className={style.cacheRouteFlex} path="/settings">
                    <SettingsMenu />
                    <SettingsContainer />
                  </CacheRoute>
                  <CacheRoute className={style.cacheRoute} path="/editCam/:id" cacheKey="addCam">
                    <EditCamContainer />
                  </CacheRoute>
                  <CacheRoute className={style.cacheRoute} path="/createReaction">
                    <EditCreateEvent />
                  </CacheRoute>
                  <CacheRoute className={style.cacheRoute} path="/editReaction/:id">
                    <EditCreateEvent />
                  </CacheRoute>
                  <CacheRoute className={style.cacheRoute} path="/createSwitchingReaction">
                    <EditCreateSwitchingEvent />
                  </CacheRoute>
                  <CacheRoute className={style.cacheRoute} path="/editSwitchingReaction/:id">
                    <EditCreateSwitchingEvent />
                  </CacheRoute>
                  <CacheRoute className={style.cacheRoute} path="/faceDetection">
                    <FaceDetection />
                  </CacheRoute>
                  <CacheRoute className={style.cacheRoute} path="/events">
                    <Events />
                  </CacheRoute>
                  <CacheRoute className={style.cacheRoute} path="/security">
                    <Security />
                  </CacheRoute>
                  <CacheRoute className={style.cacheRoute} path="/createRule">
                    <EditCreateRule />
                  </CacheRoute>
                  <CacheRoute className={style.cacheRoute} path="/editRule/:id">
                    <EditCreateRule />
                  </CacheRoute>
                  <CacheRoute className={style.cacheRoute} path="/faceDetectionDahua">
                    <FaceDetectionDahua />
                  </CacheRoute>
                </CacheSwitch>
              </div>
            </>
)}
          </div>
        </DndProvider>
      </MemoryRouter>
    )
}

export default withRouter(withSnackbar(Main))
