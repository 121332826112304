import {store} from "../../../App";
import {action} from "../../ReduxHelper";
import {
    CURRENT_PLAN_CREATE,
    CURRENT_PLAN_DELETE,
    EDIT_PLAN_CREATE,
    EDIT_PLAN_DELETE,
    PLAN_CREATE,
    PLAN_DELETE,
    PLAN_LIST,
    PLAN_UPDATE,
} from "./consts"

import PlanDao from "./planDao";

let api = null;
let dao = null;

export const initiateApiPlans = async () => {
    api = store.getState().api;
    dao = new PlanDao(api);
    await initiatePlans()
}

export const initiatePlans = async () => {
    try {
        store.dispatch(action(PLAN_LIST, await dao.list()))
        const {plans} = store.getState();
        await createCurrent(plans[0])
    } catch (e) { }
}

export const createCurrent = async (current) => {
    store.dispatch(action(CURRENT_PLAN_CREATE,current || null))
}

export const deleteCurrent = async () => {
    store.dispatch(action(CURRENT_PLAN_DELETE,null))
}

export const createEdit = async (edit) => {
    store.dispatch(action(EDIT_PLAN_CREATE,edit || null))
}

export const deleteEdit = async () => {
    store.dispatch(action(EDIT_PLAN_DELETE,null))
}

export const savePlan = async (plan) => {
    try {
        let newPlan = null;
        if (plan.id) {
            newPlan = await dao.update(plan)
            store.dispatch(action(PLAN_UPDATE, newPlan))
        } else {
            newPlan = await dao.create(plan)
            store.dispatch(action(PLAN_CREATE, newPlan))
        }
        store.dispatch(action(CURRENT_PLAN_CREATE, newPlan))
    } catch (e) {}
}

export const deletePlan = async (plan) => {
    try {
        store.dispatch(action(PLAN_DELETE, await dao.delete(plan)))
        if (store.getState().plans.length > 0) store.dispatch(action(CURRENT_PLAN_CREATE, store.getState().plans[0]))
        else store.dispatch(action(CURRENT_PLAN_DELETE, {}))
    } catch (e) {}
}
