import React from "react";
import PropTypes from 'prop-types';
import style from "./ButtonRectangle.module.scss"

export default class ButtonRectangle extends React.Component {
    render() {
        return (
          <div onMouseLeave={this.props.onMouseLeave} onMouseMove={this.props.onMouseMove} style={{width: this.props.width || "8.54vw", height: this.props.height || "2.19vw",...this.props.style}} onClick={() => !this.props.disabled && this.props.action()} className={(!this.props.disabled) ? `${style.buttonRectangle} ${this.props.dark && style.dark}` : style.disabled}>
            {this.props.icon && <div className={style.icon} />}
            <div className={style.caption}>
              {this.props.caption}
              {this.props.children}
            </div>
          </div>
        )
    }
}

ButtonRectangle.propTypes = {
    action: PropTypes.func,
    caption: PropTypes.string,
    dark: PropTypes.bool,
    width: PropTypes.string,
    height: PropTypes.string,
    disabled: PropTypes.bool,
};
