import React from "react";
import {withRouter} from "react-router";
import {validate} from "class-validator";
import style from "../AddCam/AddCam.module.scss"
import ButtonSquare from "../../../Components/Buttons/ButtonSquare/ButtonSquare";
import arrowIcon from "../../../Assets/icons/arrow-dark.svg"
import {worlds} from "../../../Utils/localization";
import ButtonRectangle from "../../../Components/Buttons/ButtonRectangle/ButtonRectangle";
import TaxonomyShort from "../../../Components/TaxonomyShort/TaxonomyShort";
import AddCamOne from "../AddCam/AddCamOne";
import {
createEdit, deleteEdit, getCam, updateCam,
} from "../../../api/state/cam/actions";
import {patterns} from "../../../api/consts";
import {parseRtsp} from "../../../api/globalActions";

class EditCam extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: null,
        }
    }

    async componentDidMount() {
        await createEdit(await getCam(this.props.match.params.id))
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
            if (!this.props.editCam && this.props.match.params?.id) {
                const cam = await getCam(this.props.match.params.id)
                const camAuth = parseRtsp(cam?.model,cam?.url);
                if (camAuth) {
                    cam.ip = camAuth.hostname
                    cam.login = camAuth.username
                    cam.password = camAuth.password
                }
                await createEdit(cam)
            }
        if (this.props.editCam && this.props.match.params?.id && this.props.editCam.id !== this.props.match.params?.id) {
            const cam = await getCam(this.props.match.params.id)
            const camAuth = parseRtsp(cam?.model,cam?.url);
            if (camAuth) {
                cam.ip = camAuth.hostname
                cam.login = camAuth.username
                cam.password = camAuth.password
            }
            await createEdit(cam)
        }
    }

    componentWillUnmount() {
        deleteEdit()
    }

    checkInErrors = () => {
        this.setState({errors: null});
        const cam = this.props.editCam;
        if (cam.model === "other") {
            if (!cam.ip) cam.ip = "127.0.0.1";
        } else {
            cam.url = patterns.find((i) => i.name === cam.model).pattern
                .replace("{account}", cam.login ? `${cam.login}:${cam.password}@` : "")
                .replace("{ip}",cam.ip)
                .replace("{thread}","0");
            cam.secondaryUrl = patterns.find((i) => i.name === cam.model).pattern
                .replace("{account}", cam.login ? `${cam.login}:${cam.password}@` : "")
                .replace("{ip}",cam.ip)
                .replace("{thread}","1");
        }
        validate(cam).then(async (errors) => { // errors is an array of validation errors
            if (errors.length > 0) {
                this.setState({errors});
            } else {
                await updateCam(cam)
                this.props.history.goBack()
            }
        });
    }

    render() {
        return (
          <div className={style.addCam}>
            <div className={style.header}>
              <ButtonSquare width="2.5rem" height="2.5rem" icon={arrowIcon} back />
              <div className={style.caption}>{worlds.editCamcorder}</div>
              <div className={style.rightContent}>
                <ButtonRectangle action={this.checkInErrors} caption={worlds.save} />
              </div>
            </div>
            <div className={style.middle} />
            <div className={style.content}>
              <div className={style.footer}>
                <AddCamOne edit errors={this.state.errors} />
                <div className={style.taxonomy}>
                  <div className={style.taxonomyCaption}>{worlds.taxonomy}</div>
                  <div className={style.taxonomyHelper}>{worlds.chooseWhichGroupAddCamera}</div>
                  <div className={style.taxonomyContent}>
                    {this.props.editCam && <TaxonomyShort camTaxa={this.props.editCam.parents} setTaxonomy={this.setTaxonomy} />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
    }
}

export default withRouter(EditCam)
