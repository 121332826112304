import {connect} from 'react-redux';
import Settings from './Settings';

const mapStateToProps = (state) => state

const mapDispatchToProps = (dispatch) => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
