import React from "react";
import {connect} from "react-redux";
import style from "./TaxonomyShort.module.scss"
import Folder from "./Folder/Folder";
import {initiateApi, initiateTaxonomy} from "../../api/state/taxonomy/actions";

class TaxonomyShort extends React.Component {
    async componentDidMount() {
        initiateApi(this.loading)
        // await initiateTaxonomy()
    }

    loading = (bool) => {
        this.setState({preloader: bool})
    }

    render() {
        return (
          <div className={style.taxonomyAddCam}>
            {this.props.taxonomies.filter((i) => !i.hasOwnProperty("isArchive")).map((item,i) => <Folder key={i} item={item} />)}
          </div>
        )
    }
}

export default connect((state) => state,null)(TaxonomyShort)
