import React from "react";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {InputAdornment} from "@material-ui/core";
import {validate} from "class-validator";
import moment from "moment-timezone";
import style from "./EditCreateRule.module.scss";
import arrowIcon from "../../../../Assets/icons/arrow-dark.svg";
import ButtonSquare from "../../../../Components/Buttons/ButtonSquare/ButtonSquare";
import {worlds} from "../../../../Utils/localization";
import ButtonRectangle from "../../../../Components/Buttons/ButtonRectangle/ButtonRectangle";
import TextFieldM from "../../../../Components/Inputs/TextFieldM";
import AutocompleteM from "../../../../Components/Inputs/AutocompleteM";
import iconWatch from "../../../../Assets/icons/watch.svg";
import WeekDayPicker from "../../../../Components/WeekDayPicker/WeekDayPicker";
import TimeShow from "./TimeShow/TimeShow";
import {
    createEditTrigger,
    createTrigger,
    deleteEditTrigger,
    updateTrigger,
} from "../../../../api/state/trigger/actions";
import Trigger from "../../../../api/models/Trigger";

const events = ["Поставить на охрану","Снять с охраны"]

class EditCreateRule extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            errors: null,
        }
    }

    componentDidMount() {
        this.createTrigger();
    }

    createTrigger = () => {
        if (this.props.match.params.id) {
            createEditTrigger(this.props.triggers.find((i) => i.id === this.props.match.params.id))
        } else {
            const trigger = new Trigger()
            const filter = {
                $and: [{"moment.hour": new Date(moment().format()).getHours()},{"moment.minute": new Date(moment().format()).getMinutes()}],
                $or: [{"moment.dow": 1},{"moment.dow": 2},{"moment.dow": 3},{"moment.dow": 4},{"moment.dow": 5}],
            }
            trigger.events = ["cron.minutely"]
            trigger.reactions = [{guard: false,type: "guard.set"}]
            trigger.filter = filter;
            trigger.data = {triggerType: "guard"}
            createEditTrigger(trigger)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.editTrigger?.data?.triggerType !== "guard" && this.props.history.location.pathname === "/createRule") {
            this.createTrigger()
        }
    }

    componentWillUnmount() {
        deleteEditTrigger()
    }

    changeName = (e) => {
        const trigger = this.props.editTrigger;
        trigger.name = e.target.value;
        createEditTrigger(trigger)
    }

    showDate = () => {
        this.setState({show: !this.state.show});
    }

    mainShow = (e) => {
        if (e.target.className.indexOf("timeShow") >= 0) this.setState({show: false});
    }

    changeDays = (day) => {
        day = parseInt(day, 10);
        const trigger = this.props.editTrigger;
        if (trigger.filter?.$or.find((i) => i["moment.dow"] === day)) {
            trigger.filter.$or = trigger.filter?.$or.filter((i) => i["moment.dow"] !== day);
        } else if (trigger.filter.$or) {
            trigger.filter.$or.push({"moment.dow": day})
        }
        createEditTrigger(trigger)
    }

    time = (time) => {
        this.setState({time});
    }

    chooseTime = () => {
        const trigger = this.props.editTrigger;
        trigger.filter.$and = [{"moment.hour": parseInt(this.state.time.hours, 10)},{"moment.minute": parseInt(this.state.time.minutes, 10)}]
        createEditTrigger(trigger)
        this.setState({show: false});
    }

    changeEvent = (event) => {
        const trigger = this.props.editTrigger;
        if (event === events[0]) {
            trigger.reactions[0].guard = true;
        } else {
            trigger.reactions[0].guard = false;
        }
        createEditTrigger(trigger)
    }

    createOrEdit = () => {
        const trigger = this.props.editTrigger;
        validate(trigger).then(async (errors) => { // errors is an array of validation errors
            if (errors.length > 0) {
                this.setState({errors});
            } else {
                if (trigger.id) {
                    updateTrigger(trigger)
                } else {
                    createTrigger(trigger)
                }
                this.props.history.goBack()
            }
        });
    }

    render() {
        const trigger = this.props.editTrigger;
        if (!trigger || this.props.editTrigger.data?.triggerType !== "guard") {
            return null;
        }
        const time = (trigger.filter?.$and)
            ? `${(`0${trigger.filter.$and?.[0]?.['moment.hour']}`).slice(-2)}:${(`0${trigger.filter.$and?.[1]?.['moment.minute']}`).slice(-2)} `
            : `${(`0${new Date(moment().format()).getHours()}`).slice(-2)}:${(`0${new Date(moment().format()).getMinutes()}`).slice(-2)} `
        const days = trigger.filter?.$or?.map((item) => item['moment.dow']) ?? [];
        return (
          <div className={style.editCreateRule}>
            {this.state.show && <TimeShow click={this.mainShow} time={this.time} choose={this.chooseTime} />}
            <div className={style.header}>
              <ButtonSquare width="1.25vw" height="1.25vw" icon={arrowIcon} back />
              <div className={style.rule}>
                <div className={style.caption}>
                  {trigger.id ? worlds.edit : worlds.addRule}
                </div>
                <ButtonRectangle action={() => this.createOrEdit()} caption={trigger.id ? worlds.save : worlds.add} />
              </div>
            </div>
            <div className={style.content}>
              <label>
                {worlds.ruleName}
                <small>300</small>
              </label>
              <TextFieldM
                placeholder={worlds.name}
                value={trigger.name}
                name="name"
                onChange={this.changeName}
                errors={this.state.errors}
                variant="outlined"
                style={{width: "100%", height: "2.92vw",margin: "0"}}
              />
              <label>{worlds.event}</label>
              <div className={style.pointer}>
                <AutocompleteM
                  value={trigger.reactions?.[0]?.guard ? events[0] : events[1]}
                  onChange={(e, newValue) => {
                                       this.changeEvent(newValue);
                                   }}
                  disableClearable
                  inputValue={trigger.reactions?.[0]?.guard ? events[0] : events[1]}
                  options={events}
                  style={{width: "100%", height: "2.92vw"}}
                />
                <label>{worlds.time}</label>
                <TextFieldM
                  placeholder={worlds.choose}
                  variant="outlined"
                  style={{width: "100%", height: "2.92vw",margin: "0 0 .42vw 0"}}
                  value={time}
                  disableKeyboard
                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end" onClick={() => this.showDate()}>
                                        <img className={style.iconWatch} src={iconWatch} />
                                      </InputAdornment>
                                    ),
                                }}
                />
                <WeekDayPicker caption={worlds.daysOfWeek} days={days} action={this.changeDays} />
              </div>
            </div>
          </div>
        )
    }
}

export default withRouter(connect((state) => state,null)(EditCreateRule));
