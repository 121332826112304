import {
    ADD_DOWNLOAD_FILE,
    CLEAR_DOWNLOAD_FILES,
    DELETE_DOWNLOAD_FILE,
    FILE_MANAGER_CURRENT_PATH,
    FILE_MANAGER_STORAGE,
} from "./consts";

const prevState = {
    fileManagerPath: "/",
    fileManagerStore: [],
    downloadFiles: [],
}

export const downloadFiles = (state = prevState.downloadFiles, action) => {
    switch (action.type) {
        case ADD_DOWNLOAD_FILE:
            if (state.find((i) => i.filename === action.obj.filename)) {
                state.splice(state.findIndex((i) => i.filename === action.obj.filename),1,{...action.obj});
            } else {
                state.push({...action.obj})
            }
            return [...state];
        case DELETE_DOWNLOAD_FILE:
            state = state.splice(state.findIndex((i) => i.filename === action.obj.filename));
            return state;
        case CLEAR_DOWNLOAD_FILES:
            return [];
        default:
            return state;
    }
}

export const fileManagerPath = (state = prevState.fileManagerPath, action) => {
    switch (action.type) {
        case FILE_MANAGER_CURRENT_PATH:
            return action.obj;
        default:
            return state;
    }
}

export const fileManagerStore = (state = prevState.fileManagerStore, action) => {
    switch (action.type) {
        case FILE_MANAGER_STORAGE:
            return action.obj;
        default:
            return state;
    }
}
