import {withStyles} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import React from "react";

export default withStyles({
    root: {
        width: "1.25vw",
        height: "1.25vw",
        padding: "0",
        transition: "all 0.5s ease",
        '&:hover': {
            color: "#FB3C4A",
        },
        color: "#3C4347",
        '&$checked': {
            color: "#FB3C4A",
            background: 'none',
        },
        '& .MuiSvgIcon-root': {
            width: "1.25vw",
            height: "1.25vw",
            padding: "0",
            margin: "0",
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);
