import React from "react";
import style from "./RenewalItem.module.scss";
import CheckBox from "../../../../Components/CheckBox/CheckBox";
import {worlds} from "../../../../Utils/localization";

export default class RenewalItem extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
          <div className={style.renewalItem}>
            <CheckBox onChange={(e) => this.props.check(e, this.props.item)} className={style.checkbox} checked={this.props.item.check} />
            <div className={style.info}>
              <div className={style.caption}>{this.props.item.caption}</div>
              <div className={style.version}>
                {worlds.version}
                {' '}
                {this.props.item.version}
              </div>
            </div>
          </div>
        )
    }
}
