import React from "react";
import moment from "moment-timezone";
import style from "./DateTimePicker.module.scss"
import DatePicker from "./DatePicker/DatePicker";
import TimePicker from "./TimePicker/TimePicker";
import {worlds} from "../../Utils/localization";

export default class DateTimePicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            time: {hours: new Date(moment().format()).getHours(),minutes: new Date(moment().format()).getMinutes()},
            date: {day: new Date(moment().format()).getDate(),month: new Date(moment().format()).getMonth() + 1,year: new Date(moment().format()).getFullYear()},
            days: worlds.dateDays,
            months: worlds.dateMonth,
            reset: false,
            showDate: {show: false,index: -1},
        }
    }

    time = (time) => {
        this.setState({time})
        const {date} = this.state;
        const format = `${date.year}-${(`0${date.month}`).slice(-2)}-${(`0${date.day}`).slice(-2)}T${(`0${time.hours}`).slice(-2)}:${(`0${time.minutes}`).slice(-2)}:00`;
        this.props.dateTime(format)
    }

    date = (date) => {
        this.setState({date})
        const {time} = this.state;
        const format = `${date.year}-${(`0${date.month}`).slice(-2)}-${(`0${date.day}`).slice(-2)}T${(`0${time.hours}`).slice(-2)}:${(`0${time.minutes}`).slice(-2)}:00`
        this.props.dateTime(format)
    }

    render() {
        return (
          <div className={style.dateTimePicker}>
            <DatePicker reset={this.props.reset} isReset={this.props.isReset} date={(date) => this.date(date,this.state.showDate.index)} days={this.state.days} months={this.state.months} />
            <div className={style.border} />
            <TimePicker reset={this.props.reset} isReset={this.props.isReset} time={(time) => this.time(time,this.state.showDate.index)} />
          </div>
        );
    }
}
