import {IsNotEmpty} from "class-validator";
import {worlds} from "../../Utils/localization";

export default class Taxonomy {
    id: string = "";

    @IsNotEmpty({
        message: worlds.emptyField,
    })
    name: string = "";

    parent: any = null;

    path: string = "";

    data: any = {};

    children: Array<Taxonomy> = [];

    serialize(taxonomy:any) {
        this.id = taxonomy?.id ?? "";
        this.name = taxonomy?.name ?? "";
        this.parent = taxonomy?.parent ?? "";
        this.path = taxonomy?.path ?? "";
    }

    deserialize() {
        const taxonomy:any = {};
        taxonomy.id = this.id;
        taxonomy.name = this.name;
        taxonomy.parent = this.parent?.length > 0 ? this.parent : null;
        taxonomy.path = this.path;
        return taxonomy;
    }

    fillChild(taxonomy:Array<Taxonomy>) {
        this.children = taxonomy.filter((i) => i.parent === this.id);
    }
}
