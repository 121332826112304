import React from "react";
import {connect} from "react-redux";
import {InputAdornment} from "@material-ui/core";
import * as _ from 'lodash';
import {validate} from "class-validator";
import style from "./FaceApi.module.scss";
import {worlds} from "../../../Utils/localization";
import ButtonRectangle from "../../../Components/Buttons/ButtonRectangle/ButtonRectangle";
import TextFieldM from "../../../Components/Inputs/TextFieldM";
import SwitchM from "../../../Components/SwitchM/SwitchM";
import iconInfo from "../../../Assets/icons/info.svg";
import {setFaceApiConfig, updateFaceApiConfig} from "../../../api/state/faceApi/actions";

class FaceApi extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            cameras: 12,
            errors: null,
        }
    }

    editConfig =async (val,name) => {
        let config = this.props.faceApiConfig;
        config = _.set(config,name,String(val));
        await updateFaceApiConfig(config)
    }

    checkInErrors = () => {
        this.setState({errors: null});
        validate(this.props.faceApiConfig).then(async (errors) => { // errors is an array of validation errors
            if (errors.length > 0) {
                this.setState({errors});
            } else {
                await setFaceApiConfig(this.props.faceApiConfig)
            }
        });
    }

    render() {
        const config = this.props.faceApiConfig
        return (
          <div className={style.faceApi}>
            <div className={style.header}>
              <div className={style.text}>
                <div className={style.caption}>{worlds.faceDetectionConfiguration}</div>
                <div className={style.license}>
                  {worlds.license}
                  :
                  {' '}
                  {this.state.cameras}
                  {' '}
                  {worlds.cameras.toLowerCase()}
                </div>
              </div>
              <ButtonRectangle caption={worlds.default} disabled width="8.96vw" />
            </div>
            <div className={style.content}>
              {/* <label>{worlds.camera}</label> */}
              {/* <TextFieldM */}
              {/*    errors={this.state.errors} */}
              {/*    name="name" */}
              {/*    placeholder={worlds.cameraName} */}
              {/*    variant={"outlined"} */}
              {/*    style={{width: "33.07vw", height: "2.92vw", margin:"0"}} */}
              {/* /> */}
              <label>{worlds.port}</label>
              <TextFieldM
                name="port"
                errors={this.state.errors}
                onChange={(e) => this.editConfig(e.target.value,"port")}
                value={config.port}
                variant="outlined"
                style={{width: "33.07vw", height: "2.92vw", margin: "0"}}
              />
              <div className={style.switchBlock}>
                <div className={style.caption}>{worlds.minimizeVideoStreamDelay}</div>
                <SwitchM onChange={(e) => this.editConfig(config.ffmpegMinimizeDelay === "true" ? "false" : "true",'ffmpegMinimizeDelay')} checked={config.ffmpegMinimizeDelay === "true"} />
              </div>
              <label>{worlds.numberOfFrames}</label>
              <div className={style.inputHint}>
                <TextFieldM
                  name="ffmpegFps"
                  errors={this.state.errors}
                  value={config.ffmpegFps}
                  onChange={(e) => this.editConfig(e.target.value,"ffmpegFps")}
                  variant="outlined"
                  style={{width: "33.07vw", height: "2.92vw", margin: "0"}}
                />
                <div className={style.hint}>
                  <div className={style.iconInfo}>
                    <img src={iconInfo} width="100%" height="100%" />
                  </div>
                  <div className={style.detail}>{worlds.higherValueLeadsToArtifacts}</div>
                </div>
              </div>
              <label>{worlds.faceRecognitionModelOptions}</label>
              <div className={style.inputHint}>
                <TextFieldM
                  name="minConfidence"
                  errors={this.state.errors}
                  value={config.faceModelOptions.minConfidence}
                  onChange={(e) => this.editConfig(e.target.value,"faceModelOptions[minConfidence]")}
                  variant="outlined"
                  style={{width: "33.07vw", height: "2.92vw", margin: "0"}}
                />
                <div className={style.hint}>
                  <div className={style.iconInfo}>
                    <img src={iconInfo} width="100%" height="100%" />
                  </div>
                  <div className={style.detail}>{worlds.minimumAccuracy}</div>
                </div>
              </div>
              <div className={style.switchBlock}>
                <div className={style.caption} style={{padding: "0.83vw 0"}}>{worlds.drawingBoxArea}</div>
                <SwitchM onChange={(e) => this.editConfig(config.faceDetectionImagesFullDraw === "true" ? "false" : "true",'faceDetectionImagesFullDraw')} checked={config.faceDetectionImagesFullDraw === "true"} />
              </div>
              <div className={style.switchBlock}>
                <div className={style.caption} style={{padding: "0.83vw 0"}}>{worlds.groupFaceRecognition}</div>
                <SwitchM onChange={(e) => this.editConfig(config.faceDetectionImagesGroupResult === "true" ? "false" : "true",'faceDetectionImagesGroupResult')} checked={config.faceDetectionImagesGroupResult === "true"} />
              </div>
              <label>{worlds.screeningToCertainSize}</label>
              <TextFieldM
                name="faceDetectionImagesScreeningToSize"
                errors={this.state.errors}
                value={config.faceDetectionImagesScreeningToSize}
                onChange={(e) => this.editConfig(e.target.value,"faceDetectionImagesScreeningToSize")}
                variant="outlined"
                style={{width: "33.07vw", height: "2.92vw", margin: "0"}}
              />
              <label>{worlds.storageLimitOfTemporaryDescriptors}</label>
              <TextFieldM
                name="faceDetectionImagesLimitScreening"
                errors={this.state.errors}
                value={config.faceDetectionImagesLimitScreening}
                onChange={(e) => this.editConfig(e.target.value,"faceDetectionImagesLimitScreening")}
                variant="outlined"
                style={{width: "33.07vw", height: "2.92vw", margin: "0"}}
                InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <div className={style.endInput}>
                                  {worlds.days}
                                </div>
                              </InputAdornment>
                            ),
                        }}
              />
              <label>{worlds.timeBeforeAutoClearing}</label>
              <TextFieldM
                name="faceDetectionImagesScreeningTime"
                errors={this.state.errors}
                value={config.faceDetectionImagesScreeningTime}
                onChange={(e) => this.editConfig(e.target.value,"faceDetectionImagesScreeningTime")}
                variant="outlined"
                style={{width: "33.07vw", height: "2.92vw", margin: "0"}}
                InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <div className={style.endInput}>
                                  {worlds.sec}
                                </div>
                              </InputAdornment>
                            ),
                        }}
              />
              <label>{worlds.expansionDetectionBox}</label>
              <TextFieldM
                name="faceDetectionImagesBoxExpand"
                errors={this.state.errors}
                value={config.faceDetectionImagesBoxExpand}
                onChange={(e) => this.editConfig(e.target.value,"faceDetectionImagesBoxExpand")}
                variant="outlined"
                style={{width: "33.07vw", height: "2.92vw", margin: "0"}}
              />
              <label>{worlds.timeoutBetweenFrame}</label>
              <TextFieldM
                name="faceDetectionImagesTimeout"
                errors={this.state.errors}
                value={config.faceDetectionImagesTimeout}
                variant="outlined"
                onChange={(e) => this.editConfig(e.target.value,"faceDetectionImagesTimeout")}
                style={{width: "33.07vw", height: "2.92vw", margin: "0"}}
                InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <div className={style.endInput}>
                                  {worlds.ms}
                                </div>
                              </InputAdornment>
                            ),
                        }}
              />
              <div className={style.saveButton}>
                <ButtonRectangle action={() => this.checkInErrors()} width="10.31vw" height="2.92vw" caption={worlds.save} />
              </div>
            </div>
          </div>
        )
    }
}

export default connect((state) => state,null)(FaceApi)
