import React from "react";
import style from "./TimeLineHour.module.scss"

export default class TimeLineHour extends React.Component {
    render() {
        return (
          <div className={style.timeLineHour}>
            <div className={style.caption}>
              <div className={style.separator} />
            </div>
          </div>
        )
    }
}
