import React from "react";
import {HashRouter, Route} from 'react-router-dom'
import {CacheSwitch} from 'react-router-cache-route'
import {SnackbarProvider} from "notistack";
import {createTheme} from "@material-ui/core";
import {ThemeProvider} from "@material-ui/core/styles";
import SnackBar from "./Components/SnackBar/SnackBar";
import MainContainer from "./Containers/Main/MainContainer";

const theme = createTheme({
    zIndex: {
        snackbar: 9999999,
        modal: 9998,
    },
    palette: {
        type: 'dark',
    },
});
export const snackbarRef = React.createRef()
export default class Router extends React.Component {
    componentDidMount() {

    }

    render() {
        return (
          <ThemeProvider theme={theme}>
            <HashRouter>
              <SnackbarProvider
                ref={snackbarRef}
                disableWindowBlurListener
                elevation={9999999999}
                onError={() => {}}
                anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                content={(key, message) => (
                  <SnackBar
                    id={key}
                    key={key}
                    message={message}
                    variant={key.replace(/\d/g,'')}
                  />
)}
              >
                <CacheSwitch>
                  <Route exact path="/">

                    <MainContainer />

                  </Route>
                </CacheSwitch>
              </SnackbarProvider>
            </HashRouter>
          </ThemeProvider>
        )
    }
}
