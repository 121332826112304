import {withStyles} from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';

export default withStyles({
    root: {
        color: '#8C939B',
        height: "0.2vw",
    },
    thumb: {
        maxHeight: "1vw",
        maxWidth: "1vw",
        height: "1vw",
        width: "1vw",
        backgroundColor: '#fff',
        border: '0.26vw solid #FB3C4A',
        marginTop: "-0.41vw",
        marginLeft: "-0.5vw",
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
    active: {},
    track: {
        height: "0.2vw",
    },
    rail: {
        height: "0.2vw",
    },
})(Slider);
