import React from "react";
import {withRouter} from "react-router-dom";
import style from "./ButtonTopMenu.module.scss"

class ButtonTopMenu extends React.Component {
    onClick = () => {
        if (this.props.href) this.props.history.push(this.props.href)
        if (this.props.action) this.props.action()
    }

    render() {
        let active = false;
        if (this.props.location.pathname === this.props.href) {
            active = true;
        }
        return (
          <div style={{...this.props.style}} onClick={() => this.onClick()} className={`${style.button} ${active || this.props.active ? style.active : ""}`}>
            <span>{this.props.caption}</span>
          </div>
        )
    }
}

export default withRouter(ButtonTopMenu)
