import React from 'react';
import Popover from '@material-ui/core/Popover';
import {withStyles} from "@material-ui/core";

const PopoverM = withStyles({
    root: {
        pointerEvents: 'none',
    },
    paper: {
        backgroundColor: "#2A2F32",
        fontFamily: "Inter",
        fontSize: "0.73vw",
        color: "#EAEAEA",
        borderRadius: "0.23vw",
        border: "0.05vw solid #3C4347",
        padding: "0.52vw 0.62vw",
        marginTop: "-0.94vw",
    },
})(Popover);

export default function PopoverCaption(props) {
    return (
      <PopoverM
        open={props.open}
        anchorEl={props.anchor}
        anchorOrigin={{
                vertical: 'top',
                horizontal: props.position,
            }}
        transformOrigin={{
                vertical: 'bottom',
                horizontal: props.position,
            }}
        disableRestoreFocus
      >
        {props.caption}
      </PopoverM>

    );
}
