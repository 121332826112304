import {connect} from 'react-redux';
import AddCam from './AddCam';

const mapStateToProps = (state) => state

const mapDispatchToProps = (dispatch) => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(AddCam);
