import React from 'react'
import {connect} from "react-redux";
import style from './Screens.module.scss'
import Cell from './Cell/Cell'
import FooterMenu from "./FooterMenu/FooterMenu";
import {emitter} from "../../Utils/globalEmitter";
import {changeSize} from "./Cell/actions";
import {worlds} from "../../Utils/localization";
import ButtonSquare from "../../Components/Buttons/ButtonSquare/ButtonSquare";
import fullScreenIcon from "../../Assets/icons/fullScreen.svg"
import {store} from "../../App"
import {action} from "../../api/ReduxHelper";
import {CHANGE_CELLS} from "./actions";
import {electronApi} from "../../api/state/electron/actions";

class Screens extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isFull: false,
      ref: React.createRef(),
      moveScreen: false,
      fullFooterMenu: false,
      contentStyle: `${style.content}`,
      isWebStyle: "",
    }
  }

  async componentDidMount() {
    if (!window.require) {
      this.setState({isWebStyle: style.webScreen})
      store.dispatch(action(CHANGE_CELLS, JSON.parse(localStorage.getItem("cells") ?? [])))
    } else {
      const data = await electronApi?.('get-cells')
      store.dispatch(action(CHANGE_CELLS,JSON.parse(data ?? [])))
    }

    this.emitter = emitter.addListener('resize', (bool) => {
      if (bool) {
        this.setState({contentStyle: `${style.content} ${style.center}`,fullFooterMenu: true})
      } else {
        this.setState({contentStyle: `${style.content}`,fullFooterMenu: false})
      }
    })
    document.addEventListener("fullscreenchange",(el) => {
      if (!document.fullscreenElement) {
        this.setState({isFull: false,moveScreen: false})
      }
    })
  }

   goFull = async () => {
    if (!document.fullscreenElement) {
      this.setState({isFull: true})
      this.state.ref.current.requestFullscreen();
    } else if (document.exitFullscreen) {
        await document.exitFullscreen();
        this.setState({isFull: false})
      }
  }

  onMouseMove = () => {
    if (this.state.isFull) {
      this.setState({moveScreen: true})
      clearTimeout(this.timeoutMove)
      this.timeoutMove = setTimeout(() => {
        this.setState({moveScreen: false})
      }, 1000)
    }
  }

  addItem = (newData,count,url) => {
    const data = newData || [...this.props.cells]
    for (let i = 0; i < (count > 1 ? count : 1); i++) {
      const newItem = {
        id: Math.random() * 100,
        size: 1,
        width: 24,
        height: 24,
        url: url || "",
      }
      data.push(newItem)
      const sizeInScreen = data.reduce((a, b) => a + ((b.height + 1) * (b.width + 1)), 0);
      if (sizeInScreen > 10150) {
        return
      }
      store.dispatch(action(CHANGE_CELLS,data))
    }
  }

  acceptPattern= (pattern) => {
    let data = [...this.props.cells];
    // eslint-disable-next-line default-case
    switch (pattern) {
      case "1x1":
        if (data.length === 0) {
          this.addItem(data)
        }
        store.dispatch(action(CHANGE_CELLS, [data[0]]));
        for (let i = 0; i < 4; i++) {
          changeSize(data[0], true);
        }
        break;
      case "2x2":
        for (let i = 0; i < 4; i++) {
          data.map((item, i) => {
            changeSize(item, false)
          })
        }
        this.addItem(data, 4 - data.length)
        if (data.length > 4) data = data.slice(0, 4);
        store.dispatch(action(CHANGE_CELLS, data))
        for (let i = 0; i < 4; i++) {
          data.map((item, i) => {
            if (item.size < 2) {
              changeSize(item, true);
            }
          })
        }
        break;
      case "2x8":
        for (let i = 0; i < 4; i++) {
          data.map((item, i) => {
            changeSize(item, false)
          })
        }
        this.addItem(data, 10 - data.length)
        if (data.length > 10) data = data.slice(0, 10);
        data.sort((a, b) => {
          if (a.url && !b.url) return -1
          if (!a.url && b.url) return 1
          if (a.url && b.url) return 0
        })
        electronApi?.('getCell')
        store.dispatch(action(CHANGE_CELLS, data))
        for (let i = 0; i < 4; i++) {
          data.map((item, i) => {
            if (item.size < 2 && i < 2) {
              changeSize(item, true);
            }
          })
        }
        break;
      case "4x4":
        for (let i = 0; i < 4; i++) {
          data.map((item, i) => {
            changeSize(item, false);
          })
        }
        data.sort((a, b) => {
          if (a.url && !b.url) return -1
          if (!a.url && b.url) return 1
          if (a.url && b.url) return 0
        })
        this.addItem(data, 16 - data.length)
        if (data.length > 16) data = data.slice(0, 16);
        store.dispatch(action(CHANGE_CELLS, data))
        break;
    }
  }

  clearAll = () => {
    emitter.emit("clearAllCells")
    store.getState().snackbar("success",{header: worlds.screenClearedSuccessfully,description: ""});
  }

  dropItem = (id) => {
    const data = [...this.props.cells]
    const item = data.find((i) => i.id === id)
    data.splice(data.indexOf(item), 1)
    store.dispatch(action(CHANGE_CELLS,data))
  }

  render() {
    return (
      <div className={`${style.screen} ${this.state.isWebStyle}`}>
        <div onMouseMove={this.onMouseMove} ref={this.state.ref} className={`${this.state.contentStyle} ${this.state.isFull && style.fullScreen}`}>
          {this.state.isFull && (
            <div className={`${style.controls} ${this.state.moveScreen && style.controlsShow}`}>
              <div className={style.controlsCaption}>{worlds.youAreInFullScreenMode}</div>
              <div className={style.controlsButton}>
                {worlds.toExitFullScreenMode}
                <ButtonSquare width="2.27vw" height="2.27vw" style={{marginLeft: "0.5vw"}} icon={fullScreenIcon} action={() => this.goFull()} />
              </div>
            </div>
)}
          {
                  this.props.cells.map((cell,i) => <Cell key={cell.id} cellId={cell.id} dropItem={this.dropItem} item={cell} />)
                }
        </div>
        <FooterMenu clearAll={this.clearAll} acceptPattern={this.acceptPattern} goFull={this.goFull} addItem={this.addItem} />
      </div>

    )
  }
}

export default connect((state) => state,null)(Screens)
