import React from "react";
import style from "./PlanEdit.module.scss"
import TextFieldM from "../../../../Components/Inputs/TextFieldM";
import {worlds} from "../../../../Utils/localization";
import ButtonRectangle from "../../../../Components/Buttons/ButtonRectangle/ButtonRectangle";
import {deleteEdit, savePlan} from "../../../../api/state/plan/actions";

export default class PlanEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            planName: this.props.editPlan?.name ?? "",
        }
    }

    changePlanName = (e) => {
        this.setState({planName: e.target.value})
    }

    cancel = () => {
        deleteEdit();
    }

    save = async () => {
        const plan = this.props.editPlan;
        plan.name = this.state.planName;
        await savePlan(plan)
        deleteEdit();
    }

    render() {
        return (
          <div className={style.planEdit}>
            <TextFieldM style={{width: "100%",marginLeft: "-2.1vw"}} variant="outlined" placeholder={worlds.enterPlanName} value={this.state.planName} onChange={this.changePlanName} />
            <div className={style.controls}>
              <ButtonRectangle dark height="2.18vw" width="6.35vw" caption={worlds.cancel} action={() => this.cancel()} />
              <ButtonRectangle height="2.18vw" width="6.35vw" caption={worlds.save} action={() => this.save()} />
            </div>
          </div>
        )
    }
}
