import React from "react";
import {connect} from "react-redux";
import style from "./ModalResetAll.module.scss"
import ButtonRectangle from "../../Buttons/ButtonRectangle/ButtonRectangle";
import {worlds} from "../../../Utils/localization";
import {closeModal} from "../actions";

class ModalResetAll extends React.Component {
    reset = () => {

    }

    render() {
        return (
          <div className={style.modalResetAll}>
            <div className={style.caption}>
              {`${worlds.resetAllSettings} ?`}
            </div>
            <div className={style.controls}>
              <ButtonRectangle width="7.55rem" caption={worlds.reset} action={() => { this.reset(); closeModal() }} />
              <ButtonRectangle width="7.55rem" dark caption={worlds.cancel} action={() => closeModal()} />
            </div>
          </div>
        )
    }
}

export default connect((state) => state,null)(ModalResetAll)
