import React from "react";
import {InputAdornment} from "@material-ui/core";
import moment from "moment-timezone";
import style from "./FaceDahuaForm.module.scss";
import {worlds} from "../../../Utils/localization";
import CancelIcon from "../../../Assets/icons/cancel.svg";
import iconImage from "../../../Assets/icons/image.svg";
import SearchIcon from "../../../Assets/icons/search.svg";
import TextFieldM from "../../../Components/Inputs/TextFieldM";
import iconCalendar from "../../../Assets/icons/calendar.svg";
import ButtonRectangle from "../../../Components/Buttons/ButtonRectangle/ButtonRectangle";
import DateTimePicker from "../../../Components/DateTimePicker/DateTimePicker";

export default class FaceDahuaForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: "",
            dateRange: [new Date(moment().format()), new Date(moment().format())],
            showDate: {show: false,index: 0},
            isReset: false,
        }
    }

    reset = (bool) => {
        this.setState({isReset: bool});
    }

    changeDate = (date, index) => {
        const tempDate = this.state.dateRange;
        tempDate[index] = new Date(date);
        if (tempDate[0] > tempDate[1]) tempDate[0] = new Date(tempDate[1].setMinutes(tempDate[1].getMinutes() - 1));
        this.setState({dateRange: tempDate});
    }

    showDateIndex = (index) => {
        this.setState({showDate: {show: true,index}});
    }

    mainClose = (e) => {
        if (e.target.className.indexOf("dateBack") >= 0 && this.state.showDate.show) this.setState({showDate: {show: false,index: 0}});
    }

    render() {
        return (
          <div>
            {this.state.showDate.show
            && (
            <div className={style.dateBack} onClick={(e) => this.mainClose(e)}>
              <div style={{right: this.state.showDate.index === 1 && "12.6vw"}} className={style.dateTime}>
                <DateTimePicker reset={this.reset} isReset={this.state.isReset} dateTime={(date) => this.changeDate(date,this.state.showDate.index)} />
              </div>
            </div>
)}
            <div className={style.faceDahuaForm} onClick={() => this.state.showDate.show && this.setState({showDate: {show: false, index: 0}})}>
              <TextFieldM
                placeholder={worlds.searchByCameraName}
                variant="outlined"
                style={{width: "53.17vw",margin: "0 2.5vw 0 0",height: "100%"}}
                onChange={(e) => { this.setState({searchText: e.target.value}) }}
                value={this.state.searchText}
                InputProps={{
                                endAdornment: (
                                  <>
                                    {this.state.searchText.length > 0
                                        && (
                                        <InputAdornment position="start" onClick={() => { this.setState({searchText: ""}) }}>
                                          <img className={style.icon} src={CancelIcon} />
                                        </InputAdornment>
)}
                                    <InputAdornment position="start">
                                      <img className={style.icon} src={iconImage} />
                                    </InputAdornment>
                                    <div className={style.separate} />
                                    <InputAdornment position="end">
                                      <img className={style.icon} src={SearchIcon} />
                                    </InputAdornment>
                                  </>
                                ),
                            }}
              />
              <div className={style.datePeriod}>
                <div className={style.text}>
                  {worlds.period}
                  :
                </div>
                <TextFieldM
                  variant="outlined"
                  style={{width: "9.63vw",margin: "0",height: "100%"}}
                  value={this.state.dateRange[0].toLocaleString('ru').slice(0,17).replace(",","")}
                  InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <img className={style.iconCalendar} src={iconCalendar} onClick={() => this.showDateIndex(0)} />
                              </InputAdornment>
                            ),
                        }}
                />
                <div className={style.dash} />
                <TextFieldM
                  variant="outlined"
                  style={{width: "9.63vw",margin: "0 1.25vw 0 0",height: "100%"}}
                  value={this.state.dateRange[1].toLocaleString('ru').slice(0,17).replace(",","")}
                  InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <img className={style.iconCalendar} src={iconCalendar} onClick={() => this.showDateIndex(1)} />
                              </InputAdornment>
                            ),
                        }}
                />
                <ButtonRectangle action={() => this.reset(true)} caption={worlds.reset} dark width="6.61vw" height="100%" />
              </div>
            </div>
          </div>
        )
    }
}
