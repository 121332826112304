import {LOAD_DISKS} from "./consts";

const prevState = {
    disks: {},
}

export const disks = (state = prevState.disks, action) => {
    switch (action.type) {
        case LOAD_DISKS:
            return {...action.obj};
        default:
            return state;
    }
}
