import React from "react";
import {connect} from "react-redux";
import style from "./Switching.module.scss";
import {worlds} from "../../../Utils/localization";
import SwitchingItem from "./SwitchingItem/SwitchingItem";
import {showModalAddScreen, showModalFileManager} from "../../../Components/Modals/actions";
import HeaderSetup from "../HeaderSetup/HeaderSetup";
import ButtonAddCam from "../../../Components/Buttons/ButtonAddCam/ButtonAddCam";
import {getAudioList, uploadAudioFile} from "../../../api/state/switching/actions";

const Switching = (props) => {
    const fileSelector = () => {
        showModalFileManager(false,async (item) => {
            await uploadAudioFile(item.filepath);
            await getAudioList()
        });
    }

    return (
      <div className={style.switching}>
        <HeaderSetup caption={worlds.switchingModule} />
        <div className={style.header}>
          <ButtonAddCam caption={worlds.addAudioFile} style={{margin: 0,padding: "0 1vw"}} action={() => fileSelector()} />
          <ButtonAddCam caption={worlds.addScreenID} style={{margin: 0,marginLeft: "1vw",padding: "0 1vw"}} action={() => showModalAddScreen()} />
        </div>
        <div className={style.eventList}>
          <div className={style.headList}>
            <div className={style.headItem} style={{width: "95%"}}>{worlds.eventType}</div>
            <div className={style.headItem} style={{width: "5%"}} />
          </div>
          {props.sounds.length > 0 ? props.sounds.map((item, i) => <SwitchingItem name={item} key={i} />) : <div className={style.addEvent}>{worlds.addReactionsOnEvent}</div>}
        </div>
      </div>
    )
}

export default connect((state) => state,null)(Switching);
