import React, {useState} from 'react';
import {connect} from "react-redux";
import style from './FaceDetection.module.scss';
import {worlds} from "../../Utils/localization";
import ButtonTopMenu from "../../Components/Buttons/ButtonTopMenu/ButtonTopMenu";
import FaceDetectionSearch from "./FaceDetectionSearch";
import FaceDetectionView from "./FaceDetectionView";

function FaceDetection(props) {
    const [search,setSearch] = useState(false)

    return (
      <div className={style.faceDetectionContainer}>
        <div className={style.menu}>
          <ButtonTopMenu caption={worlds.faceDetection} active={!search} action={() => setSearch(false)} />
          <ButtonTopMenu caption={`${worlds.faceDetection} ${worlds.search}`} active={search} action={() => setSearch(true)} />
        </div>
        {search ? <FaceDetectionSearch /> : <FaceDetectionView />}
      </div>
    );
}

export default connect((state) => state,null)(FaceDetection);
