import React from "react";
import moment from "moment-timezone";
import style from "./PeriodPicker.module.scss";
import {worlds} from "../../../../../Utils/localization";
import TimePicker from "../../../../../Components/DateTimePicker/TimePicker/TimePicker";
import ButtonRectangle from "../../../../../Components/Buttons/ButtonRectangle/ButtonRectangle";

export default class PeriodPicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startTime: {hours: new Date(moment().format()).getHours(),minutes: new Date(moment().format()).getMinutes()},
            endTime: {hours: new Date(moment().format()).getHours(),minutes: new Date(moment().format()).getMinutes()},
            date: {day: new Date(moment().format()).getDate(),month: new Date(moment().format()).getMonth() + 1,year: new Date(moment().format()).getFullYear()},
        }
    }

    startTime = (time) => {
        this.setState({startTime: time});
    }

    endTime = (time) => {
        this.setState({endTime: time});
    }

    sendPeriod = () => {
        if (this.state.startTime.hours > this.state.endTime.hours || (this.state.endTime.hours === this.state.startTime.hours && this.state.endTime.minutes < this.state.startTime.minutes)) {
            this.props.period({start: this.state.startTime,end: this.state.startTime})
        } else {
            this.props.period({start: this.state.startTime, end: this.state.endTime})
        }
    }

    render() {
        return (
          <div className={`${style.periodPicker} ${this.props.bottom && style.bottom}`}>
            <div className={style.times}>
              <div className={style.period}>
                <div className={style.caption}>{worlds.start}</div>
                <TimePicker time={(time) => this.startTime(time)} />
              </div>
              <div className={style.period}>
                <div className={style.caption}>{worlds.end}</div>
                <TimePicker time={(time) => this.endTime(time)} />
              </div>
            </div>
            <ButtonRectangle action={() => this.sendPeriod()} caption={worlds.add} width="100%" dark />
          </div>
        )
    }
}
