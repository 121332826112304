import React from "react";
import {withRouter} from "react-router-dom";
import PropTypes from "prop-types";
import style from "./ButtonAddCam.module.scss"
import {createEdit} from "../../../api/state/cam/actions";
import Cam from "../../../api/models/Cam";

class ButtonAddCam extends React.Component {
    onClick = async () => {
        createEdit(new Cam())
        if (this.props.href && this.props.action) {
            this.props.action()
            this.props.history.push(this.props.href)
        }
        if (this.props.href) this.props.history.push(this.props.href)
        else this.props.action()
    }

    render() {
        return (
          <div
            style={{
width: this.props.width || "90%", height: this.props.height || "2.19vw", margin: this.props.margin || "auto 0.94vw 0.57vw", ...this.props.style,
}}
            onClick={() => this.onClick()}
            className={style.buttonAddCam}
          >
            <div className={style.icon} />
            <div className={style.caption}>{this.props.caption}</div>
          </div>
        )
    }
}

export default withRouter(ButtonAddCam)

ButtonAddCam.propTypes = {
    action: PropTypes.func,
    caption: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    margin: PropTypes.string,
};
