import React from "react";
import moment from "moment-timezone";
import style from "./TimePicker.module.scss"

export default class TimePicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            minutes: Array.from(Array(60).keys()),
            hours: Array.from(Array(24).keys()),
            total: {hours: 0,minutes: 0},
            mouseDown: false,
            prevScroll: null,
        }
    }

    componentDidMount() {
        let date;
        if (this.props.currentTime) {
            const temp = new Date(moment().format());
            date = new Date(temp.getFullYear(),temp.getMonth(),temp.getDate(),
                this.props.currentTime.hours,this.props.currentTime.minutes, 0);
        } else date = new Date(moment().format())
        this.setTotal(date.getHours(),date.getMinutes())
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.isReset !== this.props.isReset) {
            let date;
            if (this.props.currentTime) {
                const temp = new Date(moment().format());
                date = new Date(temp.getFullYear(),temp.getMonth(),temp.getDate(),
                    this.props.currentTime.hours,this.props.currentTime.minutes, 0);
            } else date = new Date(moment().format())
            this.setTotal(date.getHours(),date.getMinutes())
            this.props.reset(false)
        }
    }

    setTotal = (hours,minutes) => {
        while (this.state.total.minutes !== minutes) {
            this.next("minutes")
        }
        while (this.state.total.hours !== hours) {
            this.next("hours")
        }
        this.props.time(this.state.total)
    }

    next = (tag) => {
        const minutes = this.state[tag];
        const first = minutes.shift()
        minutes.push(first)
        const {total} = this.state;
        total[tag] = minutes[minutes.length / 2]
        this.setState({[tag]: minutes,total})
    }

    back = (tag) => {
        const minutes = this.state[tag];
        const first = minutes.pop()
        minutes.unshift(first)
        const {total} = this.state;
        total[tag] = minutes[minutes.length / 2]
        this.props.time(this.state.total)
        this.setState({[tag]: minutes,total})
    }

    scroll = (el) => {
        if (el.deltaY > 0) {
            this.next(el.currentTarget.getAttribute('tag'))
        } else {
            this.back(el.currentTarget.getAttribute('tag'))
        }
        this.props.time(this.state.total)
        el.stopPropagation()
        el.preventDefault()
    }

    onMouseMove = (el) => {
        if (this.state.mouseDown) {
            if (this.state.prevScroll && el.pageY > this.state.prevScroll) {
                this.next(el.currentTarget.getAttribute('tag'))
            }
            if (this.state.prevScroll && el.pageY < this.state.prevScroll) {
                this.back(el.currentTarget.getAttribute('tag'))
            }
            this.setState({prevScroll: el.pageY})
        }
    }

    render() {
        return (
          <div className={style.timePicker}>
            <div className={style.shadowUp} />
            <div onMouseMove={this.onMouseMove} onMouseDown={() => this.setState({mouseDown: true})} onMouseUp={() => this.setState({mouseDown: false,prevScroll: 0})} tag="hours" onWheel={this.scroll} className={style.seconds}>
              {this.state.hours.map((item,i) => <div onClick={() => this.setTotal(item,this.state.total.minutes)} key={item} className={style.second}>{(`0${item}`).slice(-2)}</div>)}
            </div>
            <div className={style.borders}>
              <div className={style.bord} />
              <div className={style.dots}>:</div>
              <div className={style.bord} />
            </div>
            <div onMouseMove={this.onMouseMove} onMouseDown={() => this.setState({mouseDown: true})} onMouseUp={() => this.setState({mouseDown: false,prevScroll: 0})} tag="minutes" onWheel={this.scroll} className={style.seconds}>
              {this.state.minutes.map((item,i) => <div onClick={() => this.setTotal(this.state.total.hours,item)} key={item} className={style.second}>{(`0${item}`).slice(-2)}</div>)}
            </div>
            <div className={style.shadowDown} />
          </div>
        )
    }
}
