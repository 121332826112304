import React from "react";
import {connect} from "react-redux";
import style from "./ModalBrowser.module.scss"

class ModalBrowser extends React.Component {
    constructor(props) {
        super(props);
        this.ref = React.createRef()
    }

    render() {
        return (
          <div className={style.modalBrowser}>
            <embed src={`http://${this.props.url}/`} width="100%" height="100%" />
            {/* <Iframe url={`http://${this.props.url}/`} */}
            {/*        ref = {this.ref} */}
            {/*        width="100%" */}
            {/*        height="100%" */}
            {/*        frameBorder="0" */}
            {/*        sandbox="allow-downloads allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-popups allow-popups-to-escape-sandbox allow-presentation allow-same-origin allow-scripts allow-top-navigation allow-top-navigation-by-user-activation" */}
            {/*        id="browser" */}
            {/*        className="myClassname" */}
            {/*        display="initial" */}
            {/*        position="relative"/> */}
          </div>
        )
    }
}

export default connect((state) => state,null)(ModalBrowser)
