import {IsNotEmpty} from "class-validator";
import {worlds} from "../../Utils/localization";

interface CamPlan{
    type: string,
    id: string,
    x: number,
    y: number
}

export default class Plan {
    id: string = "";

    @IsNotEmpty({
        message: worlds.emptyField,
    })
    name: string = "";

    @IsNotEmpty({
        message: worlds.emptyField,
    })
    account: string = "";

    @IsNotEmpty({
        message: worlds.emptyField,
    })
    image:string = "";

    data: any = {};

    cams: Array<CamPlan> = [];

    serialize(plan:any) {
        this.id = plan?.id ?? "";
        this.name = plan?.name ?? "";
        this.image = plan?.stage?.image?.filedata ?? "";
        plan?.stage?.objects.map((item:any) => {
            this.cams.push({
                type: item.type,
                id: item.id,
                x: item.x,
                y: item.y,
            })
        });
        this.account = plan?.account ?? "";
    }

    deserialize() {
        const plan:any = {};
        plan.id = this.id;
        plan.name = this.name;
        plan.stage = {};
        plan.stage.objects = this.cams.map((item) => ({
                type: item.type,
                id: item.id,
                x: item.x,
                y: item.y,
            }));
        plan.stage.width = 0;
        plan.stage.height = 0;
        plan.account = this.account;
        plan.data = this.data;
        plan.stage.image = {filedata: this.image};
        return plan;
    }

    updateCam(cam:CamPlan) {
        this.cams = this.cams.filter((i) => i.id !== cam.id);
        this.cams.push(cam);
    }

    deleteCam(cam:CamPlan) {
        this.cams = this.cams.filter((i) => i.id !== cam.id);
    }
}
