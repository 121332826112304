import {IBase} from "../iBase";
import Account from "../../models/Account";

export default class AccountDao implements IBase<Account> {
    api:any;

    constructor(api:any) {
        this.api = api;
    }

    async authorization(obj: any): Promise<Account> {
        const newAccount = new Account();
        newAccount.serialize((await this.api.emit("account.login", {auth: obj})).account);
        return newAccount;
    }

    async authorizationWithToken(obj: any): Promise<Account> {
        const newAccount = new Account();
        newAccount.serialize((await this.api.emit("account.loginWithToken", {auth: {token: obj}})).account);
        return newAccount;
    }

    async create(obj: Account): Promise<Account> {
        const account = obj.deserialize();
        const newAccount = new Account();
        newAccount.serialize((await this.api.emit("account.create", {account})).account);
        return newAccount;
    }

    async delete(obj: Account): Promise<Account> {
        const account = obj.deserialize();
        const newAccount = new Account();
        newAccount.serialize((await this.api.emit("account.delete", {account})).account);
        return newAccount;
    }

    async get(id: string): Promise<Account> {
        // eslint-disable-next-line no-multi-assign
        const account = new Account().id = id;
        const newAccount = new Account();
        newAccount.serialize((await this.api.emit("account.get", {account})).account);
        return newAccount;
    }

    async list(filter: any = {}): Promise<Array<Account>> {
        const accountsDeserialized = await this.api.emit("account.list", {filter});
        // eslint-disable-next-line no-array-constructor
        const accounts = Array<Account>();
        accountsDeserialized.accounts.map((item:any) => {
            const account = new Account();
            account.serialize(item);
            return accounts.push(account);
        })
        return accounts;
    }

    async update(obj: Account): Promise<Account> {
        const account = obj.deserialize();
        const newAccount = new Account();
        newAccount.serialize((await this.api.emit("account.update", {account})).account);
        return newAccount;
    }
}
