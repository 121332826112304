import React from "react";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import style from "./DeviceItem.module.scss";
import camLight from "../../../../Assets/icons/camLight.svg";
import {showModalAuthDHCP} from "../../../../Components/Modals/actions";

class DeviceItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            added: false,
            camName: "",
        }
    }

    checkCam = () => Object.values(this.props.cams).find((i) => {
                try {
                    const url = new URL(i.url.replace('rtsp', 'http'));
                    if (url.hostname === this.props.elem.ip) {
                        return i;
                    }
                } catch (e) {
                    return ""
                }
            })?.name || ""

    addCam = async () => {
        showModalAuthDHCP(this.props.elem.ip,this.props.elem.mac);
    }

    render() {
        return (
          <div className={style.deviceItem}>
            <div className={style.device} style={{width: "3.85vw",padding: "0",justifyContent: "center"}}>
              <img src={camLight} className={style.icon} />
            </div>
            <div className={style.device} style={{width: "15vw"}}>{this.props.elem.mac}</div>
            <div className={style.device} style={{width: "15vw"}}>{this.props.elem.ip}</div>
            <div className={style.device} style={{width: "17.49vw"}}>{this.props.elem.hostname}</div>
            <div className={style.device} style={{width: "14.02vw"}}>{this.checkCam()}</div>
            <div className={style.device} style={{width: "11.83vw",padding: "0",justifyContent: "center"}}>
              {!this.checkCam() && <div className={style.addCam} onClick={() => this.addCam()} />}
            </div>
          </div>
        );
    }
}

export default withRouter(connect((state) => state,null)(DeviceItem))
