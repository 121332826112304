import {connect} from 'react-redux';
// eslint-disable-next-line import/no-named-as-default
import Main from './Main';
import {addSnackBar} from "../Header/actions";

const mapStateToProps = (state) => state

const mapDispatchToProps = (dispatch) => ({
    addSnackBar: (snackbar) => dispatch(addSnackBar(snackbar)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Main);
