import React from "react";
import style from "./PlanItem.module.scss";
import {createCurrent} from "../../../../api/state/plan/actions";

export default class PlanItem extends React.Component {
    constructor(props) {
        super(props);
    }

    changePlan = () => {
        createCurrent(this.props.plan)
        this.props.openCloseMenu()
    }

    render() {
        return (
          <div className={style.planItem} onClick={() => this.changePlan()}>
            <div className={style.caption}>{this.props.plan.name}</div>
          </div>
        )
    }
}
