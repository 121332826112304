import {KEYBOARD_CURRENT_INPUT, KEYBOARD_ENABLE, KEYBOARD_SHOW} from "./consts";

const prevState = {
    keyboardInputRef: {ref: null,callBack: null},
    keyboardShow: false,
    keyboardEnable: true,
}

export const keyboardInputRef = (state = prevState.keyboardInputRef, action) => {
    switch (action.type) {
        case KEYBOARD_CURRENT_INPUT:
            return action.obj;
        default:
            return state;
    }
}

export const keyboardShow = (state = prevState.keyboardShow, action) => {
    switch (action.type) {
        case KEYBOARD_SHOW:
            return action.obj;
        default:
            return state;
    }
}

export const keyboardEnable = (state = prevState.keyboardEnable, action) => {
    switch (action.type) {
        case KEYBOARD_ENABLE:
            return action.obj;
        default:
            return state;
    }
}
