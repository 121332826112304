import React from "react";
import {connect} from "react-redux";
import style from "./AdditionalSettings.module.scss";
import {worlds} from "../../../Utils/localization";
import {
    showModalChangePassword,
    showModalResetAllSettings,
    showModalTerminal,
} from "../../../Components/Modals/actions";
import ButtonRectangle from "../../../Components/Buttons/ButtonRectangle/ButtonRectangle";
import MenuPower from "./MenuPower/MenuPower";
import Item from "./Item";

class AdditionalSettings extends React.Component {
    render() {
        return (
          <div className={style.additionalSettings}>
            <div className={style.header}>
              <div className={style.caption}>{worlds.additionalSettings}</div>
              <div className={style.headerMenu}>
                <ButtonRectangle style={{margin: "0 0.5vw"}} action={() => showModalChangePassword()} caption={worlds.passwordChange} width="8.96vw" dark />
                <ButtonRectangle style={{margin: "0 0.5vw"}} action={() => showModalResetAllSettings()} caption={worlds.resetSettings} width="8.96vw" dark />
                <MenuPower />
              </div>
            </div>
            <div className={style.content}>
              <div className={style.eventList}>
                <div className={style.headList}>
                  <div className={style.headItem} style={{width: "100%"}}>{worlds.name}</div>
                  <div className={style.headItem} style={{width: "10.31vw"}}>{worlds.status}</div>
                  <div className={style.headItem} style={{minWidth: "13.475vw"}}>{worlds.action}</div>
                  <div className={style.headItem} style={{minWidth: "8.475vw"}}>{worlds.startup}</div>
                  <div className={style.headItem} style={{minWidth: "5vw"}}>{worlds.status}</div>
                </div>
                <div className={style.itemList}>
                  <Item name="app" autoload={false} status start stop restart />
                  <Item name="brc" autoload={false} status start stop restart />
                  <Item name="faceapi" autoload status start stop restart />
                  <Item name="iptables" autoload status start stop restart />
                  <Item name="chrony" autoload status start stop restart />
                  <Item name="openvpn" autoload status start stop restart />
                  <Item name="ntp" autoload status start stop restart />
                  {/* <Item name={"network"} autoload={false} status={false} start={false} stop={false} restart={true}/> */}
                  {/* <Item name={"xterm"} autoload={false} status={false} start={true} stop={true} restart={false}/> */}
                  <ButtonRectangle caption={worlds.openTerminal} action={() => showModalTerminal()} />
                </div>
              </div>
            </div>
          </div>
        )
    }
}

export default connect((state) => state,null)(AdditionalSettings);
