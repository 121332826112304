import React from "react";
import CacheRoute, {CacheSwitch} from "react-router-cache-route/index";
import style from "./Settings.module.scss"
import Logs from "./Logs/Logs";
import Taxonomy from "./Taxonomy/Taxonomy";
import HardManager from "./HardManager/HardManager";
import Renewal from "./Renewal/Renewal";
import Network from "./Network/Network";
import DHCP from "./DHCP/DHCP";
import FaceApi from "./FaceApi/FaceApi";
import Devices from "./Devices/Devices";
import SMTP from "./SMTP/SMTP";
import MotionDetector from "./MotionDetector/MotionDetector";
import NTP from "./NTP/NTP";
import Licensing from "./Licensing/Licensing";
import SecurityDrill from "./SecurityDrill/SecurityDrill";
import AdditionalSettings from "./AdditionalSettings/AdditionalSettings";
import Cameras from "./Cameras/Cameras";
import Switching from "./Switching/Switching";

export default class Settings extends React.Component {
    render() {
        return (
          <div className={style.settings}>
            <CacheSwitch>
              <CacheRoute className={style.cacheRoute} exact path="/settings">
                <HardManager />
              </CacheRoute>
              <CacheRoute className={style.cacheRoute} path="/settings/logs">
                <Logs />
              </CacheRoute>
              <CacheRoute className={style.cacheRoute} path="/settings/taxonomy">
                <Taxonomy />
              </CacheRoute>
              <CacheRoute className={style.cacheRoute} path="/settings/switching">
                <Switching />
              </CacheRoute>
              <CacheRoute className={style.cacheRoute} path="/settings/renewal">
                <Renewal />
              </CacheRoute>
              <CacheRoute className={style.cacheRoute} path="/settings/network">
                <Network />
              </CacheRoute>
              <CacheRoute className={style.cacheRoute} path="/settings/dhcp">
                <DHCP />
              </CacheRoute>
              <CacheRoute className={style.cacheRoute} path="/settings/faceapi">
                <FaceApi />
              </CacheRoute>
              <CacheRoute className={style.cacheRoute} path="/settings/devices">
                <Devices />
              </CacheRoute>
              <CacheRoute className={style.cacheRoute} path="/settings/smtp">
                <SMTP />
              </CacheRoute>
              <CacheRoute className={style.cacheRoute} path="/settings/cameras">
                <Cameras />
              </CacheRoute>
              <CacheRoute className={style.cacheRoute} path="/settings/motionDetector">
                <MotionDetector />
              </CacheRoute>
              <CacheRoute className={style.cacheRoute} path="/settings/ntp">
                <NTP />
              </CacheRoute>
              <CacheRoute className={style.cacheRoute} path="/settings/drill">
                <SecurityDrill />
              </CacheRoute>
              <CacheRoute className={style.cacheRoute} path="/settings/licensing">
                <Licensing />
              </CacheRoute>
              <CacheRoute className={style.cacheRoute} path="/settings/additionalSettings">
                <AdditionalSettings />
              </CacheRoute>
            </CacheSwitch>
          </div>

        )
    }
}
