import React from "react";
import {connect} from "react-redux";
import ButtonSquare from "../../../../Components/Buttons/ButtonSquare/ButtonSquare";
import {worlds} from "../../../../Utils/localization";
import iconAddPlan from "../../../../Assets/icons/addPlan.svg";
import PlanChoice from "../PlanChoice/PlanChoice";
import MenuPlanShort from "../../MenuPlanShort/MenuPlanShort";
import {createEdit} from "../../../../api/state/plan/actions";
import {showModalDeletePlan} from "../../../../Components/Modals/actions";

class PlanCurrent extends React.Component {
    editPlan = () => {
        createEdit(this.props.currentPlan)
    }

    render() {
        return (
          <>
            {this.props.currentAccount.username !== "user" && (
              <ButtonSquare
                action={this.props.addPlan}
                name={worlds.addPlan}
                icon={iconAddPlan}
                width="1.87vw"
                height="1.87vw"
                style={{marginRight: "1.56vw"}}
              />
)}
            <PlanChoice closeOpenPlanList={this.props.closeOpenPlanList} currentPlan={this.props.currentPlan} plans={this.props.plans} openMenu={this.props.openMenu} />
            {this.props.currentAccount.username !== "user" && (
              <div style={{marginLeft: "auto",marginRight: "1vw"}}>
                <MenuPlanShort edit={() => this.editPlan()} style={{marginLeft: "auto"}} delete={() => showModalDeletePlan(this.props.currentPlan)} />
              </div>
)}
          </>
        )
    }
}

export default connect((state) => state,null)(PlanCurrent)
