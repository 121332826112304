import React from 'react';
import Popover from '@material-ui/core/Popover';
import PopupState, {bindPopover, bindTrigger} from 'material-ui-popup-state';
import style from "./MenuPlanShort.module.scss"
import ButtonPopup from "../../../Components/Buttons/ButtonPopup/ButtonPopup";
import trash from "../../../Assets/icons/trash.svg"
import edit from "../../../Assets/icons/edit.svg"
import {worlds} from "../../../Utils/localization";

export default function MenuPlanShort(props) {
    return (
      <PopupState variant="popover">
        {(popupState) => (
          <div className={style.menuPlanShort}>
            <div className={style.button} {...bindTrigger(popupState)} />
            <Popover
              onClick={() => popupState.close()}
              {...bindPopover(popupState)}
              anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'start',
                        }}
              transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'end',
                        }}
              PaperProps={{
                            className: style.menu,
                        }}
            >
              <ButtonPopup popupState={popupState} action={() => props.edit()} icon={edit} caption={worlds.edit} />
              <ButtonPopup action={() => props.delete()} icon={trash} caption={worlds.trash} />
            </Popover>
          </div>
            )}
      </PopupState>
    );
}
