import {plainToClass} from "class-transformer";
import {
    CURRENT_TAXONOMY_CREATE,
    CURRENT_TAXONOMY_DELETE,
    EDIT_TAXONOMY_CREATE,
    EDIT_TAXONOMY_DELETE,
    TAXONOMY_CREATE,
    TAXONOMY_DELETE,
    TAXONOMY_LIST,
    TAXONOMY_UPDATE,
} from "./consts";
import Taxonomy from "../../models/Taxonomy";

const prevState = {
    taxonomies: [],
    currentTaxonomy: {},
    editTaxonomy: {},
}

export const taxonomies = (state = prevState.taxonomies, action) => {
    switch (action.type) {
        case TAXONOMY_LIST:
            return plainToClass(Taxonomy,[...action.obj]);
        case TAXONOMY_CREATE:
            if (state.find((i) => i.id === action.obj.id)) return state;
                state.push(action.obj);
            return plainToClass(Taxonomy,[...state]);
        case TAXONOMY_UPDATE:
            state.splice(state.findIndex((i) => i.id === action.obj.id), 1, action.obj);
            return plainToClass(Taxonomy,[...state]);
        case TAXONOMY_DELETE:
            state.splice(state.findIndex((i) => i.id === action.obj.id), 1);
            return plainToClass(Taxonomy,[...state]);
        default:
            return state;
    }
}

export const editTaxonomy = (state = prevState.editTaxonomy, action) => {
    switch (action.type) {
        case EDIT_TAXONOMY_CREATE:
            return plainToClass(Taxonomy,{...state,...action.obj});
        case EDIT_TAXONOMY_DELETE:
            return null;
        default:
            return state;
    }
}

export const currentTaxonomy = (state = prevState.currentTaxonomy, action) => {
    switch (action.type) {
        case CURRENT_TAXONOMY_CREATE:
            return plainToClass(Taxonomy,{...state,...action.obj});
        case CURRENT_TAXONOMY_DELETE:
            return null;
        default:
            return state;
    }
}
