import React from "react";
import PopupState, {bindPopover, bindTrigger} from "material-ui-popup-state";
import Popover from "@material-ui/core/Popover";
import style from "./RuleMenu.module.scss";
import ButtonPopup from "../../../../../Components/Buttons/ButtonPopup/ButtonPopup";
import iconEdit from "../../../../../Assets/icons/edit.svg";
import {worlds} from "../../../../../Utils/localization";
import iconTrash from "../../../../../Assets/icons/trash.svg";
import {showModalDeleteRule} from "../../../../../Components/Modals/actions";

export default class RuleMenu extends React.Component {
    render() {
        return (
          <PopupState variant="popover">
            {(popupState) => (
              <div className={style.ruleMenu}>
                <div className={style.button} {...bindTrigger(popupState)} />
                <Popover
                  onClick={popupState.close}
                  {...bindPopover(popupState)}
                  anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                  transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                  PaperProps={{
                                className: style.menu,
                            }}
                >
                  <ButtonPopup href={`/editRule/${this.props.trigger.id}`} popupState={popupState} icon={iconEdit} caption={worlds.edit} />
                  <ButtonPopup action={() => showModalDeleteRule(this.props.trigger)} icon={iconTrash} caption={worlds.trash} />
                </Popover>
              </div>
                )}
          </PopupState>
        );
    }
}
