import {store} from "../App";
import {action} from "./ReduxHelper";
import {patterns, SERVER_BRC, SET_TIME} from "./consts";
import {worlds} from "../Utils/localization";
import {globalLoader} from "./state/account/actions";
import {emitter} from "../Utils/globalEmitter";
import {showModalUpdateSystem} from "../Components/Modals/actions";

export const initiateApiEvents = () => {
    emitter.addListener('errorApi',(err) => {
        const error = typeof err === "string" ? err : err?.message ?? "Error"
        store.getState().snackbar("warning",{header: error,description: ""});
    })
    emitter.addListener('loadingApi',(bool,text) => {
        globalLoader(bool,text);
    })
}

export const getServerBrc = async () => {
    const {api} = store.getState();
    try {
        const server = (await api.emit("server.list", {include: ["version"]}))?.servers[0];
        server.version = (await api.emit("server.get", {server: {id: server.id}, include: ["version"]}))?.version;
        store.dispatch(action(SERVER_BRC, server));
    } catch (e) {}
}

export const getVersionFaceApi = async () => {
    const {api} = store.getState();
    try {
        const info = await api.emit("faceapi.version", {});
        return info.version
    } catch (e) {}
}

export const getLicenseBrc = async () => {
    const {api} = store.getState();
    try {
        const res = await api.emit("strazh.license.brc.get", {});
        return res.license;
    } catch (e) {
        console.error(e)
        return false;
    }
}

export const getLicenseFaceApi = async () => {
    const {api} = store.getState();
    try {
        const res = await api.emit("strazh.license.faceapi.get", {});
        return res.license;
    } catch (e) {
        console.error(e)
        return false;
    }
}

export const setLicenseBrc = async (filepath,data) => {
    const {api} = store.getState();
    try {
        const license = filepath ? {filepath} : {data};
        if (!license) return
        await api.emit("strazh.license.brc.set", {license});
        store.getState().snackbar("success",{header: worlds.licenseActivated,description: ""});
    } catch (e) {
        console.error(e)
        return false;
    }
}

export const setLicenseFaceApi = async (filepath,data) => {
    const {api} = store.getState();
    try {
        const license = filepath ? {filepath} : {data};
        if (!license) return
        await api.emit("strazh.license.faceapi.set", {license});
        store.getState().snackbar("success",{header: worlds.licenseActivated,description: ""});
    } catch (e) {
        console.error(e)
        return false;
    }
}

export const getServerTime = async () => {
    const {api} = store.getState();
    try {
        const date = (await api.emit("global.time",{},true,true)).time;
        store.dispatch(action(SET_TIME,date));
    } catch (e) {
        console.error(e);
    }
}

export const parseRtsp = (model,rtsp) => {
    const pattern = patterns.find((i) => i.name === model)?.pattern ?? "";
    if (!pattern || !rtsp) return null;
    return new URL(rtsp.replace(/^rtsp/, 'http'));
}

export const globalUpdate = async () => {
    const {api} = store.getState();
    try {
        const {status} = await api.emit("strazh.update.run", {});
        if (status) {
            showModalUpdateSystem();
        } else {
            store.getState().snackbar("success",{header: worlds.theLatestVersionOfTheApplicationIsInstalled,description: ""});
        }
    } catch (e) {
        store.getState().snackbar("warning",{header: e,description: ""});
        return false;
    }
}

export const ping = (host) => {
    // const api = store.getState().api;
    // let res = api.emit("strazh.tools.ping",{host});
    // console.log(res);
}

export const powerOff = async () => {
    const {api} = store.getState();
    try {
        const status = await api.emit("strazh.control.strazh.poweroff", {});
    } catch (e) {}
}

export const reboot = async () => {
    const {api} = store.getState();
    try {
        const status = await api.emit("strazh.control.strazh.reboot", {});
    } catch (e) {}
}

export const restartService = async (name) => {
    const {api} = store.getState();
    try {
        const status = await api.emit(`strazh.control.${name}.reboot`, {});
        return status
    } catch (e) {}
}

export const statusService = async (name) => {
    const {api} = store.getState();
    try {
        return await api.emit(`strazh.control.${name}.status`, {}, true, true);
    } catch (e) {
        return {enable: false,autoload: false};
    }
}

export const stopService = async (name) => {
    const {api} = store.getState();
    try {
        const status = await api.emit(`strazh.control.${name}.stop`, {});
        return status;
    } catch (e) {}
}

export const startService = async (name) => {
    const {api} = store.getState();
    try {
        const status = await api.emit(`strazh.control.${name}.start`, {});
        return status;
    } catch (e) {}
}

export const enableService = async (name) => {
    const {api} = store.getState();
    try {
        const status = await api.emit(`strazh.control.${name}.enable`, {});
        return status;
    } catch (e) {}
}

export const disableService = async (name) => {
    const {api} = store.getState();
    try {
        const status = await api.emit(`strazh.control.${name}.disable`, {});
        return status;
    } catch (e) {}
}
