import React from "react";
import PopupState, {bindPopover, bindTrigger} from "material-ui-popup-state";
import Popover from "@material-ui/core/Popover";
import style from "./ReactionsMenu.module.scss";
import ButtonPopup from "../../../../../Components/Buttons/ButtonPopup/ButtonPopup";
import iconEdit from "../../../../../Assets/icons/edit.svg";
import {worlds} from "../../../../../Utils/localization";
import iconTrash from "../../../../../Assets/icons/trash.svg";
import {showModalDeleteRule} from "../../../../../Components/Modals/actions";

export default class ReactionsMenu extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
          <PopupState variant="popover">
            {(popupState) => (
              <div className={style.reactionsMenu}>
                <div className={style.button} {...bindTrigger(popupState)} />
                <Popover
                  {...bindPopover(popupState)}
                  anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                  transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                  PaperProps={{
                                className: style.menu,
                            }}
                >
                  <ButtonPopup popupState={popupState} icon={iconEdit} caption={worlds.edit} href={`/editSwitchingReaction/${this.props.item.id}`} />
                  <ButtonPopup action={() => showModalDeleteRule(this.props.item)} icon={iconTrash} caption={worlds.trash} />
                </Popover>
              </div>
                )}
          </PopupState>
        )
    }
}
