import {IsNotEmpty} from "class-validator";
import {worlds} from "../../Utils/localization";

export default class Account {
    id: string = "";

    @IsNotEmpty({
        message: worlds.emptyField,
    })
    username: string = "";

    @IsNotEmpty({
        message: worlds.emptyField,
    })
    password: string = "";

    mail: string = "";

    name: string = "";

    groups: Array<string> = [];

    createdAt: Date = new Date();

    updatedAt: Date = new Date();

    passwordUpdatedAt: Date = new Date();

    data: any = {};

    serialize(account:any) {
        this.id = account?.id ?? "";
        this.username = account?.username ?? "";
        this.password = account?.password ?? "";
        this.mail = account?.mail ?? "";
        this.name = account?.name ?? "";
        this.groups = account?.groups ?? [];
    }

    deserialize() {
        const account:any = {};
        account.id = this.id;
        account.username = this.username;
        account.password = this.password;
        account.mail = this.mail;
        account.name = this.name;
        account.groups = this.groups;
        return account;
    }
}
