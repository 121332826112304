import {
IsIP, IsNotEmpty, IsNumberString, MaxLength, MinLength,
} from "class-validator";
import {worlds} from "../../Utils/localization";

export default class Cam {
    id: string = "";

    @MinLength(3 , {
        message: worlds.titleTooShort,
    })
    @MaxLength(50 , {
        message: worlds.titleTooLong,
    })
    name: string = "";

    // @Contains("rtsp://", {
    //     message: worlds.containsRtsp
    // })
    @IsNotEmpty({
        message: worlds.thisFieldCannotBeEmpty,
    })
    url:string = "";

    // @Contains("rtsp://", {
    //     message: worlds.containsRtsp
    // })
    @IsNotEmpty({
        message: worlds.thisFieldCannotBeEmpty,
    })
    secondaryUrl:string = "";

    player:string = "";

    isArchive:boolean = false;

    isPtz:boolean = false;

    online:boolean = false;

    account:string = "";

    server:string = ""

    sound: string = "no"

    parents:Array<string> = []

    latlng:Array<bigint> = []

    faceApi:boolean = false

    guard:boolean = false

    polygon:string = ""

    archive:Array<string> = []

    urls:Array<string> = []

    detection:boolean=false;

    @IsIP(4,{
        message: worlds.errorIpAddress,
    })
    ip:string = "";

    login:string = "";

    password:string = "";

    description:string = "";

    model:string = "other";

    mac:string = "";

    rtsm: any = {};

    @IsNumberString({},{
        message: worlds.thisFieldMustContainOnlyNumericValue,
    })
    @IsNotEmpty({
        message: worlds.thisFieldCannotBeEmpty,
    })
    archiveDays:string = "1"

    serialize(cam:any) {
        this.id = cam?.id ?? "";
        this.name = cam?.name ?? "";
        this.url = cam?.url ?? "";
        this.secondaryUrl = cam?.secondary?.url ?? "";
        this.isArchive = cam?.archive?.enable ?? false;
        this.isPtz = cam?.ptz ?? false;
        this.account = cam?.account ?? "";
        this.server = cam?.server ?? "";
        this.parents = cam?.taxonomies ?? [];
        this.latlng = cam?.latlng ?? [];
        this.faceApi = cam?.services?.faceapi ?? false;
        this.guard = cam?.services?.guard ?? false;
        this.description = cam?.data?.description ?? "";
        this.model = cam?.data?.model ?? "other";
        this.archiveDays = `${cam?.archive?.days}` ?? "0";
        this.rtsm = cam?.api_connection?.rtsm;
        this.sound = cam?.api_connection?.brc?.details?.acodec
    }

    deserialize() {
        const cam:any = {};
        cam.id = this.id;
        cam.name = this.name;
        cam.url = this.url;
        cam.ptz = this.isPtz
        cam.account = this.account
        cam.server = this.server
        cam.taxonomies = this.parents
        cam.latlng = this.latlng
        cam.secondary = {url: this.secondaryUrl};
        cam.services = {faceapi: this.faceApi,guard: this.guard}
        cam.data = {description: this.description,model: this.model}
        cam.api_connection = {brc: {details: {max_keep_days: parseInt(this.archiveDays, 10)}}};
        cam.archive = {days: this.archiveDays,enable: this.isArchive};
        cam.api_connection.brc.details.acodec = this.sound;
        cam.api_connection.brc.details.stream_acodec = this.sound;
        cam.api_connection.rtsm = this.rtsm;

        return cam;
    }
}
