import React from "react";
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {withStyles} from '@material-ui/core';
import {connect} from "react-redux";
import style from "./ModalChangeLanguage.module.scss"
import RadioButton from "../../Buttons/RadioButton/RadioButton";

import ButtonRectangle from "../../Buttons/ButtonRectangle/ButtonRectangle";
import {worlds} from "../../../Utils/localization";
import {changeLanguage} from "./actions";
import {closeModal} from "../actions";

const FormControlLabelM = withStyles({
    root: {
        '& .MuiFormControlLabel-label': {
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: "0.94vw",
            color: "#8C939B",
        },
        '& .Mui-checked + .MuiFormControlLabel-label': {
                color: "#EAEAEA",
        },
    },
})(FormControlLabel)

class ModalChangeLanguage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentLanguage: this.props.currentLanguage,
        }
    }

    render() {
        return (
          <div className={style.modalChangeLanguage}>
            <RadioGroup style={{width: "23.44vw", display: "flex", justifyContent: "space-between"}} row onChange={(e) => this.setState({currentLanguage: e.target.value})} defaultValue={this.state.currentLanguage} aria-label="language" name="customized-radios">
              <FormControlLabelM value="ru" control={<RadioButton />} label="Русский" inputProps={{color: "white"}} />
              <FormControlLabelM value="kk" control={<RadioButton />} label="Қазақ" />
              <FormControlLabelM value="en" control={<RadioButton />} label="English" />
            </RadioGroup>
            <div className={style.controls}>
              <ButtonRectangle width="7.08vw" caption={worlds.save} action={() => { changeLanguage(this.state.currentLanguage); closeModal(); }} />
              <ButtonRectangle width="7.08vw" dark caption={worlds.cancel} action={() => closeModal()} />
            </div>
          </div>
        )
    }
}

export default connect((store) => store,null)(ModalChangeLanguage)
