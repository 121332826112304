import {withStyles} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";
import TextFieldAutoComplete from "./TextFieldAutoComplete";

const AutoCom = withStyles({
    paper: {
        borderRadius: 0,
        backgroundColor: "#3C4347",
        maxHeight: "10rem",
        overflowY: "scroll",
        color: "#EAEAEA",
    },
    option: {
        fontFamily: "Inter",
        fontSize: "0.73vw",
        '&:hover': {
            backgroundColor: "#2A2F32",
        },
    },
    popper: {
        zIndex: 99999,
    },
    clearIndicator: {
        transform: "scale(0.8)",
        color: "#8C939B",
    },
    popupIndicator: {

        color: "#8C939B",
    },
})(Autocomplete);

export default class AutocompleteM extends React.Component {
    render() {
        return (
          <AutoCom
            clearText=""
            openText=""
            closeText=""
            autoSelect
            defaultValue={this.props.defaultValue}
            value={this.props.value}
            onChange={this.props.onChange}
            disablePortal={false}
            inputValue={this.props.inputValue}
            onInputChange={this.props.onInputChange}
            options={this.props.options}
            style={this.props.style}
            disableClearable={this.props.disableClearable}
            renderInput={(params) => <TextFieldAutoComplete disabled={this.props.select} {...params} variant="outlined" style={{width: "100%", margin: 0}} />}
          />
        )
    }
}
