import React from "react";
import {connect} from "react-redux";
import {InputAdornment} from "@material-ui/core";
import moment from "moment-timezone";
import style from "./Devices.module.scss";
import {worlds} from "../../../Utils/localization";
import SearchIcon from "../../../Assets/icons/search.svg";
import TextFieldM from "../../../Components/Inputs/TextFieldM";
import ButtonAddCam from "../../../Components/Buttons/ButtonAddCam/ButtonAddCam";
import RuleItem from "./RuleItem/RuleItem";

class Devices extends React.Component {
    test = [{id: "1", date: new Date(moment().format())},
        {id: "2", date: new Date(2020, 7,11,11,56,0)}];

    render() {
        return (
          <div className={style.devices}>
            <div className={style.header}>
              <TextFieldM
                type="text"
                variant="outlined"
                style={{height: "100%",margin: "0",width: "83%"}}
                        // value={this.state.searchText}
                placeholder={worlds.searchByRules}
                        // onChange={(e)=>this.setState({searchText:e.target.value})}
                InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <img style={{width: "0.94vw"}} src={SearchIcon} />
                              </InputAdornment>
                            ),
                        }}
              />
              <ButtonAddCam href="/createReaction" caption={worlds.addReaction} width="12.24vw" height="100%" margin="0" />
            </div>
            <div className={style.ruleList}>
              <div className={style.headList}>
                <div className={style.headItem} style={{width: "13.38vw"}}>{worlds.dateTime}</div>
                <div className={style.headItem} style={{width: "14.79vw"}}>{worlds.device}</div>
                <div className={style.headItem} style={{width: "23.44vw"}}>{worlds.ruleName}</div>
                <div className={style.headItem} style={{width: "23.44vw"}}>{worlds.reaction}</div>
                <div className={style.headItem} style={{width: "2.13vw"}} />
              </div>
              {this.test.length > 0 ? this.test.map((item, i) => <RuleItem rule={item} key={i} />) : <div className={style.noData}>{worlds.addRulesToSee}</div>}
            </div>
          </div>
        )
    }
}

export default connect((state) => state,null)(Devices)
