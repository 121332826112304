import React, {useState} from "react";
import {connect} from "react-redux";
import style from "./AdditionalSettings.module.scss";
import {worlds} from "../../../Utils/localization";
import iconPlay from "../../../Assets/icons/play.svg";
import iconStop from "../../../Assets/icons/stop.svg";
import iconRefresh from "../../../Assets/icons/renewal.svg";
import ButtonSquare from "../../../Components/Buttons/ButtonSquare/ButtonSquare";
import Switch from "../../../Components/SwitchM/SwitchM";
import {
    disableService,
    enableService,
    restartService,
    startService,
    statusService,
    stopService,
} from "../../../api/globalActions";

const Item = (props) => {
    const [status,setStatus] = useState(false);
    const [autoload,setAutoload] = useState(false);

    const checkStatus = async () => {
        const res = await statusService(props.name)
        setStatus((prevState) => {
            prevState = res.enable;
            return prevState;
        })
        setAutoload((prevState) => {
            prevState = res.autoload;
            return prevState;
        })
    }

    const changeStatus = async () => {
        if (autoload) {
            await disableService(props.name);
            setAutoload(false);
        } else {
            await enableService(props.name);
            setAutoload(true);
        }
    }

    return (
      <div className={style.item}>
        <div className={style.status} style={{width: "100%"}}>{props.name}</div>
        {props.status
                ? (
                  <div
                    className={style.status}
                    style={{width: "10.31vw", color: status ? "green" : "red"}}
                  >
                    {status ? "active" : "stopped"}
                  </div>
)
                : (
                  <div
                    className={style.status}
                    style={{width: "10.31vw"}}
                  />
)}
        {props.status
            ? status
                ? (
                  <div className={style.status} style={{minWidth: "13.475vw"}}>
                    {props.stop && <ButtonSquare action={() => { stopService(props.name); props.status && checkStatus() }} name="Stop" iconWidth="1.75vw" iconHeight="1vw" width="1.75vw" height="1.75vw" icon={iconStop} />}
                    {props.restart && <ButtonSquare action={() => restartService(props.name)} name="Restart" iconWidth="1.75vw" iconHeight="1vw" width="1.75vw" height="1.75vw" icon={iconRefresh} />}
                  </div>
)
                : (
                  <div className={style.status} style={{minWidth: "13.475vw"}}>
                    {props.start && <ButtonSquare action={() => { startService(props.name); props.status && checkStatus() }} name="Start" iconWidth="1.75vw" iconHeight="1vw" width="1.75vw" height="1.75vw" icon={iconPlay} />}
                  </div>
)
                : (
                  <div className={style.status} style={{minWidth: "13.475vw"}}>
                    {props.restart && <ButtonSquare action={() => { restartService(props.name); props.status && checkStatus() }} name="Restart" iconWidth="1.75vw" iconHeight="1vw" width="1.75vw" height="1.75vw" icon={iconRefresh} />}
                    {props.start && (
                    <>
                      <ButtonSquare action={() => { stopService(props.name); props.status && checkStatus() }} name="Stop" iconWidth="1.75vw" iconHeight="1vw" width="1.75vw" height="1.75vw" icon={iconStop} />
                      <ButtonSquare action={() => { startService(props.name); props.status && checkStatus() }} name="Start" iconWidth="1.75vw" iconHeight="1vw" width="1.75vw" height="1.75vw" icon={iconPlay} />
                    </>
)}
                  </div>
)}
        <div className={style.status} style={{minWidth: "8.475vw"}}>
          {props.autoload
                    ? <Switch onChange={() => changeStatus()} checked={autoload} />
                    : "-"}
        </div>
        <div className={style.status} style={{minWidth: "5vw"}}>
          <ButtonSquare name={worlds.update} icon={iconRefresh} action={() => checkStatus()} />
        </div>
      </div>
    )
}

export default connect((state) => state,null)(Item);
