import {connect} from 'react-redux';
import Plans from './Plans';

const mapStateToProps = (state) => state

const mapDispatchToProps = (dispatch) => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(Plans);
