import React from "react";
import {connect} from "react-redux";
import style from "./ModalAddScreen.module.scss"
import ButtonRectangle from "../../Buttons/ButtonRectangle/ButtonRectangle";
import {worlds} from "../../../Utils/localization";
import {closeModal} from "../actions";
import {createOpenScreenTriggerEgsv} from "../../../api/state/guard/actions";
import TextFieldM from "../../Inputs/TextFieldM";

class ModalAddScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenId: "",
            host: "",
        }
    }

    addScreen = () => {
        createOpenScreenTriggerEgsv(this.state.screenId,this.state.host);
    }

    render() {
        return (
          <div className={style.modalAddScreen}>
            <div className={style.content}>
              <TextFieldM
                style={{margin: "0.42vw 0", width: "100%"}}
                variant="outlined"
                value={this.state.screenId}
                placeholder="Screen ID"
                onChange={(e) => this.setState({screenId: e.target.value})}
              />
              <TextFieldM
                variant="outlined"
                style={{margin: "0.42vw 0 1.87vw", width: "100%"}}
                value={this.state.host}
                placeholder={worlds.hostname}
                onChange={(e) => this.setState({host: e.target.value})}
              />
            </div>
            <div className={style.controls}>
              <ButtonRectangle width="15.55rem" caption={worlds.addScreenID} action={() => { this.addScreen(); closeModal() }} />
              <ButtonRectangle width="7.55rem" dark caption={worlds.cancel} action={() => closeModal()} />
            </div>
          </div>
        )
    }
}

export default connect((state) => state,null)(ModalAddScreen)
