import React from 'react';
import CacheRoute, {CacheSwitch} from "react-router-cache-route/index";
import {connect} from "react-redux";
import style from './Events.module.scss';

import Alarm from './Alarm/Alarm';
import Reactions from './Reactions/Reactions';
import ButtonTopMenu from "../../Components/Buttons/ButtonTopMenu/ButtonTopMenu";
import {worlds} from "../../Utils/localization";
import Switching from "./Switching/Switching";
import AlarmSwitching from "./AlarmSwitching/AlarmSwitching";

function Events(props) {
    return (
      <div className={style.eventsContainer}>
        <div className={style.menu}>
          <ButtonTopMenu caption={worlds.disturbingEvents} href="/events" />
          {props.currentAccount.username !== "user" && <ButtonTopMenu caption={worlds.reactionsOnEvents} href="/events/reactions" />}
          {props.currentAccount.username !== "user" && <ButtonTopMenu caption={worlds.switchingModule} href="/events/switching" />}
          <ButtonTopMenu caption={worlds.switchModuleEvents} href="/events/alarmSwitching" />
        </div>
        <CacheSwitch>
          <CacheRoute path="/events/" exact><Alarm /></CacheRoute>
          {props.currentAccount.username !== "user" && <CacheRoute path="/events/reactions"><Reactions /></CacheRoute>}
          {props.currentAccount.username !== "user" && <CacheRoute path="/events/switching"><Switching /></CacheRoute>}
          <CacheRoute path="/events/alarmSwitching"><AlarmSwitching /></CacheRoute>
        </CacheSwitch>
      </div>
    );
}

export default connect((state) => state,null)(Events);
