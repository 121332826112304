import React from "react";
import PopupState, {bindPopover, bindTrigger} from "material-ui-popup-state";
import Popover from "@material-ui/core/Popover";
import {worlds} from "../../../Utils/localization";
import ButtonSquare from "../../../Components/Buttons/ButtonSquare/ButtonSquare";
import style from "./ScreenPatternMenu.mudule.scss"
import screensPatternsIcons from "../../../Assets/icons/screensPatterns.png";
import patter1x1Icon from "../../../Assets/icons/pattern1x1.svg"
import patter2x2Icon from "../../../Assets/icons/pattern2x2.svg"
import patter3x3Icon from "../../../Assets/icons/pattern3x3.svg"
import patter4x4Icon from "../../../Assets/icons/pattern4x4.svg"

export default class ScreenPatternMenu extends React.Component {
    render() {
        return (
          <PopupState variant="popover">
            {(popupState) => (
              <div className={style.screenPatternMenu}>
                <div {...bindTrigger(popupState)}>
                  <ButtonSquare name={worlds.screenPattern} width="1.87vw" height="1.87vw" icon={screensPatternsIcons} />
                </div>
                <Popover
                  onClick={() => popupState.close()}
                  {...bindPopover(popupState)}
                  anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                  transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                  PaperProps={{
                                className: style.menu,
                            }}
                >
                  <ButtonSquare width="3.75vw" height="3.75vw" icon={patter1x1Icon} name="1x1" action={() => this.props.acceptPattern("1x1")} />
                  <ButtonSquare width="3.75vw" height="3.75vw" icon={patter2x2Icon} name="2x2" action={() => this.props.acceptPattern("2x2")} />
                  <ButtonSquare width="3.75vw" height="3.75vw" icon={patter3x3Icon} name="3x3" action={() => this.props.acceptPattern("2x8")} />
                  <ButtonSquare width="3.75vw" height="3.75vw" icon={patter4x4Icon} name="4x4" action={() => this.props.acceptPattern("4x4")} />
                </Popover>
              </div>
                )}
          </PopupState>
        )
    }
}
