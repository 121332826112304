import {combineReducers} from 'redux'
import {cells} from "./Containers/Screens/reducer";
import {snackbar} from "./Containers/Header/reducer";
import {
api, currentLanguage, loader, modal, modalContent, serverBrc, serverDisconnect, serverTime,
} from "./api/reducer"
import {currentPlan, editPlan, plans} from "./api/state/plan/reducer"
import {currentTaxonomy, editTaxonomy, taxonomies} from "./api/state/taxonomy/reducer";
import {cams, currentCam, editCam} from "./api/state/cam/reducer";
import {accounts, currentAccount, editAccount} from "./api/state/account/reducer";
import {
    faceApi,
    faceApiConfig,
    faceApiSearch,
    faceApiSearchData,
    faceApiSearchProgress,
} from "./api/state/faceApi/reducer";
import {networkConfig} from "./api/state/network/reducer";
import {
currentTrigger, editTrigger, reactions, triggers,
} from "./api/state/trigger/reducer";
import {
guard, guardCamView, guardCount, guardDetections,
} from "./api/state/guard/reducer";
import {ntpConfig} from "./api/state/ntp/reducer";
import {downloadFiles, fileManagerPath, fileManagerStore} from "./api/state/fileManager/reducer";
import {keyboardEnable, keyboardInputRef, keyboardShow} from "./api/state/keyboard/reducer";
import {dhcpClients} from "./api/state/dhcp/reducer";
import {disks} from "./api/state/fileSystem/reducer";
import {logs} from "./api/state/logs/reducer";
import {sounds, switchingEvents} from "./api/state/switching/reducer";
import {systemConfig} from "./api/state/system/reducer";

export default () => combineReducers({
    cells,
    snackbar,
    api,
    serverDisconnect,
    loader,
    modal,
    modalContent,
    plans,
    editPlan,
    currentPlan,
    taxonomies,
    editTaxonomy,
    serverBrc,
    currentTaxonomy,
    cams,
    currentCam,
    editCam,
    accounts,
    currentAccount,
    editAccount,
    faceApi,
    faceApiConfig,
    faceApiSearchData,
    currentLanguage,
    networkConfig,
    triggers,
    editTrigger,
    currentTrigger,
    reactions,
    guard,
    guardDetections,
    guardCount,
    guardCamView,
    ntpConfig,
    faceApiSearch,
    fileManagerPath,
    fileManagerStore,
    faceApiSearchProgress,
    keyboardEnable,
    keyboardShow,
    keyboardInputRef,
    downloadFiles,
    dhcpClients,
    serverTime,
    disks,
    logs,
    switchingEvents,
    sounds,
    systemConfig,
});
