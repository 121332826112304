import React from "react";
import {InputAdornment} from "@material-ui/core";
import style from "./Header.module.scss";
import ButtonSquare from "../../../../Components/Buttons/ButtonSquare/ButtonSquare";
import searchIcon from "../../../../Assets/icons/search.svg";
import TextFieldM from "../../../../Components/Inputs/TextFieldM";
import {worlds} from "../../../../Utils/localization";
import cancelIcon from "../../../../Assets/icons/cancel.svg";

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search: false,
        }
    }

    changeSearch = () => {
        this.setState({search: true});
    }

    searchCancel() {
        this.props.searchAction({target: {value: ""}})
        this.setState({search: false});
    }

    render() {
        if (!this.state.search) {
            return (
              <div className={style.header}>
                <span>{this.props.caption}</span>
                <ButtonSquare action={this.changeSearch} width="1.87vw" height="1.87vw" icon={searchIcon} />
              </div>
            )
        }
            return (
              <div className={style.searchInput}>
                <TextFieldM
                  type="text"
                  variant="outlined"
                  style={{height: "2.92vw", width: "100%", margin: "0"}}
                  placeholder={worlds.search}
                  onChange={this.props.searchAction}
                  InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <img style={{width: "0.94vw"}} src={searchIcon} />
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment position="end" onClick={() => this.searchCancel()}>
                                <img className={style.cancelSearch} src={cancelIcon} />
                              </InputAdornment>
                            ),
                        }}
                />
              </div>
            )
    }
}

export default Header;
