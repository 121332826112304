import React, {useEffect, useState} from "react";
import {useDrop} from 'react-dnd'
import style from "./Cell.module.scss";
// import Player from "../../../Components/Player/Player";
import Player from "../../../Components/PlayerMSE/Player";
import {worlds} from "../../../Utils/localization";
import ButtonSquare from "../../../Components/Buttons/ButtonSquare/ButtonSquare";
import plusIcon from "../../../Assets/icons/plusSmall.svg"
import minusIcon from "../../../Assets/icons/minusSmall.svg"
import cancelIcon from "../../../Assets/icons/cancel.svg"
import {changeCell, changeSize, ItemTypes} from "./actions"
import {emitter} from "../../../Utils/globalEmitter";
import {getCam, getPlayer} from "../../../api/state/cam/actions";

const Cell = (props) => {
    const [cam,setCam] = useState()
    const [cell,setCell] = useState(props.item)
    const [showMenu,setShowMenu] = useState(false);
    const [menuTimeOut,setMenuTimeOut] = useState(null)

    const [{ isOver}, drop] = useDrop({
        accept: ItemTypes.CAM,
        drop: async (obj,monitor,ct) => {
            const didDrop = monitor.didDrop()
            if (didDrop && !monitor.getItem()) {
                return
            }
            cell.camId = monitor.getItem().id;
            const cam = await getCam(cell.camId);
            setCam((prevState) => {
                prevState = cam
                return {...prevState};
            })
            changeCell(cell)
            return { name: 'Dustbin' }
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            isOverCurrent: monitor.isOver({ shallow: true }),
            canDrop: monitor.canDrop(),
            item: monitor.getItem(),
        }),
    })

    useEffect(() => {
        if (cell !== props.item) {
            setCell(props.item)
        }
    })
    const checkCam = async () => {
        if (cell.camId) {
            try {
                const cam = await getCam(cell.camId);
                cam.player = await getPlayer(cell.camId)
                setCam(cam);
            } catch (e) {
                clearCellCurrent();
            }
        }
    }

    useEffect(() => {
        checkCam()
        emitter.addListener("clearAllCells",() => {
            clearCellCurrent()
        })
        emitter.addListener("camDelete",(camEvent) => {
            if (cell.camId === camEvent.id) {
                clearCellCurrent()
            }
        })
    },[])

    const clearCellCurrent = () => {
        setCell((prevState) => {
            prevState.url = ""
            prevState.camId = ""
            changeCell(prevState)
            setCam(null)
            return prevState;
        });
    }

    const showMenuTimeOut = () => {
        setMenuTimeOut((prevState) => {
            clearInterval(prevState);
            prevState = setTimeout(() => {
                setShowMenu(false)
            }, 10000)
            return prevState;
        })
    }

    const styles = {
        width: `${cell.width}%`,
        height: `${cell.height}%`,
        order: cell.order,
    }
    return (
      <div ref={drop} className={`${style.cell} ${isOver && style.hoveCam}`} style={{...styles,fontSize: cell.size > 1 ? "2em" : "1em",order: props.order}}>
        {!cam && (
          <div className={style.closeButton}>
            <ButtonSquare width="2.25rem" height="2.25rem" icon={cancelIcon} iconHeight="40%" iconWidth="40%" action={() => props.dropItem(cell.id)} fill="#222324" radius="0.1rem" />
          </div>
)}
        <div onClick={() => showMenuTimeOut()} className={`${style.controls} ${showMenu && style.controlsShow}`}>
          <div onClick={() => setShowMenu(!showMenu)} className={style.cellMenu}><div className={style.cellMenuIcon} /></div>
          <div className={style.cellMenuControls}>
            <ButtonSquare width="2.05vw" height="2.05vw" icon={plusIcon} iconHeight="40%" iconWidth="40%" action={() => changeSize(cell,true)} fill="#222324" radius="0.1rem" />
            <ButtonSquare width="2.05vw" height="2.05vw" icon={minusIcon} iconHeight="40%" iconWidth="40%" action={() => changeSize(cell,false)} fill="#222324" radius="0.1rem" />
          </div>
        </div>
        {cam ? <Player close={clearCellCurrent} cam={cam} videoKey={props.cellId} />
                : <div className={style.caption}>{worlds.dragCameraToWindow}</div>}
      </div>
    )
}

export default Cell
