import React from "react";
import {connect} from "react-redux";
import * as _ from "lodash";
import {withRouter} from "react-router";
import style from "./AddCam.module.scss"
import {worlds} from "../../../Utils/localization";
import TextFieldM from "../../../Components/Inputs/TextFieldM";
import SwitchM from "../../../Components/SwitchM/SwitchM";
import Cam from "../../../api/models/Cam";
import {createEdit} from "../../../api/state/cam/actions";
import AutocompleteM from "../../../Components/Inputs/AutocompleteM";
import {patterns} from "../../../api/consts";
import {parseRtsp} from "../../../api/globalActions";
import ButtonTopMenu from "../../../Components/Buttons/ButtonTopMenu/ButtonTopMenu";

const soundCodecs = [
    "no",
    "copy",
    "aac",
]

class AddCamOne extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 1,
        }
    }

    async componentDidMount() {
        if (!this.props.edit) {
            const cam = new Cam();
            await createEdit(cam)
        }
        if (!this.props.edit && this.props.match.params.rtsp) {
            await this.editCam(decodeURIComponent(this.props.match.params.rtsp) ,"url");
            await this.editCam(decodeURIComponent(this.props.match.params.rtsp2) ,"secondaryUrl");
            await this.editCam(decodeURIComponent(this.props.match.params.ip) ,"ip");
            await this.editCam(decodeURIComponent(this.props.match.params.mac) ,"mac");
        }
    }

   async componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.match?.params?.rtsp !== this.props.match?.params?.rtsp) {
            if (this.props.match?.params?.rtsp) {
                await this.editCam(decodeURIComponent(this.props.match.params.rtsp) ,"url");
                await this.editCam(decodeURIComponent(this.props.match.params.rtsp2) ,"secondaryUrl");
                await this.editCam(decodeURIComponent(this.props.match.params.ip) ,"ip");
                await this.editCam(decodeURIComponent(this.props.match.params.mac) ,"mac");
            } else {
                await this.editCam("" ,"url");
                await this.editCam("","ip");
                await this.editCam("","mac");
                await this.editCam("","secondaryUrl");
            }
        }
        if ((prevProps.editCam?.url !== this.props.editCam?.url)) {
            const camAuth = parseRtsp(this.props.editCam?.model,this.props.editCam?.url);
            if (camAuth) {
                await this.editCam(camAuth.hostname ,"ip");
                await this.editCam(camAuth.username ,"login");
                await this.editCam(camAuth.password ,"password");
            }
        }
    }

    switch = () => {
       const cam = this.props.editCam;
       cam.isArchive = !cam.isArchive;
       createEdit(cam)
    }

    switchApi = () => {
        const cam = this.props.editCam;
        cam.faceApi = !cam.faceApi;
        createEdit(cam)
    }

    switchSound = (codec) => {
        const cam = this.props.editCam;
        cam.sound = codec;
        createEdit(cam)
    }

    switchGuard = () => {
        const cam = this.props.editCam;
        cam.guard = !cam.guard;
        createEdit(cam)
    }

    editCam = async (val,name) => {
        const cam = this.props.editCam;
        _.set(cam,name,val);
        if (name === "model") {
            const camAuth = parseRtsp(cam.model,cam.url);
            if (camAuth) {
                cam.ip = camAuth.hostname;
                cam.login = camAuth.username;
                cam.password = camAuth.password;
            }
        }
        await createEdit(cam)
    }

    render() {
        const cam = this.props.editCam;
        if (!cam) return null;
        const {errors} = this.props;
        return (
          <div className={style.addCamForm}>
            <div className={style.header}>
              <ButtonTopMenu style={{width: "15vw"}} active={this.state.type === 1} action={() => this.setState({type: 1})} caption={worlds.basicSettings} />
              <ButtonTopMenu style={{width: "15vw"}} active={this.state.type === 2} action={() => { this.setState({type: 2}) }} caption={worlds.additionalSettings} />
            </div>
            {this.state.type === 1
                && (
                <>
                  {!this.props.match?.params?.mac
                        && (
                        <>
                          <label>
                            {worlds.cameraModel}
                            <span>*</span>
                          </label>
                          <AutocompleteM
                            value={patterns.find((i) => i.name === cam.model).name}
                            onChange={(event, newValue) => {
                                this.editCam(patterns.find((i) => i.name === newValue).name,"model")
                            }}
                            inputValue={patterns.find((i) => i.name === cam.model).name}
                            options={patterns.map((i) => i.name)}
                            disableClearable
                          />
                          {cam.model === "other"
                            ? (
                              <>
                                <label>
                                  {worlds.streamURL}
                                  <span>*</span>
                                </label>
                                <TextFieldM
                                  name="url"
                                  errors={errors}
                                  value={cam.url}
                                  onChange={(e) => { this.editCam(e.target.value,"url") }}
                                  placeholder="rtsp:// or rtmp://"
                                  variant="outlined"
                                  style={{width: "100%", height: "2.92vw"}}
                                />
                                <label>
                                  {worlds.secondStreamURL}
                                  <span>*</span>
                                </label>
                                <TextFieldM
                                  name="secondaryUrl"
                                  errors={errors}
                                  value={cam.secondaryUrl}
                                  onChange={(e) => { this.editCam(e.target.value,"secondaryUrl") }}
                                  placeholder="rtsp:// or rtmp://"
                                  variant="outlined"
                                  style={{width: "100%", height: "2.92vw"}}
                                />
                              </>
)

                        : (
                          <>
                            <label>
                              {worlds.ipAddress}
                              <span>*</span>
                              <small>{}</small>
                            </label>
                            <TextFieldM
                              name="ip"
                              errors={errors}
                              value={cam.ip}
                              onChange={(e) => { this.editCam(e.target.value,"ip") }}
                              placeholder={worlds.ipAddress}
                              variant="outlined"
                              style={{width: "100%", height: "2.92vw"}}
                            />
                            <label>
                              {worlds.login}
                              <small>{}</small>
                            </label>
                            <TextFieldM
                              name="login"
                              errors={errors}
                              value={cam.login}
                              onChange={(e) => { this.editCam(e.target.value,"login") }}
                              placeholder={worlds.login}
                              variant="outlined"
                              style={{width: "100%", height: "2.92vw"}}
                            />
                            <label>
                              {worlds.password}
                              <small>{}</small>
                            </label>
                            <TextFieldM
                              name="password"
                              errors={errors}
                              value={cam.password}
                              onChange={(e) => { this.editCam(e.target.value,"password") }}
                              placeholder={worlds.password}
                              variant="outlined"
                              style={{width: "100%", height: "2.92vw"}}
                            />
                          </>
)}
                        </>
)}
                  <label>
                    {worlds.camcorderName}
                    <span>*</span>
                    <small>{}</small>
                  </label>
                  <TextFieldM
                    name="name"
                    errors={errors}
                    value={cam.name}
                    onChange={(e) => { this.editCam(e.target.value,"name") }}
                    placeholder={worlds.camcorderName}
                    variant="outlined"
                    style={{width: "100%", height: "2.92vw"}}
                  />
                  <div style={{backgroundColor: cam.isArchive ? "#3C4347" : "#2A2F32"}} className={style.record}>
                    <div className={style.recordCaption}>{worlds.enableRecording}</div>
                    <SwitchM checked={cam.isArchive} onChange={() => this.switch()} />
                  </div>

                  <div className={style.withHide} style={{maxHeight: !cam.isArchive && 0}}>
                    <label>
                      {worlds.archiveStoragePeriod}
                      <small>{worlds.days}</small>
                    </label>
                    <TextFieldM
                      value={cam.archiveDays}
                      onChange={(e) => this.editCam(e.target.value,"archiveDays")}
                      placeholder={worlds.archiveStoragePeriod}
                      variant="outlined"
                      name="archiveDays"
                      errors={errors}
                      style={{width: "100%", height: "2.92vw"}}
                    />
                  </div>
                </>
)}
            {this.state.type === 2
                && (
                <>
                  <label>{worlds.cameraCoordinates}</label>
                  <div className={style.line}>
                    <TextFieldM
                      value={cam.latlng?.[0]}
                      onChange={(e) => this.editCam(e.target.value,"latlng[0]")}
                      placeholder={worlds.latitude}
                      variant="outlined"
                      style={{width: "50%", height: "2.92vw"}}
                    />
                    <TextFieldM
                      value={cam.latlng?.[1]}
                      onChange={(e) => { if (!cam.latlng?.[0]) this.editCam(0,"latlng[0]"); this.editCam(e.target.value,"latlng[1]") }}
                      placeholder={worlds.longitude}
                      variant="outlined"
                      style={{width: "50%", height: "2.92vw"}}
                    />
                  </div>
                  <label>
                    {worlds.description}
                    <small>{}</small>
                  </label>
                  <TextFieldM
                    value={cam.description}
                    onChange={(e) => this.editCam(e.target.value, "description")}
                    placeholder={worlds.description}
                    variant="outlined"
                    style={{width: "100%", height: "2.92vw"}}
                  />
                  <label>{worlds.sound}</label>
                  <AutocompleteM
                    value={soundCodecs.find((i) => i === cam.sound)}
                    onChange={(event, newValue) => {
                            this.switchSound(soundCodecs.find((i) => i === newValue))
                        }}
                    inputValue={soundCodecs.find((i) => i === cam.sound)}
                    options={soundCodecs}
                    disableClearable
                  />
                  {this.props.systemConfig.services.faceapi
                    && (
                    <div style={{backgroundColor: cam.faceApi ? "#3C4347" : "#2A2F32"}} className={style.record}>
                      <div className={style.recordCaption}>{worlds.faceDetection}</div>
                      <SwitchM checked={cam.faceApi} onChange={() => this.switchApi()} />
                    </div>
)}

                  <div style={{backgroundColor: cam.guard ? "#3C4347" : "#2A2F32"}} className={style.record}>
                    <div className={style.recordCaption}>{worlds.motionDetector}</div>
                    <SwitchM checked={cam.guard} onChange={() => this.switchGuard()} />
                  </div>
                </>
)}
          </div>
        )
    }
}

export default withRouter(connect((state) => state,null)(AddCamOne))
