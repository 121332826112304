import React from "react";
import {connect} from "react-redux";
import style from "./TopMenu.module.scss"
import ButtonTopMenu from "../Buttons/ButtonTopMenu/ButtonTopMenu";
import {worlds} from "../../Utils/localization";
import Profile from "../Profile/Profile";
import AlarmMonitor from "../AlarmMonitor/AlarmMonitor";
import iconGuardOn from "../../Assets/icons/guardOn.svg"
import iconGuardOff from "../../Assets/icons/guardOff.svg"
import ButtonSquare from "../Buttons/ButtonSquare/ButtonSquare";

class TopClass extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
          <div className={style.topMenu}>
            <ButtonTopMenu caption={worlds.screens} href="/" />
            <ButtonTopMenu caption={worlds.plans} href="/plans" />
            <ButtonTopMenu caption={worlds.events} href="/events" />
            {this.props.systemConfig.services.faceapi && <ButtonTopMenu caption={worlds.faceDetection} href="/faceDetection" />}
            {this.props.currentAccount.username !== "user" && <ButtonTopMenu caption={worlds.controlPanel} href="/settings" />}
            <ButtonTopMenu caption={worlds.security} href="/security" />
            <ButtonTopMenu caption={worlds.monitoring} href="/monitoring" />
            <div className={style.guard}>
              <ButtonSquare name={this.props.guard ? worlds.armedSystem : worlds.systemNotArmed} width="1.46vw" height="1.46vw" icon={this.props.guard ? iconGuardOn : iconGuardOff} iconHeight="1.46vw" iconWidth="1.46vw" />
            </div>
            <AlarmMonitor />
            <Profile
              userName={this.props.currentAccount.username}
            />
          </div>
        )
    }
}

export default connect((state) => state,null)(TopClass)
