import React from "react";
import {connect} from "react-redux";
import style from "./MotionDetector.module.scss";
import HeaderSetup from "../HeaderSetup/HeaderSetup";
import {worlds} from "../../../Utils/localization";
import SwitchM from "../../../Components/SwitchM/SwitchM";
import TextFieldM from "../../../Components/Inputs/TextFieldM";
import ButtonRectangle from "../../../Components/Buttons/ButtonRectangle/ButtonRectangle";

class MotionDetector extends React.Component {
    render() {
        return (
          <div className={style.motionDetector}>
            <HeaderSetup caption={worlds.motionDetectorSetup} />
            <div className={style.content}>
              <div className={style.switch}>
                <div className={style.caption}>{worlds.enableMotionDetector}</div>
                <SwitchM />
              </div>
              <label>{worlds.pixelSizeThreshold}</label>
              <TextFieldM
                name="pixelSize"
                variant="outlined"
                style={{width: "100%", height: "2.92vw", margin: "0"}}
              />
              <label>{worlds.noiseReductionLevel}</label>
              <TextFieldM
                name="noiseReduction"
                variant="outlined"
                style={{width: "100%", height: "2.92vw", margin: "0"}}
              />
              <div className={style.button}>
                <ButtonRectangle caption={worlds.save} height="2.92vw" width="10.31vw" />
              </div>
            </div>
          </div>
        )
    }
}

export default connect((state) => state,null)(MotionDetector)
