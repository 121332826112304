import LocalizedStrings from 'react-localization';

export const worlds = new LocalizedStrings({
    en: {
        action: "Action",
        add: "Add",
        addAudioFile: "Add audio file",
        addCamcorder: "Add camcorder",
        addCamera: "Add camera",
        addCell: "Add cell",
        addDevice: "Add device",
        addDevicesToSee: "Add devices to see them here",
        addGroup: "Add group",
        additionalSettings: "Additional settings",
        addMotionZone: "Add motion zone",
        addPlan: "Add plan",
        addReaction: "Add reaction",
        addReactionsOnEvent: "Add reactions on events to see them here",
        addRule: "Add rule",
        addRulesToSee: "Add rules to see them here",
        addScreenID: "Add Screen ID",
        afterThisProcedureYouNeedToRestartTheCameras: "After this procedure, you need to restart the cameras",
        age: "Age",
        alarmIsOff: "Alarm is off",
        alarmMonitor: "Alarm monitor",
        app: "Application",
        apply: "To apply",
        archiveStoragePeriod: "Archive storage period",
        armedSystem: "Armed system",
        attentionAnxiety: "Attention! ANXIETY",
        attentionTechnicalWorkInProgress: "Attention! Technical work in progress!",
        authorization: "Authorization",
        authorisationError: "Authorisation Error",
        availableUpdates: "Available updates",
        basicSettings: "Basic settings",
        bindEmail: "Bind email",
        cameraAddedSuccessfully: "Camera added successfully",
        cameraAlreadyInCatalog: "Mistake! The camera is already in this catalog",
        cameraCoordinates: "Camera coordinates",
        camcorderName: "Camcorder name",
        cameraEditedSuccessfully: "Camera edited successfully",
        cameraList: "Camera list",
        cameraModel: "Camera model",
        cameraName: "Camera name",
        cameraRemovedSuccessfully: "Camera removed successfully",
        cameras: "Cameras",
        camera: "Camera",
        camerasSetup: "Camera setup",
        cancel: "Cancel",
        cancelAllCurrentDownloads: "Cancel all current downloads?",
        cameraRemoval: "Camera removal",
        capacity: "Capacity",
        changeUser: "Change user",
        choose: "Choose",
        chooseAll: "Choose all",
        chooseFile: "Choose file",
        chooseReaction: "Choose reaction",
        chooseWhichGroupAddCamera: "Choose which group to add the camera to",
        clearScreen: "Clear screen",
        clearTheList: "Clear the list",
        close: "Close",
        containsRtsp: "URL doesn't contain rtsp://",
        continueDownload: "Continue Download",
        controlPanel: "Control Panel",
        core: "Core",
        createScreenshot: "Create screenshot",
        dateDays: ["Mo","Tu","We","Th","Fr","Sa","Su"],
        dateMonth: ["January","February","March","April","May","June","July","August","September","October","November","December"],
        dateTime: "Date and time",
        days: "days",
        daysOfWeek: "Days of the week",
        default: "Default",
        delete: "Delete",
        deleteAudioFile: "Delete audio file",
        deleteGroup: "Delete group",
        deleteLicense: "Delete license",
        deletePlan: "Delete plan",
        deleteRule: "Delete rule",
        description: "Description",
        detections: "Detections",
        detectionSearchIsOver: "Detection search is over",
        device: "Device",
        devices: "Devices",
        disable: "Disable",
        disabled: "Disabled",
        diskManager: "Disk manager",
        diskMounted: "Disk mounted",
        diskSpace: "Disk space",
        diskUnmounted: "Disk unmounted",
        disturbingEvents: "Disturbing events",
        dnsServer: "DNS server",
        dragCameraToWindow: "Drag the camera to the window",
        dragCameraOrCamGroup: "Drag camera or camera group to the desired taxonomy section",
        drawingBoxArea: "Drawing the box (area) of face detection on the full image of the frame",
        download: "Download",
        edit: "Edit",
        editCamcorder: "Edit camcorder",
        editGroup: "Edit group",
        emotion: "Emotion",
        emptyField: "The field cannot be empty",
        emptyList: "The list is empty",
        enable: "Enable",
        enabled: "Enabled",
        enableMotionDetector: "Enable motion detector",
        enableRecording: "Enable recording",
        end: "End",
        endDateRecording: "End date and time of recording",
        enterPlanName: "Enter plan name",
        entry: "Entry",
        error: "Error",
        errorDnsServer: "DNS server error",
        errorIpAddress: "IP address error",
        errorMoveCamera: "Error! Cannot add camera",
        errorSubnetMask: "Subnet mask error",
        event: "Event",
        events: "Events",
        eventDescription: "Event description",
        eventType: "Event type",
        everyDay: "Everyday",
        exit: "Exit",
        expansionDetectionBox: "Expansion of the detection box (area) around the face",
        export: "Export",
        faceDetection: "Face detection",
        faceDetectionConfiguration: "Face detection configuration",
        faceRecognitionModelOptions: "Face recognition model options",
        fileManager: "File manager",
        filled: "Filled",
        filter: "Filter",
        firstStreamURL: "First stream URL",
        formatDrive: "Format drive",
        free: "Free",
        from: "From",
        fullScreen: "In full screen",
        gateway: "Gateway",
        gender: "Gender",
        goToMoment: "Go to the moment",
        groupFaceRecognition: "Group face recognition",
        groupName: "Group name",
        guarded: "Guarded",
        hide: "Hide",
        higherValueLeadsToArtifacts: "A higher value leads to artifacts in the image (recommended values are from 1 to 10)",
        hostname: "Hostname",
        inputSignals: "Input Signals",
        install: "Install",
        interfaceLanguage: "Interface language",
        ipAddress: "IP address",
        latitude: "Latitude",
        letterSubject: "Letter subject",
        level: "Level",
        license: "License",
        licensing: "Licensing",
        licenseActivated: "License activated",
        loading: "Loading",
        login: "Login",
        logs: "Logs",
        longitude: "Longitude",
        manually: "Manually",
        macAddress: "Mac address",
        minimizeVideoStreamDelay: "Minimize video stream delay",
        minimumAccuracy: "0.5 - minimum accuracy",
        monitoring: "Monitoring",
        mode: "Mode",
        motionDetector: "Motion detector",
        motionDetectorSetup: "Motion detector setup",
        mount: "Mount",
        ms: "ms",
        multipleSources: "Multiple sources",
        name: "Name",
        nationality: "Nationality",
        network: "Network",
        networkCard: "Network card",
        networkConfiguration: "Network configuration",
        networkInterface: "Network interface",
        noData: "No data",
        noiseReductionLevel: "Noise reduction level",
        ntpSetup: "NTP setup",
        numberOfFrames: "Number of frames per second",
        offPlan: "off plan",
        other: "Other",
        openAlarmMonitor: "Open alarm monitor",
        openScreen: "Open screen",
        openTerminal: "Open terminal",
        openVideoStream: "Open video stream",
        password: "Password",
        passwordChange: "Password change",
        period: "PeriodPicker",
        pixelSizeThreshold: "Pixel size threshold",
        planned: "Planned",
        plans: "Plans",
        port: "Port",
        primary: "Primary stream",
        priority: "Priority",
        processor: "Processor",
        ptz: "PTZ",
        put: "Put",
        putOnGuard: "Put on guard",
        ram: "RAM",
        reaction: "Reaction",
        reactionsOnEvents: "Ammutation on events",
        reboot: "Reboot",
        recording: "Recording",
        releaseSignal: "Release signal",
        removeCamera: "Remove camera",
        removeAllCameras: "Delete all cameras",
        remove: "Remove",
        removeGuard: "Remove guard",
        renewal: "Renewal",
        replaceImage: "Replace Image",
        reset: "Reset",
        resetSettings: "Reset settings",
        resetAllSettings: "Reset all settings",
        ruleDeletedSuccessfully: "Rule deleted successfully",
        ruleName: "Rule name",
        save: "Save",
        screen: "Screen",
        screenClearedSuccessfully: "Screen cleared successfully",
        screeningToCertainSize: "Screening to a certain size of the detection box (area) around the face",
        screenPattern: "Screen pattern",
        screenshot: "Screenshot",
        screens: "Screens",
        search: "Search",
        searchByCameras: "Search by cameras",
        searchByCameraName: "Search by camera name",
        searchByDescription: "Search by description",
        searchByEvents: "Search by events",
        searchByReactions: "Search by reactions",
        searchByRules: "Search by rules",
        sec: "sec",
        secondStreamURL: "Second stream URL",
        secondary: "Secondary",
        security: "Security",
        securityDrill: "Security drill",
        sendByEmail: "Send by email",
        sensitivity: "Sensitivity",
        server: "Server",
        serverAddress: "Server address",
        settings: "Settings",
        settingsSavedSuccessfully: "Settings saved successfully",
        scheduled: "Scheduled",
        show: "Show",
        singleSource: "Single source",
        smtpSetup: "SMTP setup",
        sound: "Sound",
        soundEvent: "Sound event",
        start: "Start",
        startDateRecorder: "Start date and time of recording",
        startSearchingForDetections: "Start searching for detections",
        startup: "Startup",
        state: "State",
        status: "Status",
        storageLimitOfTemporaryDescriptors: "Storage limit of temporary descriptors for face detection screening",
        streamURL: "Stream URL",
        streamURLorStreamList: "Stream URL or stream list",
        streamType: "Stream type",
        subnetMask: "Subnet mask",
        success: "Success",
        successfullyMovedToDirectory: "Successfully moved to directory",
        system: "System",
        systemCheck: "System check",
        systemMonitor: "System monitor",
        systemNotArmed: "System not armed",
        switchingModule: "Switching module",
        switchModuleEvents: "Switch Module Events",
        switchOff: "Switch off",
        taxonomy: "Taxonomy",
        terminal: "Terminal",
        theLatestVersionOfTheApplicationIsInstalled: "The latest version of the application is installed",
        thereWillBeAnUpdateOfTheSystem: "There will be an update of the system",
        thisFieldCannotBeEmpty: "This field cannot be empty",
        thisFieldMustContainOnlyNumericValue: "This field must contain only a numeric value",
        time: "Time",
        timeBeforeAutoClearing: "Time before automatically clearing temporary descriptors for face detection screening",
        timeoutBetweenFrame: "Timeout between frame processing during face detection",
        titleTooLong: "Title is too long",
        titleTooShort: "Title is too short",
        to: "To",
        toExitFullScreenMode: "To exit full screen mode, press",
        totalCameras: "Total cameras",
        trash: "Trash",
        turnOffTheDevice: "Turn off the device",
        type: "Type",
        unmount: "Unmount",
        until: "until",
        update: "Update",
        usbDeviceDetected: "USB device detected",
        usbDeviceRemoved: "USB device removed",
        used: "Used",
        user: "User",
        version: "version",
        videoDuration: "Video duration",
        videoFormat: "Video format",
        warning: "Warning",
        webSettings: "WEB settings",
        youAreInFullScreenMode: "You are in full screen mode",
        zone: "Zone",
    },
    ru: {
        action: "Действие",
        add: "Добавить",
        addAudioFile: "Добавить аудио файл",
        addCamcorder: "Добавить видеокамеру",
        addCamera: "Добавить камеру",
        addCell: "Добавить ячейку",
        addDevice: "Добавить устройство",
        addDevicesToSee: "Добавьте устройства, чтобы видеть их здесь",
        addGroup: "Добавить группу",
        additionalSettings: "Дополнительные настройки",
        addMotionZone: "Добавить зону движения",
        addPlan: "Добавить план",
        addReaction: "Добавить реакцию",
        addReactionsOnEvent: "Добавьте реакции на события, чтобы видеть их здесь",
        addRule: "Добавить правило",
        addRulesToSee: "Добавьте правила, чтобы видеть их здесь",
        addScreenID: "Добавить ID экрана",
        afterThisProcedureYouNeedToRestartTheCameras: "После данной процедуры, необходимо перезагузить камеры",
        age: "Возраст",
        alarmMonitor: "Тревожный монитор",
        alarmIsOff: "Тревога отключена",
        app: "Приложение",
        apply: "Применить",
        archiveStoragePeriod: "Период хранения архива",
        authorization: "Авторизация",
        armedSystem: "Система под охраной",
        attentionAnxiety: "Внимание! ТРЕВОГА",
        attentionTechnicalWorkInProgress: "Внимание! Ведутся технические работы!",
        authorisationError: "Ошибка авторизации",
        availableUpdates: "Доступные обновления",
        basicSettings: "Основные настройки",
        bindEmail: "Привязать email",
        cameraAddedSuccessfully: "Камера успешно добавлена",
        cameraAlreadyInCatalog: "Ошибка! Камера уже есть в данном каталоге",
        cameraCoordinates: "Координаты видеокамеры",
        camcorderName: "Имя видеокамеры",
        cameraEditedSuccessfully: "Камера успешно отредактирована",
        cameraList: "Список камер",
        cameraModel: "Модель камеры",
        cameraName: "Наименование камеры",
        cameraRemovedSuccessfully: "Камера успешно удалена",
        cameraRemoval: "Удаление камеры",
        cameras: "Камеры",
        camera: "Камера",
        camerasSetup: "Настройка камер",
        cancel: "Отменить",
        cancelAllCurrentDownloads: "Отменить все текущие загрузки?",
        capacity: "Емкость",
        changeUser: "Смена пользователя",
        choose: "Выбрать",
        chooseAll: "Выбрать все",
        chooseFile: "Выбрать файл",
        chooseReaction: "Выберите реакцию",
        chooseWhichGroupAddCamera: "Выберите в какую группу добавить камеру",
        clearScreen: "Очистить экран",
        clearTheList: "Очистить список",
        close: "Закрыть",
        containsRtsp: "URL не содержит rtsp://",
        continueDownload: "Продолжить загрузку",
        controlPanel: "Панель управления",
        core: "Ядро",
        createScreenshot: "Создать скриншот",
        dateDays: ["Пн","Вт","Ср","Чт","Пт","Сб","Вс"],
        dateMonth: ["Январь","Февраль","Март","Апрель","Май","Июнь","Июль","Август","Сентябрь","Октябрь","Ноябрь","Декабрь"],
        dateTime: "Дата и время",
        days: "дней",
        daysOfWeek: "Дни недели",
        default: "По умолчанию",
        delete: "Удалить",
        deleteAudioFile: "Удалить аудиофайл",
        deleteGroup: "Удалить группу",
        deleteLicense: "Удалить лицензию",
        deletePlan: "Удалить план",
        deleteRule: "Удалить правило",
        description: "Описание",
        detections: "Детекции",
        detectionSearchIsOver: "Поиск детекций окончен",
        device: "Устройство",
        devices: "Устройства",
        disable: "Отключить",
        disabled: "Отключена",
        diskManager: "Диск менеджер",
        diskMounted: "Диск смонтирован",
        diskSpace: "Место на диске",
        diskUnmounted: "Диск размонтирован",
        disturbingEvents: "Тревожные события",
        dnsServer: "DNS сервер",
        dragCameraToWindow: "Перетащите камеру в окно",
        dragCameraOrCamGroup: "Перетащите камеру или группу камер в нужный раздел таксономии",
        drawingBoxArea: "Отрисовка бокса (области) детекции лица на полном изображении кадра",
        download: "Скачать",
        edit: "Редактировать",
        editCamcorder: "Редактировать видеокамеру",
        editGroup: "Редактировать группу",
        emotion: "Эмоция",
        emptyField: "Поле не может быть пустым",
        emptyList: "Список пуст",
        enable: "Включить",
        enabled: "Включена",
        enableMotionDetector: "Включить детектор движения",
        enableRecording: "Включить запись",
        end: "Окончание",
        endDateRecording: "Дата и время окончания записи",
        enterPlanName: "Введите название плана",
        entry: "Войти",
        error: "Ошибка",
        errorDnsServer: "Ошибка DNS сервера",
        errorIpAddress: "Ошибка IP адреса",
        errorMoveCamera: "Ошибка! Нельзя добавить камеру",
        errorSubnetMask: "Ошибка маски подсети",
        event: "Событие",
        events: "События",
        eventDescription: "Описание события",
        eventType: "Тип события",
        everyDay: "Ежедневно",
        exit: "Выход",
        expansionDetectionBox: "Расширение бокса (области) детекции вокруг лица",
        export: "Экспорт",
        faceDetection: "Детекция лиц",
        faceDetectionConfiguration: "Настройка детекции лиц",
        faceRecognitionModelOptions: "Опции модели распознавания лиц",
        fileManager: "Файловый менеджер",
        filled: "Заполнено",
        filter: "Фильтр",
        firstStreamURL: "URL первого потока",
        formatDrive: "Форматировать диск",
        free: "Свободно",
        from: "От кого",
        fullScreen: "На всесь экран",
        gateway: "Шлюз",
        gender: "Пол",
        goToMoment: "Перейти к моменту",
        groupFaceRecognition: "Групповое распознавание лиц",
        groupName: "Имя группы",
        guarded: "Под охраной",
        hide: "Скрыть",
        higherValueLeadsToArtifacts: "Большее значение приводит к артефактам на изображении (рекомендуемые значения от 1 до 10)",
        hostname: "Имя хоста",
        inputSignals: "Входные сигналы",
        install: "Установить",
        interfaceLanguage: "Язык интерфейса",
        ipAddress: "IP адрес",
        latitude: "Широта",
        letterSubject: "Тема письма",
        level: "Уровень",
        license: "Лицензия",
        licensing: "Лицензирование",
        licenseActivated: "Лицензия активирована",
        loading: "Загрузка",
        login: "Логин",
        logs: "Логи",
        longitude: "Долгота",
        manually: "Вручную",
        macAddress: "Mac адрес",
        minimizeVideoStreamDelay: "Минимизировать задержку видеопотока",
        minimumAccuracy: "0.5 - минимальная точность",
        monitoring: "Мониторинг",
        mode: "Режим",
        motionDetector: "Детектор движения",
        motionDetectorSetup: "Настройка детектора движения",
        mount: "Смонтировать",
        ms: "мс",
        multipleSources: "Несколько источников",
        name: "Название",
        nationality: "Национальность",
        network: "Сеть",
        networkCard: "Сетевая карта",
        networkConfiguration: "Настройка сети",
        networkInterface: "Сетевой интерфейс",
        noData: "Нет данных",
        noiseReductionLevel: "Уровень шумоподавления",
        ntpSetup: "Настройка NTP",
        numberOfFrames: "Количество кадров в секунду",
        offPlan: "с плана",
        openAlarmMonitor: "Открыть тревожный монитор",
        openScreen: "Открыть экран",
        openTerminal: "Открыть терминал",
        openVideoStream: "Открыть видеопоток",
        other: "Другая",
        password: "Пароль",
        passwordChange: "Смена пароля",
        plans: "Планы",
        period: "Период",
        pixelSizeThreshold: "Порог размера пикселей",
        planned: "Запланировано",
        port: "Порт",
        primary: "Первичный поток",
        priority: "Приоритет",
        processor: "Процессор",
        ptz: "PTZ",
        put: "Поставить",
        putOnGuard: "Поставить на охрану",
        ram: "ОЗУ",
        reaction: "Реакция",
        reactionsOnEvents: "Реакции на события",
        reboot: "Перезагрузить",
        recording: "Запись",
        releaseSignal: "Деблокировка сигнала",
        removeCamera: "Удалить камеру",
        removeAllCameras: "Удалить все камеры",
        remove: "Снять",
        removeGuard: "Снять с охраны",
        renewal: "Обновление",
        replaceImage: "Заменить изображение",
        reset: "Сбросить",
        resetSettings: "Сброс настроек",
        resetAllSettings: "Сбросить все натройки",
        ruleDeletedSuccessfully: "Правило успешно удалено",
        ruleName: "Наименование правила",
        save: "Сохранить",
        screen: "Экран",
        screenClearedSuccessfully: "Экран успешно очищен",
        screeningToCertainSize: "Отсеивание до определённого размера бокса (области) детекции вокруг лица",
        screenPattern: "Шаблон экрана",
        screenshot: "Скриншот",
        screens: "Экраны",
        search: "Поиск",
        searchByCameras: "Поиск по камерам",
        searchByCameraName: "Поиск по названию камеры",
        searchByDescription: "Поиск по описанию",
        searchByEvents: "Поиск по событиям",
        searchByReactions: "Поиск по реакциям",
        searchByRules: "Поиск по правилам",
        sec: "сек",
        secondStreamURL: "URL второго потока",
        secondary: "Вторичный поток",
        security: "Охрана",
        securityDrill: "Учебная тревога",
        sendByEmail: "Отправить на email",
        sensitivity: "Чувствительность",
        server: "Сервер",
        serverAddress: "Адрес сервера",
        settings: "Настройки",
        settingsSavedSuccessfully: "Настройки успешно сохранены",
        scheduled: "По расписанию",
        show: "Показать",
        singleSource: "Один источник",
        smtpSetup: "Настройка SMTP",
        sound: "Звук",
        soundEvent: "Звуковое событие",
        start: "Начало",
        startSearchingForDetections: "Начало поиска детекций",
        startDateRecorder: "Дата и время начала записи",
        state: "Состояние",
        startup: "Автозагрузка",
        status: "Статус",
        storageLimitOfTemporaryDescriptors: "Лимит хранения временных дескрипторов для отсеивания детекции лиц",
        streamURL: "URL потока",
        streamURLorStreamList: "URL потока или списка потоков",
        streamType: "Тип потока",
        subnetMask: "Маска подсети",
        success: "Успешно",
        successfullyMovedToDirectory: "Успешно перемещенна в каталог",
        system: "Система",
        systemCheck: "Проверка системы",
        systemMonitor: "Системный монитор",
        systemNotArmed: "Система не под охраной",
        switchingModule: "Модуль коммутации",
        switchModuleEvents: "События модуля коммутации",
        switchOff: "Выключить",
        taxonomy: "Таксономия",
        terminal: "Терминал",
        theLatestVersionOfTheApplicationIsInstalled: "Уставновлена последняя версия приложения",
        thereWillBeAnUpdateOfTheSystem: "Будет произведено обновление систем",
        thisFieldCannotBeEmpty: "Данное поле не может быть пустым",
        thisFieldMustContainOnlyNumericValue: "Это поле должно содержать только числовое значение",
        time: "Время",
        timeBeforeAutoClearing: "Время до автоматической очистки временных дескрипторов для отсеивания детекции лиц",
        timeoutBetweenFrame: "Таймаут между обработкой кадра при детекции лиц",
        titleTooLong: "Заголовок слишком длинный",
        titleTooShort: "Заголовок слишком короткий",
        to: "Кому",
        toExitFullScreenMode: "Для выхода из полноэкранного режима, нажмите",
        totalCameras: "Всего камер",
        trash: "Удалить",
        turnOffTheDevice: "Выключение устройства",
        type: "Тип",
        unmount: "Размонтировать",
        until: "до",
        update: "Обновить",
        usbDeviceDetected: "Обнаружено USB устройство",
        usbDeviceRemoved: "Извлечено USB устройство",
        used: "Используется",
        user: "Пользователь",
        version: "версия",
        videoDuration: "Длительность видео",
        videoFormat: "Формат видео",
        warning: "Внимание",
        webSettings: "WEB настройки",
        youAreInFullScreenMode: "Вы находитесь в полноэкранном режиме",
        zone: "Зона",
    },
    kk: {
        action: "Акт",
        add: "Қосу",
        addAudioFile: "Аудиофайл қосыңыз",
        addCamcorder: "Бейнекамераны қосу",
        addCamera: "Камераны қосу",
        addCell: "Ұяшықтарды қосу",
        addDevice: "Құрылғыны қосыңыз",
        addDevicesToSee: "Мұнда көру үшін құрылғылар қосыңыз",
        addGroup: "Топты қосыңыз",
        additionalSettings: "Қосымша түзетулер",
        addMotionZone: "Қозғалыс аймағын қосу",
        addPlan: "Жоспар қосу",
        addReaction: "Көзқарасын қосу",
        addReactionsOnEvent: "Осы жерде көру үшін оқиғаларға көзқарасын қосыңыз",
        addRule: "Ереже қосу",
        addRulesToSee: "Осы жерде көру үшін ережелерді қосыңыз",
        addScreenID: "Экран ID қосыңыз",
        afterThisProcedureYouNeedToRestartTheCameras: "Бұл процедурадан кейін камераларды қайта қосыңыз",
        age: "Жасыңыз",
        alarmMonitor: "Дабыл мониторы",
        alarmIsOff: "Дабыл өшірулі",
        app: "Қосымша",
        apply: "Қолдану",
        archiveStoragePeriod: "Мұрағат сақтау мерзімі",
        attentionAnxiety: "Назар аударыңыз! Мазасыздық",
        attentionTechnicalWorkInProgress: "Назар аударыңыз! Орындалған техникалық жұмыстар!",
        authorization: "Автоматтық түрде қайта қосылу",
        armedSystem: "Қауіпсіздік жүйесі",
        authorisationError: "Авторизацияның қателігі",
        availableUpdates: "Қол жетімді жаңартулар",
        basicSettings: "Негізгі қалпына келтіру параметрлері",
        bindEmail: "Email байланыстыру",
        cameraAddedSuccessfully: "Камера сәтті қосылды",
        cameraAlreadyInCatalog: "Қате! Каталогта камера бар",
        cameraCoordinates: "Бейнекамералардлың  орналасу жері",
        camcorderName: "Бейнекамераның атауы",
        cameraEditedSuccessfully: "Камера сәтті өңделді",
        cameraList: "Камералардың тізімі",
        cameraModel: "Камераның моделі",
        cameraName: "Камераның атауы",
        cameraRemovedSuccessfully: "Камера сәтті жойылды",
        cameraRemoval: "Камераны жою",
        cameras: "Камералар",
        camera: "Камера",
        camerasSetup: "Камераны орнату",
        cancel: "Бас тарту",
        cancelAllCurrentDownloads: "Ағымдағы барлық қайта қосулардан бас тарту?",
        capacity: "Сыйымдылығы",
        changeUser: "Пайдаланушының өзгеруі",
        choose: "Таңдау",
        chooseAll: "Барлығын таңдаңыз",
        chooseFile: "Файл таңдау",
        chooseReaction: "Көзқарасын таңдау",
        chooseWhichGroupAddCamera: "Камераны қандай топқа қосуыңызды таңдаңыз",
        clearScreen: "Экранды тазарту",
        clearTheList: "Тізімді тазарту",
        close: "Жабу",
        containsRtsp: "URL құрамында rtsp:// жоқ ",
        continueDownload: "Қайта қосуды жалғастыру",
        controlPanel: "Басқару панелі",
        core: "Өзек",
        createScreenshot: "Скриншот жасау",
        dateDays: ["Дс","Сс","Ср","Бс","Жм","Сб","Жс"],
        dateMonth: ["Қаңтар","Ақпан","Наурыз","Сәуір","Мамыр","Маусым","Шілде","Тамыз","Қыркүйек","Қазан","Қараша","Желтоқсан"],
        dateTime: "Датасы және уақыты",
        days: "күндер",
        daysOfWeek: "Апта күндері",
        default: "Әдепкі",
        delete: "Жою",
        deleteAudioFile: "Аудиофайлды жою",
        deleteGroup: "Топты жою",
        deleteLicense: "Лицензияны жою",
        deletePlan: "Жоспарды жою",
        deleteRule: "Ережені жою",
        description: "Сипаттама",
        detections: "Анықтау",
        detectionSearchIsOver: "Іздеу аяқталды",
        device: "Құрылғы",
        devices: "Құрылғылар",
        disable: "Өшіру",
        disabled: "Өшірілді",
        diskManager: "Диск менеджер",
        diskMounted: "Диск орнатылды",
        diskSpace: "Дисктегі орын",
        diskUnmounted: "Диск алынды",
        disturbingEvents: "Алаңдаушылық тудыратын жағдай",
        dnsServer: "DNS сервер",
        dragCameraToWindow: "Камераны терезеге сүйретіп апарыңыз",
        dragCameraOrCamGroup: "Камераны немесе камера тобын қажетті таксономия бөліміне сүйреп апарыңыз",
        drawingBoxArea: "Жақтаудың толық кескініне тұлғаны анықтау қорабын (ауданын) салу",
        download: "Көшіріп алу",
        edit: "Өңдеу",
        editCamcorder: "Бейнекамераны өңдеу",
        editGroup: "Топты өңдеу",
        emotion: "Эмоция",
        emptyField: "Өріс бос бола алмайды",
        emptyList: "Тізім бос",
        enable: "Қосу",
        enabled: "Қосылды",
        enableMotionDetector: "Қозғалыс детекторын қосу",
        enableRecording: "Жазу қосылып тұр",
        end: "Аяқталуы",
        endDateRecording: "Жазудың аяқталу күні мен уақыты",
        enterPlanName: "Жоспар атауын енгізіңіз",
        entry: "Кіру",
        error: "Қате",
        errorDnsServer: "DNS серверінің қатесі",
        errorIpAddress: "IP-адрес қатесі",
        errorMoveCamera: "Қате! Камера қосу мүмкін емес",
        errorSubnetMask: "Ішкі желі маскасының қатесі",
        event: "Іс-шара",
        events: "Іс-шаралар",
        eventDescription: "Іс-шараның сипаттамасы",
        eventType: "Іс-шараның түрі",
        everyDay: "Күн сайын",
        exit: "Шығу",
        expansionDetectionBox: "Анықтау қорабын (аймақты) беттің айналасында кеңейту",
        export: "Экспорт",
        faceDetection: "Беттерді анықтау",
        faceDetectionConfiguration: "Беттерді анықтауды қалыптастыру",
        faceRecognitionModelOptions: "Бетті танудың модель параметрлері",
        fileManager: "Файл менеджері",
        filled: "Толтырылды",
        filter: "Фильтр",
        firstStreamURL: "Бірінші бағыттың URL",
        formatDrive: "Дискті босату",
        free: "Бос",
        from: "Кімнен",
        fullScreen: "Толық экранда",
        gateway: "Шлюз",
        gender: "Жыныс",
        goToMoment: "Бір сәтке қайтып келу",
        groupFaceRecognition: "Топтық тұлғаларды тану",
        groupName: "Топтың атауы",
        guarded: "Күзетіледі",
        hide: "Жасыру",
        higherValueLeadsToArtifacts: "Үлкен мән суреттегі артефактілерге әкеледі (ұсынылған мәндер 1-ден 10-ға дейін)",
        hostname: "Хост атауы",
        inputSignals: "Кіріс сигналдары",
        install: "Орнату",
        interfaceLanguage: "Интерфейс тілі",
        ipAddress: "IP адресі",
        latitude: "Ені",
        letterSubject: "Хаттың тақырыбы",
        level: "Деңгейі",
        license: "Лицензиясы",
        licensing: "Лицензиялау",
        licenseActivated: "Лицензиясы  қосылған",
        loading: "Жүктеу",
        login: "Логин",
        logs: "Логи",
        longitude: "Бойлығы",
        manually: "Қолмен теру",
        macAddress: "Mac мекенжайы",
        minimizeVideoStreamDelay: "Бейне ағынының кідірісін азайту",
        minimumAccuracy: "0.5 - минималды дәлдігі",
        monitoring: "Мониторинг",
        mode: "Режимі",
        motionDetector: "Қозғалыс детекторы",
        motionDetectorSetup: "Қозғалыс детекторын қалтына келтіру",
        mount: "Тау",
        ms: "мс",
        multipleSources: "Бірнеше көздері",
        name: "Атауы",
        nationality: "Ұлты",
        network: "Желіс",
        networkCard: "Желілік картасы",
        networkConfiguration: "Желісті қалпына келтіру",
        networkInterface: "Желілік интерфейс",
        noData: "Мәліметтері жоқ",
        noiseReductionLevel: "Шуды басу деңгейі",
        ntpSetup: "NTP қалпына келтіру",
        numberOfFrames: "Секундасындағы кадрлар саны",
        offPlan: "жоспардан",
        openAlarmMonitor: "Дабыл мониторын ашу",
        openTerminal: "Ашық терминал",
        openScreen: "Экранды ашыңыз",
        openVideoStream: "Бейне бағытын ашыңыз",
        other: "Басқа",
        password: "Паролі",
        passwordChange: "Парольді өзгерту",
        plans: "Жоспарлар",
        period: "Кезең",
        pixelSizeThreshold: "Пикселдер өлшемінің шегі",
        planned: "Жоспарланған",
        port: "Порты",
        priority: "Басымдық",
        primary: "бастапқы ағын",
        processor: "Процессор",
        ptz: "PTZ",
        put: "Орнату",
        putOnGuard: "Күзетке қою",
        ram: "ОЗУ",
        reaction: "Реакциясы",
        reactionsOnEvents: "Жағдайға көзқарасы",
        reboot: "Қайта жүктеңіз",
        recording: "Жазба",
        releaseSignal: "Босату сигналы",
        removeCamera: "Камераны жою",
        removeAllCameras: "Барлық камераларды жою",
        resetAllSettings: "Барлық параметрлерді қалпына келтіріңіз",
        remove: "Шешіп алу",
        replaceImage: "Кескінді ауыстыру",
        removeGuard: "Күзеттен алып тастау",
        renewal: "Жаңарту",
        reset: "Алып тастау",
        resetSettings: "Қалпына келтіру",
        ruleDeletedSuccessfully: "Ереже сәтті жойылды",
        ruleName: "Ереженің атауы",
        save: "Сақтау",
        screen: "Экран",
        screenClearedSuccessfully: "Экран сәтті тазаланды",
        screeningToCertainSize: "Беттің айналасындағы анықтау қорабының (аумағының) белгілі бір мөлшеріне дейін",
        screenPattern: "Экран шаблоны",
        screenshot: "Скриншот",
        screens: "Экрандар",
        search: "Іздеу",
        searchByCameras: "Камера арқылы іздеу",
        searchByCameraName: "Камера атауы бойынша іздеу",
        searchByDescription: "Сипаттама бойынша іздеу",
        searchByEvents: "Оқиғалар бойынша іздеу",
        searchByReactions: "Реакциялары бойынша іздеу",
        searchByRules: "Ережелер бойынша іздеу",
        sec: "сек",
        secondStreamURL: "Екінші бюағыттық URL",
        secondary: "қайталама ағын",
        security: "Күзет",
        securityDrill: "Жаттығу дабыл",
        sendByEmail: "Email-ға жолдау",
        sensitivity: "Сезімталдық",
        server: "Сервер",
        serverAddress: "Сервер мекенжайы",
        settings: "Қалпына келтіру параметрлері",
        settingsSavedSuccessfully: "Параметрлер сәтті сақталды",
        scheduled: "Кесте бойынша",
        show: "Көрсету",
        singleSource: "Бір дереккөз",
        smtpSetup: "SMTP қалпына келтіру параметрлері",
        sound: "Дыбыс",
        soundEvent: "Дыбыс оқиғасы",
        start: "Басталу",
        startDateRecorder: "Анықтауды іздеуді бастаңыз",
        startSearchingForDetections: "Жазудың басталу датасы мен уақыты",
        startup: "Жіберу",
        state: "Күйі",
        status: "Мәртебесі",
        storageLimitOfTemporaryDescriptors: "Бетті анықтауды іріктеу үшін уақытша дескрипторлардың сақтау шегі",
        streamURL: "Бағыттың URL",
        streamURLorStreamList: "Бағыттың немесе бағыттар тізімінің URL",
        streamType: "Тип потока",
        subnetMask: "Ішкі желі маскасы",
        success: "Сәтті",
        successfullyMovedToDirectory: "Каталогқа сәтті көшірілді",
        system: "Жүйе",
        systemCheck: "Жүйені тексеру",
        systemMonitor: "Жүйелік монитор",
        systemNotArmed: "Жүйе күзетулісіз",
        switchingModule: "Ауыстыру модулі",
        switchModuleEvents: "Модуль оқиғаларын ауыстыру",
        switchOff: "Өшіру",
        taxonomy: "Таксономия",
        terminal: "Терминал",
        theLatestVersionOfTheApplicationIsInstalled: "Қосымшаның соңғы нұсқасы орнатылған",
        thereWillBeAnUpdateOfTheSystem: "Жүйелер жаңарттылады",
        thisFieldCannotBeEmpty: "Бұл өріс бос болмауы керек",
        thisFieldMustContainOnlyNumericValue: "Бұл өрісте тек сандық мән болуы керек",
        time: "Уақыты",
        timeBeforeAutoClearing: "Бетті анықтауды сүзу үшін уақытша дескрипторларды автоматты түрде тазартатын уақыт",
        timeoutBetweenFrame: "Бетті анықтау кезінде кадрды өңдеу арасындағы күту",
        titleTooLong: "Тақырып тым ұзақ",
        titleTooShort: "Тақырып тым қысқа",
        to: "Кімге",
        toExitFullScreenMode: "Толық экран режимінен шығу үшін түймесін басыңыз",
        totalCameras: "Жалпы камералар",
        trash: "Жою",
        turnOffTheDevice: "құрылғыны өшіріңіз",
        type: "Түрі",
        unmount: "Ажырату",
        until: "будан бұрын",
        update: "Жаңарту",
        usbDeviceDetected: "USB құрылғысы анықталды",
        usbDeviceRemoved: "USB құрылғысы алынды",
        used: "Пайдаланады",
        user: "Пайдаланушы",
        version: "нұсқасы",
        videoDuration: "Бейне ұзақтығы",
        videoFormat: "Бейне форматы",
        warning: "Назар аударыңыз",
        webSettings: "WEB параметрлері",
        youAreInFullScreenMode: "Сіз толық экран режиміндесіз",
        zone: "Аймақ",
    },
});
