import React, {useEffect, useState} from "react";
import {InputAdornment} from "@material-ui/core";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import style from "./ModalLogon.module.scss"
import {worlds} from "../../../Utils/localization";
import TextFieldM from "../../Inputs/TextFieldM";
import eyeIcon from "../../../Assets/icons/eye.svg";
import ButtonRectangle from "../../Buttons/ButtonRectangle/ButtonRectangle";
import {authorization} from "../../../api/state/account/actions";

const ModalLogon = (props) => {
    const [username,setUsername] = useState("");
    const [password,setPassword] = useState("");
    const [showPassword,setShowPassword] = useState(false);

    useEffect(() => () => {
            document.onkeypress = null;
            props.history.push("/");
        },[])

    document.onkeypress = (e) => {
        if (e.code === "Enter" && !props.currentAccount) {
            logon()
        }
    }

    const logon = async () => {
        const user = {username,password}
        try {
            await authorization(user)
            props.history.push("/")
            document.onkeypress = null;
        } catch (e) {

        }
    }

    return (
      <div className={style.modalLogon}>
        <TextFieldM
          type="text"
          style={{margin: "0.42vw 0", width: "23.38vw"}}
          variant="outlined"
          name="login"
          value={username}
          placeholder={worlds.login}
          onChange={(e) => setUsername(e.target.value)}
        />
        <TextFieldM
          type={showPassword ? 'text' : 'password'}
          variant="outlined"
          name="password"
          style={{margin: "0.42vw 0 1.87vw", width: "23.38vw"}}
          value={password}
          placeholder={worlds.password}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <img className={style.passwordShow} src={eyeIcon} onClick={() => setShowPassword(!showPassword)} />
                      </InputAdornment>
                    ),
                }}
        />
        <ButtonRectangle disabled={props.serverDisconnect} action={() => logon()} width="23.38vw" height="2.91vw" caption={worlds.entry} />
      </div>
    )
}

export default withRouter(connect((state) => state,null)(ModalLogon))
