import React from "react";
import {connect} from "react-redux";
import style from "./ModalDiskUnmount.module.scss"
import ButtonRectangle from "../../Buttons/ButtonRectangle/ButtonRectangle";
import {worlds} from "../../../Utils/localization";
import {closeModal} from "../actions";

class ModalDeleteCam extends React.Component {
    render() {
        return (
          <div className={style.modalDiskUnmount}>
            <div className={style.caption}>
              {`${worlds.unmount} ${worlds.devices}?`}
            </div>
            <div className={style.controls}>
              <ButtonRectangle width="9.55rem" caption={worlds.unmount} action={() => { this.props.unmount(); closeModal() }} />
              <ButtonRectangle width="7.55rem" dark caption={worlds.cancel} action={() => closeModal()} />
            </div>
          </div>
        )
    }
}

export default connect((state) => state,null)(ModalDeleteCam)
