import {plainToClass} from "class-transformer";
import {
    ACCOUNT_CREATE,
    ACCOUNT_DELETE,
    ACCOUNT_LIST,
    ACCOUNT_UPDATE,
    CURRENT_ACCOUNT_CREATE,
    CURRENT_ACCOUNT_DELETE,
    EDIT_ACCOUNT_CREATE,
    EDIT_ACCOUNT_DELETE,
} from "./consts";
import Account from "../../models/Account";

const prevState = {
    accounts: [],
    currentAccount: null,
    editAccount: null,
}

export const accounts = (state = prevState.accounts, action) => {
    switch (action.type) {
        case ACCOUNT_LIST:
            return plainToClass(Account,[...action.obj]);
        case ACCOUNT_CREATE:
            if (state.find((i) => i.id === action.obj.id)) return state;
            state.push(action.obj);
            return plainToClass(Account,[...state]);
        case ACCOUNT_UPDATE:
            state.splice(state.findIndex((i) => i.id === action.obj.id), 1,action.obj);
            return plainToClass(Account,[...state]);
        case ACCOUNT_DELETE:
            state.splice(state.findIndex((i) => i.id === action.obj.id), 1);
            return plainToClass(Account,[...state]);
        default:
            return state;
    }
}

export const editAccount = (state = prevState.editAccount, action) => {
    switch (action.type) {
        case EDIT_ACCOUNT_CREATE:
            return plainToClass(Account,{...state,...action.obj});
        case EDIT_ACCOUNT_DELETE:
            return null;
        default:
            return state;
    }
}

export const currentAccount = (state = prevState.currentAccount, action) => {
    switch (action.type) {
        case CURRENT_ACCOUNT_CREATE:
            if (!action.obj) return state;
            return plainToClass(Account,{...state,...action.obj});
        case CURRENT_ACCOUNT_DELETE:
            return null;
        default:
            return state;
    }
}
