import React from "react";
import style from "./SwitchingItem.module.scss";
import ButtonSquare from "../../../../Components/Buttons/ButtonSquare/ButtonSquare";
import iconTrash from "../../../../Assets/icons/trash.svg"
import {showModalDeleteAudioFile} from "../../../../Components/Modals/actions";
import {worlds} from "../../../../Utils/localization";

class SwitchingItem extends React.Component {
    render() {
        return (
          <div className={style.switchingItem}>
            <div className={style.log} style={{width: "95%"}}>{this.props.name}</div>
            <div className={style.log} style={{width: "5%"}}>
              <ButtonSquare name={worlds.delete} icon={iconTrash} action={() => showModalDeleteAudioFile(this.props.name)} />
            </div>
          </div>
        )
    }
}

export default SwitchingItem;
