import React from 'react';
import moment from "moment-timezone";
import style from './ReactionsTableItem.module.scss';
import ReactionsMenu from "../ReactionsMenu/ReactionsMenu";

function ReactionsTableItem(props) {
  return (
    <div className={style.reactionsTableItem}>
      <div className={style.item} style={{width: "13.38vw"}}>{moment(props.data.dateTime).format('DD.MM.YYYY HH:mm:ss')}</div>
      <div className={style.item} style={{width: "36.46vw"}}>{props.data.ruleName}</div>
      <div className={style.item} style={{width: "36.46vw"}}>{props.data.reaction}</div>
      <div className={style.options}>
        <ReactionsMenu />
      </div>
    </div>
    // <tr>
    //   <td>{ props.dateTime }</td>
    //   <td>{ props.ruleName }</td>
    //   <td>{ props.reaction }</td>
    //   <td className="text-center">
    //     <button className={ `${styles.optionButton} btn btn-link` } type="button">
    //       <img src={ optionButton } alt="" />
    //     </button>
    //   </td>
    // </tr>
  );
}

export default ReactionsTableItem;
