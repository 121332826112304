import React, {useState} from 'react';

import {connect} from "react-redux";
import Pagination from "@material-ui/lab/Pagination";
import style from './AlarmSwitching.module.scss';
import {worlds} from "../../../Utils/localization";

import AlarmSwitchingForm from './AlarmSwitchingForm/AlarmSwitchingForm';
import AlarmSwitchingListItem from './AlarmSwitchingListItem/AlarmSwitchingListItem';
import {initiateSwitching} from "../../../api/state/switching/actions";

function AlarmSwitching(props) {
    const [page,setPage] = useState(1);
    const [searchText,setSearchText] = useState("");
    const [dateRange,setDateRange] = useState([null,null])
    const [camsIds,setCamsIds] = useState([]);

    const searchCams = (e) => {
        setSearchText(e);
    }

    const changePage = (el,pageNum) => {
        setPage(pageNum)
        if (dateRange[1] && dateRange[0]) {
            const filter = {updated_at: {$gte: `${dateRange[0]}`,$lte: `${dateRange[1]}`}};
            initiateSwitching(filter,7,7 * pageNum - 7);
        } else {
            initiateSwitching(null,7, 7 * pageNum - 7);
        }
    }

  return (
    <div className={style.alarmContainer}>
      <AlarmSwitchingForm camsIds={camsIds} setDateRangeCB={setDateRange} searchText={searchText} searchTextChange={searchCams} />
      {(props.switchingEvents.loggers.length > 0)
          ? props.switchingEvents.loggers.map((data,key) => <AlarmSwitchingListItem key={key} data={data} />)
          : <div className={style.emptyList}><div>{worlds.emptyList}</div></div>}
      <div className={style.pagination}>
        <Pagination outlined="false" onChange={changePage} count={Math.round(props.switchingEvents.count / 7)} shape="rounded" />
      </div>
    </div>
  );
}

export default connect((state) => state,null)(AlarmSwitching);
