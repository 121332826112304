import {ADD_SWITCHING_EVENT, ADD_SWITCHING_EVENT_LIST, SOUND_LIST} from "./consts";

const prevState = {
    switchingEvents: {count: 0,loggers: []},
    sounds: [],
}

export const sounds = (state = prevState.sounds, action) => {
    switch (action.type) {
        case SOUND_LIST:
            return [...action.obj];
        default:
            return state;
    }
}

export const switchingEvents = (state = prevState.switchingEvents, action) => {
    switch (action.type) {
        case ADD_SWITCHING_EVENT_LIST:
            if (!action.obj) return state;
            return action.obj;
        case ADD_SWITCHING_EVENT:
            if (!action.obj) return state;
            if (state.loggers.count >= 7) {
                state.loggers.pop();
            }
            state.loggers.unshift({...action.obj})
            state.count++;
            return {...state};
        default:
            return state;
    }
}
