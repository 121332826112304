import React from "react";
import * as _ from 'lodash';
import style from "./NetworkCard.module.scss"
import {worlds} from "../../../../Utils/localization";
import TextFieldM from "../../../../Components/Inputs/TextFieldM";
import CheckBox from "../../../../Components/CheckBox/CheckBox";
import {editNetworkConfig} from "../../../../api/state/network/actions";

export default class NetworkCard extends React.Component {
    onChange = (val) => {
        const card = this.props.networkConfig;
        if (val.target.checked) {
            card.wan.inet = "static"
        } else {
            card.wan.inet = "dhcp"
        }
        editNetworkConfig(card)
    }

    handleChange = (val,name) => {
        const config = this.props.networkConfig;
        _.set(config[this.props.type.toLowerCase()],name,val)
        editNetworkConfig(config)
    }

    render() {
        const card = this.props.networkConfig[this.props.type.toLowerCase()]
        if (!card) {
            return null;
        }
        return (
          <div className={style.networkCard}>
            {this.props.type === "WAN"
                && (
                <div className={style.dhcp}>
                  <label>Вручную</label>
                  <CheckBox onChange={this.onChange} checked={card.inet === "static"} />
                </div>
)}
            <label>{worlds.ipAddress}</label>
            <TextFieldM style={{width: "26.04vw"}} name="address" errors={this.props.errors?.[this.props.type.toLowerCase()]} onChange={(e) => this.handleChange(e.target.value,"address")} disabled={card.inet === "dhcp"} variant="outlined" value={card.address} />
            <label>{worlds.subnetMask}</label>
            <TextFieldM style={{width: "26.04vw"}} name="netmask" errors={this.props.errors?.[this.props.type.toLowerCase()]} onChange={(e) => this.handleChange(e.target.value,"netmask")} disabled={card.inet === "dhcp"} variant="outlined" value={card.netmask} />
            {this.props.type === "WAN"
                && (
                <>
                  <label>{worlds.gateway}</label>
                  <TextFieldM style={{width: "26.04vw"}} name="gateway" errors={this.props.errors?.[this.props.type.toLowerCase()]} onChange={(e) => this.handleChange(e.target.value,"gateway")} disabled={card.inet === "dhcp"} variant="outlined" value={card.gateway} />
                  <label>{worlds.dnsServer}</label>
                  <TextFieldM style={{width: "26.04vw"}} name="dns-nameservers" errors={this.props.errors?.[this.props.type.toLowerCase()]} onChange={(e) => this.handleChange(e.target.value,"dns-nameservers")} disabled={card.inet === "dhcp"} variant="outlined" value={card["dns-nameservers"]} />
                </>
)}
          </div>
        )
    }
}
