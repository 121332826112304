import React from "react";
import flv from "flvjs-megacam";

export const createPlayer = async (state) => new Promise((resolve,reject) => {
        if (state.player) {
            state.player.unload()
        }
        flv.LoggingControl.enableAll = false;
        const {host} = new URL(window.location.hostname && window.location.hostname !== "localhost" ? `http://${window.location.hostname}:4080/` : window.config.host);
        const url = state.url.replace("localhost",host).replace(":4080","");
        const player = flv.createPlayer({
            type: 'flv',
            url,
            isLive: false,
            hasAudio: false,
            autoplay: true,
        }, {
            enableStashBuffer: true,
        })
        try {
            player.attachMediaElement(state.ref.current)
            player.load();
            player.play();
        } catch (e) {
            reject(e);
        }
        state.player = player;
        resolve(state)
    })

export const getDate = (currentTime) => `${currentTime.getFullYear()}-${(`0${currentTime.getMonth() + 1}`).slice(-2)}-${(`0${currentTime.getDate()}`).slice(-2)}`

export const getTime = (currentTime) => `${(`0${currentTime.getHours()}`).slice(-2)}:${(`0${currentTime.getMinutes()}`).slice(-2)}:${(`0${currentTime.getSeconds()}`).slice(-2)}`
