import React from "react";
import {connect} from "react-redux";
import reactImageSize from 'react-image-size';
import style from "./ModalAddPolygonInCam.module.scss"
import {worlds} from "../../../Utils/localization";
import {camDetectionConfigGet, camDetectionConfigSet, getCamGlobal} from "../../../api/state/cam/actions";
import ButtonAddCam from "../../Buttons/ButtonAddCam/ButtonAddCam";
import ButtonRectangle from "../../Buttons/ButtonRectangle/ButtonRectangle";
import iconClose from "../../../Assets/icons/cancel.svg"
import ButtonSquare from "../../Buttons/ButtonSquare/ButtonSquare";
import Preloader from "../../Preloader/Preloader";
import TextFieldM from "../../Inputs/TextFieldM";
import AutocompleteM from "../../Inputs/AutocompleteM";

const types = ['secondary','primary'];

class ModalAddPolygonInCam extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            config: {polygon: []},
            url: "",
            loadImage: false,
            md_type: types[0],
            width: 0,
            height: 0,
            camConfig: {},
        }
        this.ref = React.createRef()
    }

    async componentDidMount() {
        let url = (await getCamGlobal(this.props.cam.id))?.snapshot?.url
        const {host} = new URL(window.location.hostname && window.location.hostname !== "localhost" ? `http://${window.location.hostname}:4080/` : window.config.host);
        url = `${url.replace("localhost", host).replace(":4080", "")}?size=original`;

        try {
            const { width, height } = await reactImageSize(url);
            this.setState({width,height})
        } catch (e) {}

        this.setState({url})
        let config = (await camDetectionConfigGet(this.props.cam))?.config
        if (!config || !config.polygon) {
            config = {polygon: []};
        } else {
            for (const p in config.polygon) {
                const cords = config.polygon[p].split(" ")
                if (this.state.width && this.ref.current) {
                    for (const c in cords) {
                        const cordWithOutSpace = cords[c].split(",");
                        cordWithOutSpace[0] = Math.round((this.ref.current.clientWidth * cordWithOutSpace[0]) / this.state.width);
                        cordWithOutSpace[1] = Math.round((this.ref.current.clientHeight * cordWithOutSpace[1]) / this.state.height);
                        cords[c] = cordWithOutSpace.join(",")
                    }
                    config.polygon[p] = cords.join(" ")
                }
            }
            if (config.md_type) {
                this.setState({md_type: config.md_type})
            }
        }
        this.setState({config})
    }

    drawPolygon = (cord) => (
      <svg
        className={style.polygon}
        key={Math.random() + 10}
        ref={(ref) => {
            if (ref) {
                ref.setAttribute("cord",cord)
                new global.window.SVG(ref).size("100%", "100%")
                    .polygon(cord)
            }
        }}
      />
)

    hoverPolygonButton = (cord) => {
        for (let i = 0; i < this.ref.current.children.length; i++) {
            if (cord === this.ref.current.children[i].getAttribute('cord')) {
                this.ref.current.children[i].classList.add(style.polygonHover);
            }
        }
    }

    unHoverPolygonButton = (cord) => {
        for (let i = 0; i < this.ref.current.children.length; i++) {
            if (cord === this.ref.current.children[i].getAttribute('cord')) {
                this.ref.current.children[i].classList.remove(style.polygonHover);
            }
        }
    }

    createPolygon = () => {
        const polygon = document.createElement("svg")
        polygon.classList.add(style.polygon);
        polygon.style.zIndex = "99"
        polygon.style.border = "0.05vw solid red"
        this.ref.current.appendChild(polygon)
        const poly3 = new global.window.SVG(polygon).size("100%", "100%")
            .polygon().draw({snapToGrid: 20});
        poly3.on('drawstart', (e) => {
            document.addEventListener('keydown', (e) => {
                if (e.keyCode === 13) {
                    poly3.draw('done');
                    poly3.off('drawstart');
                }
                if (e.keyCode === 17) {
                    poly3.draw('param', 'snapToGrid', 20);
                }
            });

            document.addEventListener('keyup', (e) => {
                poly3.draw('param', 'snapToGrid', 1);
            });
        });

        poly3.on('drawstop', (event) => {
            let points = ""
            for (let i = 0; i < event.target.animatedPoints.length; i++) {
                points += `${event.target.animatedPoints[i].x},${event.target.animatedPoints[i].y} `
            }
            points = points.trim()
            const {config} = this.state;
            config.polygon.push(points)
            this.setState({config})
            if (this.ref.current) this.ref.current.removeChild(polygon)
        });
    }

    deletePolygon = (cord) => {
        const {config} = this.state;
        config.polygon = config.polygon.filter((i) => i !== cord)
        this.setState({config})
    }

    savePolygon = async () => {
        const config = {...this.state.config}
        config.polygon = [...this.state.config.polygon]
        if (this.state.width && this.ref.current) {
            for (const p in config.polygon) {
                const cords = config.polygon[p].split(" ")
                if (this.state.width && this.ref.current) {
                    for (const c in cords) {
                        const cordWithOutSpace = cords[c].split(",");
                        cordWithOutSpace[0] = Math.round((this.state.width * cordWithOutSpace[0]) / this.ref.current.clientWidth);
                        cordWithOutSpace[1] = Math.round((this.state.height * cordWithOutSpace[1]) / this.ref.current.clientHeight);
                        cords[c] = cordWithOutSpace.join(",")
                    }
                    config.polygon[p] = cords.join(" ")
                }
            }
        }
        config.md_type = this.state.md_type;
        await camDetectionConfigSet(this.props.cam,config)
    }

    resetPolygon = async () => {
        let config = (await camDetectionConfigGet(this.props.cam))?.config ?? {polygon: []}
        if (!config.polygon) {
            config = {polygon: []};
        }
        this.setState({config})
    }

    setSensivity = async (sens) => {
        const {config} = this.state;
        config.threshold = sens
        this.setState({config})
    }

    render() {
        return (
          <div className={style.modalAddPolygonInCam}>
            <div className={style.header}>
              {this.state.config.polygon.map((item,index) => (
                <ButtonRectangle onMouseLeave={() => this.unHoverPolygonButton(item)} onMouseMove={() => this.hoverPolygonButton(item)} style={{marginRight: "1vw",padding: "0 0.5vw", marginBottom: "0.5vw"}} action={() => {}} caption={`${worlds.zone} ${index + 1}`}>
                  <ButtonSquare style={{background: "none",marginLeft: "auto"}} fill="none" icon={iconClose} action={() => this.deletePolygon(item)} />
                </ButtonRectangle>
))}
              <ButtonAddCam style={{marginLeft: 0}} caption={worlds.addMotionZone} width="13vw" action={() => this.createPolygon()} />
            </div>
            <div className={style.screen} ref={this.ref}>
              {this.state.config.polygon.map((item) => this.drawPolygon(item))}
              <Preloader position="inherit" show={!this.state.loadImage} />
              <img style={{display: this.state.loadImage ? "block" : "none"}} src={this.state.url} onLoadCapture={() => this.setState({loadImage: true})} />
            </div>
            <div className={style.controls}>
              <ButtonRectangle caption={worlds.save} action={() => this.savePolygon()} />
              <div className={style.sensivity}>
                <label>{worlds.sensitivity}</label>
                <TextFieldM
                  value={this.state.config?.threshold ?? 0}
                  onChange={(e) => { this.setSensivity(e.target.value) }}
                  placeholder="0"
                  variant="outlined"
                  style={{width: "15vw", height: "2.5vw", margin: 0}}
                />
              </div>
              <div className={style.sensivity}>
                <label>{worlds.streamType}</label>
                <AutocompleteM
                  value={this.state.md_type}
                  onChange={(event, newValue) => {
                                this.setState({md_type: newValue})
                            }}
                  inputValue={this.state.md_type}
                  disableClearable
                  options={types}
                  style={{
width: "15vw", height: "1.8vw", margin: 0, marginTop: "-1vw",
}}
                />
              </div>
              <ButtonRectangle caption={worlds.reset} action={() => this.resetPolygon()} />
            </div>
          </div>
        )
    }
}

export default connect((state) => state,null)(ModalAddPolygonInCam)
