import React from "react";
import moment from "moment-timezone";
import style from "./LogItem.module.scss";
import {worlds} from "../../../../Utils/localization";
import {accountGet} from "../../../../api/state/account/actions";

class LogItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            account: "",
        }
    }

    async componentDidMount() {
        const accId = this.props.log?.event?.payload?.account;
        if (accId) this.setState({account: (await accountGet(accId)).username})
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.log?.event?.payload?.account !== prevProps.log?.event?.payload?.account) {
            if (this.props.log?.event?.payload?.account) this.setState({account: (await accountGet(this.props.log?.event?.payload?.account)).username})
            else this.setState({account: ""})
        }
    }

    render() {
        return (
          <div className={style.logItem}>
            <div className={style.log} style={{width: "10.31vw"}}>{moment(this.props.log.updated_at).format('DD.MM.YYYY HH:mm:ss')}</div>
            <div className={style.log} style={{width: "13.475vw"}}>{this.props.log.type}</div>
            <div className={style.log} style={{width: "20.29vw"}}>{this.props.log.event.name}</div>
            <div className={style.log} style={{width: "13.475vw"}}>{}</div>
            <div className={style.log} style={{width: "13.475vw"}}>{this.state.account}</div>
            <div className={style.log} style={{width: "6.198vw"}}>
              {this.props.log.level === "warning" && <span className={style.warning}>{worlds.warning}</span>}
              {this.props.log.level === "info" && <span className={style.info}>Info</span>}
              {this.props.log.level === "error" && <span className={style.error}>{worlds.error}</span>}
            </div>
          </div>
        )
    }
}

export default LogItem;
