import React from "react";
import {withRouter} from "react-router";
import style from "../SettingsMenu.module.scss";

class Controls extends React.Component {
    constructor(props) {
        super(props);
    }

    onClick = () => {
        if (!this.props.close) {
            if (this.props.href) this.props.history.push(this.props.href)
            if (this.props.action) this.props.action()
        } else {
            this.props.close(this.props.caption);
        }
    }

    render() {
        let active = false;
        if (this.props.location.pathname === this.props.href) {
            active = true;
        }
        return (
          <div onClick={() => this.onClick()} className={`${style.controls} ${active && style.controlsActive} ${this.props.overflow && style.close}`}>
            {this.props.horizontal && <div className={style.dotsHor} />}
            {this.props.vertical && <div style={{height: this.props.count && `${this.props.count * 2.41}vw`}} className={style.dots} />}
            <div className={style.icon} style={{backgroundImage: `url(${this.props.icon})`}} />
            <div className={style.caption}>{this.props.caption}</div>
          </div>
        )
    }
}

export default withRouter(Controls);
