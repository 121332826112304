import React, {useCallback, useRef, useState} from "react";
import {useDrag} from "react-dnd";
import {plainToClass} from "class-transformer";
import {withRouter} from "react-router";
import style from "./Camera.module.scss";
import Cam from "../../../../api/models/Cam";
import PopoverContext from "../../../../Components/Popovers/PopoverContext/PopoverContext";
import PopoverMenuItem from "../PopoverMenuItem/PopoverMenuItem";
import {showModalDeleteCam} from "../../../../Components/Modals/actions";
import {worlds} from "../../../../Utils/localization";
import iconEdit from "../../../../Assets/icons/edit.svg";
import iconDelete from "../../../../Assets/icons/trash.svg";

const ItemTypes = {
    CT: "ct",
}

const Camera = (props) => {
    const [display,setDisplay] = useState("block")
    const item = plainToClass(Cam,props.item);
    const ref = useRef()
    const [showPopup,setShowPopup] = useState(false)
    const [{ isDragging }, drag] = useDrag({
        item: { item , type: ItemTypes.CT , taxa: props.taxa},
        begin: (monitor) => {
            setDisplay("none")
        },
        end: (item, monitor) => {
            setDisplay("block")
            const dropResult = monitor.getDropResult()
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    })
    const opacity = isDragging ? 0.1 : 1

    const handler = useCallback(() => {
        setShowPopup((prevState) => { prevState = false; return prevState })
    }, [])

    const leftMouseDown = (el) => {
        handler()
        const buttonPressed = el.button;
        if (buttonPressed === 2) {
            setShowPopup((prevState) => {
                prevState = true;
                return {...prevState}
            })
        }
    }

    return (
      <div style={{width: display === "none" && "10vw",height: "1.5vw"}} ref={drag}>
        <PopoverContext
          close={() => handler()}
          anchor={ref.current}
          position="left"
          show={showPopup}
          style={{marginTop: "1vw"}}
        >
          <div className={style.menu}>
            <PopoverMenuItem action={() => props.history.push(`/editCam/${props.item.id}`)} caption={worlds.edit} icon={iconEdit} />
            <PopoverMenuItem action={() => showModalDeleteCam(props.item,props.parent?.id)} caption={worlds.delete} icon={iconDelete} />
          </div>
        </PopoverContext>
        <div ref={ref} className={style.cam} onMouseDown={leftMouseDown}>
          {!props.noParents && <div className={style.dotsHor} />}
          {!props.noParents && <div style={{height: props.last && "1.15vw"}} className={style.dots} />}
          <div className={style.icon} />
          <div className={style.caption}>{props.item.name}</div>
          <div style={{display }} className={style.background} />
        </div>
      </div>
    )
}

export default withRouter(Camera);
