import React from "react";
import style from "./TimeLine.module.scss"
import TimeLineHour from "./TimeLineHour/TimeLineHour";
import TimeLineArchive from "./TimeLineArchive/TimeLineArchive";
import TimeLineEvent from "./TimeLineEvent/TimeLineEvent";

export default class TimeLine extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            position: 0,
            hoverTime: "",
            hoverPosition: 0,
        }

        this.timeLine = React.createRef()
    }

    componentDidMount() {
        this.setPositionTrackInTime();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.time !== this.props.time) {
            this.setPositionTrackInTime();
        }
    }

    changePosition = (position) => {
        this.setState({position})
    }

    onClick = (el) => {
        const time = this.getTimeMouse(el);
        this.props.playArchive(time)
    }

    onMouseMove = async (el) => new Promise((resolve, reject) => {
            const time = this.getTimeMouse(el);
            if (time) this.setState({hoverTime: `${(`0${time.hour}`).slice(-2)}:${(`0${time.minute}`).slice(-2)}`})
        })

    onMouseLeave = (el) => {
        this.setState({hoverTime: ""})
    }

    setPositionTrackInTime = () => {
        const timeLine = (this.timeLine.current.offsetWidth / (24 * 60)).toFixed(3);
        const currentTime = {hour: this.props.time.getHours(),minute: this.props.time.getMinutes()};
        const hour = (currentTime.hour * 1) > 0 ? currentTime.hour * 60 : 0;
        const time = (timeLine * (hour + (currentTime.minute * 1))).toFixed(0);
        this.changePosition(time)
    }

    getTimeMouse = (el) => {
        const targetCoords = el.currentTarget.offsetParent.getBoundingClientRect()
        const xCoord = el.clientX - targetCoords.left;
        if (xCoord > 0 && xCoord < targetCoords.width) this.setState({hoverPosition: xCoord})
        const time = (targetCoords.width / (24 * 60 - 1)).toFixed(3);
        if (time < 0.1) return
        const currentMinute = Math.trunc(xCoord / time);
        if (currentMinute < 0) return
        const hour = currentMinute > 60 ? Math.trunc(currentMinute / 60) : 0;
        const minute = currentMinute > 60 ? currentMinute % 60 : currentMinute;
        return {hour,minute}
    }

    sortDate = (data) => {
        const timeString = new Date(this.props.time).toDateString();
        const startTimeString = new Date(data.start).toDateString()
        const endTimeString = new Date(data.end).toDateString()
        const isCurrentDateStart = timeString === startTimeString
        const isCurrentDateEnd = timeString === endTimeString
        const isAllDay = +new Date(timeString) > +new Date(startTimeString) && +new Date(timeString) < +new Date(endTimeString)
        return isCurrentDateStart || isCurrentDateEnd || isAllDay
    }

    render() {
        return (
          <div className={style.timeLine}>
            <div className={style.timeHour}>
              {
                    Array.from(Array(12)).map((_,i) => <TimeLineHour key={i} hour={i * 2} />)
                }
            </div>
            <div ref={this.timeLine} onMouseLeave={this.onMouseLeave} onMouseMove={this.onMouseMove} className={style.archive}>
              <div style={{marginLeft: `${this.state.position}px`}} className={style.track} />
              <div style={{marginLeft: `${this.state.hoverPosition}px`}} className={style.showTime}>
                {this.state.hoverTime
                        && (
                        <>
                          <div className={style.showTimeSeparator} />
                          <div className={style.showTimeView}>
                            {this.state.hoverTime}
                          </div>
                        </>
)}
              </div>
              <div className={style.archiveBlocks}>
                {this.props.detectionsData && this.props.detectionsData.filter((i) => i.start && i.end && this.sortDate(i)).map((item,i) => <TimeLineEvent time={this.props.time} key={i} onClick={this.onClick} archive={item} parent={this.timeLine.current} />)}
              </div>
              <div className={style.archiveBlocks}>
                {this.props.archiveVideo && this.props.archiveVideo.filter((i) => i.start && i.end && this.sortDate(i)).map((item,i) => <TimeLineArchive time={this.props.time} key={i} onClick={this.onClick} archive={item} parent={this.timeLine.current} />)}
              </div>
            </div>
          </div>
        )
    }
}
